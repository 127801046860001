import { benefits } from '../../constants';

export const upgradeBenefitGroups = {
  identity: [benefits.fraudReport, benefits.emailAlerts, benefits.webMonitoring, benefits.fraudSupport],
  identityComplimentary: [
    benefits.fraudReport,
    benefits.emailAlerts,
    benefits.webMonitoring,
    benefits.ukBasedSubscriptionSupport,
  ],
  credit_expert: [
    benefits.dailyScore,
    benefits.allAlerts,
    benefits.dailyReport,
    benefits.phoneSupport,
    benefits.influencers,
    benefits.webMonitoring,
    benefits.simulator,
    benefits.boost,
  ],
  basic: [benefits.monthlyScore, benefits.productComparisonService, benefits.boost],
};

export const downgradeBenefitGroups = {
  credit_expert: [
    benefits.dailyScore,
    benefits.influencers,
    benefits.ukBasedSupport,
    benefits.dailyReport,
    benefits.creditAlerts,
    benefits.simulator,
  ],
  basic: [
    benefits.monthlyScore,
    benefits.brokerService,
    benefits.boost,
    benefits.productComparisonService,
    benefits.eligibilityUpdates,
  ],
};

export const freeAccountBenefits = {
  basic: [
    benefits.monthlyScore,
    benefits.brokerService,
    benefits.eligibilityRatings,
    benefits.eligibilityUpdates,
    benefits.boost,
  ],
  creditExpertToFree: [
    benefits.dailyScoreUpdate,
    benefits.ScoreUpdateAlerts,
    benefits.personalisedTools,
    benefits.FraudAlert,
    benefits.creditReport,
    benefits.customerSupport,
  ],
};
