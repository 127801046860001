import styled from 'styled-components';

export const StyledCard = styled.div`
  border-radius: 5px;
  box-shadow: 0 10px 20px 0 #00000020;
  padding: 16px;
  margin-top: 16px;
  background-color: white;
`;

export const StyledReviewBoxContainer = styled.div`
  margin: 0;
  padding-top: 16px;
  color: #63666a;
  font-weight: 300;
  line-height: 1.5;

  ul {
    list-style-type: disc;
    margin: 0;
    padding-left: 20px;
  }
  li {
    padding-left: 16px;

    &:not(:last-of-type) {
      margin-bottom: 20px;
    }
  }
`;

export const StyledSubmitButton = styled.button`
  background-color: #e63888;
  border: none;
  margin: 16px auto 0;
  padding: 14px 16px;
  font-size: 16px;
  font-weight: 200;
  line-height: 1.25;
  color: white;
  display: block;
  width: 100%;
  cursor: pointer;
  border-radius: 4px;
  transition: box-shadow 250ms ease-in-out, background-color 250ms ease-in-out;

  &:hover,
  &:active,
  &:focus {
    background-color: #ce3079;
    outline: none;
    box-shadow: 0 0 0 4px #ce307966;
  }
`;
