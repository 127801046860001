import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';

import { setAutomationElement } from '@experian-uk/corvetteuk-common-ui';

import { CardBenefits, CardContainer, CardContent, PricePoint, ProductFactSheet } from './productPanel.styles';
import {
  ActionButton,
  ButtonContainer,
  HeaderNumber,
  OptionsCardHeader,
  OptionsCardHeaderContent,
  ActionButtonMessage,
  UpgradeBlockedExplanation,
  UnableToSwitchErrorMessage,
} from './productOptionsCards.styles';
import BlueTick from './blueTick';
import { DownloadIcon } from '../Icons/DownloadIcon';
import updateSession from '../../actions/session/update';
import syncSession from '../../actions/session/sync';
import selectOffer from '../../actions/offers/select';
import tooltipForBenefit from './tooltipForBenefit';
import { products } from '../../constants';
import { clickECDEvent } from '../../pages/home/homePageEcd2';
import { ModalUpsell } from '../../components/ModalUpsell';
import sendReward from '../../actions/conductrics/sendReward';
import GOALS from '../../constants/abTestGoals';
import abTests from '../../constants/abtests';

@connect(
  state => ({
    error: state.error,
    expiryDate: state.billing.endDate,
    freeOffer: state.offers.basic,
    isCoolingOffEligible: (state.subscriptions.current || {}).isCoolingOffEligible,
    conductrics: state.conductrics,
  }),
  dispatch =>
    bindActionCreators(
      {
        selectOffer,
        push,
        syncSession,
        updateSession,
        sendReward,
      },
      dispatch
    )
)
class ProductOptionCard extends React.Component {
  static propTypes = {
    headerNumber: PropTypes.number.isRequired,
    currentSubscription: PropTypes.string.isRequired,
    fewerPanels: PropTypes.bool.isRequired,
    offer: PropTypes.shape({
      copyLink: PropTypes.string.isRequired,
      benefitCopy: PropTypes.arrayOf(PropTypes.string).isRequired,
      titleText: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
      priceSummary: PropTypes.string.isRequired,
      productName: PropTypes.string.isRequired,
      family: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }).isRequired,
    isIdCeFreeTrial: PropTypes.bool.isRequired,
    switchPermitted: PropTypes.bool.isRequired,
    selectOffer: PropTypes.func.isRequired,
    updateSession: PropTypes.func.isRequired,
    syncSession: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    sendReward: PropTypes.func.isRequired,
    conductrics: PropTypes.shape({
      error: PropTypes.bool,
      fetching: PropTypes.bool,
      sels: PropTypes.objectOf(PropTypes.string),
    }),
  };

  static defaultProps = {
    conductrics: {
      error: null,
      fetching: false,
      sels: {},
    },
  };

  state = {
    modalUpsellShowing: false,
  };

  hideModal = () => {
    this.setState(prevState => ({
      modalUpsellShowing: !prevState.modalUpsellShowing,
    }));
  };

  goToSwitch = async () => {
    const { offer } = this.props;
    const identityOfferId = offer.id;
    const identityOfferFamily = offer.family;

    await this.props.selectOffer(identityOfferId, identityOfferFamily);

    this.props.updateSession({
      selectedOfferId: identityOfferId,
      selectedOfferFamily: identityOfferFamily,
    });
    await this.props.syncSession();

    this.props.push('/switch');
  };

  render() {
    const { currentSubscription, headerNumber, offer, fewerPanels, isIdCeFreeTrial, switchPermitted } = this.props;
    const { modalUpsellShowing } = this.state;
    const { sels: selections } = this.props.conductrics || {};
    const homeAATestVariant = selections ? selections[abTests.PRODMOVE_AA_TEST_HP?.apiCode] : null;
    const gridArea = headerNumber !== 1 || fewerPanels ? 'right' : 'middle';
    const isValidVariant = ['A', 'B'].includes(homeAATestVariant);

    const linkToIdentitySwitch = async e => {
      e.preventDefault();

      if (offer.family === products.credit_expert) {
        clickECDEvent('prodmove_switch_to_creditExpert');

        this.setState({
          modalUpsellShowing: true,
        });

        return;
      }

      if (offer.family === products.identity) {
        clickECDEvent('prodmove_switch_to_ID');
        if (isValidVariant) {
          this.props.sendReward(GOALS.SWITCH_TO_IDPLUS_ACCOUNT);
        }
      }

      if (offer.family === products.basic) {
        clickECDEvent('prodmove_switch_to_free');
        if (isValidVariant) {
          this.props.sendReward(GOALS.SWITCH_TO_FREE_ACCOUNT);
        }
      }

      await this.goToSwitch();
    };

    const getCardActionButton = () => {
      if (isIdCeFreeTrial) {
        return (
          <UpgradeBlockedExplanation {...setAutomationElement('blocked-upgrade-in-trial')}>
            You cannot upgrade as you currently have all CreditExpert features. You will be able to upgrade after your
            trial has ended
          </UpgradeBlockedExplanation>
        );
      }

      if (!switchPermitted) {
        return (
          <React.Fragment>
            <UnableToSwitchErrorMessage {...setAutomationElement('unable-to-switch')}>
              Please try again later or contact us
            </UnableToSwitchErrorMessage>
            <ActionButton {...setAutomationElement('panel-button')} disabled>
              Switch to {offer.productName}
            </ActionButton>
          </React.Fragment>
        );
      }

      return (
        <React.Fragment>
          <ActionButtonMessage>Cancel your {currentSubscription} subscription and</ActionButtonMessage>
          <ActionButton {...setAutomationElement('panel-button')} onClick={linkToIdentitySwitch}>
            Switch to {offer.productName}
          </ActionButton>
        </React.Fragment>
      );
    };

    return (
      <CardContainer gridLocation={gridArea} {...setAutomationElement(`panel-${offer.family}`)}>
        <OptionsCardHeader>
          {!fewerPanels && <HeaderNumber>{headerNumber}</HeaderNumber>}
          <OptionsCardHeaderContent>
            <h3>{offer.titleText}</h3>
            <hr />
            <PricePoint {...setAutomationElement(`${offer.family}-price-summary`)} color="#002556">
              {offer.priceSummary || <React.Fragment>&nbsp;</React.Fragment>}
            </PricePoint>
          </OptionsCardHeaderContent>
        </OptionsCardHeader>
        <CardContent>
          <CardBenefits family={offer.family}>
            <h3 {...setAutomationElement('benefits-included-title')}>Benefits include:</h3>
            <ul>
              {offer.benefitCopy.map(txt => (
                <li key={txt}>
                  <BlueTick />
                  {txt}
                  {tooltipForBenefit(txt)}
                </li>
              ))}
            </ul>
          </CardBenefits>
        </CardContent>
        <ButtonContainer>{getCardActionButton()}</ButtonContainer>
        <ProductFactSheet>
          <DownloadIcon />
          <a {...setAutomationElement(`${offer.family}-panel-product-factsheet-link`)} href={offer.copyLink}>
            View {offer.productName} product fact sheet
          </a>
        </ProductFactSheet>
        <ModalUpsell isShowing={modalUpsellShowing} toggleHide={this.hideModal} credExClick={this.goToSwitch} />
      </CardContainer>
    );
  }
}

export default ProductOptionCard;
