import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { setAutomationElement } from '@experian-uk/corvetteuk-common-ui';

import flagChallengeFinished from '../../../actions/billing/flagChallengeFinished';
import { CardFormContainer3ds, IFrame } from '../../../pages/home/index.styles';
import getEnv from '../../Context/env/getEnv';
import withEnv from '../../Context/env/decorator';
import { DONE_MESSAGE } from '../../../pages/challenge-finished';

@withEnv
@connect(
  state => ({
    billing: state.billing,
  }),
  dispatch =>
    bindActionCreators(
      {
        flagChallengeFinished,
      },
      dispatch
    )
)
export default class AddCardChallenge extends React.Component {
  static propTypes = {
    challengeUrl: PropTypes.string.isRequired,
    flagChallengeFinished: PropTypes.func.isRequired,
    isApplePay: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    this.iframeRef = React.createRef();
    this.handleMessageFromIframe = this.handleMessageFromIframe.bind(this);
  }

  state = {
    postMessageReceived: false,
  };

  componentDidMount() {
    window.addEventListener('message', this.handleMessageFromIframe);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleMessageFromIframe);
  }

  handleMessageFromIframe(event) {
    if (this.state.postMessageReceived) {
      return;
    }

    const host = getEnv().REACT_APP_PUBLIC_HOST;

    if (!event || event.origin !== host) {
      return;
    }

    if (event.source !== this.iframeRef.current.contentWindow) {
      return;
    }

    if (event.data !== DONE_MESSAGE) {
      return;
    }

    this.setState({ postMessageReceived: true });
    this.props.flagChallengeFinished();
  }

  render() {
    return (
      <CardFormContainer3ds isApplePay={this.props.isApplePay}>
        <section>
          <IFrame ref={this.iframeRef} {...setAutomationElement('newCardChallenge3ds')} src={this.props.challengeUrl} />
        </section>
      </CardFormContainer3ds>
    );
  }
}
