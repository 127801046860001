import React from 'react';
import PropTypes from 'prop-types';
import { EnvContext, envPropTypes, getEnv } from '.';

export default class EnvProvider extends React.Component {
  static propTypes = {
    env: envPropTypes,
    children: PropTypes.node,
  };

  static defaultProps = {
    env: null,
    children: null,
  };

  state = {
    env: {},
  };

  componentDidMount() {
    const env = getEnv();

    this.setState({ env });
  }

  render() {
    return <EnvContext.Provider value={this.props.env || this.state.env}>{this.props.children}</EnvContext.Provider>;
  }
}
