import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumbs } from '@experian-uk/corvetteuk-common-ui';
import { Container } from './index.styles';
import ContactUs from './contactUs';
import { getHomeUrl } from '../../helpers';

const TopContainer = ({ steps, customerNumber, currentSubscription }) => (
  <Container>
    <Breadcrumbs steps={steps} home={getHomeUrl(currentSubscription)} />
    <ContactUs customerNumber={customerNumber} />
  </Container>
);

TopContainer.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      text: PropTypes.string,
    })
  ).isRequired,
  customerNumber: PropTypes.string,
  currentSubscription: PropTypes.string,
};

TopContainer.defaultProps = {
  customerNumber: '',
  currentSubscription: '',
};

export default TopContainer;
