import fetch from '../fetch';

export const FETCH_PRODUCT_DETAILS = 'FETCH_PRODUCT_DETAILS';
export const PRODUCT_DETAILS_FETCHED = 'PRODUCT_DETAILS_FETCHED';

export default () => async dispatch => {
  dispatch({ type: FETCH_PRODUCT_DETAILS });

  try {
    const piDataResponse = await dispatch(fetch('/productidentification', {}, false, null, true));

    const payload = piDataResponse;

    return dispatch({
      type: PRODUCT_DETAILS_FETCHED,
      payload,
    });
  } catch (e) {
    return dispatch({
      type: PRODUCT_DETAILS_FETCHED,
      payload: new Error('Error in fetch from Product Identification service'),
      error: true,
      meta: {
        critical: false,
      },
    });
  }
};
