import { cta, errorMessages } from '../../constants';
import { getDynamicString } from '../../helpers';

const messages = {
  addPaymentMethod: {
    heading: getDynamicString('billing.changeFailure.intro', ['card details']),
    messages: [getDynamicString('billing.changeFailure.subtitle', ['card details'])],
  },
  addDDPaymentMethod: {
    heading: getDynamicString('billing.changeFailure.intro', ['subscriptions']),
    messages: [getDynamicString('billing.changeFailure.subtitle', ['subscription'])],
  },
  authorizePaymentMethod: {
    heading: getDynamicString('changeFailure.intro', ['subscription']),
    messages: [getDynamicString('changeFailure.default', ['subscription'])],
    buttonPath: '/',
    buttonText: `${cta.goBack} ${cta.subscriptionsPage}`,
  },
  getPaymentMethods: {
    heading: errorMessages.billing.savedCard,
    messages: [errorMessages.tryAgain],
  },
  proRata: {
    heading: getDynamicString('changeFailure.intro', ['subscription']),
    messages: [errorMessages.technicalIssues],
  },
  validation: {
    heading: errorMessages.billing.invalidCard,
    messages: [errorMessages.billing.tryAgain],
  },
  ddValidation: {
    heading: errorMessages.billing.invalidDD,
    messages: [errorMessages.billing.tryAgain],
  },
  threeDSValidationError: {
    heading: errorMessages.threeDSFailure,
    messages: null,
  },
};

const cardAuthorization = (statusCode, defaultMessage = 'addPaymentMethod') =>
  [400, 402].includes(statusCode) ? messages.validation : messages[defaultMessage];
const ddAuthorization = (statusCode, defaultMessage = 'addDDPaymentMethod') =>
  [400, 402].includes(statusCode) ? messages.ddValidation : messages[defaultMessage];

export default {
  ...messages,
  cardAuthorization,
  ddAuthorization,
};
