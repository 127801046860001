import flatten from 'lodash/flatten';
import Cookies from 'js-cookie';

import setCurrentUser from './setCurrentUser';
import mockTokens from '../../mockTokens.json';
import { getEnv } from '../../components/Context/env';

export default () => (dispatch, getState) => {
  const env = getEnv();
  const useMocks = env.REACT_APP_ENV === 'local' && !window.Cypress?.isIntegrationTest;
  const mockMatcher = /_token$/;
  const state = getState();
  let cookieToken = state.auth?.token || null;

  if (window.Cypress?.isIntegrationTest || env.REACT_APP_ENV === 'local') {
    cookieToken = Cookies.get('authId');
  }

  if (!cookieToken) {
    return null;
  }

  if (useMocks && mockMatcher.test(cookieToken)) {
    const mockUser = flatten(
      Object.keys(mockTokens).map(subType => [mockTokens[subType].trial, mockTokens[subType].notrial])
    )
      .filter(x => x)
      .find(user => user.token === cookieToken);

    const mockData = {
      subscriptionType: mockUser.subscriptionType,
      isTrial: mockUser.isTrial,
    };

    return dispatch(setCurrentUser(cookieToken, undefined, mockData));
  }

  return dispatch(setCurrentUser(cookieToken));
};
