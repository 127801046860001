import styled, { keyframes } from 'styled-components';
import { rem } from 'polished';

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Spinner = styled.div`
  animation: ${spin} 2s infinite linear;
  border: 0.5rem solid ${props => props.theme.colors.experianLightGrey};
  border-radius: 50%;
  border-top: 0.5rem solid ${props => props.theme.colors.experianPurple};
  height: 3.75rem;
  margin: auto;
  width: 3.75rem;
  z-index: 100;
`;

Spinner.displayName = 'Spinner';

export { Spinner };

const SpinnerContainer = styled.div`
  height: 100%;
  left: 0;
  position: fixed;
  top: calc(50% - 3.75rem);
  width: 100%;
  z-index: 90;
`;

SpinnerContainer.displayName = 'SpinnerContainer';

export { SpinnerContainer };

const LoadingBackground = styled.div`
  background-color: ${props => props.theme.colors.baseWhite};
  height: 100%;
  left: 0;
  opacity: 0.5;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 90;
`;

LoadingBackground.displayName = 'LoadingBackground';

export { LoadingBackground };

export const SolidLoadingBackground = styled(LoadingBackground)`
  opacity: 1;
`;

export const Text = styled.div`
  text-align: center;
  margin-top: ${rem(25)};
`;
