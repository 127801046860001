import fetch from '../fetch';

import { defined } from '../../helpers/defined';

import { products } from '../../constants';

export const FETCH_OTHER_PRODUCTS = 'FETCH_OTHER_PRODUCTS';
export const OTHER_PAID_PRODUCTS_FOUND = 'OTHER_PAID_PRODUCTS_FOUND';
export const OTHER_PRODUCTS_FETCH_FAILED = 'OTHER_PRODUCTS_FETCH_FAILED';
export const NO_OTHER_PAID_PRODUCTS_FOUND = 'NO_OTHER_PAID_PRODUCTS_FOUND';

export const endpoint = '/subscriptions/productcheck';
export const errorMessage = `Could not fetch ${endpoint}`;
const paidSubscriptions = [products.credit_expert, products.identity, products.breach, products.breachPlus];

export default personalInfo => async dispatch => {
  dispatch({ type: FETCH_OTHER_PRODUCTS });

  const fetched = {};

  if (!defined('object', personalInfo)) {
    return Promise.reject(new Error('Personal details must be supplied'));
  }

  const request = {
    method: 'POST',
    body: {
      personalInfo,
    },
  };

  try {
    const productCheckResponse = await dispatch(fetch(endpoint, request));
    const [otherProducts] = productCheckResponse.data;

    const { subscriptions } = otherProducts;
    const hasPaidSubscription =
      subscriptions === null
        ? false
        : subscriptions.some(({ active, product } = {}) => active && paidSubscriptions.includes(product));

    fetched.payload = { otherProducts: subscriptions };
    fetched.type = hasPaidSubscription ? OTHER_PAID_PRODUCTS_FOUND : NO_OTHER_PAID_PRODUCTS_FOUND;
    fetched.error = false;
  } catch (error) {
    fetched.error = true;
    fetched.payload = errorMessage;
    fetched.type = OTHER_PRODUCTS_FETCH_FAILED;
  }
  return dispatch(fetched);
};
