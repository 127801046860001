import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { AnimatedLogo } from '@experian-uk/corvetteuk-common-ui';
import { activationStatuses, errorCodes, products, splitsList } from '../../constants';
import setupActionRetry from '../../actions/actionRetry';
import fulfillSubscription from '../../actions/fulfillments/fulfill';
import getOtherProducts from '../../actions/productCheck/getOtherProducts';
import updateActiveStatus from '../../actions/customers/updateActiveStatus';
import validateVoucher from '../../actions/voucherValidation';
import getEnv from '../../components/Context/env/getEnv';
import getCustomer from '../../actions/auth/getCustomer';
import getAllSubscriptions from '../../actions/subscriptions/getAll';
import { subscriptionSchema } from '../../schemas';
import { getSplitStatus } from '../../reducers/split';
import offerConsts from '../../constants/offers';

const { defaultBreachOfferId, defaultBreachPlusOfferId } = offerConsts;

const Container = styled.div`
  align-items: center;
  display: flex;
  height: 100vh;
  overflow: hidden;
  justify-content: center;
  width: 100vw;
`;

@connect(
  state => ({
    fulfillmentSucceeded: state.fulfillments.success,
    currentSubscription: state.subscriptions.current,
    customer: state.auth.currentUser,
    hasOtherPaidProductsFetching: state.productCheck.fetching,
    hasOtherPaidProducts: state.productCheck.paid,
    hasOtherPaidProductsError: state.productCheck.error,
    isStatusActiveFetching: state.customers.fetching,
    isStatusActive: !state.customers.fetching && state.customers.statusUpdated,
    isStatusActiveError: state.customers.error,
    query: state.router.location.query,
    splits: getSplitStatus(state.split, [splitsList.breachPlus]),
    splitsFetched: state.split.fetched,
    subscriptionFetched: state.subscriptions.fetched || state.subscriptions.error,
    validVoucher: !state.voucherValidationResult.fetching && !state.voucherValidationResult.error,
    voucherErrorCode: state.voucherValidationResult.errorCode,
  }),
  dispatch =>
    bindActionCreators(
      {
        getCustomer,
        getAllSubscriptions,
        fulfillSubscription,
        getOtherProducts,
        updateActiveStatus,
        push,
        setupActionRetry,
        validateVoucher,
      },
      dispatch
    )
)
export default class Activation extends React.PureComponent {
  static propTypes = {
    currentSubscription: PropTypes.shape(subscriptionSchema),
    customer: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      dob: PropTypes.string,
      mothersMaidenName: PropTypes.string,
      customerId: PropTypes.string,
    }),
    isStatusActive: PropTypes.bool,
    isStatusActiveError: PropTypes.bool,
    isStatusActiveFetching: PropTypes.bool,
    fulfillmentSucceeded: PropTypes.bool,
    fulfillSubscription: PropTypes.func.isRequired,
    getCustomer: PropTypes.func.isRequired,
    getAllSubscriptions: PropTypes.func.isRequired,
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string,
    }).isRequired,
    hasOtherPaidProducts: PropTypes.bool,
    hasOtherPaidProductsError: PropTypes.bool,
    hasOtherPaidProductsFetching: PropTypes.bool,
    getOtherProducts: PropTypes.func.isRequired,
    updateActiveStatus: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    query: PropTypes.shape({
      offerId: PropTypes.string,
      voucherCode: PropTypes.string,
      status: PropTypes.string,
    }),
    setupActionRetry: PropTypes.func.isRequired,
    splits: PropTypes.shape({}),
    splitsFetched: PropTypes.bool,
    subscriptionFetched: PropTypes.bool,
    validateVoucher: PropTypes.func.isRequired,
    validVoucher: PropTypes.bool,
    voucherErrorCode: PropTypes.oneOf([errorCodes.attributionError, errorCodes.serviceUnavailable]),
  };

  static defaultProps = {
    currentSubscription: {},
    customer: {},
    isStatusActive: null,
    isStatusActiveError: null,
    isStatusActiveFetching: null,
    fulfillmentSucceeded: false,
    hasOtherPaidProducts: null,
    hasOtherPaidProductsError: null,
    hasOtherPaidProductsFetching: null,
    query: {},
    splits: {},
    splitsFetched: false,
    subscriptionFetched: false,
    validVoucher: false,
    voucherErrorCode: null,
  };

  state = {
    processing: false,
    errorInProgress: false,
  };

  componentDidMount() {
    this.props.getCustomer();
    this.props.getAllSubscriptions();
    this.initialize();
  }

  async componentDidUpdate() {
    await this.initialize();
  }

  handleError = async errorCode => {
    if (!this.state.errorInProgress) {
      this.setState({ errorInProgress: true });
      const errorPath = Object.values(errorCodes).includes(errorCode) ? errorCode : '';
      if (errorCode === errorCodes.serviceUnavailable) {
        await this.props.setupActionRetry({ path: `${this.props.location.pathname}${this.props.location.search}` });
      }
      this.props.push(`/error/${errorPath}`);
    }
  };

  initialize = async () => {
    if (this.state.processing) {
      return;
    }

    const { offerId, status, voucherCode } = this.props.query;
    const missingVoucherCode = !voucherCode || ['undefined', 'null'].includes(voucherCode);
    const customerDataLoaded = !!this.props.customer.customerId;
    const { firstName, lastName, dob, mothersMaidenName } = this.props.customer;
    const personalInfo = {
      firstName,
      lastName,
      dob,
      mothersMaidenName,
    };

    if (!customerDataLoaded) {
      return;
    }

    if (!this.props.splitsFetched || !this.props.subscriptionFetched) {
      return;
    }

    if (
      [
        activationStatuses.hasDuplicateAccount,
        activationStatuses.hasExistingSubscription,
        activationStatuses.shouldFulfill,
      ].includes(status)
    ) {
      if (this.props.hasOtherPaidProductsFetching) {
        return;
      }
      if (this.props.hasOtherPaidProductsError) {
        await this.handleError(errorCodes.serviceUnavailable);
        return;
      }
      if (this.props.hasOtherPaidProducts === null) {
        await this.props.getOtherProducts(personalInfo);
        return;
      }

      if (this.props.hasOtherPaidProducts) {
        await this.handleError(errorCodes.activation);
        return;
      }
      if (status === activationStatuses.hasDuplicateAccount) {
        if (this.props.isStatusActiveFetching) {
          return;
        }

        if (this.props.isStatusActiveError) {
          await this.handleError(errorCodes.serviceUnavailable);
          return;
        }

        if (this.props.isStatusActive == null) {
          await this.props.updateActiveStatus();
          return;
        }
      }
    }

    this.setState({
      processing: true,
    });

    if (status === activationStatuses.hasExistingSubscription) {
      if (!missingVoucherCode && status === activationStatuses.hasExistingSubscription) {
        const breachPlusEnabledSubscriptions = [products.basic];
        if (
          breachPlusEnabledSubscriptions.includes(this.props.currentSubscription.family) &&
          this.props.splits[splitsList.breachPlus]
        ) {
          this.props.push(
            `/switch?voucherCode=${voucherCode}&offerId=${defaultBreachPlusOfferId}&switchingTo=${products.breachPlus}`
          );
          return;
        }
      }
    }

    if (
      missingVoucherCode ||
      ![activationStatuses.hasDuplicateAccount, activationStatuses.shouldFulfill].includes(status)
    ) {
      const errorCode =
        !missingVoucherCode && Object.values(activationStatuses).includes(status) ? errorCodes.activation : '';
      await this.handleError(errorCode);
    } else {
      await this.props.validateVoucher(voucherCode);
      if (this.props.validVoucher) {
        await this.props.fulfillSubscription(offerId || defaultBreachOfferId);
        if (!this.props.fulfillmentSucceeded) {
          await this.handleError(errorCodes.serviceUnavailable);
        } else {
          window.location = `${getEnv().REACT_APP_IDENTITY_URL}?newSignUp=1`;
        }
      } else {
        await this.handleError(this.props.voucherErrorCode);
      }
    }
  };

  render() {
    return (
      <Container>
        <AnimatedLogo />
      </Container>
    );
  }
}
