import {
  COULD_NOT_VALIDATE_VOUCHER,
  INVALID_VOUCHER,
  VALIDATE_VOUCHER,
  VALID_VOUCHER,
} from '../actions/voucherValidation';
import { errorCodes } from '../constants';

export const defaultState = {
  ecdFailureReason: undefined,
  error: null,
  errorCode: null,
  fetching: false,
  voucherCode: '',
  voucherEndDate: undefined,
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case COULD_NOT_VALIDATE_VOUCHER:
      return {
        ...state,
        ecdFailureReason: action.payload.ecdFailureReason,
        error: action.payload.reason,
        errorCode: errorCodes.serviceUnavailable,
        fetching: false,
        voucherCode: '',
      };
    case INVALID_VOUCHER:
      return {
        ...state,
        ecdFailureReason: action.payload.ecdFailureReason,
        error: action.payload.reason,
        errorCode: errorCodes.attributionError,
        fetching: false,
        voucherCode: '',
      };
    case VALIDATE_VOUCHER:
      return {
        ...state,
        fetching: true,
        error: action.payload,
      };
    case VALID_VOUCHER:
      return {
        ...state,
        error: null,
        fetching: false,
        voucherCode: action.payload.code,
        voucherEndDate: action.payload.endDate,
        ecdFailureReason: null,
        errorCode: null,
      };
    default:
      return state;
  }
};
