import styled from 'styled-components';
import { Typography } from '@experian-uk/corvetteuk-common-ui';
import { rem } from 'polished';
import WarningIcon from './help-icon.svg';

import { Detail, DetailValue } from '../Details/index.styles';

const { Headings, Copy } = Typography;

export const Heading = styled(Headings.Secondary)`
  color: ${props => props.theme.colors.experianLightBlue};
  margin-top: 0.5rem;
  text-align: center;

  ${props =>
    props.theme.media(
      '20',
      `
    text-align: left;
  `
    )}
`;

export const WarningCopy = styled(Copy.SmallText)`
  position: relative;
  color: ${props => props.theme.colors.subNavBlue};
  margin-bottom: 1.5rem;
  padding: 0 1rem;

  &::before {
    position: absolute;
    content: url(${WarningIcon});
    height: 1.125rem;
    width: 1.125rem;
    left: -0.625rem;
    top: -2px;
  }
`;

export const DDCopy = styled(Copy.SmallText)`
  position: relative;
  padding-bottom: ${rem(26)};
  color: #797979;
  font-size: ${rem(16)};
`;

export const Container = styled.section`
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 2rem;

  > * {
    ${props =>
      props.theme.media(
        '20',
        `
      flex: 0 0 50%;
    `
      )}
  }
`;

const CardError = styled.div`
  margin: 1rem auto;

  ${props =>
    props.theme.media(
      '20',
      `
    max-width: 30%;
    margin: 1rem 0;
  `
    )}

  ${props =>
    props.theme.media(
      '30',
      `
    max-width: 25%;
  `
    )}
`;

CardError.displayName = 'CardError';

export { CardError };

const MobilePayDetail = styled(Detail)`
  align-items: center;
  justify-content: center;
  padding-right: 0;

  dt,
  dd {
    flex: 0;
    white-space: nowrap;
  }

  dt {
    margin-right: 2.875rem;
  }
`;

const DetailValueLogo = styled(DetailValue)`
  height: initial;

  svg {
    width: 75px;
  }
`;

export { DetailValueLogo, MobilePayDetail };
