import moment from 'moment';
import { dateFormats } from '../../constants';

export default (freeTrialTerms, trialEndDate, todayDate = moment()) => {
  if (!freeTrialTerms || !freeTrialTerms.remaining || !trialEndDate) {
    return {};
  }
  const endDate = moment(trialEndDate);

  if (!moment(endDate).isValid || moment(endDate).isBefore(moment(todayDate))) {
    return {};
  }

  return {
    ...freeTrialTerms,
    ends: endDate.format(dateFormats.long),
  };
};
