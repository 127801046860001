import fetch from '../fetch';
import { products, errorMessages } from '../../constants';

export const FETCH_LOCK_STATUS = 'FETCH_LOCK_STATUS';
export const LOCK_STATUS_FETCHED = 'LOCK_STATUS_FETCHED';

export default () => async (dispatch, getState) => {
  const state = getState();

  // Fetch lock status for CE customers only
  if (state.subscriptions?.current?.family !== products.credit_expert) return;

  dispatch({ type: FETCH_LOCK_STATUS });

  const fetched = {
    type: LOCK_STATUS_FETCHED,
  };

  try {
    const lockData = await dispatch(fetch('/creditlock'));
    fetched.payload = lockData?.lockStatus;
  } catch (error) {
    const errorMessage = {
      heading: errorMessages.someDataMissing,
      messages: [errorMessages.technicalIssues],
    };

    fetched.error = true;
    fetched.payload = new Error(JSON.stringify(errorMessage));
  }

  dispatch(fetched);
};
