import { getEnv } from '../components/Context/env';

let timer;

export default logoutFn => store => next => action => {
  const sessionTimeout = Number(getEnv().REACT_APP_SESSION_TIMEOUT || 300) * 1000;
  const state = store.getState();

  if (!state.auth.isAuthenticated) {
    return next(action);
  }

  if (timer) {
    clearTimeout(timer);
  }

  timer = setTimeout(logoutFn, sessionTimeout);

  return next(action);
};
