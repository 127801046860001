import { FETCH } from './fetch';

export default (service, meta = {}) => ({
  type: FETCH,
  payload: {
    body: {
      service,
    },
  },
  meta,
});
