import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Route, Redirect } from 'react-router';
import { connect } from 'react-redux';

@connect(
  state => ({
    currentDescriptors: get(state, 'subscriptions.current.descriptors', []),
  }),
  null
)
export default class ProtectedRoute extends React.Component {
  static propTypes = {
    allowedDescriptors: PropTypes.arrayOf(PropTypes.string).isRequired,
    currentDescriptors: PropTypes.arrayOf(PropTypes.string).isRequired,
  };

  ensureAtLeastOneAllowedOfferDefined = () => {
    if (!this.props.allowedDescriptors || this.props.allowedDescriptors.length === 0) {
      throw new Error('You must define at least one allowed descriptor for a ProtectedRoute');
    }
  };

  render() {
    this.ensureAtLeastOneAllowedOfferDefined();
    const { allowedDescriptors, currentDescriptors } = this.props;

    if (!currentDescriptors || currentDescriptors.length === 0) {
      return null;
    }
    const hasAllowedDescriptor = currentDescriptors.some(desc => allowedDescriptors.includes(desc));
    return hasAllowedDescriptor ? <Route {...this.props} /> : <Redirect to={{ pathname: '/' }} />;
  }
}
