import moment from 'moment';
import { subscriptionActions, dateFormats, familyName, errorMessages } from '../constants';
import getDynamicString from './getDynamicString';

const getDefermentInfo = defermentInfo => {
  let defermentMessage;
  if (defermentInfo) {
    const { defermentDt, type, family } = defermentInfo;
    const defermentDate = moment(defermentDt).isValid()
      ? moment(defermentDt).format(dateFormats.long)
      : 'your next billing date';
    switch (type) {
      case subscriptionActions.activation:
        defermentMessage = getDynamicString('subscriptions.pendingChange', [familyName[family], defermentDate]);
        break;
      case subscriptionActions.cancellation:
        defermentMessage = getDynamicString('subscriptions.pendingCancellation', [defermentDate]);
        break;
      default:
        defermentMessage = errorMessages.default;
        break;
    }
  }
  return defermentMessage;
};

export default getDefermentInfo;
