import React from 'react';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';

import DownloadIconSVG from './download-icon.svg';

export const DownloadIcon = styled(props => <SVG alt="download" src={DownloadIconSVG} {...props} />)`
  height: 2.18rem;
  margin-right: 1rem;
  width: 2.18rem;

  .cls-1 {
    fill: ${props => props.theme.colors.baseWhite};
  }
  .cls-2,
  .cls-3,
  .cls-6 {
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2px;
  }
  .cls-2 {
    stroke: ${props => props.theme.colors.experianDarkBlue};
  }
  .cls-3 {
    stroke: #7d91bb;
  }
  .cls-4 {
    mask: url(#mask);
  }
  .cls-5 {
    fill: #7d91bb;
  }
  .cls-6 {
    stroke: #fefefe;
  }
`;
