import { v4 as uuid } from 'uuid';

export const SET_CURRENT_USER = 'auth/SET_CURRENT_USER';

// The session ID will allow us to track user journeys from the first service call
// We may make a few calls before we have retrieved the customerId and correlationId
// from the /customers endpoint. By passing the generated sessionId with each call,
// we can aim to link the sessionId to the customerId once we have it, and thus identify
// in our logs other calls the customer triggers in the same session.
// The number generated is not guaranteed to be unique, but in the unlikely event of
// a duplicate we should still be able to gain some useful information about the user and
// will hopefully have access to other data that may help us differentiate between journeys.

export default (token, sessionId = uuid(), mockData = null) => {
  const authenticated = !!token;

  const payload = { authenticated, token, sessionId };

  if (mockData) {
    payload.mockData = mockData;
  }

  return {
    type: SET_CURRENT_USER,
    payload,
  };
};
