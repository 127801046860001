import styled from 'styled-components';
import { Button } from '@experian-uk/corvetteuk-common-ui';

export const ContactUsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: ${props => props.theme.base.expSpacingXSmall};
  align-items: flex-start;

  > div:first-of-type {
    margin-right: ${props => props.theme.base.expSpacingSmall};
  }

  ${({ theme }) =>
    theme.media(
      '20',
      `
    justify-content: left;
  `
    )};
`;

export const StyledButton = styled(Button)`
  margin-top: 0;

  ${({ theme }) =>
    theme.mediaEnd(
      '20',
      `
      min-width: 8.75rem;
  `
    )};
`;

export const CustomerNumber = styled.div`
  color: ${props => props.theme.colors.experianPurple};
  margin-top: ${props => props.theme.base.expSpacingXXSmall};
`;
