import React from 'react';
import PropTypes from 'prop-types';

import { PriceDescription, Price } from './PriceDetails.styles';

const PriceDetails = ({ isFree, price }) => (
  <React.Fragment>
    <Price>{price}</Price>
    {!isFree && <PriceDescription>per month</PriceDescription>}
  </React.Fragment>
);

PriceDetails.propTypes = {
  isFree: PropTypes.bool,
  price: PropTypes.string.isRequired,
};

PriceDetails.defaultProps = {
  isFree: false,
};

export default PriceDetails;
