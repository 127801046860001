export default (offers, excludeList = ['data']) => {
  if (!offers || typeof offers !== 'object') {
    return [];
  }

  const productList = Object.keys(offers).filter(
    key => !excludeList.includes(key) && offers[key] && typeof offers[key] === 'object'
  );

  const availableOfferIds = productList.map(key => {
    const offer = offers[key];
    return offer.id || offer.trialId || offer.offerId;
  });

  return (availableOfferIds || []).filter(offerId => offerId);
};
