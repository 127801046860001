import React from 'react';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setAutomationElement } from '@experian-uk/corvetteuk-common-ui';

import Analytics, { MilestoneInputEvent } from '@experian-uk/web-common-analytics';
import { EnvConsumer } from '../Context/env';
import Checkbox from '../Inputs/Checkbox';
import Loading from '../Loading';
import addEvent from '../../actions/ecd/addEvent';
import confirmSelect from '../../actions/offers/confirmSelect';
import fulfillSubscription from '../../actions/fulfillments/fulfill';
import updateSession from '../../actions/session/update';
import syncSession from '../../actions/session/sync';
import { addDDPaymentMethod } from '../../actions/billing/paymentMethods';
import { isFreeTrial, mapFamilyToProduct } from '../../helpers/subscription';
import subscriptionType from '../../schemas/subscription';

import {
  Container,
  ConfirmButton,
  ContentHeading,
  DDCopy,
  EditDetailsButton,
  DetailLabel,
  DetailsContainer,
  DetailValue,
  StyledParagraph,
  InlineLinkAnchor,
  Divider,
  ConfirmForm,
  DirectDebitOverlayTrigger,
} from './index.styles';
import { stateVariables, relativeUrls } from '../../constants';
import clearSession from '../../helpers/clearSession';
import DirectDebitOverlay from '../DirectDebitOverlay';
import isServer from '../../helpers/isServer';
import getCommonECDData from '../../reducers/selectors/getCommonECDData';
import { commonEcdSchema } from '../../schemas';

const formatSortCode = sortCode => {
  const splitSortcode = sortCode.match(/.{1,2}/g);
  return splitSortcode.join(' ');
};

const redirectErrorToSwitchPage = (pushFn, selectedOffer) => {
  pushFn(`${relativeUrls.switch}?offerId=${selectedOffer.id}&switchingTo=${selectedOffer.family}`, {
    [stateVariables.persistErrorsOnLocationChange]: true,
  });
};

const createEcdEvent = (from, to) => ({
  category: 'Product Movement',
  action: 'Prod Move Confirmation',
  label: `${from}>${to}`,
});

@connect(
  state => ({
    billing: state.billing,
    commonEcdData: getCommonECDData(state),
    fulfillmentsSuccess: state.fulfillments.success,
    loading: state.fulfillments.loading || state.billing.loading || state.fulfillments.success,
    session: state.session,
    subscription: state.subscriptions.current,
  }),
  dispatch =>
    bindActionCreators(
      {
        addDDPaymentMethod,
        addEvent,
        confirmSelect,
        fulfillSubscription,
        push,
        syncSession,
        updateSession,
      },
      dispatch
    )
)
export default class ConfirmDDCard extends React.Component {
  static propTypes = {
    addEvent: PropTypes.func.isRequired,
    addDDPaymentMethod: PropTypes.func.isRequired,
    billing: PropTypes.shape({
      directDebitSaved: PropTypes.bool,
    }).isRequired,
    commonEcdData: PropTypes.shape(commonEcdSchema),
    confirmSelect: PropTypes.func.isRequired,
    directDebitDetails: PropTypes.shape({
      accountHolderName: PropTypes.string,
      accountNumber: PropTypes.string,
      sortCode: PropTypes.string,
    }),
    fulfillmentsSuccess: PropTypes.bool,
    fulfillSubscription: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    push: PropTypes.func.isRequired,
    session: PropTypes.shape({}),
    subscription: PropTypes.shape(subscriptionType),
    syncSession: PropTypes.func.isRequired,
    updateSession: PropTypes.func.isRequired,
  };

  static defaultProps = {
    commonEcdData: null,
    directDebitDetails: {},
    fulfillmentsSuccess: false,
    loading: false,
    session: {},
    subscription: {},
  };

  state = {
    canContinue: false,
    showGuaranteeOverlay: false,
    isECDInitialized: false,
  };

  componentDidMount() {
    this.initializeAnalytics();

    window.addEventListener('focus', this.onFocus);
  }

  componentWillUnmount() {
    window.removeEventListener('focus', this.onFocus);
  }

  onFocus = () => {
    this.setState(prevState => ({
      ...prevState,
      canContinue: false,
    }));
  };

  handleChange = () => {
    this.setState(prevState => ({
      canContinue: !prevState.canContinue,
    }));
  };

  checkout = async () => {
    // Temporary until this page is connected to the switch page in future work
    const selectedOffer = {
      id: 'ee3d4b3e-420a-4e5f-a27b-a3a47c0a02fa',
      terms: [
        {
          price: 14.99,
        },
      ],
      family: 'credit_expert',
      descriptors: ['paid', 'free_trial'],
    };

    await this.props.addDDPaymentMethod(this.props.directDebitDetails, selectedOffer.id);

    if (this.props.billing.directDebitSaved === true) {
      await this.props.fulfillSubscription(selectedOffer.id);
      if (this.props.fulfillmentsSuccess) {
        const selectionData = {
          cancel: false,
          family: mapFamilyToProduct(selectedOffer.family),
          price: selectedOffer.terms[0].price,
          freeTrial: isFreeTrial(selectedOffer.descriptors),
          oldProduct: this.props.subscription.family,
        };
        await this.props.confirmSelect(selectionData);

        await this.initializeAnalytics();

        if (this.state.isECDInitialized) {
          Analytics.publishOnce(
            MilestoneInputEvent.fromObject({
              product_movement: {
                journey_type: 'Confirmation',
                page_funnel_step: `${this.props.subscription.family} > ${selectedOffer.family} - Step 4`,
              },
            })
          );
        }
        // Remove GA3 event in Q2FY25
        this.props.addEvent(relativeUrls.thanks, createEcdEvent(selectionData.oldProduct, selectedOffer.family));
        await clearSession(this.props.session, this.props.updateSession, this.props.syncSession);
        this.props.push(relativeUrls.thanks);
      } else {
        redirectErrorToSwitchPage(this.props.push, selectedOffer);
      }
    } else {
      redirectErrorToSwitchPage(this.props.push, selectedOffer);
    }
  };

  toggleGuaranteeOverlay = () => {
    this.setState(prevState => ({
      showGuaranteeOverlay: !prevState.showGuaranteeOverlay,
    }));
  };

  async initializeAnalytics() {
    if (!isServer() && this.props.commonEcdData && !this.state.isECDInitialized) {
      this.setState(state => ({ ...state, isECDInitialized: true }));

      await Analytics.init(
        {
          ...this.props.commonEcdData,
        },
        'ProdMoveConfirmPage'
      );
    }
  }

  render() {
    const { directDebitDetails } = this.props;
    return (
      <Container>
        {this.props.loading && <Loading solidBackground />}
        <ContentHeading>Confirm Details</ContentHeading>
        <DDCopy>You may cancel this Direct Debit at any time by contacting Experian or your bank</DDCopy>
        <DetailsContainer>
          <DetailLabel>Account holder&apos;s name</DetailLabel>
          <DetailValue>{directDebitDetails.accountHolderName}</DetailValue>
          <DetailLabel>Sort code</DetailLabel>
          <DetailValue>{formatSortCode(directDebitDetails.sortCode)}</DetailValue>
          <DetailLabel>Account number</DetailLabel>
          <DetailValue>{directDebitDetails.accountNumber}</DetailValue>
        </DetailsContainer>
        <DirectDebitOverlayTrigger
          type="button"
          onClick={this.toggleGuaranteeOverlay}
          data-automation-test-element="direct-debit-guarantee-button"
        >
          The Direct Debit Guarantee
        </DirectDebitOverlayTrigger>
        <Divider />
        {/* post to DD endpoint action="/DDendpoint */}
        <ConfirmForm method="post">
          <Checkbox
            id="agreeToTerms"
            checked={this.state.canContinue}
            onChange={this.handleChange}
            label=" I confirm that I am the account holder and am authorised to set up
            Direct Debit payments on this account"
          />
          <EnvConsumer>
            {env => (
              <StyledParagraph>
                It is important you understand the terms of our contract with you, which are in the&nbsp;
                <InlineLinkAnchor href={`${env.REACT_APP_EXPERIAN_URL}/consumer/terms.html`}>
                  Experian Terms &amp; Conditions{' '}
                </InlineLinkAnchor>
                and{' '}
                <InlineLinkAnchor href={`${env.REACT_APP_EXPERIAN_URL}/consumer/privacy.html`}>
                  Privacy Policy
                </InlineLinkAnchor>{' '}
                and by starting your free trial, you&apos;ll be accepting these. You should read these documents and
                contact us if you have any questions.
              </StyledParagraph>
            )}
          </EnvConsumer>
          <EnvConsumer>
            {env => (
              <StyledParagraph>
                You&apos;ll also continue to get free access to our comparison services. Discover more about your{' '}
                <InlineLinkAnchor
                  href={`${env.REACT_APP_EXPERIAN_URL}/consumer/product-factsheets/CreditExpertwithAccount.pdf`}
                >
                  features and rights
                </InlineLinkAnchor>{' '}
              </StyledParagraph>
            )}
          </EnvConsumer>
          <ConfirmButton
            type="primary"
            label="Confirm"
            {...setAutomationElement('confirmButton')}
            disabled={!this.state.canContinue}
            isButton
            className={!this.state.canContinue ? 'disabled' : ''}
            href="#"
            onClick={e => {
              e.preventDefault();
              this.checkout();
            }}
          />
          <EditDetailsButton htmlType="submit" type="secondary" isButton label="Edit details" />
        </ConfirmForm>
        <DirectDebitOverlay isShowing={this.state.showGuaranteeOverlay} toggleHide={this.toggleGuaranteeOverlay} />
      </Container>
    );
  }
}
