import React from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { setAutomationElement } from '@experian-uk/corvetteuk-common-ui';
import InViewComponent from '../../InView';
import { splitsList } from '../../../constants';
import { getSplitStatus } from '../../../reducers/selectors';
import getBreachDeferredStatus from '../../../helpers/subscription/getBreachDeferredStatus';
import BlueTick from '../blueTick';
import tooltipForBenefit from '../tooltipForBenefit';
import {
  BreachProductFactSheet,
  BreachCurrentSubscriptionHeader,
  BreachExpiryBanner,
  BreachCardBenefits,
  BreachContainer,
  BreachDownloadIcon,
} from './breachProductPanels.styles';
import BreachVoucherExtension from './breachVoucherExtension';

const BreachCurrentSubscriptionCard = ({ currentSubscription, showSmallerCard }) => {
  const billingSubscription = useSelector(state => state.billing);
  const split = useSelector(state => state.split);

  const expiryDate = dayjs(billingSubscription?.endDate).format('DD MMMM YYYY');

  const showUnderSplit = getSplitStatus(split, [splitsList.productMovementBreachExtension])[
    splitsList.productMovementBreachExtension
  ];

  const { hasDeferredActivation } = getBreachDeferredStatus(currentSubscription, billingSubscription);

  const showBreachExtension = showUnderSplit && !hasDeferredActivation;

  return (
    <InViewComponent label="your_subscription_card">
      <BreachContainer
        gridLocation="main"
        isCurrentProduct
        {...setAutomationElement(`panel-${currentSubscription.family}`)}
      >
        <BreachCurrentSubscriptionHeader>
          <p>Your subscription</p>
          <h2>{currentSubscription.titleText}</h2>
        </BreachCurrentSubscriptionHeader>
        {billingSubscription?.endDate && (
          <BreachExpiryBanner>
            <p>Complimentary</p>
            <b>Ends {expiryDate}</b>
          </BreachExpiryBanner>
        )}
        {!showSmallerCard && (
          <BreachCardBenefits>
            <h3>Your features:</h3>
            <ul>
              {currentSubscription.benefitCopy.map(txt => (
                <li key={txt}>
                  <BlueTick />
                  <span>{txt}</span>
                  {tooltipForBenefit(txt)}
                </li>
              ))}
            </ul>
          </BreachCardBenefits>
        )}
        {showBreachExtension && (
          <BreachVoucherExtension
            {...setAutomationElement(`${currentSubscription.family}-voucher-extension-accordion`)}
          />
        )}
        <BreachProductFactSheet>
          <BreachDownloadIcon />
          <a
            {...setAutomationElement(`${currentSubscription.family}-panel-product-factsheet-link`)}
            href={currentSubscription.copyLink}
          >
            View {currentSubscription.productName} product fact sheet
          </a>
        </BreachProductFactSheet>
      </BreachContainer>
    </InViewComponent>
  );
};

BreachCurrentSubscriptionCard.propTypes = {
  showSmallerCard: PropTypes.bool.isRequired,
  currentSubscription: PropTypes.shape({
    copyLink: PropTypes.string.isRequired,
    benefitCopy: PropTypes.arrayOf(PropTypes.string).isRequired,
    titleText: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
    productName: PropTypes.string.isRequired,
    family: PropTypes.string.isRequired,
    endDt: PropTypes.string,
  }).isRequired,
};

export default BreachCurrentSubscriptionCard;
