export default {
  billing: {
    changeFailure: {
      intro: "We're having some technical issues right now and we couldn't change your {0}.",
      default: "We're having some technical issues right now and we couldn't change your {0}. Please try again later.",
      subtitle: 'Please try again later.',
    },
    invalidCard: 'Sorry, we have been unable to validate the card details you provided.',
    invalidDD: 'Sorry, we have been unable to validate the direct debit details you provided.',
    paymentDetails:
      "We're having some technical issues right now and couldn't get your current payment details. Please try again later.",
    savedCard: "We’re having some technical issues right now and couldn't get your current payment details.",
    tryAgain: 'Please try again, making sure all details are correct before continuing.',
    expiredVoucherCode: 'Your voucher has expired.',
    invalidVoucherCode: 'We don’t recognise this voucher. Please check and try again.',
    usedVoucherCode:
      'This voucher has already been used. Vouchers are unique and should only be used by the person they are sent to.',
    limit: 'Voucher not redeemed. You can only extend a complimentary subscription by up to 3 years.',
    subscriptionError:
      'We could not redeem your voucher due to a problem at our end. Please call us on 0344 481 8182 between 8am and 6pm, Monday to Friday.',
    technicalError: 'We’re experiencing temporary technical issues. Please try again later.',
  },
  changeFailure: {
    intro: "Sorry, you can't change your {0} right now",
    cancellation: 'Your subscription has already been cancelled',
    default: "We're having some technical issues right now and  we couldn't change your {0}. Please try again later.",
  },
  threeDSFailure: 'There was an issue processing your request. Please re-enter your card details to retry.',
  default: 'Sorry, something went wrong. Please try again later.',
  forms: {
    required: 'Please enter {0}',
    format: 'Please enter a valid {0}',
  },
  oopsHeading: 'Oops, something went wrong here.',
  oopsMessage: 'Please try again or come back later.',
  subscriptions: {
    billingDate:
      'Today is the day on which we take payment for the next month of your subscription, so it is not possible to change your subscription right now. Please check back tomorrow.',
    startDate:
      'Today is the first day of your trial, so it is not possible to change your subscription. Please try again later.',
    pendingCancellation:
      'You have until {0} to use this service. From this date onwards, your account will be deactivated.',
    pendingChange:
      'Your subscription is due to change to {0} on {1}. From this date onwards, these options will be available to you.',
  },
  tryAgain: 'Please try again later.',
  technicalIssues: "We're having some technical issues and ask that you please try again later.",
  someDataMissing: 'Unfortunately, we were unable to retrieve all of your data, so some features may be missing.',
};
