import { type as UPDATE_SESSION } from '../actions/session/update';

export default (state = {}, action = {}) => {
  switch (action.type) {
    case UPDATE_SESSION:
      return {
        ...action.payload,
      };
    default:
      return state;
  }
};
