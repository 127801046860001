import deepmerge from 'deepmerge';
import Cookies from 'js-cookie';
import { FETCH } from '../actions/fetch';
import { getEnv } from '../components/Context/env';
import isServer from '../helpers/isServer';
import setCurrentUser from '../actions/auth/setCurrentUser';

const defaultHeaders = {
  'Content-Type': 'application/json',
  'x-brand': 'creditmatcher',
  'x-api-version': '3.0.0',
  'x-clientid': 'web_prodmove',
};

export default store => next => action => {
  if (action.type !== FETCH) return next(action);

  const env = getEnv();
  const { url, body = {} } = action.payload;
  const { isRemote, overrideToken } = action.meta;
  const { auth } = store.getState();

  const baseHeaders = isRemote
    ? {
        'Content-Type': 'application/json',
      }
    : defaultHeaders;
  const headers = {
    ...baseHeaders,
    ...(body.headers || {}),
    'CSRF-Token': auth.csrfToken,
  };

  const payload = deepmerge({}, body);
  const token = overrideToken || auth.token;

  if (payload.meta?.require3dsHeaders) {
    headers['x-Is3DsCapable'] = true;
  }

  if (auth.currentUser?.customerId) {
    headers['x-customerId'] = auth.currentUser.customerId;
  }

  payload.headers = { ...headers };

  if (!isRemote && token) {
    payload.headers.Authorization = `Bearer ${token}`;
  }

  const proxyUrlDisplay = body.service
    ? `${env.REACT_APP_GATEWAY_URL}/${body.service.service}/${body.service.endpoint}`
    : url;

  let fetchUrl = `/internal/proxy?for=${proxyUrlDisplay}&method=${
    typeof payload.method === 'undefined' ? 'GET' : payload.method
  }`;

  if (auth.mockData) {
    fetchUrl = body.service ? `/internal/mock${proxyUrlDisplay}` : `/internal${proxyUrlDisplay}`;
  }

  if (isRemote) {
    fetchUrl = url;

    return fetch(fetchUrl, {
      method: 'POST',
      body: JSON.stringify({
        ...payload.body,
      }),
    }).then(res => res.json());
  }

  return fetch(fetchUrl, {
    method: 'POST',
    headers: new Headers(headers),
    body: JSON.stringify({
      requestedUrl: url,
      appName: 'Product Movement',
      requestTime: new Date(),
      sessionId: auth.sessionId,
      ...payload,
    }),
  }).then(res => {
    next(action);

    if (!res.ok) {
      if (res.status === 403) {
        Cookies.remove('authId');
        store.dispatch(setCurrentUser());
        if (!isServer() && window.location) {
          window.location = env.REACT_APP_AUTH_URL;
        }

        return Promise.reject();
      }

      throw res;
    }

    return res.json();
  });
};
