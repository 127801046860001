import React from 'react';
import PropTypes from 'prop-types';
import { ProductPanelContainer, Wrapper } from './index.styles';
import productData from './FreeProduct/productData';

import { benefitsDocument, products } from '../../constants';
import { isObjectEmpty } from '../../helpers';
import { mapFamilyToProduct } from '../../helpers/subscription';
import ProductPanel from './productPanel';
import BreachProductPanel from './BreachProductPanels/breachProductPanel';

const getCopy = (family, isComplimentary, isCurrentSubscription) => {
  let productKey = mapFamilyToProduct(family);
  if ([products.breach, products.breachPlus].includes(family) && !isCurrentSubscription) {
    productKey = products.basic;
  }
  const data = productData.find(datum => datum.productKey === productKey);
  if (isComplimentary && ![products.basic].includes(productKey)) {
    data.pdfDownloadLink = `${benefitsDocument.baseUrl}/${benefitsDocument[`${productKey}_complimentary`]}`;
  }
  return data;
};

const ProductPanels = ({
  currentSubscription,
  offers,
  switchPermitted,
  freeTrial,
  isComplimentary,
  shouldShowBreachRetentionComponent,
}) => {
  const availableOffers = offers.filter(offer => {
    if (!switchPermitted) {
      return currentSubscription.family === offer.family || offer.family === products.basic;
    }
    return true;
  });

  const allOffersAvailable =
    availableOffers.length ===
    Object.keys(products).filter(key => ![products.breach, products.breachPlus].includes(key)).length;

  if (currentSubscription.family === products.credit_expert || currentSubscription.family === products.identity) {
    return (
      <ProductPanel
        currentSubscription={currentSubscription}
        freeTrial={freeTrial}
        offers={availableOffers}
        switchPermitted={switchPermitted}
      />
    );
  }

  if (currentSubscription.family === products.breach || currentSubscription.family === products.breachPlus) {
    return (
      <BreachProductPanel
        currentSubscription={currentSubscription}
        offers={availableOffers}
        shouldShowBreachRetentionComponent={shouldShowBreachRetentionComponent}
      />
    );
  }

  return (
    <Wrapper showBreachVoucherInput>
      {availableOffers.map(
        offer =>
          !isObjectEmpty(offer) && (
            <ProductPanelContainer
              key={offer.family}
              selected={currentSubscription.family === offer.family}
              isComplimentary={isComplimentary}
              currentSubscription={currentSubscription.family}
              offer={offer}
              copy={getCopy(offer.family, isComplimentary, currentSubscription.family === offer.family)}
              switchPermitted={switchPermitted}
              freeTrial={freeTrial}
              allOffersAvailable={allOffersAvailable}
              fewerPanels={availableOffers.length < 3}
            />
          )
      )}
    </Wrapper>
  );
};

ProductPanels.propTypes = {
  currentSubscription: PropTypes.shape({
    family: PropTypes.string.isRequired,
  }).isRequired,
  freeTrial: PropTypes.shape({
    ends: PropTypes.string,
    length: PropTypes.shape({
      unit: PropTypes.string,
      value: PropTypes.number,
    }),
  }),
  offers: PropTypes.arrayOf(
    PropTypes.shape({
      family: PropTypes.string.isRequired,
    })
  ).isRequired,
  switchPermitted: PropTypes.bool,
  isComplimentary: PropTypes.bool,
  shouldShowBreachRetentionComponent: PropTypes.bool,
};

ProductPanels.defaultProps = {
  freeTrial: {},
  switchPermitted: true,
  isComplimentary: false,
  shouldShowBreachRetentionComponent: false,
};

export default ProductPanels;
