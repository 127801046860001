import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setAutomationElement } from '@experian-uk/corvetteuk-common-ui';

import uiChangeState from '../../actions/uiChangeState';
import icons from '../Icons';
import { getCardLogo } from '../../helpers/billing';
import { cardDetailsSchema } from '../../schemas';

import RecycleContainer from './RecycleContainer';
import { Container, ActionButton, CardImg } from './CurrentCard.styles';
import { DetailsContainer, Detail, DetailLabel, DetailValue } from '../Details/index.styles';
import { selectPage } from '../../reducers/uiState';

@connect(
  state => ({
    showCardForm: selectPage(state.uiState, window.location.pathname).showCardForm,
  }),
  dispatch =>
    bindActionCreators(
      {
        uiChangeState,
      },
      dispatch
    )
)
export default class CurrentCard extends React.Component {
  static propTypes = {
    uiChangeState: PropTypes.func.isRequired,
    showCardForm: PropTypes.bool,
    cardType: cardDetailsSchema.creditCardType,
    endingNumbers: PropTypes.string,
    expiryDate: PropTypes.string,
    isRecycle: PropTypes.bool,
  };

  static defaultProps = {
    cardType: '',
    endingNumbers: '',
    showCardForm: false,
    expiryDate: '',
    isRecycle: false,
  };

  handleClick = e => {
    e.preventDefault();
    this.props.uiChangeState(window.location.pathname, { showCardForm: true });
  };

  render() {
    const { cardType, endingNumbers, expiryDate, isRecycle } = this.props;
    const cardLogo = getCardLogo(cardType.toLowerCase(), icons.cardLogos, 1);

    return (
      <Container {...setAutomationElement('currentCardDetails')} isError={isRecycle}>
        <DetailsContainer data-hook="fs-exclude-element">
          <Detail>
            <DetailLabel>Card type:</DetailLabel>
            <DetailValue {...setAutomationElement('subCardType')}>
              {cardLogo && <CardImg src={cardLogo} alt={cardType} />}
            </DetailValue>
          </Detail>
          <Detail>
            <DetailLabel>Card ending:</DetailLabel>
            <DetailValue {...setAutomationElement('subCardEnd')}>{endingNumbers}</DetailValue>
          </Detail>
          <Detail>
            <DetailLabel>Expires:</DetailLabel>
            <DetailValue {...setAutomationElement('subExpiryDate')}>{expiryDate}</DetailValue>
          </Detail>
        </DetailsContainer>
        {isRecycle && <RecycleContainer />}
        <ActionButton
          {...setAutomationElement('subUseDiffCard')}
          type="secondary"
          isButton
          onClick={this.handleClick}
          label="Use a different card"
          disabled={this.props.showCardForm}
        />
      </Container>
    );
  }
}

CurrentCard.displayName = 'CurrentCard';
