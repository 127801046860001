import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';

const StyledPaymentStepsContainer = styled.div`
  margin-bottom: 20px;
  width: 100%;
`;

const StyledPaymentStepsCard = styled.div`
  background-color: #eff0f0;
  padding: 25px 20px 20px 20px;
  width: 100%;
`;

const StyledStepContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
  padding: 0;
`;

const StyledStepNumber = styled.div`
  height: 32px;
  width: 32px;
  min-width: 32px;
  background-color: #1d4f91;
  border-radius: 50%;
  display: block;
  color: #fff;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  padding-top: 5px;
`;

const PaymentStepsCard = ({ stepNumber, stepMessage, children }) => (
  <StyledPaymentStepsContainer>
    <StyledPaymentStepsCard>
      <StyledStepContainer>
        <StyledStepNumber>{stepNumber}</StyledStepNumber>
        {stepMessage}
      </StyledStepContainer>
      {children}
    </StyledPaymentStepsCard>
  </StyledPaymentStepsContainer>
);

PaymentStepsCard.propTypes = {
  stepNumber: PropTypes.number.isRequired,
  stepMessage: PropTypes.node.isRequired,
  children: PropTypes.node,
};

PaymentStepsCard.defaultProps = {
  children: undefined,
};

export default PaymentStepsCard;
