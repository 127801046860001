import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import moment from 'moment';
import isEqual from 'lodash/isEqual';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { Button, setAutomationElement } from '@experian-uk/corvetteuk-common-ui';
import Analytics, { MilestoneInputEvent } from '@experian-uk/web-common-analytics';
import getCurrentSubscriptionPrice from '../../reducers/selectors/currentSubscriptionPrice';
import { dateFormats, familyName, fulfillmentStatuses, products, subscriptionActions } from '../../constants';
import Container from '../../components/Container';
import PanelContainer from '../../components/PanelContainer';
import PanelHeader from '../../components/PanelHeader';
import StyledSubscription from '../../components/StyledSubscription';
import TopContainer from '../../components/TopContainer';
import { getEnv } from '../../components/Context/env';
import { getHomeUrl, isObjectEmpty, getUpgradeType, getBreachBillingDatePrice } from '../../helpers';
import { getFreeTrialData, mapFamilyToProduct } from '../../helpers/subscription';
import isServer from '../../helpers/isServer';
import subscriptionProps from '../../propTypes/subscription';
import messaging from './messaging';
import subtitle from './subtitle';
import getSubscriptionDetails from './getSubscriptionDetails';
import getCommonECDData from '../../reducers/selectors/getCommonECDData';
import { getAvailableOffers } from '../../reducers/offers';
import { commonEcdSchema } from '../../schemas';
import {
  Heading,
  CopyText,
  BodyContent,
  DetailsContainer,
  ButtonContainer,
  InlineLink,
  FreeButtonContainer,
  Reminder,
  ReminderList,
} from './index.styles';
import { trackUpsellStage } from '../../helpers/trackUpsell/trackUpsellStage';
import { PAYMENT_LOG_STEPS } from '../../constants/paymentLogSteps';

dayjs.extend(advancedFormat);

const ThanksIndex = () => {
  const billing = useSelector(state => state.billing);
  const subscription = useSelector(state => state.subscriptions.current);
  const commonEcdData = useSelector(state => getCommonECDData(state));
  const currentSubscriptionPrice = useSelector(state => getCurrentSubscriptionPrice(state));
  const customer = useSelector(state => state.auth.currentUser);
  const confirmSelect = useSelector(state => state.confirmSelection);
  const cancellationReason = useSelector(state => state.cancellationReason.reason);
  const offers = useSelector(state => getAvailableOffers(state.offers, state.subscriptions));
  const selection = useSelector(state => state.selection || {});
  const upsellCorrelationId = useSelector(state => state.upsellProgress?.upsellCorrelationId);

  const [isECDInitialized, setIsECDInitialized] = useState(false);

  const getNewProductSelection = () =>
    confirmSelect?.family ||
    (subscription?.defermentInfo ? subscription?.defermentInfo.family : mapFamilyToProduct(subscription?.family)) ||
    products.basic;

  useEffect(() => {
    const initializeAnalytics = async () => {
      if (!isServer() && commonEcdData && !isECDInitialized) {
        setIsECDInitialized(true);
        await Analytics.init(
          {
            ...commonEcdData,
            productChange: { switchingTo: getNewProductSelection() },
          },
          'ProdMoveThanksPage'
        );
        trackUpsellStage(
          PAYMENT_LOG_STEPS.THANKS_PAGE_SHOWN,
          customer.customerId,
          upsellCorrelationId,
          selection.offerId,
          selection.family
        );
      }
    };

    initializeAnalytics();
  }, [commonEcdData]);

  const newProductSelection = getNewProductSelection();

  const renderReady = subscription && billing;

  if (!renderReady) {
    return null;
  }

  const env = getEnv();

  const { freeTrialTerms, nextBillingDate } = billing;

  const { customerNumber } = customer;

  const { thankYouMessage, subscriptionConfirmation } = messaging;

  const steps = [{ text: 'Subscriptions', url: '/' }];

  const breachSubscriptions = [products.breach, products.breachPlus];
  const isBreachUser = breachSubscriptions.includes(subscription.family);

  const { defermentInfo, freeTrialEndDt } = subscription;

  let { freeTrial } = confirmSelect || subscription;

  let identityDeferred = subscription.family === products.credit_expert && confirmSelect?.family === products.identity;

  if (!confirmSelect) {
    freeTrial &&= !isObjectEmpty(getFreeTrialData(freeTrialTerms, freeTrialEndDt));
    identityDeferred = defermentInfo && defermentInfo.family === products.identity;
  }

  const parseAndFormatPrice = price => (!Number.isNaN(parseInt(price, 10)) ? `£${price}` : '');
  const currentPrice = confirmSelect
    ? parseAndFormatPrice(confirmSelect.price)
    : parseAndFormatPrice(currentSubscriptionPrice);

  const cancellation = confirmSelect?.cancel;

  const headingStart = identityDeferred ? subscriptionConfirmation.deferred : subscriptionConfirmation.default;

  const currentOffer = offers.find(o => o.family === subscription?.breachDefermentInfo?.family);
  const newSelection = confirmSelect ? selection : { offerId: currentOffer?.id };
  const { deferredProductBillDate, deferredProductPrice } =
    getBreachBillingDatePrice(subscription, offers, newSelection) || {};
  const hasDeferredBreachProduct = deferredProductBillDate && deferredProductPrice;
  const basicToBreach = !(currentPrice !== '£0' && currentPrice !== '£179.88');

  const subscriptionDetails = getSubscriptionDetails(
    nextBillingDate,
    currentPrice,
    deferredProductBillDate,
    hasDeferredBreachProduct,
    deferredProductPrice,
    isBreachUser,
    billing,
    basicToBreach
  );

  const buttonLabel =
    !identityDeferred && !hasDeferredBreachProduct
      ? `Go to your ${familyName[newProductSelection]} homepage`
      : 'Go to homepage';

  const inFreeTrial = freeTrial && !hasDeferredBreachProduct;

  const paidSwitchButton = (
    <Button
      type="primary"
      label={buttonLabel}
      href={getHomeUrl(subscription.family, inFreeTrial, isBreachUser)}
      onClick={() => {}}
      {...setAutomationElement('thanksButton')}
    />
  );

  const fulfillmentDataComplete =
    (!!nextBillingDate || isBreachUser) &&
    (subscription.fulfillmentStatus === fulfillmentStatuses.succeeded || hasDeferredBreachProduct);

  const heading =
    fulfillmentDataComplete || newProductSelection === products.basic || cancellation ? (
      'Thank you'
    ) : (
      <span>
        You are now subscribed to <StyledSubscription product={newProductSelection} />
      </span>
    );

  const oldProduct = confirmSelect ? confirmSelect.oldProduct : null;
  const moveToBreachPlus = subscription.family === products.breachPlus && oldProduct === products.breach;
  const moveToBreach = subscription.family === products.breach && oldProduct === products.breachPlus;

  const pendingCancellation = subscription.defermentInfo?.type === subscriptionActions.cancellation;
  const isInTrial = subscription.freeTrial;
  const trialEndDate = subscription.freeTrialEndDt;
  let daysLeftInTrial;
  if (isInTrial && trialEndDate) {
    daysLeftInTrial = dayjs(trialEndDate).diff(dayjs(), 'day') + 1; // include today
  }

  const pageLoadECD = {
    product_movement_success: {
      family: 'credit_expert',
      productSubscriptionLookingToMoveTo: 'basic',
      isWithinFreeTrial: isInTrial,
      isPendingCancellation: pendingCancellation,
      daysLeftOfTrialInCurrentProductSubscription: daysLeftInTrial,
    },
  };

  const serviceCallsComplete = !!billing && !!subscription;
  const pageLoadEventAdded = window.$ECD2?.some(existingEcdEvent => isEqual(existingEcdEvent, pageLoadECD));
  const isCreditExpertCancellation =
    subscription.family === products.credit_expert && (newProductSelection === products.basic || cancellation);

  // When billing and subscriptions calls are complete, check if we need to add the ECD2 event for CE cancellation
  if (!isServer() && serviceCallsComplete && !pageLoadEventAdded && isCreditExpertCancellation && isECDInitialized) {
    Analytics.publish(MilestoneInputEvent.fromObject(pageLoadECD));
  }

  return (
    <Container as="main" id="main">
      <TopContainer steps={steps} customerNumber={customerNumber} currentSubscription={subscription.family} />
      <Heading {...setAutomationElement('thanksTitle')}>{heading}</Heading>
      {newProductSelection !== products.basic && !cancellation && (
        <React.Fragment>
          <CopyText {...setAutomationElement('thanksDescription')}>
            {thankYouMessage({
              movementType: getUpgradeType(subscription.family, newProductSelection),
              moveToBreachPlus,
              moveToBreach,
            })[newProductSelection] || ''}
            {identityDeferred &&
              fulfillmentDataComplete &&
              ` Your subscription will change from ${familyName[subscription.family]} to ${
                familyName[products.identity]
              }${moment(nextBillingDate).isValid() ? ` on ${moment(nextBillingDate).format(dateFormats.long)}.` : '.'}`}
          </CopyText>
          {fulfillmentDataComplete && (
            <PanelContainer>
              <PanelHeader
                headingStart={headingStart}
                styledSubscription={newProductSelection}
                subtitle={subtitle(currentPrice, freeTrial, isBreachUser, deferredProductPrice, basicToBreach)}
                setAutomationTitle="thanksCardTitle"
                setAutomationSubtitle="thanksCardDescription"
              />
              <BodyContent>
                <DetailsContainer data={subscriptionDetails} />
                <ButtonContainer>{paidSwitchButton}</ButtonContainer>
              </BodyContent>
            </PanelContainer>
          )}
          {!fulfillmentDataComplete && paidSwitchButton}
        </React.Fragment>
      )}
      {(newProductSelection === products.basic || cancellation) && (
        <React.Fragment>
          <CopyText {...setAutomationElement('thanksDescription')}>
            You&rsquo;ll shortly have a free Experian account. Your account is still covered by the same{' '}
            <InlineLink href={`${env.REACT_APP_EXPERIAN_URL}/consumer/terms.html`}>Terms</InlineLink> and{' '}
            <InlineLink href={`${env.REACT_APP_EXPERIAN_URL}/consumer/privacy.html`}>Privacy Policy</InlineLink> you
            originally agreed to. If you previously paid for your subscription, we will not take any future payments.
          </CopyText>
          {cancellationReason === 'breach' && (
            <Reminder>
              Remember to activate your code once your billing period is over.
              <ReminderList>
                <li>
                  Once you’ve received your cancellation email, log in to your Experian account and go to ‘My
                  Subscriptions’.
                </li>
                <li>Select Identity Plus.</li>
                <li>Enter your voucher code for your complimentary Identity Plus subscription to begin.</li>
              </ReminderList>
            </Reminder>
          )}
          <FreeButtonContainer>
            <Button
              type="primary"
              label="Go to Experian homepage"
              href={getHomeUrl(subscription.family)}
              onClick={() => {}}
              {...setAutomationElement('thanksButton')}
            />
          </FreeButtonContainer>
        </React.Fragment>
      )}
    </Container>
  );
};

ThanksIndex.propTypes = {
  billing: PropTypes.shape({
    freeTrialTerms: PropTypes.shape({
      remaining: PropTypes.shape({
        value: PropTypes.number,
      }),
    }),
    nextBillingDate: PropTypes.string,
    endDate: PropTypes.string,
  }),
  commonEcdData: PropTypes.shape(commonEcdSchema),
  confirmSelect: PropTypes.shape({
    cancel: PropTypes.bool,
    family: PropTypes.string,
    freeTrial: PropTypes.bool,
    price: PropTypes.number,
    oldProduct: PropTypes.string,
  }),
  customer: PropTypes.shape({
    customerNumber: PropTypes.string,
  }),
  initializeEcd: PropTypes.func,
  subscription: subscriptionProps,
  currentSubscriptionPrice: PropTypes.number,
  cancellationReason: PropTypes.string,
  offers: PropTypes.arrayOf(PropTypes.shape({})),
  selection: PropTypes.shape({
    offerId: PropTypes.string,
    family: PropTypes.string,
  }),
};

ThanksIndex.defaultProps = {
  billing: {},
  commonEcdData: null,
  confirmSelect: null,
  customer: {},
  subscription: {},
  currentSubscriptionPrice: null,
  cancellationReason: undefined,
  initializeEcd: () => ({}),
  offers: [],
  selection: {},
};

export default ThanksIndex;
