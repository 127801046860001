import styled from 'styled-components';
import { Typography } from '@experian-uk/corvetteuk-common-ui';
import Button from '@nebula/button';
import NewCard from '../../components/NewCard';

const { Headings } = Typography;

export const Heading = styled(Headings.Primary)`
  text-align: center;
  margin-bottom: ${props => props.theme.base.expSpacingSmall};

  ${props =>
    props.theme.media(
      '20',
      `
    text-align: left;
  `
    )}
`;

export const StyledNewCard = styled(NewCard)`
  ${({ theme }) =>
    theme.mediaEnd(
      '15',
      `
      margin: auto;
  `
    )};
`;

export const CardFormContainer = styled.section`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  > form {
    flex: 0 0 100%;
    order: 1;

    ${({ theme }) =>
      theme.media(
        '15',
        `
      flex: 1 0 50%;
      order: 0;
    `
      )}
  }

  > section {
    flex: 0 0 100%;
    order: 0;
    padding: 1rem;

    ${({ theme }) =>
      theme.media(
        '15',
        `
      flex: 0 1 calc(50% - 1rem);
      order: 1;
    `
      )}
  }
`;

export const CardFormContainer3ds = styled(CardFormContainer)`
  margin-bottom: ${props => props.theme.base.expSpacingSmall};
  justify-content: ${props => (props.isApplePay ? 'end' : 'start')};

  > section {
    border: 1px solid ${props => props.theme.colors.baseMediumGrey};
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;

  > button {
    margin: 0 auto 30px;
    width: 100%;
  }

  ${({ theme }) =>
    theme.media(
      '5',
      `
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 40px;
    max-width: 370px;

    > button {
      margin: 0;
      width: auto;
    }
  `
    )};
`;

export const IFrame = styled.iframe`
  border: none;
  display: block;
  margin: 0 auto;
  min-height: 70vh;
  overflow: scroll;
  width: 100%;

  @media (max-width: 1024px) {
    min-height: 95vh;
  }
`;

export const StyledButton = styled(Button)`
  span {
    font-size: 16px;
  }
`;

export const NewCardDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  margin: 0 0 40px auto;

  @media ${({ theme }) => theme.media.tabletPortraitMin} {
    width: calc((100% - 48px) / 2);
  }
`;
