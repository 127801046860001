import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import selectOffer from '../../actions/offers/select';
import syncSession from '../../actions/session/sync';
import updateSession from '../../actions/session/update';
import { products } from '../../constants';
import { clickInteractionECD } from '../../pages/home/homePageEcd2';

const useBreachUpsellHelpers = offers => {
  const dispatch = useDispatch();
  const [showModalUpsell, setShowModalUpsell] = useState(false);
  const ceOffer = offers.filter(o => products.credit_expert === o.family)[0];

  const goToSwitch = async (targetOfferId, targetSubscription) => {
    dispatch(selectOffer(targetOfferId, targetSubscription));
    dispatch(
      updateSession({
        selectedOfferId: targetOfferId,
        selectedOfferFamily: targetSubscription,
      })
    );
    dispatch(syncSession());
    dispatch(push('/switch'));
  };

  const credExClick = (targetOfferId, targetSubscription) => e => {
    e.preventDefault();
    goToSwitch(targetOfferId, targetSubscription);
  };

  const breachUpsellButtonClick = async (targetOfferId, targetSubscription) => {
    if (targetSubscription === products.credit_expert) {
      clickInteractionECD('subscription_panel_credit_expert');
      setShowModalUpsell(true);
      return;
    }
    const ecdComponentName = targetSubscription === 'identity' ? 'identity_plus' : 'free';

    clickInteractionECD(`subscription_panel_${ecdComponentName}`);
    await goToSwitch(targetOfferId, targetSubscription);
  };

  const toggleModal = () => {
    setShowModalUpsell(!showModalUpsell);
  };

  return {
    showModalUpsell,
    ceOffer,
    credExClick,
    breachUpsellButtonClick,
    toggleModal,
  };
};

export default useBreachUpsellHelpers;
