import React from 'react';
import { ErrorAlert, SuccessAlert, InformationAlert } from '@nebula/alert';

const PaymentMethodRecycleAlert = () => <ErrorAlert showIcon message="Your current payment method has expired." />;
const PaymentMethodSuccessAlert = () => <SuccessAlert showIcon message="Payment method updated." />;
const ApplePayPaymentMethodUpdateFailedAlert = () => (
  <ErrorAlert
    showIcon
    message="We're having some technical issues right now and we couldn't change your card details. Please try again later."
  />
);
const ApplePayDetailsCantBeUpdatedAlert = () => (
  <InformationAlert
    showIcon
    message="Apple Pay details can’t be updated on this device. You can change your payment method to card if you need to."
  />
);

export {
  PaymentMethodRecycleAlert,
  PaymentMethodSuccessAlert,
  ApplePayPaymentMethodUpdateFailedAlert,
  ApplePayDetailsCantBeUpdatedAlert,
};
