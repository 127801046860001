import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { Button, Typography, setAutomationElement } from '@experian-uk/corvetteuk-common-ui';
import nebulaTheme from '@nebula/theme';

import { DeactivateAccountContainer, Heading, ReportReminder, StatReportLink } from './index.styles';
import { getEnv } from '../../Context/env';
import { clickECDEvent, clickInteractionECD } from '../../../pages/home/homePageEcd2';
import GOALS from '../../../constants/abTestGoals';
import sendReward from '../../../actions/conductrics/sendReward';
import abTests from '../../../constants/abtests';

const { Copy } = Typography;

const DeactivateAccount = () => {
  const dispatch = useDispatch();
  const { sels: selections } = useSelector(state => state.conductrics);
  const [homeAATestVariant, setHomeAATestVariant] = useState(null);

  useEffect(() => {
    setHomeAATestVariant(selections ? selections[abTests.PRODMOVE_AA_TEST_HP?.apiCode] : null);
  }, [selections]);

  const onClickCloseButton = async e => {
    e.preventDefault();

    clickInteractionECD('deactivate_your_account');

    clickECDEvent('prodmove_close_experian_account');

    if (['A', 'B'].includes(homeAATestVariant)) {
      await dispatch(sendReward(GOALS.CLOSE_ACCOUNT));
    }
    window.location = `${getEnv().REACT_APP_INS_URL}/account/close`;
  };

  return (
    <DeactivateAccountContainer>
      <Heading>Deactivate your Experian account</Heading>
      <Copy.Text>
        If you deactivate your Experian account you&rsquo;ll also cancel any subscriptions you have and won&rsquo;t be
        able to see your free Experian credit score.
      </Copy.Text>
      <ThemeProvider theme={nebulaTheme}>
        <ReportReminder data-automation-test-element="stat-report-reminder">
          Remember you can get a free copy of your{' '}
          <StatReportLink
            href="https://www.experian.co.uk/consumer/statutory-report.html"
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              clickECDEvent('Statutory_credit_report');
            }}
          >
            Statutory Credit Report
          </StatReportLink>{' '}
          at any time. This shows a basic version of your credit history. Please note the Statutory Credit Report{' '}
          <strong>doesn’t</strong> include your credit score, or any other benefits you get with a free or paid Experian
          account.
        </ReportReminder>
      </ThemeProvider>
      <Button
        type="secondary"
        label="Deactivate your Experian account"
        onClick={onClickCloseButton}
        {...setAutomationElement('deactivateAccount')}
      />
    </DeactivateAccountContainer>
  );
};

export default DeactivateAccount;
