import React from 'react';
import PropTypes from 'prop-types';
import { SmallText, StyledFooter } from './index.styles';
import { products, familyName } from '../../constants';
import { getHomeUrl, isObjectEmpty } from '../../helpers';
import markers from './markers';
import customCopy from './copy';
import getLinks from './getLinks';

export default class Footer extends React.Component {
  static propTypes = {
    creditExpertOffer: PropTypes.shape({
      terms: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    currentLocation: PropTypes.shape({
      pathname: PropTypes.string,
      switchingTo: PropTypes.string,
    }),
    currentSubscriptionFamily: PropTypes.string,
    productPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    urls: PropTypes.shape({
      experianUrl: PropTypes.string,
      insUrl: PropTypes.string,
    }),
    isDeferred: PropTypes.bool,
  };

  static defaultProps = {
    creditExpertOffer: {},
    currentLocation: {},
    currentSubscriptionFamily: '',
    productPrice: null,
    urls: {},
    isDeferred: false,
  };

  static generateCopyItems = copyItemsArray => {
    if (!Array.isArray(copyItemsArray) || copyItemsArray.length < 1) {
      return undefined;
    }
    return copyItemsArray.map(copyItem => <SmallText key={copyItem.id}>{copyItem.content}</SmallText>);
  };

  getProductPrice() {
    const { currentSubscriptionFamily, creditExpertOffer, productPrice, isDeferred } = this.props;

    if (isObjectEmpty(creditExpertOffer)) {
      return null;
    }

    const [currentTerm] = creditExpertOffer.terms;

    if (isDeferred && currentSubscriptionFamily === products.credit_expert) {
      return currentTerm.price;
    }

    if (currentSubscriptionFamily !== products.basic) {
      return productPrice;
    }

    return currentTerm.price;
  }

  getMarkerForProduct = () => {
    if (this.props.currentSubscriptionFamily !== products.basic) {
      return markers.default;
    }
    return markers.dagger;
  };

  getMarkerForBrokerage = () => {
    if (this.props.currentSubscriptionFamily !== products.basic) {
      return markers.dagger;
    }
    return markers.default;
  };

  getProduct = () => {
    if (this.props.currentSubscriptionFamily !== products.basic) {
      return familyName[this.props.currentSubscriptionFamily];
    }
    return familyName[products.credit_expert];
  };

  getCustomCopy = (maxItemsPerColumn = 4) => {
    const { currentSubscriptionFamily } = this.props;
    const { pathname, switchingTo } = this.props.currentLocation;
    const currentPath = pathname.replace('/', '');
    let allCopy;
    if (currentPath && switchingTo && customCopy[currentPath] && customCopy[currentPath][switchingTo]) {
      allCopy = customCopy[currentPath][switchingTo];
    } else if (customCopy[currentPath] && customCopy[currentPath][currentSubscriptionFamily]) {
      allCopy = customCopy[currentPath][currentSubscriptionFamily];
    } else if (customCopy[currentSubscriptionFamily]) {
      allCopy = customCopy[currentSubscriptionFamily];
    }
    if (allCopy) {
      const numberOfCopyItems = allCopy.length;
      let leftPanelCopy;
      let rightPanelCopy;
      if (numberOfCopyItems > maxItemsPerColumn) {
        leftPanelCopy = Array.from(allCopy).slice(0, numberOfCopyItems / 2);
        rightPanelCopy = Array.from(allCopy).slice(numberOfCopyItems / 2);
      }
      return {
        leftPanel: Footer.generateCopyItems(leftPanelCopy || allCopy),
        rightPanel: Footer.generateCopyItems(rightPanelCopy),
      };
    }
    return {};
  };

  render() {
    const { currentSubscriptionFamily, urls } = this.props;

    const customisedCopy = this.getCustomCopy();

    const exclusionMap = {
      [products.breach]: ['footerStatement'],
    };

    return (
      <StyledFooter
        homeUrl={getHomeUrl(currentSubscriptionFamily)}
        insUrl={urls.insUrl}
        experianUrl={urls.experianUrl}
        footerLinks={getLinks(exclusionMap[currentSubscriptionFamily])}
        product={this.getProduct()}
        productPrice={this.getProductPrice()}
        trialProduct={currentSubscriptionFamily === products.identity ? familyName.credit_expert : ''}
        markerForProduct={this.getMarkerForProduct()}
        markerForBrokerage={this.getMarkerForBrokerage()}
        leftPanelContent={customisedCopy.leftPanel}
        rightPanelContent={customisedCopy.rightPanel}
        openLinksInNewTab
      />
    );
  }
}
