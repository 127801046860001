import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import WidescreenInformationAlert from '../WidescreenInformationAlert';
import { dateFormats } from '../../../constants';

const BreachExtensionSuccess = ({ endDate }) => {
  if (!endDate) return null; // New end date not yet retrieved from billing

  const date = dayjs(endDate).format(dateFormats.long);

  return (
    <WidescreenInformationAlert
      text={`Your subscription now ends on ${date}.`}
      heading="Voucher redeemed."
      type="success"
    />
  );
};

BreachExtensionSuccess.propTypes = {
  endDate: PropTypes.string.isRequired,
};

export default BreachExtensionSuccess;
