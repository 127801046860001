import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const BenefitsItems = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, minmax(120px, 1fr));
  row-gap: 16px;
  column-gap: 16px;
  padding: 0;
  margin: 0 auto 16px;
  text-align: center;
  color: #63666a;
  width: fit-content;

  li {
    list-style: none;
    display: flex;
    font-weight: 200;
    font-size: 14px;
    line-height: 20px;
    min-width: 178px;
    justify-content: center;
  }

  svg {
    margin-right: 5px;
  }

  ${({ theme }) =>
    theme.media(
      '10',
      `
    grid-template-columns: repeat(3, 1fr);
    row-gap: 9px;
    margin-bottom: 14px;

    svg {
      margin-right: 7px;
    }
  `
    )};

  @media (max-width: 400px), (min-width: 590px) and (max-width: 710px) {
    li {
      font-size: 12px;
      letter-spacing: -0.5px;
      min-width: 142px;
    }
  }
`;

const LearnMore = styled.p`
  font-weight: 200;
  font-size: 14px;
  line-height: 14px;
  color: #63666a;
  margin-top: 16px;

  a {
    color: inherit;
  }

  ${({ theme }) =>
    theme.media(
      '10',
      `
    margin-top: 14px;
  `
    )};
`;

const Banner = styled.div`
  background: #d2dce9;
  width: 100%;
  margin: 24px 0;
  padding: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #1d4f91;

  ${({ theme }) =>
    theme.media(
      '10',
      `
    margin: 32px 0;
  `
    )}
`;

const TickIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 15.227C3.58168 15.227 0 11.8184 0 7.61349C0 3.40864 3.58168 0 8 0C12.4183 0 16 3.40864 16 7.61349C16 11.8184 12.4183 15.227 8 15.227ZM8 14.3041C11.8828 14.3041 15.0303 11.3087 15.0303 7.61349C15.0303 3.91831 11.8828 0.922848 8 0.922848C4.11723 0.922848 0.969697 3.91831 0.969697 7.61349C0.969697 11.3087 4.11723 14.3041 8 14.3041ZM12.3293 4.75568C12.1436 4.57207 11.8367 4.56649 11.6437 4.74321L6.52133 9.43535L5.11114 7.41409C4.96303 7.20179 4.66211 7.14396 4.43903 7.28492C4.21595 7.42588 4.15519 7.71226 4.3033 7.92456L6.03494 10.4066C6.2023 10.6464 6.5571 10.6835 6.77509 10.4838L12.3162 5.40811C12.5091 5.23139 12.515 4.93929 12.3293 4.75568Z"
      fill="#1D4F91"
    />
  </svg>
);

const Benefits = ({ trial }) => (
  <React.Fragment>
    <BenefitsItems>
      <li>
        <TickIcon /> Daily credit score updates
      </li>
      <li>
        <TickIcon /> Score builder
      </li>
      <li>
        <TickIcon /> Daily Experian Credit Report
      </li>
      <li>
        <TickIcon /> Expert phone support
      </li>
      <li>
        <TickIcon /> Credit and fraud alerts
      </li>
      <li>
        <TickIcon /> Score influencers
      </li>
    </BenefitsItems>

    <LearnMore>
      Learn more about{' '}
      <a
        href="https://www.experian.co.uk/consumer/product-factsheets/CreditExpertwithAccount.pdf"
        target="_blank"
        rel="noreferrer"
      >
        CreditExpert
      </a>
    </LearnMore>

    <Banner id="cancel-banner" data-automation-test-element="upsell-subtitle">
      {!trial && (
        <React.Fragment>
          &pound;14.99/month.
          <br />
        </React.Fragment>
      )}
      Cancel anytime, online or by phone.
    </Banner>
  </React.Fragment>
);

Benefits.propTypes = {
  trial: PropTypes.bool.isRequired,
};

export default Benefits;
