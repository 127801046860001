import { ApplePay, GooglePay } from '@epl/icons';

export default vendor => {
  const dataMap = new Map([
    [
      'GooglePay',
      {
        Logo: GooglePay,
        tradeName: 'Google Pay',
      },
    ],
    [
      'ApplePay',
      {
        Logo: ApplePay,
        tradeName: 'Apple Pay',
      },
    ],
  ]);

  return dataMap.get(vendor);
};
