import { PRODUCT_DETAILS_FETCHED } from '../actions/product/getDetails';

const defaultState = {};

export default (state = defaultState, action = {}) => {
  if (action.error) {
    return {
      ...state,
      error: [PRODUCT_DETAILS_FETCHED].includes(action.type),
    };
  }

  switch (action.type) {
    case PRODUCT_DETAILS_FETCHED:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
