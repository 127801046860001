import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const HeaderContainer = styled.div`
  z-index: 2;
  color: white;
  position: relative;
  max-width: 400px;
  margin: 0 auto;
  padding: 24px 0;

  h1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    font-size: 32px;
    font-weight: 500;
    line-height: 50px;
    width: fit-content;
    min-width: fit-content;
    padding: 0;
  }

  p {
    font-size: 18px;
    font-weight: 500;
    margin: 0;
    padding-top: 13px;
    line-height: 28px;
  }

  ${({ theme: { media } }) =>
    media(
      '10',
      `
    max-width: 90%;

    h1 {
      font-size: 40px;
    }
  `
    )}
`;

const MidlineBreak = styled.span`
  display: inline-block;

  > span {
    font-weight: 200;
  }
`;

export default class Header extends React.Component {
  getHeaderDescription = () => {
    const { variant, eligibleForTrial } = this.props;
    if (eligibleForTrial) {
      if (variant === 'D') {
        return (
          <React.Fragment>
            <h1 data-automation-test-element="upsell-title">
              <MidlineBreak>
                Try <span>Credit</span>Expert,&nbsp;
              </MidlineBreak>
              <MidlineBreak>free for 30 days.</MidlineBreak>
            </h1>
            <p data-automation-test-element="upsell-subtitle">Cancel anytime, online or by phone.</p>
          </React.Fragment>
        );
      }
      if (variant === 'E') {
        return (
          <h1 data-automation-test-element="upsell-title">
            <MidlineBreak>
              Try <span>Credit</span>Expert,&nbsp;
            </MidlineBreak>
            <MidlineBreak>free for 30 days.</MidlineBreak>
          </h1>
        );
      }
    } else {
      if (variant === 'D') {
        return (
          <React.Fragment>
            <h1 data-automation-test-element="upsell-title">
              <MidlineBreak>Reach your goals&nbsp;</MidlineBreak>
              <MidlineBreak>
                with <span>Credit</span>Expert
              </MidlineBreak>
            </h1>
            <p data-automation-test-element="upsell-subtitle">
              <span>&#163;</span>14.99/month.
              <br /> Cancel anytime, online or by phone.
            </p>
          </React.Fragment>
        );
      }
      if (variant === 'E') {
        return (
          <h1 data-automation-test-element="upsell-title">
            <MidlineBreak>Reach your goals&nbsp;</MidlineBreak>
            <MidlineBreak>
              with <span>Credit</span>Expert
            </MidlineBreak>
          </h1>
        );
      }
    }
    return null;
  };

  render() {
    return <HeaderContainer>{this.getHeaderDescription()}</HeaderContainer>;
  }
}

Header.propTypes = {
  variant: PropTypes.string.isRequired,
  eligibleForTrial: PropTypes.bool.isRequired,
};
