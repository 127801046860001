import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field, Fields, reduxForm, propTypes as formProps, formValueSelector } from 'redux-form';
import creditCardType from 'credit-card-type';
import { connect } from 'react-redux';
import { setAutomationElement } from '@experian-uk/corvetteuk-common-ui';

import { CVVInput } from '../Inputs';
import { ParagraphAlert } from '../Alerts/Alerts.styles';
import icons from '../Icons';
import { getDynamicString } from '../../helpers';
import { defined } from '../../helpers/defined';
import { validateField } from '../../helpers/forms';
import { hiddenInput } from '../../helpers/formStyledComponent';
import validateCardExpiryDate from './validateCardExpiryDate';
import { cardTypes } from '../../constants';
import coreFields, { expiryDateFields } from './coreFields';
import {
  FieldContainer,
  FieldSetContainer,
  FieldSet,
  Form,
  ImageContainer,
  Label,
  NewCardLogo,
  Select,
} from './index.styles';
import validExpiryDate from './validExpiryDate';
import submit from './submit';

const { cardLogos } = icons;
const expiryDate = expiryDateFields();

const cardExpiryDate = fields => (
  <FieldSetContainer>
    <FieldSet>
      <Label>{expiryDate.label}</Label>
      {fields.names.map(fieldName => (
        <FieldContainer key={fieldName} inputType="select" validate={validateField.cvvNumber}>
          <Select
            {...fields[fieldName].input}
            name={fieldName}
            {...setAutomationElement(expiryDate.props[fieldName].automation)}
          >
            <option key={expiryDate.blanks[fieldName]}>{expiryDate.blanks[fieldName]}</option>
            {expiryDate.options[fieldName].map(option => (
              <option key={option.key} value={option.key}>
                {option.description}
              </option>
            ))}
          </Select>
        </FieldContainer>
      ))}
    </FieldSet>
    {fields.names.map(fieldName => {
      const {
        meta: { error, touched },
      } = fields[fieldName];
      return (
        <Fragment key={fieldName}>
          {touched && error && (
            <ParagraphAlert {...setAutomationElement(`${expiryDate.props[fieldName].automation}Validation`)}>
              {getDynamicString(`forms.${error}`, expiryDate.props[fieldName].errorMessage[error])}
            </ParagraphAlert>
          )}
        </Fragment>
      );
    })}
  </FieldSetContainer>
);
const form = 'productMovement/newCard';
const formSelector = formValueSelector(form);

@reduxForm({
  form,
  initialValues: {
    creditCardNumber: '',
    nameOnCreditCard: '',
    expirationMonth: '',
    expirationYear: '',
    CVV: '',
    creditCardType: '',
  },
  validate: validateCardExpiryDate,
  onSubmit: submit,
})
@connect(state => ({
  currentCreditCardType: formSelector(state, 'creditCardType'),
}))
export default class NewCard extends React.Component {
  changeEvents = {
    creditCardNumber: number => {
      if (!number) {
        this.props.change('creditCardType', '');
        return;
      }

      const possibleTypes = creditCardType(number);
      const currentType = possibleTypes.find(t => cardTypes.includes(t.type));

      if (currentType) {
        this.props.change('creditCardType', currentType.type);
      }
    },
  };

  static propTypes = {
    ...formProps,
    currentCreditCardType: PropTypes.string,
  };

  static defaultProps = {
    currentCreditCardType: '',
  };

  handleChange = e => {
    const { name, value } = e.currentTarget;
    if (this.changeEvents[name]) {
      this.changeEvents[name](value);
    }
  };

  render() {
    const { currentCreditCardType } = this.props;

    return (
      <Form method="post" {...setAutomationElement('newCardForm')} data-hook="fs-exclude-element">
        {coreFields.map(field => (
          <FieldSetContainer key={field.id}>
            <FieldSet>
              <Label htmlFor={field.id}>
                <span>{field.label}</span>
                <ImageContainer>
                  {field.images &&
                    defined('array', field.images) &&
                    field.images.map(image => (
                      <NewCardLogo
                        key={image}
                        src={cardLogos[image][0]}
                        alt={image}
                        disable={currentCreditCardType && currentCreditCardType !== image}
                      />
                    ))}
                </ImageContainer>
              </Label>
              <FieldContainer inputType={field.type}>
                <Field
                  automation={field.automation}
                  component={field.component}
                  errorMessageProps={field.errorMessageProps}
                  inputProps={field.props}
                  name={field.id}
                  normalize={field.normalize}
                  onChange={this.handleChange}
                  validate={field.validate}
                />
              </FieldContainer>
            </FieldSet>
          </FieldSetContainer>
        ))}
        <Fields names={Object.keys(expiryDate.options)} component={cardExpiryDate} validate={validExpiryDate} />
        <Label htmlFor="CVV">CVV number</Label>
        <CVVInput inputName="CVV" />
        <Field component={hiddenInput} name="creditCardType" {...setAutomationElement('creditCardType')} />
      </Form>
    );
  }
}
