import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import PropTypes from 'prop-types';
import { breakpoints } from '../ModalUpsell.styles';

const CEScoreUpdatesSVG = styled.svg`
  margin: ${rem(14)} auto ${rem(-2)};
  display: inherit;
  max-width: ${rem(90)};
  height: auto;

  @media only screen and (min-width: ${breakpoints.mobile}) {
    max-width: ${props => (props.isInactive ? `${rem(81)}` : `${rem(64)}`)};
  }
`;

const CEScoreUpdates = ({ className, isInactive = false }) =>
  isInactive ? (
    <CEScoreUpdatesSVG
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 92 84"
      style={{}}
      xmlSpace="preserve"
      isInactive={isInactive}
    >
      <title>Score updates icon</title>
      <g opacity="0.3">
        <mask id="mask0_1480_78514" maskUnits="userSpaceOnUse" x="0" y="3" width="82" height="69">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M41.2235 14.5165C45.6367 14.516 49.9876 15.4999 53.9494 17.3884L58.856 7.09513C53.3573 4.47401 47.3292 3.1129 41.2223 3.11353C35.1154 3.11416 29.0877 4.47651 23.5895 7.09877L28.4983 17.3911C32.4597 15.5017 36.8103 14.5169 41.2235 14.5165ZM27.1355 18.0852C23.2834 20.1755 19.9739 23.0902 17.4455 26.601C14.9176 30.1112 13.2337 34.1291 12.5096 38.3528L1.27062 36.4261C2.28906 30.4855 4.65524 24.8488 8.19233 19.9372C11.7294 15.0258 16.3459 10.9665 21.6968 8.06281L27.1355 18.0852ZM12.2963 39.8324C11.7983 44.0864 12.2807 48.3984 13.7109 52.4489C15.1413 56.5 17.4859 60.1929 20.5806 63.2462L12.5722 71.3635C8.25393 67.1032 4.96754 61.9351 2.95859 56.2455C0.949629 50.5558 0.270077 44.4918 0.970714 38.5066L12.2963 39.8324ZM65.0041 26.5962C62.475 23.0858 59.1648 20.1718 55.3123 18.0823L60.7489 8.05878C66.1004 10.9613 70.7178 15.0197 74.2559 19.9304C77.794 24.8412 80.1614 30.4774 81.1811 36.4178L69.9425 38.3469C69.2175 34.1234 67.5328 30.1058 65.0041 26.5962ZM70.1561 39.8265C70.655 44.0804 70.1735 48.3925 68.7442 52.4433C67.3146 56.4947 64.9709 60.188 61.8768 63.2419L69.887 71.3576C74.2043 67.0964 77.4896 61.9276 79.4973 56.2376C81.5051 50.5475 82.1833 44.4833 81.4814 38.4983L70.1561 39.8265Z"
            fill="#F7F4F1"
          />
        </mask>
        <g mask="url(#mask0_1480_78514)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M41.2235 14.5165C45.6367 14.516 49.9876 15.4999 53.9494 17.3884L58.856 7.09513C53.3573 4.47401 47.3292 3.1129 41.2223 3.11353C35.1154 3.11416 29.0877 4.47651 23.5895 7.09877L28.4983 17.3911C32.4597 15.5017 36.8103 14.5169 41.2235 14.5165ZM27.1355 18.0852C23.2834 20.1755 19.9739 23.0902 17.4455 26.601C14.9176 30.1112 13.2337 34.1291 12.5096 38.3528L1.27062 36.4261C2.28906 30.4855 4.65524 24.8488 8.19233 19.9372C11.7294 15.0258 16.3459 10.9665 21.6968 8.06281L27.1355 18.0852ZM12.2963 39.8324C11.7983 44.0864 12.2807 48.3984 13.7109 52.4489C15.1413 56.5 17.4859 60.1929 20.5806 63.2462L12.5722 71.3635C8.25393 67.1032 4.96754 61.9351 2.95859 56.2455C0.949629 50.5558 0.270077 44.4918 0.970714 38.5066L12.2963 39.8324ZM65.0041 26.5962C62.475 23.0858 59.1648 20.1718 55.3123 18.0823L60.7489 8.05878C66.1004 10.9613 70.7178 15.0197 74.2559 19.9304C77.794 24.8412 80.1614 30.4774 81.1811 36.4178L69.9425 38.3469C69.2175 34.1234 67.5328 30.1058 65.0041 26.5962ZM70.1561 39.8265C70.655 44.0804 70.1735 48.3925 68.7442 52.4433C67.3146 56.4947 64.9709 60.188 61.8768 63.2419L69.887 71.3576C74.2043 67.0964 77.4896 61.9276 79.4973 56.2376C81.5051 50.5475 82.1833 44.4833 81.4814 38.4983L70.1561 39.8265Z"
            fill="white"
          />
          <path
            d="M16.5764 67.3049C10.7208 61.5278 7.15885 53.8924 6.51146 45.7769C5.8641 37.6617 8.1715 29.5767 13.0333 22.9747C17.8966 16.3708 25.0013 11.6798 33.0806 9.76614C41.1609 7.85226 49.6667 8.84703 57.0631 12.5651"
            stroke="#B3BECC"
            strokeWidth="11.4029"
          />
        </g>
        <g filter="url(#filter0_d_1480_78514)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M55.3125 18.0824C59.165 20.1719 62.4752 23.0859 65.0043 26.5962C67.533 30.1059 69.2177 34.1235 69.9427 38.347L81.1813 36.4178C80.1616 30.4775 77.7942 24.8413 74.2561 19.9305C70.7179 15.0197 66.1006 10.9614 60.7491 8.05884L55.3125 18.0824ZM70.1563 39.8265C70.6552 44.0805 70.1737 48.3925 68.7444 52.4433C67.3148 56.4947 64.9711 60.1881 61.877 63.242L69.8871 71.3577C74.2045 67.0965 77.4898 61.9277 79.4975 56.2376C81.5053 50.5476 82.1835 44.4834 81.4816 38.4983L70.1563 39.8265Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_1480_78514"
          x="45.3125"
          y="0.0588379"
          width="46.4375"
          height="83.2988"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1480_78514" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1480_78514" result="shape" />
        </filter>
      </defs>
    </CEScoreUpdatesSVG>
  ) : (
    <CEScoreUpdatesSVG
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 91 77"
      style={{}}
      xmlSpace="preserve"
      isInactive={isInactive}
    >
      <title>Score dial icon</title>
      <path
        d="M45.5 11.9c5.1 0 10 1.1 14.6 3.3l4.8-10.1C58.8 2.2 52.2.7 45.5.7c-6.7 0-13.4 1.5-19.4 4.4l4.8 10.1c4.6-2.1 9.5-3.3 14.6-3.3zM29.3 16c-4.4 2.4-8.2 5.8-11.1 9.8-2.9 4.1-4.9 8.7-5.7 13.6l-11-1.9C2.6 31 5.2 24.8 9.1 19.4s9-9.9 14.9-13.1l5.3 9.7zm-17 25.2c-.6 4.9 0 9.9 1.6 14.6 1.6 4.7 4.4 9 7.9 12.5l-7.9 7.9C9.2 71.6 5.6 65.8 3.4 59.5c-2.2-6.3-3-13-2.2-19.6l11.1 1.3zm60.5-15.3c-2.9-4.1-6.7-7.4-11.1-9.8L67 6.2c5.9 3.2 11 7.7 14.9 13.1 3.9 5.4 6.5 11.7 7.6 18.2l-11 1.9c-.9-4.8-2.8-9.5-5.7-13.5zm5.9 15.3c.6 4.9 0 9.9-1.6 14.6-1.6 4.7-4.3 9-7.9 12.5l7.9 7.9c4.8-4.7 8.4-10.4 10.6-16.7 2.2-6.3 3-13 2.2-19.6l-11.2 1.3z"
        style={{
          fillRule: 'evenodd',
          clipRule: 'evenodd',
          fill: '#ecf0f6',
        }}
      />
      <path
        d="M29.3 16c-4.4 2.4-8.2 5.8-11.1 9.8-2.9 4.1-4.9 8.7-5.7 13.6l-11-1.9C2.6 31 5.2 24.8 9.1 19.4s9-9.9 14.9-13.1l5.3 9.7z"
        style={{
          fillRule: 'evenodd',
          clipRule: 'evenodd',
          fill: '#dd8838',
        }}
      />
      <path
        d="M12.3 41.2c-.6 4.9 0 9.9 1.6 14.6 1.6 4.7 4.4 9 7.9 12.5l-7.9 7.9C9.2 71.6 5.6 65.8 3.4 59.5c-2.2-6.3-3-13-2.2-19.6l11.1 1.3z"
        style={{
          fillRule: 'evenodd',
          clipRule: 'evenodd',
          fill: '#ae322f',
        }}
      />
      <path
        d="M45.5 11.9c5.1 0 10 1.1 14.6 3.3l4.8-10.1C58.8 2.2 52.2.7 45.5.7c-6.7 0-13.4 1.5-19.4 4.4l4.8 10.1c4.6-2.1 9.5-3.3 14.6-3.3z"
        style={{
          fillRule: 'evenodd',
          clipRule: 'evenodd',
          fill: '#f9db4a',
        }}
      />
    </CEScoreUpdatesSVG>
  );

CEScoreUpdates.defaultProps = {
  className: undefined,
  isInactive: undefined,
};

CEScoreUpdates.propTypes = {
  className: PropTypes.string,
  isInactive: PropTypes.bool,
};

export default CEScoreUpdates;
