import styled from 'styled-components';

import CardNumber from '../../../../components/Icons/CardNumber';
import CardSecurityCode from '../../../../components/Icons/CardSecurityCode';

export const StyledCardNumberIcon = styled(CardNumber)`
  margin-right: 5px;
`;

export const CardSecurityCodeIcon = styled(CardSecurityCode)`
  margin-right: 5px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 16px;

  p {
    margin: 0;
  }
`;
