import React from 'react';
import { EnvConsumer } from '.';

const Wrapper = Cmp => {
  const Output = props => <EnvConsumer>{context => <Cmp {...props} env={context} />}</EnvConsumer>;
  Output.displayName = `withEnv(${Cmp.displayName || Cmp.name || 'Component'})`;

  return Output;
};

export default Wrapper;
