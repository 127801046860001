import React from 'react';
import PropTypes from 'prop-types';
import { setAutomationElement } from '@experian-uk/corvetteuk-common-ui';
import { DownloadLink, FooterContent } from './Footer.styles';
import familyName from '../../../constants/familyName';
import { DownloadIcon } from '../../Icons/DownloadIcon';

const Footer = ({ fewerPanels, isComplimentary, isFree, productKey, pdfDownloadLink }) => (
  <FooterContent fewerPanels={fewerPanels}>
    <DownloadIcon />
    <DownloadLink
      href={pdfDownloadLink}
      target="_blank"
      {...setAutomationElement(`${productKey}-panel-product-factsheet-link`)}
    >
      View {familyName[productKey]}
      {isComplimentary && isFree && ' Experian'} product fact sheet
    </DownloadLink>
  </FooterContent>
);

Footer.propTypes = {
  fewerPanels: PropTypes.bool,
  isComplimentary: PropTypes.bool,
  isFree: PropTypes.bool,
  productKey: PropTypes.string.isRequired,
  pdfDownloadLink: PropTypes.string.isRequired,
};

Footer.defaultProps = {
  fewerPanels: false,
  isComplimentary: false,
  isFree: false,
};

export default Footer;
