const subtitle = (price, freeTrial, isBreachUser, deferredProductPrice, basicToBreach) => {
  if ((isBreachUser && !deferredProductPrice) || basicToBreach) {
    return 'Your subscription is complimentary';
  }
  if (!isBreachUser && price) {
    return freeTrial ? `Free for 30 days, then it’s ${price} every month from then on.` : `${price} every month`;
  }
  if (isBreachUser && deferredProductPrice) {
    return freeTrial
      ? `Free for 30 days, then it’s £${deferredProductPrice} every month from then on.`
      : `£${deferredProductPrice} every month`;
  }
  if (freeTrial) {
    return 'Free for 30 days';
  }
  return '';
};

export default subtitle;
