import React, { useState } from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';

import { cardUtility } from '../../../../../../helpers/forms';
import VisaIncLogo from '../../assets/visa-inc-logo.svg';
import MasterCardLogo from '../../assets/mc-symbol.svg';
import LockIcon from '../../assets/lock.svg';
import FormInputValidationWrapper from '../FormInputValidationWrapper';

const StyledCardNumberContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledCardNumberLabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  color: #63666a;
  font-weight: 300;
  margin-bottom: 10px;
`;

const StyledCardNumberIconsContainer = styled.div`
  margin-right: 4px;
  display: flex;
  align-items: center;
  gap: 3px;

  .lock-icon {
    margin-right: 6px;
  }

  .mastercard-icon {
    opacity: ${({ isVisaCard }) => (isVisaCard ? '0.3' : 'unset')};
    filter: ${({ isVisaCard }) => (isVisaCard ? 'blur(2px)' : 'unset')};
  }

  .visa-icon {
    opacity: ${({ isMastercard }) => (isMastercard ? '0.3' : 'unset')};
    filter: ${({ isMastercard }) => (isMastercard ? 'blur(2px)' : 'unset')};
  }
`;

const fieldAttributes = {
  name: 'creditCardNumber',
  component: FormInputValidationWrapper,
  inputMode: 'numeric',
  inputProps: {
    maxLength: 16 + 3, // 16 numbers and 3 white space after normalize
  },
  normalize: cardUtility.formatNumber,
  validate: cardUtility.isCardNumberValid,
  onChange: (event, setCardType, change) => {
    setCardType(cardUtility.returnCardType(event.target.value));
    change('creditCardType', cardUtility.returnCardType(event.target.value));
  },
  errorMessageProps: {
    required: 'Please enter your Visa or Mastercard number',
    format: 'The card number you have entered is not valid, please double check it',
    discover: 'Sorry, it looks like you’ve used Discover, please try another card',
    amex: 'Sorry, it looks like you’ve used American Express, please try another card',
    jcb: 'Sorry, it looks like you’ve used JCB, please try another card',
    dinersclub: 'Sorry, it looks like you’ve used Diners Club, please try another card',
    maestro: 'Sorry, it looks like you’ve used Maestro, please try another card',
    laser: 'Sorry, it looks like you’ve used Laser, please try another card',
    unionpay: 'Sorry, it looks like you’ve used UnionPay, please try another card',
    elo: 'Sorry, it looks like you’ve used ELO, please try another card',
    hipercard: 'Sorry, it looks like you’ve used Hipercard, please try another card',
  },
};

const CardNumberField = ({ change }) => {
  const [cardType, setCardType] = useState(null);

  return (
    <StyledCardNumberContainer>
      <StyledCardNumberLabelContainer>
        <p>Card number</p>
        <StyledCardNumberIconsContainer isMastercard={cardType === 'mastercard'} isVisaCard={cardType === 'visa'}>
          <SVG className="lock-icon" alt="lock icon" src={LockIcon} />
          <SVG
            className="mastercard-icon"
            alt="mastercard logo"
            src={MasterCardLogo}
            data-automation-test-element="ccnum-mastercard-icon"
          />
          <SVG className="visa-icon" alt="visa logo" src={VisaIncLogo} data-automation-test-element="ccnum-visa-icon" />
        </StyledCardNumberIconsContainer>
      </StyledCardNumberLabelContainer>
      <Field
        name={fieldAttributes.name}
        dataAutomationTestElement={fieldAttributes.name}
        component={fieldAttributes.component}
        inputProps={fieldAttributes.inputProps}
        normalize={fieldAttributes.normalize}
        validate={fieldAttributes.validate}
        errorMessageProps={fieldAttributes.errorMessageProps}
        onChange={event => fieldAttributes.onChange(event, setCardType, change)}
        inputMode={fieldAttributes.inputMode}
      />
    </StyledCardNumberContainer>
  );
};

CardNumberField.propTypes = { change: PropTypes.func.isRequired };

export default CardNumberField;
