import React, { useEffect, useState } from 'react';
import defaultTheme from '@nebula/theme';
import { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Button from '@nebula/button';
import { submit } from 'redux-form';
import PaymentInformation from './nebulaPaymentInformation/paymentInformation';
import UpdateCardDetails from './nebulaPaymentInformation/updateCardDetails/updateCardDetails';
import { familyName, splitsList } from '../../constants';
import { isObjectEmpty } from '../../helpers';
import getSubscriptionData from '../../helpers/subscription/getSubscriptionData';
import { getFormattedExpiryDate } from '../../helpers/billing';
import { cardDetailsSchema, subscriptionSchema } from '../../schemas';
import {
  publishOnClickApplePayECD,
  publishOnClickCancelECD,
} from './nebulaPaymentInformation/nebulaPaymentInformationsEcds';
import {
  StyledButton,
  StyledButtonsContainer,
  StyledChangePaymentMethodSection,
} from './nebulaAccountPaymentSection.styles';
import uiChangeState from '../../actions/uiChangeState';
import { getSplitStatus } from '../../reducers/split';
import getCurrentSubscriptionPrice from '../../reducers/selectors/currentSubscriptionPrice';
import { selectPage } from '../../reducers/uiState';
import ChangePaymentMethod from './nebulaPaymentInformation/updateCardDetails/changePaymentMethod';
import { addPaymentMethod, getPaymentMethods, resetCardSaved } from '../../actions/billing/paymentMethods';
import { ApplePayDetailsCantBeUpdatedAlert } from './nebulaPaymentInformation/currentPaymentMethod/paymentMethodAlerts';
import CardSaveError from '../../exceptions/cardSaveError';

const newCardFormName = 'productMovement/newCard';
const NebulaAccountPaymentInformationSection = ({
  nextBillDate,
  noFeeSubscription,
  pendingCancellation,
  currentSubscriptionPrice,
  nextBillingPrice,
  currentSubscription,
  card,
  mobilePay,
  directDebit,
  isRecycle,
  splits,
  uiChangeStateAction,
  getPaymentMethodsAction,
  addPaymentMethodAction,
  resetCardSavedAction,
  submitAction,
  loading,
  showChangePaymentMethod,
  newCardForm,
  billing,
}) => {
  const validCard = !!card && !isObjectEmpty(card);
  let expirationDate = null;
  if (validCard) {
    const { expirationMonth, expirationYear } = card;
    expirationDate = getFormattedExpiryDate(`${expirationMonth}/${expirationYear}`);
  }

  const isRecycleSplit = isRecycle && splits[splitsList.showRecycleBanner];
  const subsData = getSubscriptionData(
    nextBillDate,
    noFeeSubscription,
    pendingCancellation,
    currentSubscriptionPrice,
    nextBillingPrice,
    familyName[currentSubscription?.family],
    true
  );
  const onCancelClick = e => {
    e.preventDefault();
    uiChangeStateAction(window.location.pathname, {
      showCardForm: false,
      showChangePaymentMethod: false,
      shouldClearErrorsOfType: CardSaveError.errorType,
    });
    publishOnClickCancelECD();
  };
  const initialPaymentMethod = mobilePay ? 'ApplePay' : 'Card';
  const showApplePay = !!window?.ApplePaySession?.canMakePayments?.('YOUR MERCHANT IDENTIFIER');
  const [paymentMethodOption, setPaymentMethodOption] = useState(showApplePay ? initialPaymentMethod : 'Card');
  const applePayOption = 'ApplePay';
  const cardOption = 'Card';

  const onApplePayClick = () => {
    publishOnClickApplePayECD();
    // temporary until apple pay api logic returns a success and fail
    uiChangeStateAction(window.location.pathname, {
      showChangePaymentSuccess: true,
      showChangePaymentMethod: false,
      showApplePayChangePaymentFail: true,
    });
    setPaymentMethodOption(applePayOption);
  };
  const onSaveClick = async e => {
    e.preventDefault();
    uiChangeStateAction(window.location.pathname, {
      showCardForm: true,
    });
    await submitAction(newCardFormName);
    if (newCardForm.submitFailed) {
      return;
    }
    const modifiedCardForm = { ...newCardForm };
    modifiedCardForm.values.creditCardNumber = modifiedCardForm.values.creditCardNumber.replace(/\s/g, '');
    await addPaymentMethodAction(modifiedCardForm.values, currentSubscription.offerId);
  };

  useEffect(() => {
    if (billing.cardSaved === true) {
      uiChangeStateAction(window.location.pathname, {
        showChangePaymentMethod: false,
        showChangePaymentSuccess: true,
      });

      getPaymentMethodsAction();
      setPaymentMethodOption(cardOption);
      resetCardSavedAction(billing);
    }
  }, [billing.cardSaved]);

  const applePayButton = document.getElementById('ApplePayButton');
  applePayButton?.addEventListener('click', onApplePayClick);

  return (
    <ThemeProvider theme={defaultTheme}>
      <PaymentInformation
        validCard={validCard}
        mobilePay={mobilePay}
        directDebit={directDebit}
        card={card}
        expirationDate={expirationDate}
        isRecycleSplit={isRecycleSplit}
        subscriptionsData={subsData}
      />
      {showChangePaymentMethod && (
        <StyledChangePaymentMethodSection>
          {showApplePay && (
            <ChangePaymentMethod
              validCard={validCard}
              mobilePay={mobilePay}
              setPaymentMethodOption={setPaymentMethodOption}
            />
          )}
          {initialPaymentMethod === applePayOption && !showApplePay && <ApplePayDetailsCantBeUpdatedAlert />}
          {paymentMethodOption === cardOption && <UpdateCardDetails />}
          <StyledButtonsContainer>
            {showApplePay && paymentMethodOption === applePayOption && (
              <apple-pay-button buttonstyle="black" type="continue" locale="en-GB" id="ApplePayButton" />
            )}
            {paymentMethodOption === cardOption && (
              <StyledButton
                label="Save"
                onClick={onSaveClick}
                disabled={loading || !validCard}
                data-automation-test-element="saveCard"
              />
            )}
            <Button
              label="Cancel"
              buttonKind="secondaryBlue"
              onClick={onCancelClick}
              data-automation-test-element="cancelButton"
            />
          </StyledButtonsContainer>
        </StyledChangePaymentMethodSection>
      )}
    </ThemeProvider>
  );
};

NebulaAccountPaymentInformationSection.propTypes = {
  card: PropTypes.shape(cardDetailsSchema),
  mobilePay: PropTypes.shape({
    paymentType: PropTypes.string,
  }),
  directDebit: PropTypes.shape({
    accountNumber: PropTypes.string,
  }),
  nextBillDate: PropTypes.string,
  currentSubscription: PropTypes.shape(subscriptionSchema),
  noFeeSubscription: PropTypes.bool,
  pendingCancellation: PropTypes.bool,
  currentSubscriptionPrice: PropTypes.number,
  nextBillingPrice: PropTypes.number,
  splits: PropTypes.shape({
    showRecycleBanner: PropTypes.bool,
  }).isRequired,
  isRecycle: PropTypes.bool,
  uiChangeStateAction: PropTypes.func,
  getPaymentMethodsAction: PropTypes.func,
  addPaymentMethodAction: PropTypes.func,
  resetCardSavedAction: PropTypes.func,
  submitAction: PropTypes.func,
  loading: PropTypes.bool,
  showChangePaymentMethod: PropTypes.bool,
  newCardForm: PropTypes.shape(),
  billing: PropTypes.shape({
    authorized: PropTypes.bool,
    cardSaved: PropTypes.bool,
    challengeUrl: PropTypes.string,
    fetchFailed: PropTypes.bool,
    endDate: PropTypes.string,
    freeTrialTerms: PropTypes.shape({
      remaining: PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      }),
    }),
    nextBillingDate: PropTypes.string,
  }).isRequired,
};
NebulaAccountPaymentInformationSection.defaultProps = {
  card: null,
  mobilePay: null,
  directDebit: null,
  nextBillDate: '',
  currentSubscription: '',
  noFeeSubscription: false,
  pendingCancellation: false,
  currentSubscriptionPrice: null,
  nextBillingPrice: null,
  isRecycle: false,
  uiChangeStateAction: undefined,
  getPaymentMethodsAction: undefined,
  addPaymentMethodAction: undefined,
  resetCardSavedAction: undefined,
  submitAction: undefined,
  loading: false,
  showChangePaymentMethod: false,
  newCardForm: undefined,
};
const mapReduxStateToProps = state => ({
  showChangePaymentMethod: selectPage(state.uiState, window.location.pathname).showChangePaymentMethod,
  nextBillDate: state.billing.nextBillingDate,
  card: state.billing.card,
  mobilePay: state.billing.mobile,
  directDebit: state.billing.directDebit,
  currentSubscription: state.subscriptions.current,
  noFeeSubscription: state.billing.signupPrice === 0,
  pendingCancellation: !!state.subscriptions.current.defermentInfo,
  currentSubscriptionPrice: getCurrentSubscriptionPrice(state),
  nextBillingPrice: getCurrentSubscriptionPrice(state),
  splits: getSplitStatus(state.split, [splitsList.showRecycleBanner, splitsList.applePay]),
  isRecycle: state.billing.billingStateRecycle,
  newCardForm: state.form[newCardFormName],
  billing: state.billing,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      uiChangeStateAction: uiChangeState,
      addPaymentMethodAction: addPaymentMethod,
      getPaymentMethodsAction: getPaymentMethods,
      submitAction: submit,
      resetCardSavedAction: resetCardSaved,
    },
    dispatch
  );

export default connect(mapReduxStateToProps, mapDispatchToProps)(NebulaAccountPaymentInformationSection);
