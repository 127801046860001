import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';

import PositiveValidation from '../assets/positive-validation.svg';
import NegativeValidationIcon from '../assets/NegativeValidationIcon';

const FIELD_HEIGHT = '50px';

const StyledInputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledInputBox = styled.div`
  position: relative;
  width: 100%;
  height: ${FIELD_HEIGHT};
`;

const StyledInput = styled.input`
  padding: 10px 8px;
  width: 100%;
  max-width: 100%;
  min-height: 100%;
  border: 1px solid ${({ failing }) => (failing ? '#ec0000' : '#a5b9d3')};
  border-radius: 5px;
  box-shadow: inset 0 2px 4px #a0a0a01a;
  ${({ failing }) => failing && 'box-shadow: 0 0 0 4px #e4002b33;'}
  font-size: 16px;

  :focus-visible {
    outline: unset;
  }
`;

const StyledValidationIcon = styled.div`
  height: 24px;
  width: 24px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
`;

const StyledErrorMessage = styled.div`
  color: #ec0000;
  font-size: 14px;
  font-weight: 600;
  padding: 8px 0;
  min-width: 200px;
`;

const FormInputValidationWrapper = ({
  type = 'text',
  inputMode = 'text',
  dataAutomationTestElement,
  errorMessageProps,
  inputProps,
  input,
  meta: { touched, error },
}) => {
  const failing = (touched && error) || (input.value.length === inputProps.maxLength && error);
  const passedValidation = !error;
  return (
    <StyledInputWrapper>
      <StyledInputBox>
        <StyledInput
          type={type}
          inputMode={inputMode}
          data-automation-test-element={dataAutomationTestElement}
          {...input}
          {...inputProps}
          failing={failing}
        />
        {(failing || passedValidation) && (
          <StyledValidationIcon>
            {failing && <NegativeValidationIcon />}
            {passedValidation && <SVG alt="Passed validation icon" src={PositiveValidation} width={24} height={24} />}
          </StyledValidationIcon>
        )}
      </StyledInputBox>
      {failing && (
        <StyledErrorMessage data-automation-test-element={`${dataAutomationTestElement}ValidationError`}>
          {errorMessageProps[error]}
        </StyledErrorMessage>
      )}
    </StyledInputWrapper>
  );
};

FormInputValidationWrapper.propTypes = {
  type: PropTypes.string,
  inputMode: PropTypes.string,
  dataAutomationTestElement: PropTypes.string.isRequired,
  errorMessageProps: PropTypes.objectOf(PropTypes.string),
  inputProps: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number, PropTypes.func])
  ),
  input: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.func])).isRequired,
  meta: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number, PropTypes.func]))
    .isRequired,
};

FormInputValidationWrapper.defaultProps = {
  type: 'text',
  inputMode: 'text',
  errorMessageProps: {
    required: undefined,
    format: undefined,
    unsupported: undefined,
  },
  inputProps: undefined,
};

export default FormInputValidationWrapper;
