import moment from 'moment';
import { dateFormats } from '../../constants';

export default (
  nextBill,
  noFeeSubscription,
  pendingCancellation,
  currentSubscriptionPrice,
  nextBillingPrice,
  subscription,
  isApplePaySplit
) => {
  const nextBillDate = !!nextBill && moment(nextBill).isValid ? moment(nextBill) : undefined;
  const subscriptionDetails = [
    {
      content: subscription,
      id: 'subType',
      label: isApplePaySplit ? 'Subscription' : 'Your subscription',
    },
  ];

  const subPriceDetails = currentSubscriptionPrice && [
    {
      content: `£${currentSubscriptionPrice}`,
      id: 'subPrice',
      label: 'Price',
    },
  ];

  const billDateDetails = nextBillDate && [
    {
      content: `${nextBillDate.format('Do')} of each month`,
      id: 'billDate',
      label: isApplePaySplit ? 'Due' : 'Your billing date',
    },
  ];

  let nextBillDetails = nextBillingPrice &&
    nextBillDate && [
      {
        content: `£${nextBillingPrice} on ${nextBillDate.format(dateFormats.long)}`,
        id: 'billType',
        label: 'Next bill',
      },
    ];

  if (isApplePaySplit) {
    nextBillDetails = nextBillingPrice &&
      nextBillDate && [
        {
          content: nextBillDate.format(dateFormats.long),
          id: 'billType',
          label: 'Next bill',
        },
      ];
  }

  const paymentDetails = [
    ...subscriptionDetails,
    ...(subPriceDetails || []),
    ...(billDateDetails || []),
    ...(nextBillDetails || []),
  ];

  return pendingCancellation || noFeeSubscription ? subscriptionDetails : paymentDetails;
};
