import fetch from '../fetch';
import { errorMessages } from '../../constants';

export const COULD_NOT_VALIDATE_VOUCHER = 'COULD_NOT_VALIDATE_VOUCHER';
export const INVALID_VOUCHER = 'INVALID_VOUCHER';
export const VALIDATE_VOUCHER = 'VALIDATE_VOUCHER';
export const VALID_VOUCHER = 'VALID_VOUCHER';

export const reasonMap = {
  'Code already used': errorMessages.billing.usedVoucherCode,
  'Code expired': errorMessages.billing.expiredVoucherCode,
  'Code invalid': errorMessages.billing.invalidVoucherCode,
  'EndDate will exceed 3 years': errorMessages.billing.limit,
  'Error with customer billing subscription': errorMessages.billing.subscriptionError,
  expired: errorMessages.billing.expiredVoucherCode,
  invalid: errorMessages.billing.invalidVoucherCode,
  other: errorMessages.billing.technicalError,
  used: errorMessages.billing.usedVoucherCode,
};

const consumeErrorResponse = async (errorResponse, isExtensionError) => {
  try {
    const errorResponseJson = await errorResponse.json();
    const shortErrMsg = isExtensionError ? errorResponseJson.message : errorResponseJson.fieldErrors.voucher;
    const ecdFailureReason = shortErrMsg.toLowerCase().replaceAll(' ', '_');
    return { ecdFailureReason, mappedMessage: reasonMap[shortErrMsg] || reasonMap.other };
  } catch (err) {
    return { ecdFailureReason: 'unknown_error', mappedMessage: reasonMap.other };
  }
};

// isBreachRedemption: Free account attempting to upsell to breach
// isBreachExtension: Breach account holder attempting to extend subscription
export default (code, isBreachRedemption, isBreachExtension = false) =>
  async dispatch => {
    if (!code || typeof code !== 'string') {
      return Promise.reject(new Error('Voucher Code must be supplied'));
    }

    dispatch({ type: VALIDATE_VOUCHER });

    const fetched = {
      type: VALID_VOUCHER,
      payload: { code },
    };

    try {
      if (isBreachExtension) {
        const endpoint = 'billingsubscriptions/extend/voucher';
        const res = await dispatch(
          fetch(
            `/${endpoint}`,
            {
              method: 'PUT',
              body: {
                voucherCode: code,
              },
            },
            false,
            'anonymous',
            true
          )
        );
        fetched.payload = { ...fetched.payload, ...res };
      } else if (isBreachRedemption) {
        const endpoint = 'billingvouchers/vouchers';
        await dispatch(fetch(`/${endpoint}/${code}`, {}, false, 'anonymous', true));
      } else {
        const endpoint = 'billingvouchers/vouchers';
        await dispatch(
          fetch(`/${endpoint}`, {
            method: 'POST',
            body: {
              code,
            },
          })
        );
      }
      return dispatch(fetched);
    } catch (e) {
      if (e.status && e.status < 500) {
        const consumedError = await consumeErrorResponse(e, isBreachExtension);
        return dispatch({
          type: INVALID_VOUCHER,
          payload: {
            ecdFailureReason: consumedError.ecdFailureReason,
            reason: consumedError.mappedMessage,
          },
        });
      }
      return dispatch({
        type: COULD_NOT_VALIDATE_VOUCHER,
        payload: {
          reason: errorMessages.billing.technicalError,
          ecdFailureReason: 'technical_issue',
        },
      });
    }
  };
