import get from 'lodash/get';
import { LOCATION_CHANGE } from 'connected-react-router';
import { stateVariables } from '../constants';

const shouldClearErrors = (metaData = {}) => !!metaData.clearErrors;

const shouldPersistErrors = payloadData =>
  get(payloadData, `location.state[${stateVariables.persistErrorsOnLocationChange}]`, false);

export default (state = [], action = {}) => {
  const { meta = {}, payload, type } = action;

  if (!payload) {
    return state;
  }

  if (shouldClearErrors(meta) || (type === LOCATION_CHANGE && !shouldPersistErrors(payload))) {
    return [];
  }

  if (meta.shouldClearErrorsOfType) {
    return state.filter(s => s.type !== meta.shouldClearErrorsOfType);
  }

  if (!action.error) {
    return state;
  }

  const errors = state.filter(s => s.error !== action.payload.message);

  const criticalError = action.meta ? action.meta.critical !== false : true;

  return [
    ...errors,
    {
      type: action.payload.name || 'Error',
      error: action.payload.message,
      critical: criticalError,
    },
  ];
};
