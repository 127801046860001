import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { TextInput } from '../../TextInput';
import { getDynamicString } from '../../../helpers';
import ValidationPassIcon from './ValidationPassIcon';
import ValidationErrorIcon from './ValidationErrorIcon';
import PadlockIcon from './icons/PadlockIcon';
import visaLogoSwitchTest from '../UpsellVariantB/UpsellForm/assets/visa-inc-logo.svg';
import mastercardLogoSwitchTest from '../UpsellVariantB/UpsellForm/assets/mc-symbol.svg';

const InputWrapper = styled.div`
  width: 100%;
  position: relative;
`;

const Input = styled(TextInput)`
  border: 1px solid #a5b9d3;
  padding: 12px;
  height: auto;
  width: 100%;
  line-height: 22px;

  ${({ failing }) =>
    failing &&
    `
    border-color: #E4002B;
  `}

  ${({ failing, passing }) => (failing || passing) && 'padding-right: 48px;'}

  ${({ iconSpacing }) =>
    iconSpacing &&
    `
    padding-left: 108px;
  `}

  ::placeholder {
    color: #bcbcbc;
  }
`;

const Validation = styled.div`
  height: 24px;
  width: 24px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
`;

const Icons = styled.div`
  height: 24px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 16px;
  display: flex;
  align-items: center;
  padding-right: 8px;

  &:after {
    content: '';
    border-right: 1px solid #4c6ca4;
    height: 14px;
    width: 1px;
    position: absolute;
    right: 0px;
  }

  img {
    height: 11px;
    margin-left: 6px;
  }
`;

const Error = styled.div`
  margin-top: 8px;
  font-size: 16px;
  line-height: 24px;
  color: #e4002b;
`;

const FormField = ({
  errorMessageProps,
  dataAutomationTestElement,
  input,
  inputProps,
  type = 'text',
  inputMode = 'text',
  meta: { touched, error },
}) => {
  const failedValidation = touched && error;
  const passedValidation = touched && !error;
  return (
    <React.Fragment>
      <InputWrapper>
        {inputProps.isCCNumberInput && (
          <Icons>
            <PadlockIcon />
            <img className="mc-icon" src={mastercardLogoSwitchTest} alt="Mastercard" />
            <img className="visa-icon" src={visaLogoSwitchTest} alt="Visa" />
          </Icons>
        )}
        <Input
          {...input}
          {...inputProps}
          failing={failedValidation}
          passing={passedValidation}
          iconSpacing={inputProps.isCCNumberInput}
          data-automation-test-element={dataAutomationTestElement}
          type={type}
          inputMode={inputMode}
        />
        <Validation>
          {passedValidation && <ValidationPassIcon />}
          {failedValidation && <ValidationErrorIcon />}
        </Validation>
      </InputWrapper>
      {touched && error && (
        <Error data-automation-test-element={`${dataAutomationTestElement}ValidationError`}>
          {errorMessageProps && errorMessageProps[error]
            ? getDynamicString(`forms.${error}`, errorMessageProps[error])
            : error}
        </Error>
      )}
    </React.Fragment>
  );
};

FormField.propTypes = {
  errorMessageProps: PropTypes.shape({
    format: PropTypes.arrayOf(PropTypes.string),
    required: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  dataAutomationTestElement: PropTypes.string.isRequired,
  input: PropTypes.shape({}).isRequired,
  inputProps: PropTypes.shape({
    isCCNumberInput: PropTypes.bool,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    error: PropTypes.string,
  }).isRequired,
  type: PropTypes.string,
  inputMode: PropTypes.string,
};

FormField.defaultProps = {
  type: 'text',
  inputMode: 'text',
};

export default FormField;
