import React from 'react';
import { Typography } from '@experian-uk/corvetteuk-common-ui';
import { RecycleMessage } from './RecycleContainer.styles';

const RecycleContainer = () => (
  <RecycleMessage>
    We couldn&rsquo;t process your payment.
    <Typography.Copy.SmallText>Please update your payment details.</Typography.Copy.SmallText>
  </RecycleMessage>
);

export default RecycleContainer;
