import errors from './errors';
import defaultValidators from './defaultValidators';
import { isObjectEmpty } from '../';

const validateFunctionList = validators =>
  !!validators && typeof validators === 'object' && !isObjectEmpty(validators) ? validators : defaultValidators;

const getCustomValidator = args => {
  const validators = validateFunctionList(args.validators);
  const validator = validators[args.validator];
  if (!validator || typeof validator !== 'function') {
    throw new Error(errors.unspecifiedValidator);
  }
  const propType = (props, propName, componentName) => {
    const validatorArgs = {
      props,
      propName,
      componentName,
      ...args,
    };
    // Caller didn't specify isRequired, so don't validate undefined / default strings
    if (!!props && (!props[propName] || props[propName] === '')) {
      return null;
    }
    return validator(validatorArgs);
  };
  propType.isRequired = (props, propName, componentName) => {
    if (!props[propName]) {
      throw new Error(errors.isRequired);
    }
    const validatorArgs = {
      props,
      propName,
      componentName,
      ...args,
    };
    return validator(validatorArgs);
  };
  return propType;
};

export default getCustomValidator;
