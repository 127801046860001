import { SET_CANCELLATION_REASON } from '../actions/cancellationReasons/setCancellationReason';

export default (state = {}, action = {}) => {
  switch (action.type) {
    case SET_CANCELLATION_REASON:
      return {
        ...action.payload,
      };
    default:
      return state;
  }
};
