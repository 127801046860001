import styled from 'styled-components';

export const TooltipContainer = styled.div`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  margin-left: 0.5rem;
  align-self: self-start;
`;
