import { LOCATION_CHANGE } from 'connected-react-router';
import moment from 'moment';

import { type as SYNC_SESSION } from '../actions/session/sync';
import updateSession from '../actions/session/update';
import { getEnv } from '../components/Context/env';
import isServer from '../helpers/isServer';

export default store => next => action => {
  const actionsToTriggerRefresh = [SYNC_SESSION, LOCATION_CHANGE];

  if (!actionsToTriggerRefresh.includes(action.type)) {
    return next(action);
  }

  const env = getEnv();
  const state = store.getState();

  if (!isServer() && (env.REACT_APP_ENV === 'local' || window.Cypress?.isIntegrationTest)) {
    sessionStorage.setItem(
      'sessionData',
      JSON.stringify({
        ...state.session,
        lastSeen: moment().format(), // emulate redis adding this
      })
    );
    return next(action);
  }

  fetch('/internal/session', {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
      'CSRF-Token': state.auth.csrfToken,
    }),
    body: JSON.stringify(state.session),
  }).then(res =>
    res
      .json()
      .then(data => {
        store.dispatch(updateSession(data));
      })
      .catch(() => {})
  );

  return next(action);
};
