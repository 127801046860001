import get from 'lodash/get';
import { defined } from './defined';
import { errorMessages } from '../constants';

const errorIntro = 'Could not interpolate string';

export const interpolationErrors = {
  missingArgs: `${errorIntro}, arguments missing`,
};

const interpolate = (message, args) => {
  const argument = /\{\d+\}/.exec(message);
  if (argument) {
    const argumentNumber = parseInt(argument[0].replace(/[^0-9]/g, ''), 10);
    if (!defined('array', args) || args.length < argumentNumber) {
      throw new Error(interpolationErrors.missingArgs);
    }
    const updatedMessage = message.replace(argument[0], args[argumentNumber]);
    return interpolate(updatedMessage, args);
  }
  return message;
};

// Allows caller to specify one or more arguments for a dynamic message using C#-style syntax
// e.g. 'This is a {0} error message thrown by {1}'. The desired values for {0} and {1} should be
// specified in an array and passed as args, e.g. ['friendly', 'Product Movement']
// The returned value would then be 'This is a friendly error message thrown by Product Movement'
export default (messageKey, args, messages = errorMessages) => {
  let message = get(messages, messageKey, undefined);
  message = typeof message === 'string' ? message : undefined;
  return interpolate(message, args);
};
