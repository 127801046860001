import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Tab } from 'react-tabs';
import PropTypes from 'prop-types';
import Analytics, { InteractionInputEvent, OptimisationInputEvent } from '@experian-uk/web-common-analytics';
import { setAutomationElement } from '@experian-uk/corvetteuk-common-ui';
import {
  ModalHeader,
  Subtitle,
  ModalFooterWrapper,
  CenteredWrapper,
  TabButtonsContainer,
  TabsContainer,
  TabsContentContainer,
  TabContainer,
  ContentContainer,
  ButtonsContainer,
  Button,
  FactSheetBtn,
  CredExBtn,
  ModalFooter,
} from './ModalUpsell.styles';
import { PrimaryCTA } from './Button';
import eligibleForCeTrial from '../../actions/ecd/resolvers/eligibleForCeTrial';
import ContentWrapper from './ContentWrapper';
import abTests from '../../constants/abtests';

const interaction = (componentName, actionName) =>
  InteractionInputEvent.fromObject({
    component: componentName,
    action: actionName,
    status: 'success',
  });

const onBackCTAClick = async () => {
  Analytics.publishOnce(interaction('upsell_modal_stat_report_back', 'click'), `backButton`);
};

const onOrderCTAClick = async () => {
  Analytics.publishOnce(interaction('upsell_modal_stat_report_order_now', 'click'), `orderNow`);
};

const onStatReportTabClick = async () => {
  Analytics.publishOnce(interaction('upsell_modal_stat_report', 'toggle'), `CETab`);
};

const onCEFreeTrialTabClick = async () => {
  Analytics.publishOnce(interaction('upsell_modal_ce_tab', 'toggle'), `statTab`);
};

const onStartTrialClick = async () => {
  Analytics.publishOnce(interaction('upsell_modal_start_CE', 'click'), `startTrial`);
};

const ModalUpsellContent = ({ credExClick, setTabIndex, tabIndex }) => {
  const conductrics = useSelector(state => state?.conductrics);
  const variant =
    conductrics?.sels?.[abTests.REPORT_INTO_FREE_DAY_1_PLUS.apiCode] ??
    conductrics?.sels?.[abTests.REPORT_INTO_FREE_DAY_0.apiCode];
  const isVariantC = variant === 'C';

  const isEligibleForTrial = useSelector(state => eligibleForCeTrial(state));

  useEffect(() => {
    Analytics.publishOnce(
      OptimisationInputEvent.fromObject({
        testName: 'Upsell Modal',
        testArea: 'Product Movement',
        testVariant: `Variant${variant ?? 'A'}`,
      })
    );
  }, []);

  return (
    <TabsContainer
      {...setAutomationElement(`modal-upsell`)}
      selectedIndex={tabIndex}
      onSelect={index => setTabIndex(index)}
    >
      <CenteredWrapper>
        <TabButtonsContainer role="tablist">
          <Tab
            {...setAutomationElement(`ce-tab`)}
            role="tab"
            tabIndex="0"
            aria-label={isEligibleForTrial ? 'CreditExpert free trial' : 'CreditExpert account'}
            onClick={() => onCEFreeTrialTabClick()}
          >
            {isEligibleForTrial ? 'CreditExpert free trial' : 'CreditExpert account'}
          </Tab>
          <Tab
            {...setAutomationElement(`stat-report-tab`)}
            role="tab"
            tabIndex="0"
            aria-label="Statutory Credit Report"
            onClick={() => onStatReportTabClick()}
          >
            Statutory Credit Report
          </Tab>
          <span className="highlighter" />
        </TabButtonsContainer>
        <TabsContentContainer role="tablist">
          <TabContainer>
            <ModalHeader id="credExUpsellHeadingRedesign">
              <p>
                Get more with Credit<span>Expert</span>
              </p>
            </ModalHeader>
            <Subtitle>See the bigger picture to help you get the best out of your score:</Subtitle>
            <ContentWrapper tabNumber={1} showTestCards={isVariantC} />
            <FactSheetBtn
              href="https://www.experian.co.uk/consumer/product-factsheets/CreditExpertwithAccount.pdf"
              target="_blank"
            >
              CreditExpert product fact sheet &gt;
            </FactSheetBtn>
            <ModalFooterWrapper>
              <CredExBtn
                {...setAutomationElement(`modal-credit-expert-btn`)}
                data-target="modal-button"
                onClick={async e => {
                  credExClick?.(e);
                  onStartTrialClick();
                }}
              >
                {isEligibleForTrial ? 'Start my 30 day free trial' : 'Start now'}
              </CredExBtn>
              <ModalFooter>
                <small>
                  New customers are eligible for a 30-day free trial. A monthly fee of £14.99 applies after this
                  finishes. &dagger;
                </small>
              </ModalFooter>
            </ModalFooterWrapper>
          </TabContainer>
          <TabContainer>
            <ContentContainer>
              <ModalHeader id="credExUpsellHeadingRedesign">
                <p>Free Statutory Credit Report</p>
              </ModalHeader>
              <Subtitle>
                Your Statutory Credit Report shows a basic view of your credit history. But you <strong>won’t</strong>{' '}
                get any benefits of CreditExpert, such as:
              </Subtitle>
              <ContentWrapper tabNumber={2} showTestCards={isVariantC} />
            </ContentContainer>
            <ButtonsContainer>
              <PrimaryCTA
                {...setAutomationElement(`order-now`)}
                onClick={() => onOrderCTAClick()}
                href="https://www.experian.co.uk/consumer/statutory-report.html"
                target="_blank"
              >
                Order now
              </PrimaryCTA>
              <Button
                {...setAutomationElement(`back-to-CE-free-trial`)}
                onClick={() => {
                  setTabIndex(0);
                  onBackCTAClick();
                }}
              >
                Back
              </Button>
            </ButtonsContainer>
          </TabContainer>
        </TabsContentContainer>
      </CenteredWrapper>
    </TabsContainer>
  );
};

ModalUpsellContent.defaultProps = {
  credExClick: () => undefined,
  statReportTabDisabled: false,
};

ModalUpsellContent.propTypes = {
  credExClick: PropTypes.func,
  setTabIndex: PropTypes.func.isRequired,
  statReportTabDisabled: PropTypes.bool,
  tabIndex: PropTypes.number.isRequired,
};

export default ModalUpsellContent;
