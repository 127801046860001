import React, { useState, useEffect, cloneElement } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const FADE_DURATION = 700;
const SLIDE_DELAY = 150;
const SLIDE_DURATION = 150;
const DEFAULT_POSITION_FROM_TOP = '20%';

const StyledModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 100;
  overflow: hidden;
  background-color: #ffffffa9;
  backdrop-filter: blur(4px);

  transition: opacity ${FADE_DURATION}ms ease-in-out;
  opacity: ${({ toggleFade }) => (toggleFade ? '1' : '0')};
`;

const StyledModalContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: fit-content;
  margin: 0 auto;
  z-index: 101;
  top: 100%;
  transition: top ${SLIDE_DURATION}ms ease-in-out;
  ${({ slideToggle, positionFromTop, centerOnPosition }) =>
    slideToggle &&
    `
    top: ${positionFromTop};
    ${centerOnPosition && 'transform: translateY(-50%);'}
  `}
  ${({ slideToggle }) =>
    !slideToggle &&
    `
    top: 100%;
    transform: translateY(+50%);
  `}
`;

const fadeIn = (setToggleFade, setSlideToggle) => {
  setToggleFade(true);
  setTimeout(() => {
    setSlideToggle(true);
  }, SLIDE_DELAY);
};

const fadeOut = (setIsModalOpen, setToggleFade, setSlideToggle) => {
  setSlideToggle(false);
  setTimeout(() => {
    setToggleFade(false);
    setTimeout(() => {
      setIsModalOpen(false);
    }, FADE_DURATION);
  }, SLIDE_DELAY);
};

const ModalTransitionWrapper = ({
  children,
  setIsModalOpen,
  positionFromTop = DEFAULT_POSITION_FROM_TOP,
  centerOnPosition = true,
}) => {
  const [toggleFade, setToggleFade] = useState(false);
  const [slideToggle, setSlideToggle] = useState(false);
  useEffect(() => fadeIn(setToggleFade, setSlideToggle), []);

  return (
    <StyledModalOverlay
      toggleFade={toggleFade}
      onClick={() => {
        fadeOut(setIsModalOpen, setToggleFade, setSlideToggle);
      }}
    >
      <StyledModalContainer
        positionFromTop={positionFromTop}
        centerOnPosition={centerOnPosition}
        onClick={event => {
          event.stopPropagation();
        }}
        slideToggle={slideToggle}
      >
        {cloneElement(children, { closeModal: () => fadeOut(setIsModalOpen, setToggleFade, setSlideToggle) })}
      </StyledModalContainer>
    </StyledModalOverlay>
  );
};

ModalTransitionWrapper.propTypes = {
  setIsModalOpen: PropTypes.func.isRequired,
  positionFromTop: PropTypes.string,
  children: PropTypes.node.isRequired,
  centerOnPosition: PropTypes.bool,
};

ModalTransitionWrapper.defaultProps = {
  positionFromTop: undefined,
  centerOnPosition: true,
};

export default ModalTransitionWrapper;
