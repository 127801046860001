import dayjs from 'dayjs';
import { descriptors } from '../constants';

export default (currentSubscription, offers, selection) => {
  if (!currentSubscription || !offers || !selection) return null;

  if (['breach', 'breach_plus'].includes(selection.family)) return null;

  if (!['breach', 'breach_plus'].includes(currentSubscription.family)) return null;

  const { breachDefermentInfo } = currentSubscription;

  if (!breachDefermentInfo?.defermentDt || !dayjs(breachDefermentInfo?.defermentDt).isValid()) return null;

  const { offerId } = selection;

  const shouldHaveFreeTrial = breachDefermentInfo.descriptors?.includes(descriptors.freeTrial);

  const deferredProductBillDate = shouldHaveFreeTrial
    ? dayjs(breachDefermentInfo.defermentDt).add(30, 'day').toISOString()
    : breachDefermentInfo.defermentDt;

  const deferredProductPrice = offers?.find(offer => offer.id === offerId)?.terms[0].price;

  return {
    deferredProductBillDate,
    deferredProductPrice,
  };
};
