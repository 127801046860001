export default {
  unspecifiedValidator: 'Could not validate PropType as no custom validator was specified',
  isRequired: 'Value passed to Custom PropType validator was marked isRequired, but appears to be undefined',
  oneOf: (
    propValue,
    propName,
    componentName,
    permittedValues
  ) => `Failed prop type: Invalid prop ${propName} of value ${propValue} supplied to ${componentName},
            expected one of [ ${permittedValues.map(value => `'${value}' `).join('')}]`,
};
