import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import getAllSubscriptions from '../../actions/subscriptions/getAll';
import getAllOffers from '../../actions/offers/getAll';
import getBillingData from '../../actions/billing/getBilling';

import ThanksIndex from '.';
import Loading from '../../components/Loading';

const hasStoreComponentRefreshed = (storeComponent, refreshRequestTime) =>
  storeComponent.lastRefreshed?.getTime() >= refreshRequestTime.getTime() || storeComponent.error;

const ThanksLanding = () => {
  const [refreshRequestTime] = useState(new Date());

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllSubscriptions());
    dispatch(getBillingData());
    dispatch(getAllOffers());
  }, [dispatch]);

  const storeUpdated = useSelector(state => {
    const subscriptionsReady = hasStoreComponentRefreshed(state.subscriptions, refreshRequestTime);
    const billingReady = hasStoreComponentRefreshed(state.billing, refreshRequestTime);
    const offersReady = hasStoreComponentRefreshed(state.offers, refreshRequestTime);

    return subscriptionsReady && billingReady && offersReady;
  });

  if (storeUpdated) {
    return <ThanksIndex />;
  }

  return <Loading solidBackground />;
};

export default ThanksLanding;
