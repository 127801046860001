import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { Tabs, TabList, TabPanel } from 'react-tabs';
import { automationTestAttribute } from '@experian-uk/corvetteuk-common-ui/dist/helpers/test/automation';
import { PrimaryButton, PrimaryCTA, TertiaryButton } from './Button';

export const breakpoints = {
  smallMobile: '320px',
  mobile: '623px',
  tabletPortrait: '768px',
  tablet: '1020px',
  desktop: '1440px',
};

export const ModalHeader = styled.header`
  margin: 0 0 ${rem(4)};
  > p {
    font-weight: 300;
    font-size: ${rem(24)};
    line-height: ${rem(30)};
    color: #1d4f91;
    margin: ${rem(24)} 0 0;

    @media only screen and (min-width: ${breakpoints.mobile}) {
      font-size: ${rem(32)};
      line-height: ${rem(40)};
    }
  }
  span {
    font-weight: 500;
  }
`;

export const ModalContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: ${rem(16)} 0;

  @media only screen and (min-width: ${breakpoints.mobile}) {
    margin: ${rem(16)} ${rem(16)} 0;
  }
`;

export const Subtitle = styled.p`
  font-weight: 300;
  font-size: ${rem(18)};
  line-height: ${rem(28)};
  margin: 0 0 ${rem(20)};
  width: 100%;
  margin: ${rem(4)} auto;
  color: #575757;

  @media only screen and (min-width: ${breakpoints.mobile}) {
    font-size: ${rem(20)};
    line-height: ${rem(30)};
    width: 86%;
  }
`;

export const ModalDesc = styled.p`
  max-width: ${rem(284)};
  margin: 0 auto ${rem(24)};
  font-size: ${rem(18)};

  @media only screen and (min-width: ${breakpoints.mobile}) {
    max-width: ${rem(324)};
    font-size: ${rem(20)};
  }
`;

export const FeatureCard = styled.div`
  width: calc(100% / 2 - ${rem(8)});
  min-height: ${rem(150)};
  box-shadow: 0 ${rem(4)} ${rem(8)} rgba(0, 37, 86, 0.15);
  border-radius: ${rem(14)};
  color: #1d4f91;
  background: #ffffff;
  font-size: ${rem(16)};
  line-height: ${rem(20)};
  font-weight: 500;
  text-align: left;
  padding: ${rem(16)};
  margin-bottom: ${rem(14)};
  h6 {
    max-width: ${rem(130)};
    margin-top: unset;
    font-size: unset;
    font-weight: 500;
    line-height: ${rem(20)};
    margin-bottom: ${rem(10)};
  }

  @media only screen and (min-width: ${breakpoints.smallMobile}) {
    min-height: ${rem(175)};
  }

  @media only screen and (min-width: ${breakpoints.mobile}) {
    width: calc(100% / 3 - ${rem(8)});
    min-height: ${rem(142)};
  }
`;

export const FactSheetBtn = styled.a`
  display: block;
  width: 100%;
  max-width: ${rem(275)};
  text-align: center;
  font-size: ${rem(12)};
  line-height: ${rem(24)};
  font-weight: 400;
  padding: 0;
  margin: 0 auto ${rem(-10)};
  z-index: 0;
  position: relative;
  color: #0058e6;
  cursor: pointer;
  &:hover,
  &:focus {
    color: #26478d;
    text-decoration: underline;
  }
  @media only screen and (min-width: ${breakpoints.mobile}) {
    margin: 0 auto;
    z-index: 20;
  }
`;

export const CredExBtn = styled(PrimaryButton)`
  position: relative;
  margin: 0 auto ${rem(40)};
  width: 100%;
  max-width: initial;
  height: ${rem(50)};
  font-size: ${rem(16)};
  line-height: ${rem(24)};
  font-weight: 400;
  background: #d82b80;
  top: ${rem(25)};
`;

export const ModalFooter = styled.footer`
  margin: ${rem(-18)} ${rem(-18)} ${rem(-22)};
  background: none;
  padding: ${rem(10)} ${rem(20)} ${rem(20)} ${rem(10)};

  @media only screen and (min-width: ${breakpoints.mobile}) {
    border-radius: 0 0 ${rem(10)} ${rem(10)};
  }

  > small {
    display: inline-flex;
    color: #575756;
    font-size: ${rem(12)};
    font-weight: 300;
  }
`;

export const ModalFooterWrapper = styled.div`
  width: 100%;
  position: fixed;
  z-index: 10;
  bottom: 0;
  left: 0;
  margin: 0;
  padding: 0 ${rem(16)} ${rem(16)};
  background: #f4f6fa;
  box-shadow: 0 ${rem(-2)} ${rem(8)} rgba(0, 37, 86, 0.15);

  @media only screen and (min-width: ${breakpoints.mobile}) {
    position: relative;
    margin: ${rem(-16)} 0;
    padding: 0;
    box-shadow: none;
    background: transparent;
  }
`;

export const CenteredWrapper = styled.section`
  width: 100%;
  margin: 0 auto;
  padding-top: ${rem(34)};
  background: none;

  @media only screen and (min-width: ${breakpoints.desktop}) {
    padding-top: ${rem(20)};
  }
`;

export const TabsContainer = styled(Tabs)`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-grow: 1;
  width: 100%;
  background-color: transparent;
`;

export const TabButtonsContainer = styled(TabList)`
  display: flex;
  margin: 0 auto ${rem(24)};
  padding: ${rem(8)} !important;
  background: #fff;
  border-radius: ${rem(100)};
  max-width: ${rem(366)};
  position: relative;
  z-index: 0;
  li[aria-selected='true'] {
    box-shadow: 0 ${rem(2)} ${rem(4)} rgba(0, 37, 86, 0.16);
    border-radius: ${rem(100)};
    border-bottom: 0;
    transition: all 0.25s linear;
    background: #f2f4f7;
  }

  li {
    font-size: ${rem(14)};
    font-weight: 500;
    line-height: ${rem(24)};
    text-align: center;
    flex-grow: 1;
    border-bottom: ${rem(4)} solid #fff;
    padding: ${rem(8)} ${rem(20)};
    cursor: pointer;
    color: #1e437a;
    transition: all 0.25s linear;
    border-bottom: 0;
    border-radius: ${rem(100)};
    z-index: 10;
    min-width: 49%;
    display: flex;
    align-items: center;
    justify-content: center;
    &:focus,
    &:focus-visible,
    &:hover,
    &:active {
      border-radius: ${rem(100)};
      border-bottom: 0;
      background: #f7f8fa;
    }
    &:active {
      background: #f2f4f7;
    }

    @media only screen and (min-width: ${breakpoints.mobile}) {
      padding: ${rem(8)};
      min-width: 50%;
    }
  }

  li[class$='selected'][${automationTestAttribute}='ce-tab'] ~ span {
    transform: translate3d(${rem(6)}, 0, 0);
  }

  span {
    position: absolute;
    width: 49%;
    height: calc(100% - ${rem(16)});
    left: 0;
    border-radius: ${rem(100)};
    transform: translate3d(100%, 0, 0);
    transition: transform 0.25s ease-in-out;
    z-index: 10;
    border: ${rem(1)} solid #1e437a;
  }

  @media only screen and (min-width: ${breakpoints.mobile}) {
    padding: 0 ${rem(16)};
    margin: 0 auto ${rem(16)};
  }

  @media only screen and (min-width: ${breakpoints.desktop}) {
    padding: 0;
  }

  ${PrimaryCTA} {
    background: #d82b80;
  }
`;

export const TabsContentContainer = styled.section`
  border-radius: ${rem(14)};
`;

export const TabContainer = styled(TabPanel)`
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  text-align: center;

  ${ModalFooterWrapper} {
    margin: ${rem(-10)} 0 0;
  }

  ${ModalHeader} {
    font-size: ${rem(24)};
    line-height: ${rem(36)};
    font-weight: 300;
    color: #1d4f91;
    margin-bottom: ${rem(8)};

    @media only screen and (min-width: ${breakpoints.mobile}) {
      font-size: ${rem(32)};
    }
  }

  ul {
    width: 100%;
    max-width: 100%;
    background: #fff;
    padding: ${rem(20)} ${rem(22)};
    margin: ${rem(10)} 0 ${rem(-40)} 0;
    text-align: left;
    font-size: ${rem(14)};
    line-height: ${rem(18)};
    box-shadow: 0 ${rem(4)} ${rem(8)} rgba(0, 37, 86, 0.15);
    border-radius: ${rem(14)};

    @media only screen and (min-width: ${breakpoints.mobile}) {
      margin: 0 auto ${rem(16)};
      padding: ${rem(20)} ${rem(54)};
      max-width: ${rem(426)};
    }
  }

  header > p {
    margin-top: 0;
  }
`;

export const Button = styled(TertiaryButton)`
  border: ${rem(2)} solid #d82b80;
  color: #d82b80;
  background: transparent;
  &:hover,
  &:focus,
  &:focus-visible {
    color: #bf1865;
    background: transparent;
    border: ${rem(2)} solid #d82b80;
    box-shadow: 0 0 0 0.25rem rgba(230, 56, 136, 0.4);
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: ${rem(16)};
  width: 100%;
  position: fixed;
  z-index: 10;
  bottom: 0;
  left: 0;
  margin: 0;
  background: #f4f6fa;
  box-shadow: 0 -0.125rem 0.5rem rgba(0, 37, 86, 0.15);
  padding: ${rem(16)};

  @media only screen and (min-width: ${breakpoints.mobile}) {
    width: auto;
    position: relative;
    bottom: initial;
    left: initial;
    box-shadow: none;
    padding: 0;
  }

  button,
  a {
    width: 50%;
  }
  a {
    background: #d82b80;
  }
`;

export const ContentContainer = styled.div`
  margin-bottom: ${rem(-80)};

  @media only screen and (min-width: ${breakpoints.mobile}) {
    margin-bottom: 0;
  }

  ${Subtitle} {
    font-size: ${rem(18)};
    line-height: ${rem(30)};
    width: 90%;

    @media only screen and (min-width: ${breakpoints.mobile}) {
      font-size: ${rem(16)};
      line-height: ${rem(24)};
      font-weight: 400;
    }
  }
  h6 {
    color: #8092ab;
  }
  ${FeatureCard} {
    margin-bottom: ${rem(16)};
  }
`;

export const ModalStyles = () => css`
  dialog {
    width: 100%;
    height: ${rem(574)};
    overflow: scroll;
    background: #f4f6fa;
    padding-bottom: ${rem(160)};
    padding-top: ${rem(20)};

    @media only screen and (min-width: ${breakpoints.mobile}) {
      min-width: ${rem(612)};
      height: auto;
      overflow: auto;
      padding-bottom: ${rem(30)};

      button {
        &:focus-visible {
          outline: none;
          box-shadow: 0 0 0 0.25rem rgba(230, 56, 136, 0.4);
        }
      }
    }
  }
  button {
    &:focus-visible {
      outline: 1px solid #015cae;
      box-shadow: 0 0 0 0.25rem rgba(38, 71, 142, 0.4);
    }
  }
`;
