import styled from 'styled-components';
import { Link, Button, Typography } from '@experian-uk/corvetteuk-common-ui';
import Container from '../../components/Container';
import PanelContainer from '../../components/PanelContainer';
import WarningIcon from './warning-icon.svg';

export const BackButton = styled(Link.Button)`
  margin-bottom: 0.625rem;
`;

export const LogoutButton = styled(Link.Anchor)`
  margin-bottom: 0.625rem;
  font-weight: 500;
`;

export const InlineLink = styled(Link.Anchor)`
  font-size: inherit;
`;

export const ContentContainer = styled(PanelContainer)`
  margin: 1.25rem 0;

  ${({ theme }) =>
    theme.media(
      '20',
      `
    margin-top: 1.875rem;
  `
    )}
`;

export const ContentToggle = styled(Container)`
  ${({ theme }) =>
    theme.media(
      '10',
      `
    padding: 1.25rem;
  `
    )}
`;

export const BenefitsCaption = styled(Typography.Copy.Text)`
  ${({ theme }) =>
    theme.mediaEnd(
      '15',
      `
    text-align: center;
  `
    )}
`;

export const BodyContent = styled.div`
  margin: 20px;

  ${({ theme }) =>
    theme.media(
      '20',
      `
    margin: ${theme.base.expSpacingSmall} 20px;
  `
    )}
`;

export const FeatureList = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 0 24px;
  padding: 0;
  list-style-type: none;

  ${({ theme }) =>
    theme.media(
      '15',
      `
    display: flex;
    flex-wrap: wrap;
    margin: 7px 0 12px;
  `
    )}
`;

export const ToggleSavedCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0;

  ${({ theme: { media } }) =>
    media(
      '10',
      `
        div {
          width: 100%;
        }
    `
    )}

  ${({ theme: { media } }) =>
    media(
      '30',
      `
        padding: 0 0.9375rem;
    `
    )}
`;

export const BorderTopSection = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.baseMediumGrey};
`;

export const BorderTopSectionPadded = styled(BorderTopSection)`
  padding-top: 2rem;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${props => props.theme.base.expSpacingSmall};

  a {
    margin-bottom: 1.25rem;
  }

  ${({ theme: { media } }) =>
    media(
      '15',
      `
      flex-direction: row;

      a {
          margin-right: 2.5rem;
      }
  `
    )}
`;

export const ReducedButtonContainer = styled(ButtonContainer)`
  margin-top: 0;
`;

const ConfirmAndPay = styled.div`
  margin-top: 40px;

  ${({ theme: { media } }) =>
    media(
      '30',
      `
    display: flex;
    justify-content: flex-end;

    p {
      display: inline-block;
      margin-right: 1.25rem;
    }
  `
    )}
`;

ConfirmAndPay.displayName = 'ConfirmAndPay';

export { ConfirmAndPay };

const FormContainer = styled.div`
  margin-top: 1.5rem;
  padding-left: 0;

  ${({ theme: { media } }) =>
    media(
      '10',
      `
    padding-left: 2.2rem;
  `
    )}
`;

FormContainer.displayName = 'FormContainer';

export { FormContainer };

const PayButton = styled(Button)`
  text-align: center;
  width: 100%;

  ${({ theme: { media } }) =>
    media(
      '15',
      `
    width: auto;
  `
    )}
`;

PayButton.displayName = 'PayButton';

export { PayButton };

export const VoucherCode = styled(Typography.Copy.Text)`
  font-weight: 400;
  margin: ${({ theme }) => theme.base.expSpacingSmall} 0;

  ${({ theme }) =>
    theme.media(
      '20',
      `
    margin: ${theme.base.expSpacingMedium} 0 60px;
  `
    )}
`;

export const ErrorContainer = styled.div`
  margin-top: 1.25rem;
`;

export const BreadcrumbsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const NonCardWarningDiv = styled.div`
  display: flex;
  padding-bottom: 2rem;
`;

export const NonCardWarningCopy = styled(Typography.Copy.SmallText)`
  color: ${props => props.theme.colors.alertError};
  padding-left: 0.5rem;
`;

export const NonCardWarningIcon = styled.div`
  content: url(${WarningIcon});
  height: 1.5rem;
  width: 1.3125rem;
`;

export const IFrame = styled.iframe`
  border: none;
  display: block;
  margin: 0 auto;
  min-height: 70vh;
  overflow: scroll;
  width: 100%;

  @media (max-width: 1024px) {
    min-height: 95vh;
  }
`;
