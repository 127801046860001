import PropTypes from 'prop-types';

export const defermentInfoProps = PropTypes.shape({
  family: PropTypes.string,
  defermentDt: PropTypes.string,
});

export default PropTypes.shape({
  family: PropTypes.string,
  defermentInfo: defermentInfoProps,
  startDt: PropTypes.string,
  fulfillmentStatus: PropTypes.oneOf(['SUCCEEDED', 'FAILED']),
});
