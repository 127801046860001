import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Form from './Form';
import Privacy from './Privacy';
import Header from './Header';

const WrapperContainer = styled.div`
  width: 100%;
  background: linear-gradient(179.94deg, #0e2846 -5.32%, #1d4f91 18.2%, #e3e7eb 55.91%, #f0f1f1 82.04%);
  text-align: center;
  position: relative;

  ${({ theme }) =>
    theme.media(
      '10',
      `
        background: transparent;
    `
    )};
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;

  ${({ theme }) =>
    theme.media(
      '10',
      `
        background: linear-gradient(191.56deg, #0E2846 15.75%, #1D4F91 51.64%);
        height: 316px;
    `
    )};

  ${({ theme }) =>
    theme.media(
      '20',
      `
        background: linear-gradient(191.56deg, #0E2846 15.75%, #1D4F91 51.64%);
        height: 338px;
    `
    )};
`;

const FormWrapper = styled.div`
  background: transparent;
  margin: 0 auto;
  z-index: 2;
  position: relative;
  overflow: auto;
  max-width: 95%;

  ${({ theme }) =>
    theme.media(
      '10',
      `
        width: fit-content;
        display: block;
        background: #F7F7F8;
        border-radius: 20px 20px 0px 0px;
        padding: 33px 39px 0;
    `
    )};

  ${({ theme }) =>
    theme.media(
      '20',
      `
        padding: 33px 98px 0;
        max-width: 90%;
    `
    )};
`;

const Wrapper = ({
  variant,
  savedCard,
  useSavedCard,
  hasSavedCard,
  handleRadioSwitch,
  setCvv,
  priceInfo,
  trial,
  submitDisabled,
  submitFunction,
}) => (
  <WrapperContainer>
    <Background />
    <Header variant={variant} eligibleForTrial={trial} />
    <FormWrapper>
      <Form
        variant={variant}
        savedCard={savedCard}
        useSavedCard={useSavedCard}
        hasSavedCard={hasSavedCard}
        handleRadioSwitch={handleRadioSwitch}
        setCvv={setCvv}
        priceInfo={priceInfo}
        trial={trial}
        submitDisabled={submitDisabled}
        submitFunction={submitFunction}
      />
      <Privacy />
    </FormWrapper>
  </WrapperContainer>
);

Wrapper.propTypes = {
  variant: PropTypes.string.isRequired,
  savedCard: PropTypes.shape({
    billingProviderPaymentMethodId: PropTypes.string,
    cardNumberEnding: PropTypes.string,
    creditCardNumber: PropTypes.string,
    creditCardType: PropTypes.string,
    cvv: PropTypes.number,
    expirationMonth: PropTypes.number,
    expirationYear: PropTypes.number,
    isDefaultCard: PropTypes.bool,
    nameOnCreditCard: PropTypes.string,
    source: PropTypes.string,
  }).isRequired,
  useSavedCard: PropTypes.bool.isRequired,
  hasSavedCard: PropTypes.bool,
  handleRadioSwitch: PropTypes.func.isRequired,
  setCvv: PropTypes.func.isRequired,
  priceInfo: PropTypes.shape({
    price: PropTypes.number,
    trialDuration: PropTypes.number,
  }).isRequired,
  trial: PropTypes.bool.isRequired,
  submitDisabled: PropTypes.bool.isRequired,
  submitFunction: PropTypes.func.isRequired,
};

Wrapper.defaultProps = {
  hasSavedCard: undefined,
};

export default Wrapper;
