import moment from 'moment';

import { isObjectEmpty } from '../../helpers';

export default (hasSavedCard, card) => {
  const formattedCard = { ...card };

  if (!!hasSavedCard && !isObjectEmpty(card)) {
    return {
      billingProviderPaymentMethodId: card.billingProviderPaymentMethodId,
      CVV: card.CVV,
    };
  }

  if (card.expiryDate) {
    const expirationDate = moment(`01/${card.expiryDate}`, 'DD/MM/YY');
    const expirationMonth = expirationDate.format('MM');
    const expirationYear = expirationDate.format('YYYY');

    formattedCard.expirationMonth = expirationMonth;
    formattedCard.expirationYear = expirationYear;
    delete formattedCard.expiryDate;
  }

  formattedCard.creditCardNumber = card.creditCardNumber.replace(/\s+/g, '');

  return {
    ...formattedCard,
    isDefaultCard: true,
  };
};
