import moment from 'moment';
import { isFreeTrial, getFreeTrialData } from '../../../helpers/subscription';
import { dateFormats } from '../../../constants';
import { isObjectEmpty } from '../../../helpers';

export default (state, today = moment()) => {
  const { current } = state.subscriptions;
  const { loading, freeTrialTerms } = state.billing;

  if (!current || loading) {
    return undefined;
  }

  const hasTrial = current.descriptors && isFreeTrial(current.descriptors);

  if (!hasTrial) {
    return null;
  }

  const trialData =
    freeTrialTerms && current.freeTrialEndDt ? getFreeTrialData(freeTrialTerms, current.freeTrialEndDt, today) : {};

  if (isObjectEmpty(trialData)) {
    return 0; // still has trial, today is last day.
  }

  const endDate = moment(trialData.ends, dateFormats.long);

  return Math.abs(endDate.diff(today, 'd') + 1); // one added to be inclusive of today
};
