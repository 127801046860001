import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { setAutomationElement } from '@experian-uk/corvetteuk-common-ui';
import { HeaderContent, HeadingSecondary, SubscriptionDescription } from './Header.styles';

import StyledSubscription from '../../StyledSubscription';

const getHeaderContent = (isFree, selected) => {
  if (isFree) {
    return `You ${!selected ? 'also ' : ''}have a`;
  }

  return selected ? 'Your current subscription' : <br />;
};

const Header = ({ isComplimentary, isFree, isFreeActive, product, selected }) => (
  <HeaderContent selected={selected}>
    <SubscriptionDescription>
      <div {...setAutomationElement('panel-header-one')}>{getHeaderContent(isFree && isFreeActive, selected)}</div>
      <HeadingSecondary selected={selected} {...setAutomationElement('panel-header-two')}>
        {!isFree && (
          <Fragment>
            {isComplimentary && selected && 'Complimentary '}
            <StyledSubscription product={product} />
          </Fragment>
        )}
        {isFree && 'Free Experian account'}
      </HeadingSecondary>
    </SubscriptionDescription>
  </HeaderContent>
);

Header.propTypes = {
  isComplimentary: PropTypes.bool,
  isFree: PropTypes.bool,
  isFreeActive: PropTypes.bool,
  product: PropTypes.string.isRequired,
  selected: PropTypes.bool,
};

Header.defaultProps = {
  isComplimentary: false,
  isFree: false,
  selected: false,
  isFreeActive: false,
};

export default Header;
