import get from 'lodash/get';
import fetch from '../fetch';

export const verifyChallengeStatus = () => async dispatch => {
  try {
    const response = await dispatch(fetch('/billingpaymentauthorizations/verifychallengestatus'));
    const success = !!get(response, 'data[0].success', false);
    return success;
  } catch (error) {
    return false;
  }
};
