import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import moment from 'moment';
import { propTypes as formProps, reduxForm } from 'redux-form';

import { setAutomationElement, Typography } from '@experian-uk/corvetteuk-common-ui';
import { ModalUpsell } from '../../ModalUpsell';

import { clickECDEvent } from '../../../pages/home/homePageEcd2';
import { getEnv } from '../../Context/env';
import selectOffer from '../../../actions/offers/select';
import Header from './Header';
import Footer from './Footer';
import PriceDetails from './PriceDetails';
import {
  ActionDisplayContainer,
  ComplimentarySubscriptionText,
  Container,
  Content,
  CreditBrokerText,
  DetailContainer,
  EndDate,
  FreeTrialText,
  FreeTrialTriangle,
  PriceContainer,
  ProductDescription,
  ReadMoreButton,
  ReadMoreContent,
  UpgradeBlockedExplanation,
} from './FreeProductPanel.styles';
import { dateFormats, familyName, products, offersData } from '../../../constants';
import { isObjectEmpty, upgradeTypes, getUpgradeType } from '../../../helpers';
import { isFreeTrial, mapFamilyToProduct } from '../../../helpers/subscription';
import ActionDisplay from '../../subscriptions/ActionDisplay';
import offerProps from '../../../propTypes/offer';
import updateSession from '../../../actions/session/update';
import syncSession from '../../../actions/session/sync';
import BreachVoucherInput from './BreachVoucherInput';
import validateVoucher from '../../../actions/voucherValidation';
import checkClientValidVoucher from '../../../helpers/checkClientValidVoucher';
import { normalizeField } from '../../../helpers/forms';

@connect(
  state => ({
    error: state.error,
    expiryDate: state.billing.endDate,
    freeOffer: state.offers.basic,
    isCoolingOffEligible: (state.subscriptions.current || {}).isCoolingOffEligible,
    apiError: state.voucherValidationResult.error,
    validVoucher: state.voucherValidationResult.voucherCode,
  }),
  dispatch =>
    bindActionCreators(
      {
        selectOffer,
        push,
        syncSession,
        updateSession,
        validateVoucher,
      },
      dispatch
    )
)
@reduxForm({
  form: 'billing/voucher-code',
})
export default class FreeProductPanel extends React.Component {
  static propTypes = {
    ...formProps,
    allOffersAvailable: PropTypes.bool,
    className: PropTypes.string.isRequired,
    copy: PropTypes.shape({
      productInfo: PropTypes.string,
      readMoreContent: PropTypes.string,
      pdfDownloadLink: PropTypes.string,
    }),
    currentSubscription: PropTypes.string.isRequired,
    expiryDate: PropTypes.string,
    fewerPanels: PropTypes.bool,
    freeOffer: offerProps,
    freeTrial: PropTypes.shape({
      ends: PropTypes.string,
      remaining: PropTypes.shape({
        unit: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      }),
    }),
    offer: offerProps,
    isComplimentary: PropTypes.bool,
    isCoolingOffEligible: PropTypes.bool,
    productIdentifier: PropTypes.string,
    push: PropTypes.func.isRequired,
    selected: PropTypes.bool,
    selectOffer: PropTypes.func.isRequired,
    updateSession: PropTypes.func.isRequired,
    syncSession: PropTypes.func.isRequired,
    switchPermitted: PropTypes.bool,
    validateVoucher: PropTypes.func.isRequired,
    apiError: PropTypes.string,
  };

  static defaultProps = {
    allOffersAvailable: false,
    copy: {},
    expiryDate: '',
    fewerPanels: false,
    freeOffer: {},
    freeTrial: {},
    isComplimentary: false,
    offer: {},
    selected: false,
    switchPermitted: true,
    isCoolingOffEligible: false,
    productIdentifier: null,
    apiError: null,
  };

  static verbMap = {
    upgrade: 'Upgrade to',
    downgrade: 'Switch to',
    cancel: 'Cancel',
  };

  state = {
    modalUpsellShowing: false,
    readMoreClicked: false,
    voucher: '',
    shouldShowClientSideError: false,
    shouldShowApiError: false,
    voucherErrorMessage: null,
  };

  static getButtonText = (source, target, typeOfChange, isComplimentary) => {
    if (target === products.basic) {
      return `${
        typeOfChange === upgradeTypes.downgrade ? `${FreeProductPanel.verbMap.cancel}` : 'Add a'
      } Free Experian Account`;
    }

    const sourceProduct = mapFamilyToProduct(source);

    if (sourceProduct === target) {
      if (isComplimentary) {
        return [products.breach].includes(source)
          ? 'Deactivate my account'
          : `${FreeProductPanel.verbMap.cancel} ${(familyName[sourceProduct] || '').split(' ')[0]} account`;
      }
      return `${FreeProductPanel.verbMap.cancel} ${familyName[source]}`;
    }

    return `${FreeProductPanel.verbMap[typeOfChange]} ${familyName[target]}`;
  };

  static getFreeTrialText = (currentSubscription, product, freeTrial) => {
    let identityText = '';
    if (product === products.identity) {
      identityText = 'with complimentary CreditExpert features ';
    }
    const baseText = `You have a free ${freeTrial.remaining.value} ${freeTrial.remaining.unit} trial ${identityText}until ${freeTrial.ends}`;
    return baseText;
  };

  hideModal = () => {
    this.setState(prevState => ({
      modalUpsellShowing: !prevState.modalUpsellShowing,
    }));
  };

  handleCreditExpert = async e => {
    e.preventDefault();
    this.hideModal();

    const {
      currentSubscription,
      freeOffer,
      offer: { family, id },
    } = this.props;

    const redirectLink = [currentSubscription, family].every(product => product === products.breach)
      ? `${getEnv().REACT_APP_INS_URL}/account/close`
      : null;

    if (redirectLink) {
      window.location = redirectLink;
      return;
    }

    let targetOfferId = id;
    let targetSubscription = family;
    if (family === currentSubscription) {
      targetOfferId = freeOffer.offerId;
      targetSubscription = products.basic;
    }

    await this.props.selectOffer(targetOfferId, targetSubscription);

    this.props.updateSession({
      selectedOfferId: targetOfferId,
      selectedOfferFamily: targetSubscription,
    });

    await this.props.syncSession();

    this.props.push('/switch');
  };

  breachVoucherButtonClick = async e => {
    e.preventDefault();

    this.setState({ disableInput: true });

    const voucher = normalizeField.uppercase(this.state.voucher);

    const checkVoucher = checkClientValidVoucher(voucher, getEnv().REACT_APP_ENV);

    if (checkVoucher.shouldShowClientSideError === true) {
      this.setState({
        shouldShowClientSideError: checkVoucher.shouldShowClientSideError,
        voucherErrorMessage: checkVoucher.voucherErrorMessage,
      });
    }

    if (checkVoucher.shouldShowClientSideError === false) {
      await this.props.validateVoucher(voucher, true);
    }
    if (this.props.apiError) {
      this.setState({
        shouldShowApiError: true,
        shouldShowClientSideError: false,
      });
    }

    const { validVoucher, shouldShowApiError, shouldShowClientSideError } = this.props;

    if (validVoucher && !shouldShowApiError && !shouldShowClientSideError) {
      this.props.push(
        `/switch?voucherCode=${voucher}&offerId=${offersData.defaultBreachPlusOfferId}&switchingTo=${products.breachPlus}`
      );
    } else {
      this.setState({ disableInput: false });
    }
  };

  buttonClick = async e => {
    e.preventDefault();
    const {
      currentSubscription,
      freeOffer,
      offer: { family, id },
    } = this.props;
    const redirectLink = [currentSubscription, family].every(product => product === products.breach)
      ? `${getEnv().REACT_APP_INS_URL}/account/close`
      : null;

    if (redirectLink) {
      window.location = redirectLink;
      return;
    }

    let targetOfferId = id;
    let targetSubscription = family;
    if (family === currentSubscription) {
      targetOfferId = freeOffer.offerId;
      targetSubscription = products.basic;
    }

    if (targetSubscription === products.credit_expert) {
      clickECDEvent('prodmove_upgrade_to_CreditExpert');

      this.setState({
        modalUpsellShowing: true,
      });

      return;
    }

    if (targetSubscription === products.identity) {
      clickECDEvent('prodmove_switch_to_ID');
    }

    if (targetSubscription === products.basic) {
      clickECDEvent('prodmove_cancel_CreditExpert');
    }

    await this.props.selectOffer(targetOfferId, targetSubscription);

    this.props.updateSession({
      selectedOfferId: targetOfferId,
      selectedOfferFamily: targetSubscription,
    });

    await this.props.syncSession();

    this.props.push('/switch');
  };

  handleClick = () => {
    this.setState(prevState => ({
      readMoreClicked: !prevState.readMoreClicked,
    }));
  };

  render() {
    const {
      className,
      fewerPanels,
      selected,
      currentSubscription,
      offer,
      copy,
      switchPermitted,
      freeTrial,
      allOffersAvailable,
      isComplimentary,
      isCoolingOffEligible,
      expiryDate,
      productIdentifier,
      apiError,
    } = this.props;
    const {
      modalUpsellShowing,
      readMoreClicked,
      voucherErrorMessage,
      voucher,
      shouldShowClientSideError,
      shouldShowApiError,
      disableInput,
    } = this.state;

    let pricePerMonth = '';

    if (!!offer && offer.family === products.basic) {
      pricePerMonth = 'FREE';
    } else if (!!offer && !!offer.terms) {
      pricePerMonth = `£${offer.terms[0].price}`;
    }

    const freeTrialAvailable = offer.family !== currentSubscription && isFreeTrial(offer.descriptors);

    const idCeFreeTrial =
      offer.family === products.credit_expert &&
      currentSubscription === products.identity &&
      !isObjectEmpty(freeTrial) &&
      !!freeTrial.remaining;

    const hideSwitchButton = offer.family === products.basic;

    const userIsInFreeTrial = !isObjectEmpty(freeTrial) && !!freeTrial.remaining;

    let product = mapFamilyToProduct(offer.family);
    const isCurrentSubscription = offer.family === currentSubscription;

    const isFreeActive = ![products.breachPlus].includes(offer.family);

    if (isComplimentary && !isCurrentSubscription) {
      product = products.basic;
    }

    const changeType = getUpgradeType(currentSubscription, offer.family);

    const actionButtonLabel = FreeProductPanel.getButtonText(currentSubscription, product, changeType, isComplimentary);

    let actionButtonChangeType = changeType === upgradeTypes.upgrade ? upgradeTypes.upgrade : 'secondary';
    if (!isCurrentSubscription && product === products.basic && changeType === upgradeTypes.upgrade) {
      actionButtonChangeType = 'primary';
    }

    const showPrice = productIdentifier !== null && !isComplimentary && product !== products.basic && switchPermitted;

    const showBreachVoucherInput = currentSubscription === products.basic;
    const breachVoucherRedirect = showBreachVoucherInput && voucher !== '';

    const breachVoucherFieldChange = e => {
      const voucherCode = e.target.value;

      this.setState({
        voucher: voucherCode,
        shouldShowClientSideError: false,
        shouldShowApiError: false,
        voucherErrorMessage: null,
      });
    };

    return (
      <Container
        className={className}
        isComplimentary={isComplimentary}
        selected={selected}
        userIsInFreeTrial={userIsInFreeTrial}
        {...setAutomationElement(`panel-${product}`)}
      >
        {freeTrialAvailable && (
          <FreeTrialTriangle>
            <span {...setAutomationElement(`free-trial-${product}`)}>Free trial</span>
          </FreeTrialTriangle>
        )}
        <Header
          actionButtonLabel={actionButtonLabel}
          changeType={actionButtonChangeType}
          idCeFreeTrial={idCeFreeTrial}
          isFree={product === products.basic}
          isCoolingOffEligible={isCoolingOffEligible}
          isCurrentSubscription={isCurrentSubscription}
          isComplimentary={isComplimentary}
          isFreeActive={isFreeActive}
          onButtonClick={this.buttonClick}
          product={product}
          price={pricePerMonth}
          selected={selected}
          switchPermitted={switchPermitted}
        />
        <Content
          isComplimentary={isComplimentary}
          selected={selected}
          userIsInFreeTrial={userIsInFreeTrial}
          showBreachVoucherInput={showBreachVoucherInput}
        >
          <ProductDescription allOffersAvailable={allOffersAvailable}>
            <Typography.Copy.Text>{copy.productInfo}</Typography.Copy.Text>
            {readMoreClicked && <ReadMoreContent>{copy.readMoreContent}</ReadMoreContent>}
            <ReadMoreButton aria-expanded={readMoreClicked} aria-pressed={readMoreClicked} onClick={this.handleClick}>
              {!readMoreClicked ? 'Read more' : 'Read less'}
            </ReadMoreButton>
          </ProductDescription>
          <div>
            {showPrice && (
              <PriceContainer
                shouldPadBottom={
                  userIsInFreeTrial && ![currentSubscription, products.credit_expert].includes(offer.family)
                }
              >
                <PriceDetails price={pricePerMonth} isFree={offer.family === products.basic} />
              </PriceContainer>
            )}
            {isComplimentary && isCurrentSubscription && (
              <ComplimentarySubscriptionText>
                Complimentary{' '}
                {expiryDate && moment(expiryDate).isValid() && (
                  <EndDate>expires {moment(expiryDate).format(dateFormats.dayMonthYear)}</EndDate>
                )}
              </ComplimentarySubscriptionText>
            )}
            <DetailContainer allOffersAvailable={allOffersAvailable}>
              {switchPermitted &&
                userIsInFreeTrial &&
                product !== products.basic &&
                currentSubscription === product && (
                  <FreeTrialText {...setAutomationElement('free-trial-text')}>
                    {FreeProductPanel.getFreeTrialText(currentSubscription, product, freeTrial)}
                  </FreeTrialText>
                )}
              {idCeFreeTrial && (
                <UpgradeBlockedExplanation {...setAutomationElement('blocked-upgrade-in-trial')}>
                  You cannot upgrade as you currently have all CreditExpert features. You will be able to upgrade after
                  your trial has ended
                </UpgradeBlockedExplanation>
              )}
              {product === products.basic && switchPermitted && (
                <CreditBrokerText hideSwitchButton={hideSwitchButton}>
                  We are a credit broker not a lender &nbsp;
                  <sup>&dagger;</sup>
                </CreditBrokerText>
              )}
              {showBreachVoucherInput && product === products.identity && (
                <BreachVoucherInput
                  breachVoucherFieldChange={breachVoucherFieldChange}
                  voucherErrorMessage={voucherErrorMessage}
                  shouldShowClientSideError={shouldShowClientSideError}
                  shouldShowApiError={shouldShowApiError}
                  apiError={apiError}
                  normalize={normalizeField.uppercase}
                  onSubmit={this.breachVoucherButtonClick}
                  disableInput={disableInput}
                />
              )}
              {!hideSwitchButton && (
                <ActionDisplayContainer>
                  <ActionDisplay
                    isFree={offer.family === products.basic}
                    type={actionButtonChangeType}
                    label={actionButtonLabel}
                    onClick={breachVoucherRedirect ? this.breachVoucherButtonClick : this.buttonClick}
                    isCoolingOffEligible={isCoolingOffEligible}
                    isBreachPlus={[products.breachPlus].includes(currentSubscription)}
                    isCurrentSubscription={offer.family === currentSubscription}
                    switchPermitted={switchPermitted}
                    idCeFreeTrial={idCeFreeTrial}
                  />
                </ActionDisplayContainer>
              )}
            </DetailContainer>
          </div>
        </Content>
        {switchPermitted && (
          <Footer
            fewerPanels={fewerPanels}
            isComplimentary={isComplimentary}
            isFree={product === products.basic}
            pdfDownloadLink={copy.pdfDownloadLink}
            productKey={product}
          />
        )}
        <ModalUpsell isShowing={modalUpsellShowing} toggleHide={this.hideModal} credExClick={this.handleCreditExpert} />
      </Container>
    );
  }
}
