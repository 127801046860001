import intersection from 'lodash/intersection';
import moment from 'moment';
import { products } from '../constants';
import { SUBSCRIPTIONS_FETCHED } from '../actions/subscriptions/getAll';
import { isObjectEmpty } from '../helpers';
import { isFreeTrial } from '../helpers/subscription';

const defaultState = {
  error: false,
  fetched: false,
  lastRefreshed: null,
};

export const findCurrentPrice = state => {
  if (!state || !state.current) {
    return null;
  }

  const term = state.current.terms.find(t => !t.endDt);

  return term ? term.price : null;
};

const calculateCoolingOffEligibility = (subscription, hasFreeTrial) => {
  if (!subscription.coolingOffPeriodEndDt || hasFreeTrial) {
    return false;
  }

  return moment(subscription.coolingOffPeriodEndDt).isAfter(moment());
};

const transformSubscription = subscription => {
  if (!subscription || isObjectEmpty(subscription)) {
    return {};
  }

  const freeTrial = isFreeTrial(subscription.descriptors);
  const isCoolingOffEligible = calculateCoolingOffEligibility(subscription, freeTrial);
  const transformedSubscription = {
    ...subscription,
    freeTrial,
    isCoolingOffEligible,
  };

  // copy defermentInfo to breachDefermentInfo block in the transformedSubscription object before it is removed. This negates any update to logic that relies on the
  // defermentInfo block not being available for breach or breach plus customers.
  if (subscription.family === products.breach || subscription.family === products.breachPlus) {
    transformedSubscription.breachDefermentInfo = transformedSubscription.defermentInfo;
    delete transformedSubscription.defermentInfo;
  }

  return transformedSubscription;
};

export const hasBenefit = (state, requiredBenefits) => {
  if (!(state.current && state.current.benefits)) {
    return null;
  }

  const currentBenefits = state.current.benefits
    .map(b => {
      if (b.id && b.benefitActivated) {
        return b.id;
      }
      return null;
    })
    .filter(benefitId => benefitId);

  const matches = intersection(currentBenefits, requiredBenefits);

  return !!matches.length;
};

export default (state = defaultState, action = {}) => {
  if (action.error) {
    if ([SUBSCRIPTIONS_FETCHED].includes(action.type)) {
      return {
        ...state,
        error: true,
      };
    }

    return state;
  }

  switch (action.type) {
    case SUBSCRIPTIONS_FETCHED:
      return {
        current: transformSubscription(action.payload.subscriptions.find(s => s.active)),
        data: action.payload.subscriptions.reduce(
          (acc, cur) => ({
            ...acc,
            [cur.id]: cur,
          }),
          { ...state }
        ),
        fetched: true,
        error: false,
        lastRefreshed: new Date(),
      };
    default:
      return state;
  }
};
