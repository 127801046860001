import { v4 as uuid } from 'uuid';
import {
  UPSELL_TRACKING_ATTEMPT,
  UPSELL_TRACKING_SUCCESS,
  UPSELL_TRACKING_FAILURE,
} from '../actions/upsellstarttrackerservice/trackStartUpsell';

const defaultState = {
  error: null,
  fetching: false,
  paid: null,
  upsellCorrelationId: null,
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case UPSELL_TRACKING_ATTEMPT:
      return {
        ...state,
        fetching: true,
        upsellCorrelationId: uuid(),
      };
    case UPSELL_TRACKING_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
      };
    case UPSELL_TRACKING_FAILURE:
      return {
        ...state,
        fetching: false,
        error: true,
      };
    default:
      return state;
  }
};
