import PropTypes from 'prop-types';
import { customPropValidators, getCustomPropValidator } from '../helpers';
import { cardTypes } from '../constants';

const validCreditCardType = getCustomPropValidator({
  validator: customPropValidators.includesCaseInsensitive,
  permittedValues: cardTypes,
});

export default {
  billingProviderPaymentMethodId: PropTypes.string,
  cardNumberEnding: PropTypes.string,
  creditCardNumber: PropTypes.string,
  creditCardType: validCreditCardType,
  expirationMonth: PropTypes.number,
  expirationYear: PropTypes.number,
};
