import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import offerProps from '../../propTypes/offer';
import { products } from '../../constants';
import { isObjectEmpty } from '../../helpers';
import CurrentSubscriptionCard from './currentSubscriptionCard';
import ProductOptionCard from './productOptionCard';
import { CardsContainer } from './productPanel.styles';
import { HeaderMessageContainer } from './productOptionsCards.styles';
import { CardContent } from './cardContent';

const getPriceSummary = billingInfo => {
  let billingPeriod = '';
  switch (billingInfo.billingFrequency) {
    case 'Monthly':
    case 'monthly': {
      billingPeriod = '/ month';
      break;
    }
    case 'Annually':
    case 'annually':
    case 'Yearly':
    case 'yearly': {
      billingPeriod = '/ year';
      break;
    }
    default:
  }

  let priceSummary;
  if (billingPeriod !== '' && !!billingInfo.price) {
    priceSummary = `£${String(billingInfo.price)} ${billingPeriod}`;
  }
  return priceSummary;
};

const ProductPanel = ({ currentSubscription, freeTrial, offers, switchPermitted }) => {
  const creditExpertOffer = offers.find(offer => offer.family === products.credit_expert);
  const identityOffer = offers.find(offer => offer.family === products.identity);

  let creditExpertPriceSummary =
    !!creditExpertOffer && !!creditExpertOffer.terms ? getPriceSummary(creditExpertOffer.terms[0]) : '';
  let identityPriceSummary = !!identityOffer && !!identityOffer.terms ? getPriceSummary(identityOffer.terms[0]) : '';
  const currentPriceSummary = useSelector(
    state => state.offers.currentOffer && getPriceSummary(state.offers.currentOffer.terms[0])
  );

  // Overwrite appropriate available offer price summary with the current offer price summary
  if (!!currentSubscription && currentSubscription.family === products.credit_expert) {
    creditExpertPriceSummary = currentPriceSummary || null;
  } else if (!!currentSubscription && currentSubscription.family === products.identity) {
    identityPriceSummary = currentPriceSummary || null;
  }

  const priceSummary = {
    credit_expert: creditExpertPriceSummary,
    identity: identityPriceSummary,
    basic: 'Free',
  };

  const availableOffers = offers.filter(offer => offer.family !== currentSubscription.family);
  const fewerPanels = availableOffers.length < 2;

  return (
    <CardsContainer fewerPanels={fewerPanels}>
      <CurrentSubscriptionCard
        freeTrial={freeTrial}
        switchPermitted={switchPermitted}
        currentSubscription={{
          ...currentSubscription,
          ...CardContent[currentSubscription.family],
          priceSummary: priceSummary[currentSubscription.family],
        }}
      />
      {!fewerPanels && (
        <HeaderMessageContainer>If you want to change your subscription, your options are:</HeaderMessageContainer>
      )}
      {availableOffers.map(
        (offer, index) =>
          !isObjectEmpty(offer) && (
            <ProductOptionCard
              key={offer.family}
              offer={{
                ...offer,
                ...CardContent[offer.family],
                priceSummary: priceSummary[offer.family],
              }}
              headerNumber={index + 1}
              currentSubscription={CardContent[currentSubscription.family].productName}
              fewerPanels={fewerPanels}
              isIdCeFreeTrial={
                offer.family === products.credit_expert &&
                currentSubscription.family === products.identity &&
                !isObjectEmpty(freeTrial) &&
                !!freeTrial.remaining
              }
              switchPermitted={switchPermitted}
            />
          )
      )}
    </CardsContainer>
  );
};

ProductPanel.propTypes = {
  currentSubscription: offerProps.isRequired,
  freeTrial: PropTypes.shape({
    ends: PropTypes.string,
    remaining: PropTypes.shape({
      unit: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  }),
  offers: PropTypes.arrayOf(offerProps).isRequired,
  switchPermitted: PropTypes.bool,
};

ProductPanel.defaultProps = {
  freeTrial: {},
  switchPermitted: true,
};

export default ProductPanel;
