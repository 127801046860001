import styled from 'styled-components';

const SubscriptionText = styled.div`
  display: inline;
  font-weight: inherit;
  white-space: nowrap;
`;

const EmphasisedText = styled.span`
  ${({ theme }) =>
    theme.media(
      '10',
      `
    font-weight: 400;
  `
    )};
`;

export { SubscriptionText, EmphasisedText };
