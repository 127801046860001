import React from 'react';
import PropTypes from 'prop-types';
import { setAutomationElement } from '@experian-uk/corvetteuk-common-ui';
import { products, benefitsDocument } from '../../../constants';
import { ButtonContainer } from '../productOptionsCards.styles';
import {
  BreachOptionsCardHeader,
  BreachCardHeaderContent,
  BreachCardContent,
  BreachProductFactSheet,
  BreachCreditBrokerText,
  BreachDownloadIcon,
} from './breachProductPanels.styles';

const FreeAccountPanel = ({ getCardActionButton, isBreach, offer, showSmallerCard }) => {
  const freeProductPanelCopy = [
    'See your Experian Credit Score updated every 30 days if you log in.',
    'See if you can instantly improve your score with Experian Boost',
  ];

  return (
    <React.Fragment>
      <BreachOptionsCardHeader showSmallerCard={showSmallerCard}>
        <BreachCardHeaderContent isBreachPlus={!isBreach}>
          {!isBreach && <p>You also have a</p>}
          <h3>Free Experian account</h3>
        </BreachCardHeaderContent>
      </BreachOptionsCardHeader>
      {!showSmallerCard && (
        <React.Fragment>
          <BreachCardContent>
            <ul>
              {freeProductPanelCopy.map(txt => (
                <li key={txt}>{txt}</li>
              ))}
            </ul>
          </BreachCardContent>
          <BreachCreditBrokerText>
            Experian is a credit broker not a lender&nbsp;
            <sup>&dagger;</sup>
          </BreachCreditBrokerText>
        </React.Fragment>
      )}
      <ButtonContainer>{getCardActionButton()}</ButtonContainer>
      <BreachProductFactSheet>
        <BreachDownloadIcon />
        <a
          {...setAutomationElement(`${offer}-panel-product-factsheet-link`)}
          href={`${benefitsDocument.baseUrl}/${benefitsDocument[products.basic]}`}
        >
          View Free Experian product fact sheet
        </a>
      </BreachProductFactSheet>
    </React.Fragment>
  );
};

FreeAccountPanel.propTypes = {
  showSmallerCard: PropTypes.bool.isRequired,
  getCardActionButton: PropTypes.func.isRequired,
  isBreach: PropTypes.bool.isRequired,
  offer: PropTypes.string.isRequired,
};

export default FreeAccountPanel;
