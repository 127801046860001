import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { setAutomationElement } from '@experian-uk/corvetteuk-common-ui';
import { LoadingBackground, Spinner, SpinnerContainer, SolidLoadingBackground, Text } from './index.styles';

const Loading = ({ solidBackground }) => (
  <Fragment>
    {solidBackground ? <SolidLoadingBackground /> : <LoadingBackground />}
    <SpinnerContainer>
      <Spinner {...setAutomationElement('loading-spinner')} />
      <Text>
        We&rsquo;re just updating your account, this may take a couple of minutes. <br /> Please do not leave or refresh
        this page.
      </Text>
    </SpinnerContainer>
  </Fragment>
);

Loading.propTypes = {
  solidBackground: PropTypes.bool,
};

Loading.defaultProps = {
  solidBackground: false,
};

export default Loading;
