import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ApplePay, GooglePay } from '@epl/icons';
import { setAutomationElement } from '@experian-uk/corvetteuk-common-ui';

import uiChangeState from '../../actions/uiChangeState';

import RecycleContainer from './RecycleContainer';
import { DetailValueLogo, MobilePayDetail, WarningCopy } from './index.styles';
import { Container, ActionButton } from './CurrentCard.styles';
import { DetailsContainer, DetailLabel } from '../Details/index.styles';
import { selectPage } from '../../reducers/uiState';

@connect(
  state => ({
    showCardForm: selectPage(state.uiState, window.location.pathname).showCardForm,
  }),
  dispatch => bindActionCreators({ uiChangeState }, dispatch)
)
export default class CurrentMobilePay extends React.Component {
  static propTypes = {
    vendor: PropTypes.oneOf(['ApplePay', 'GooglePay']),
    uiChangeState: PropTypes.func.isRequired,
    showCardForm: PropTypes.func,
    isRecycle: PropTypes.bool,
  };

  static defaultProps = {
    vendor: null,
    showCardForm: false,
    isRecycle: false,
  };

  static dataMap = new Map([
    [
      'GooglePay',
      {
        Logo: GooglePay,
        tradeName: 'Google Pay',
      },
    ],
    [
      'ApplePay',
      {
        Logo: ApplePay,
        tradeName: 'Apple Pay',
      },
    ],
  ]);

  handleClick = e => {
    e.preventDefault();
    this.props.uiChangeState(window.location.pathname, { showCardForm: true });
  };

  render() {
    const { vendor, showCardForm, isRecycle } = this.props;
    const { Logo, tradeName } = CurrentMobilePay.dataMap.get(vendor) || {};

    if (!Logo) {
      return null;
    }

    return (
      <Container isError={isRecycle}>
        <DetailsContainer>
          <MobilePayDetail>
            <DetailLabel>Payment Type:</DetailLabel>
            <DetailValueLogo>
              <Logo />
            </DetailValueLogo>
          </MobilePayDetail>
        </DetailsContainer>
        <WarningCopy>
          If you choose to use a different card, you won’t be able to switch back to {tradeName}.
        </WarningCopy>
        {isRecycle && <RecycleContainer />}
        <ActionButton
          {...setAutomationElement('subUseDiffCard')}
          type="secondary"
          isButton
          onClick={this.handleClick}
          label="Use a different card"
          disabled={showCardForm}
        />
      </Container>
    );
  }
}
