export const upgradeTypes = {
  cancel: 'cancel',
  downgrade: 'downgrade',
  upgrade: 'upgrade',
};

const upgradeMap = {
  'basic/breach_plus': upgradeTypes.upgrade,
  'basic/identity': upgradeTypes.upgrade,
  'basic/credit_expert': upgradeTypes.upgrade,
  'breach/breach_plus': upgradeTypes.upgrade,
  'breach/credit_expert': upgradeTypes.upgrade,
  'breach/identity': upgradeTypes.upgrade,
  'breach_plus/breach': upgradeTypes.downgrade,
  'breach_plus/basic': upgradeTypes.cancel,
  'breach_plus/credit_expert': upgradeTypes.upgrade,
  'breach_plus/identity': upgradeTypes.upgrade,
  'credit_expert/identity': upgradeTypes.downgrade,
  'credit_expert/basic': upgradeTypes.cancel,
  'credit_expert/credit_expert': upgradeTypes.cancel,
  'identity/credit_expert': upgradeTypes.upgrade,
  'identity/basic': upgradeTypes.cancel,
  'identity/identity': upgradeTypes.cancel,
};

export const getUpgradeType = (source, target) => source && target && upgradeMap[`${source}/${target}`];
