import styled from 'styled-components';
import { rem } from 'polished';

export const InfoWrapper = styled.div`
  margin: ${rem(24)} auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: ${rem(363)};

  ${({ theme }) =>
    theme.media(
      '10',
      `
        margin: ${rem(41)} auto;
    `
    )};

  ${({ theme }) =>
    theme.media(
      '20',
      `
        margin: ${rem(47)} auto;
        max-width: 100%;
    `
    )};
`;

export const InfoHeading = styled.p`
  font-weight: 500;
  font-size: ${rem(18)};
  line-height: ${rem(26)};
  color: #1d4f91;
  margin-top: ${rem(12)};
  margin-bottom: ${rem(12)};
`;

export const InfoText = styled.p`
  font-weight: 300;
  font-size: ${rem(16)};
  line-height: ${rem(24)};
  color: #575757;
  margin-top: ${rem(4)};
  margin-bottom: ${rem(4)};
`;
