import {
  FETCH_OTHER_PRODUCTS,
  NO_OTHER_PAID_PRODUCTS_FOUND,
  OTHER_PAID_PRODUCTS_FOUND,
  OTHER_PRODUCTS_FETCH_FAILED,
} from '../actions/productCheck/getOtherProducts';

const defaultState = {
  error: null,
  fetching: false,
  paid: null,
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case FETCH_OTHER_PRODUCTS:
      return {
        ...state,
        fetching: true,
      };
    case OTHER_PAID_PRODUCTS_FOUND:
      return {
        ...state,
        fetching: false,
        error: null,
        paid: true,
      };
    case NO_OTHER_PAID_PRODUCTS_FOUND:
      return {
        ...state,
        fetching: false,
        error: null,
        paid: false,
      };
    case OTHER_PRODUCTS_FETCH_FAILED:
      return {
        ...state,
        fetching: false,
        error: true,
      };
    default:
      return state;
  }
};
