import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledModalContainer = styled.div`
  position: relative;
  margin: 0 20px;
  background-color: #fff;
  border: 2px solid #bbb;
  border-radius: 5px;
  box-shadow: 10px 10px 15px 0 #00000050, 0 0 10px 0px #00000050;
  padding: 16px;
  max-width: 495px;

  p {
    font-size: 16px;
  }
  p:first-of-type {
    font-weight: 400;
  }
  p:last-of-type {
    font-weight: 300;
  }
`;

const StyledModalCloseButton = styled.button`
  position: absolute;
  right: 50%;
  top: -15px;
  transform: translateX(50%);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid #bbb;
  margin: 0;
  background-color: #fff;
  transition: box-shadow 100ms ease-in-out, text-shadow 400ms ease-in-out;

  &::after {
    content: 'X';
    color: #004691;
    font-size: 16px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  :hover {
    box-shadow: 3px 3px 5px 0 #00000050;
    text-shadow: 2px 2px 2px #00000050;
  }

  :active {
    transition: unset;
    box-shadow: unset;
    text-shadow: unset;
  }
`;

const WhyProvidePaymentDetailsModal = ({ closeModal }) => (
  <StyledModalContainer>
    <StyledModalCloseButton type="button" tabIndex="-1" onClick={() => closeModal(false)} />
    <p>Don’t worry, you won’t be charged until your free trial ends.</p>
    <p>
      We ask for your payment information to provide a seamless subscription experience. You can cancel before your
      renewal date to avoid being charged.
    </p>
  </StyledModalContainer>
);

WhyProvidePaymentDetailsModal.propTypes = {
  closeModal: PropTypes.func,
};

WhyProvidePaymentDetailsModal.defaultProps = {
  closeModal: undefined,
};

export default WhyProvidePaymentDetailsModal;
