import React from 'react';
import { FooterOOS } from '@experian-uk/corvetteuk-common-ui';

import LoginPage from './components/loginPage';
import Container from '../../components/Container';

export default () => (
  <React.Fragment>
    <Container>
      <LoginPage />
    </Container>
    <FooterOOS />
  </React.Fragment>
);
