import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { currency } from '../helpers/formatting';

const StyledTermsAndConditionsContainer = styled.div`
  background-color: #f8f8f8;
  border-top: solid 1px #ddd;
  border-bottom: solid 1px #ddd;
  margin: 24px -16px 0;
  padding: 24px;

  p {
    color: #63666a;
    font-size: 14px;
    font-weight: 200;
    line-height: 1.5;
    margin: 0;

    & + p {
      margin-top: 20px;
    }
  }

  a {
    color: #0076dc;
    font-weight: 300;
    text-decoration: none;
  }
`;

const TermsAndConditions = ({ offerFreeTrial, price }) => (
  <StyledTermsAndConditionsContainer>
    <p>
      It’s important you understand the terms of our contract with you, which are in the{' '}
      <a rel="noopener noreferrer" target="_blank" href="https://www.experian.co.uk/consumer/terms.html">
        Experian Terms & Conditions{' '}
      </a>
      and{' '}
      <a rel="noopener noreferrer" target="_blank" href="https://www.experian.co.uk/consumer/privacy.html">
        Privacy Policy
      </a>{' '}
      and by starting your {offerFreeTrial ? 'free trial' : 'subscription'}, you’ll be accepting these. You should read
      these documents and contact us if you have any questions.
    </p>
    {offerFreeTrial ? (
      <p>
        You’ll also continue to get free access to our comparison services. Discover more about your{' '}
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.experian.co.uk/consumer/product-factsheets/CreditExpertwithAccountOctober2017.pdf"
        >
          features and rights
        </a>
        .
      </p>
    ) : (
      <p>
        By clicking Upgrade to CreditExpert you request we provide the service immediately and you will pay{' '}
        {currency(price)} per month for your CreditExpert subscription. You may be eligible for a 14 day cooling off
        period, during which you may be entitled to a refund. Discover more about your{' '}
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.experian.co.uk/consumer/product-factsheets/CreditExpertwithAccountOctober2017.pdf"
        >
          features and rights
        </a>
        . You’ll also continue to get free access to our comparison services.
      </p>
    )}
  </StyledTermsAndConditionsContainer>
);

TermsAndConditions.propTypes = {
  offerFreeTrial: PropTypes.bool.isRequired,
  price: PropTypes.number.isRequired,
};

export default TermsAndConditions;
