import { ALL_OFFERS_FETCHED } from '../actions/offers/getAll';
import { OFFER_FETCHED } from '../actions/offers/getOne';
import { descriptors, offersData, products } from '../constants';
import { isObjectEmpty } from '../helpers';
import getAvailableOfferIds from './selectors/getAvailableOfferIds';

const { credit_expert: creditExpert, identity, basic, breach, breachPlus } = products;
const { defaultPrimeOffer, offerTags } = offersData;

const defaultState = {
  isLoaded: false,
  [creditExpert]: null,
  [identity]: null,
  [basic]: null,
  error: false,
  currentOffer: null,
  lastRefreshed: null,
};

const selectFromOffers = (offers, type, primeOfferName = defaultPrimeOffer) => {
  let selections = offers.filter(o => !!o.tags && o.tags.find(t => t.name === type));

  if (type === offerTags[basic]) {
    const primeOffer = selections.find(o => o.upc === primeOfferName);
    selections = primeOffer
      ? [primeOffer]
      : selections.filter(s => s.upc.search('offer') === 0).sort((a, b) => a.upc.localeCompare(b.upc));
  }

  return {
    offerId: (selections.find(o => o.descriptors.every(d => d !== descriptors.freeTrial)) || {}).id,
    trialId: (selections.find(o => o.descriptors.some(d => d === descriptors.freeTrial)) || {}).id,
  };
};

export const getOfferById = (state, id) => {
  if (!state.data || !id) {
    return {};
  }
  return state.data[id];
};

export const getAvailableOffers = (offers, subscriptions = {}) => {
  if (!offers.data || !subscriptions.current) {
    return [];
  }

  const breachFamilies = [breach, breachPlus];
  const defaultFamiliesToInclude = [basic, creditExpert, identity];

  const currentFamily = subscriptions.current.family;
  const isBreachCustomer = breachFamilies.includes(currentFamily);

  const familiesToInclude = isBreachCustomer
    ? [...breachFamilies, ...defaultFamiliesToInclude]
    : defaultFamiliesToInclude;
  // we want to exclude offers which have a family we don't want to include
  const familiesToExclude = Object.keys(offers).filter(offer => !familiesToInclude.includes(offer));

  const availableOffers = getAvailableOfferIds(offers, familiesToExclude)
    .map(id => getOfferById(offers, id))
    .filter(offer => !isObjectEmpty(offer));

  if (breachFamilies.includes(currentFamily)) {
    availableOffers.sort(offer => (offer.family === currentFamily ? -1 : 0));
  }

  return availableOffers;
};

export default (state = defaultState, action = {}) => {
  if (action.error) {
    return {
      ...state,
      error: [ALL_OFFERS_FETCHED, OFFER_FETCHED].includes(action.type),
    };
  }

  switch (action.type) {
    case ALL_OFFERS_FETCHED: {
      const { offers } = action.payload;
      return {
        ...state,
        [creditExpert]: selectFromOffers(offers, offerTags[creditExpert]),
        [identity]: selectFromOffers(offers, offerTags[identity]),
        [basic]: selectFromOffers(offers, offerTags[basic]),
        [breach]: selectFromOffers(offers, offerTags[breach]),
        [breachPlus]: selectFromOffers(offers, offerTags[breachPlus]),
        data: offers.reduce(
          (acc, cur) => ({
            ...acc,
            [cur.id]: cur,
          }),
          {}
        ),
        isLoaded: true,
        error: false,
        lastRefreshed: new Date(),
      };
    }
    case OFFER_FETCHED: {
      const { currentOffer } = action.payload;
      return {
        ...state,
        currentOffer,
      };
    }
    default:
      return state;
  }
};
