import styled from 'styled-components';

export const CardsContainer = styled.div`
  margin-bottom: 1.625rem;
  display: grid;
  grid-gap: 32px 32px;
  grid-template-areas: ${({ fewerPanels }) =>
    fewerPanels
      ? `
        'main right';
      `
      : `
        'main header header'
        'main middle right';
      `};

  grid-template-columns: ${({ fewerPanels }) =>
    fewerPanels ? `repeat(2, minmax(0, 1fr))` : `repeat(3, minmax(0, 1fr))`};

  @media only screen and (min-width: 1201px) {
    grid-template-rows: ${({ fewerPanels }) => !fewerPanels && '60px'};
  }

  @media only screen and (max-width: 1200px) {
    grid-template-areas: ${({ fewerPanels }) =>
      fewerPanels
        ? `
          'main'
          'right';
        `
        : `
          'main'
          'header'
          'middle'
          'right';
        `};
    grid-template-columns: 1fr;
    grid-gap: 32px;
  }
`;
export const CardContainer = styled.div`
  grid-area: ${props => props.gridLocation};
  background: white;

  ${({ isCurrentProduct }) =>
    isCurrentProduct
      ? `
        border: 3px solid #1D4F91;
      `
      : `
        box-shadow: 0px 10px 20px #CBC9C9;
      `}

  border-radius: 10px;
  flex-direction: column;
  display: flex;
  ${({ isViewEventCaptured }) =>
    isViewEventCaptured
      ? `
    height: 100%;
  `
      : ''}
`;

export const CurrentSubscriptionHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
  margin-bottom: 59px;

  @media only screen and (max-width: 1200px) {
    margin-bottom: 32px;
  }

  border-radius: 6px 6px 0 0;
  background: linear-gradient(180deg, #3f6bb0 0%, #294a90 57.29%);
  background-color: #294a90;
  color: white;

  text-align: center;
  font-family: 'Roboto';
  font-style: normal;

  align-items: center;

  hr {
    width: 100%;
    height: 0px;
    border: 1px solid #99a8bb;
    margin-bottom: 13px;
  }

  h2 {
    margin: 0;
    margin-bottom: 16px;
    font-weight: 400;
    font-size: 1.938rem;
    line-height: 140%;
    width: fit-content;
  }

  b {
    font-weight: 600;
  }

  p {
    margin: 0;
  }
`;

export const PricePoint = styled.div`
  color: ${props => props.color || 'white'};
  font-size: 1.25rem;
  font-weight: 400;
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin: 0 auto;
  row-gap: 32px;
  text-align: center;
  width: 80%;
`;

export const CardBenefits = styled.div`
  flex-grow: 1;
  text-align: left;

  @media only screen and (max-width: 400px) {
    padding: 0 8px;
  }

  h3 {
    text-align: left;
    flex: 1;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.75rem;
    color: #1d4f91;
    margin-bottom: 0;
    margin-top: 0;
  }

  ul {
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #1d4f91;
    list-style: none;
    padding: 0;
    margin: 0.5rem 0 1.5rem;
  }

  li {
    align-items: center;
    display: flex;
    margin-bottom: ${({ family }) => (family === 'identity' && '11px') || (family === 'basic' && '8px') || '16px'};
  }
`;

export const ProductFactSheet = styled.div`
  border-radius: 0 0 6px 6px;
  align-items: center;
  background-color: #e4eaf5;
  display: flex;
  padding: 1.25rem 1rem;
  text-align: left;
  width: 100%;

  a {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 28px;
    text-decoration: none;
    color: #2a75dc;
  }

  img {
    width: 2.8125rem;
    height: 2.8125rem;
    vertical-align: middle;
  }
`;
