import React from 'react';
import { ThemeProvider } from 'styled-components';
import nebulaTheme from '@nebula/theme';
import { InformationAlert } from '@nebula/alert';
import { products, benefitsDocument } from '../../constants';
import getOfferPriceSummaries from './getOfferPriceSummaries';
import useOfferBenefits from './useOfferBenefits';
import { Title, Disclaimer } from './BreachUpsell.styles';

const useProductDetails = (offers, currentSubscription) => {
  const { identityPriceSummary, creditExpertPriceSummary } = getOfferPriceSummaries(offers);
  const { identityBenefits, creditExpertBenefits, freeBenefits } = useOfferBenefits(offers);
  const isBreach = currentSubscription.family === 'breach';
  const disclaimerText = (
    <ThemeProvider theme={nebulaTheme}>
      <Disclaimer $spacing={isBreach}>
        <p>
          Experian is a credit broker not a lender <sup>†</sup>
        </p>
        {isBreach ? (
          <InformationAlert message="Switching today means you’ll gain your free benefits straightaway." />
        ) : (
          <InformationAlert message="We’ll move you to this option when your subscription ends." />
        )}
      </Disclaimer>
    </ThemeProvider>
  );

  const productDetails = offer => {
    switch (offer) {
      case products.identity:
        return {
          label: 'Stay on Identity Plus',
          titleText: (
            <Title>
              <p>Stay on</p>
              <h3>
                Identity <b>Plus</b>
              </h3>
              <hr />
            </Title>
          ),
          priceSummary: identityPriceSummary,
          benefits: identityBenefits,
          showButton: true,
          link: `${benefitsDocument.baseUrl}/${benefitsDocument[products.identity]}`,
          product: 'Identity Plus',
        };
      case products.credit_expert:
        return {
          label: 'Switch to Credit Expert',
          titleText: (
            <Title>
              <p>Move to</p>
              <h3>
                Credit<b>Expert</b>
              </h3>
              <hr />
            </Title>
          ),
          priceSummary: creditExpertPriceSummary,
          benefits: creditExpertBenefits,
          showButton: true,
          link: `${benefitsDocument.baseUrl}/${benefitsDocument[products.credit_expert]}`,
          product: 'Credit Expert',
        };
      case products.breachPlus:
        return {
          label: 'Switch now',
          titleText: (
            <Title>
              <p>Move to</p>
              <h3>Free account</h3>
              <hr />
            </Title>
          ),
          priceSummary: 'No charge',
          benefits: freeBenefits,
          disclaimer: disclaimerText,
          showButton: isBreach,
          link: `${benefitsDocument.baseUrl}/${benefitsDocument[products.basic]}`,
          product: 'Free Experian',
        };
      default:
        return null;
    }
  };
  return productDetails;
};

export default useProductDetails;
