import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Navigation, { getNavData, splitsForNav, NavigationFunnel } from '@nebula/navigation';
import nebulaTheme from '@nebula/theme';
import getAlertCountAction from '../../actions/alerts/getCount';
import { pushECD2Event } from '../../helpers/pushECD2Event';

const NavigationWrapper = ({
  splits,
  getAlertCount,
  alerts,
  subscriptionDetails,
  productDetails,
  currentUser,
  boostStatus,
  isSecurePage,
  isErrorPage,
}) => {
  useEffect(() => {
    if (!isErrorPage && !isSecurePage) {
      getAlertCount();
    }
  }, [isErrorPage, isSecurePage]);

  const splitList = Object.keys(splits)
    .filter(split => splitsForNav.includes(split))
    .map(splitKey => ({ splitName: splitKey, treatment: splits[splitKey] ? 'on' : 'off' }));

  let data;
  if (!subscriptionDetails.current) {
    data = {
      splitNamesAndTreatments: [],
      domain: window.location.origin,
      customerInformation: {
        family: 'basic',
      },
    };
  } else {
    data = {
      alertsInformation: {
        count: alerts.unread,
      },
      boostInformation: {
        hasProposition: boostStatus?.hasProposition,
      },
      customerInformation: {
        benefits: subscriptionDetails.current.benefits,
        family: subscriptionDetails.current.family,
        isWithinTrial: productDetails.isFreeTrial,
        name: {
          firstName: currentUser.firstName,
          lastName: currentUser.lastName,
        },
      },
      domain: window.location.origin,
      splitNamesAndTreatments: splitList,
    };
  }

  const navData = getNavData(data);

  if (isErrorPage || isSecurePage) {
    return (
      <ThemeProvider theme={nebulaTheme}>
        <NavigationFunnel data={navData} zIndex={11} />
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={nebulaTheme}>
      <Navigation data={navData} zIndex={5} raiseEcd={pushECD2Event} />
    </ThemeProvider>
  );
};

NavigationWrapper.propTypes = {
  alerts: PropTypes.shape({
    unread: PropTypes.number,
    error: PropTypes.bool.isRequired,
  }).isRequired,
  getAlertCount: PropTypes.func.isRequired,
  splits: PropTypes.shape({}),
  boostStatus: PropTypes.shape({
    hasProposition: PropTypes.bool,
  }),
  currentUser: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
  subscriptionDetails: PropTypes.shape({
    current: PropTypes.shape({
      benefits: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          benefitActivated: PropTypes.bool,
        })
      ),
      family: PropTypes.string,
    }),
  }),
  productDetails: PropTypes.shape({
    isFreeTrial: PropTypes.bool,
  }),
  isSecurePage: PropTypes.bool,
  isErrorPage: PropTypes.bool,
};

NavigationWrapper.defaultProps = {
  splits: {},
  boostStatus: {},
  currentUser: {},
  subscriptionDetails: {},
  productDetails: {},
  isSecurePage: false,
  isErrorPage: false,
};

const mapReduxStateToProps = state => ({
  alerts: state.alerts,
  splits: state.split.treatments,
  boostStatus: state.boost.data,
  currentUser: state.auth.currentUser,
  productDetails: state.productDetails,
  subscriptionDetails: state.subscriptions,
  isSecurePage: state.app.isSecurePage,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getAlertCount: getAlertCountAction,
    },
    dispatch
  );

export default connect(mapReduxStateToProps, mapDispatchToProps)(NavigationWrapper);
