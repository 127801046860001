import React from 'react';
import { benefitsDocument, products } from '../../constants';

const CreditExpertCardBenefitCopy = [
  'Daily Experian Credit Score',
  'Daily Experian Credit Report',
  'Experian Credit Report alerts',
  'Understand what influences your score',
  'UK call support for report & Score',
  'Personalised tips to build your score',
  'Score history',
  'Score Simulator',
  'Lock your Experian Credit Report',
  'Dedicated fraud support',
  'Web monitoring',
  'Experian fraud alerts',
  'Boost your score',
  'Compare credit cards, loans & more',
  'Check your chances of getting credit',
];

const IdentityPlusBenefitCopy = [
  'Daily Experian Fraud Report',
  'Lock your Experian Credit Report',
  'Dedicated fraud support',
  'Web monitoring',
  'Experian fraud alerts',
  'Monthly Experian Credit Score',
  'Boost your score',
  'Compare credit cards, loans & more',
  'Check your chances of getting credit',
];

const FreeBenefitCopy = [
  'Monthly Experian Credit Score',
  'Boost your score',
  'Compare credit cards, loans & more',
  'Check your chances of getting credit',
];

const BreachBenefitCopy = [
  'CreditLock',
  'Your Experian Fraud Report',
  'Dedicated fraud support',
  'Web monitoring',
  'Experian fraud alerts',
];

export const CardContent = {
  credit_expert: {
    copyLink: `${benefitsDocument.baseUrl}/${benefitsDocument[products.credit_expert]}`,
    benefitCopy: CreditExpertCardBenefitCopy,
    productName: 'CreditExpert',
    titleText: (
      <React.Fragment>
        Credit<b>Expert</b>
      </React.Fragment>
    ),
  },
  identity: {
    copyLink: `${benefitsDocument.baseUrl}/${benefitsDocument[products.identity]}`,
    benefitCopy: IdentityPlusBenefitCopy,
    productName: 'Identity Plus',
    titleText: (
      <React.Fragment>
        Identity <b>Plus</b>
      </React.Fragment>
    ),
  },
  basic: {
    copyLink: `${benefitsDocument.baseUrl}/${benefitsDocument[products.basic]}`,
    benefitCopy: FreeBenefitCopy,
    productName: 'Free account',
    titleText: 'Free account',
  },
  breach: {
    copyLink: `${benefitsDocument.baseUrl}/${benefitsDocument.identity_complimentary}`,
    benefitCopy: BreachBenefitCopy,
    productName: 'Identity Plus',
    titleText: (
      <React.Fragment>
        Identity <b>Plus</b>
      </React.Fragment>
    ),
  },
};

export const BenefitTooltipValues = [
  { benefit: 'Score history', tooltipValue: 'Track how your score has changed over time' },
  { benefit: 'Score Simulator', tooltipValue: 'See how different credit decisions could affect your score' },
  { benefit: 'Web monitoring', tooltipValue: "We scan the internet to check your identity isn't being used elsewhere" },
  { benefit: 'Boost your score', tooltipValue: 'See if you can instantly improve your score with Experian Boost' },
  { benefit: 'Monthly Experian Credit Score', tooltipValue: 'Your score is updated every 30 days, if you log in.' },
];
