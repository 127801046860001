import get from 'lodash/get';
import {
  ADD_PAYMENT_METHOD,
  PAYMENT_METHODS_FETCHED,
  PAYMENT_METHOD_CHALLENGED,
  PAYMENT_METHOD_SAVED,
  NO_PAYMENT_METHODS,
  ADD_DD_PAYMENT_METHOD,
  DD_PAYMENT_METHOD_SAVED,
  RESET_CARD_SAVED,
} from '../actions/billing/paymentMethods';
import { AUTHORIZE_CARD, CARD_AUTHORIZED, CHALLENGE_PRESENTED } from '../actions/billing/authorize';
import { BILLING_FETCHED, BILLING_FETCHED_FAILED } from '../actions/billing/getBilling';
import { RESET_CHALLENGE } from '../actions/billing/resetChallenge';
import { FETCH_PRO_RATA_PRICE, PRO_RATA_PRICE_FETCHED } from '../actions/billing/proRataPrice';
import billingObject from './objects/billing';

const defaultState = Object.keys(billingObject).reduce((billingObjectItems, key) => {
  const items = billingObjectItems;
  items[key] = billingObject[key].defaultValue;
  return items;
}, {});

const mapTrialUnit = input => {
  const validUnits = ['day', 'month', 'year'];
  const unit = input.toLowerCase().replace(/s/g, '');
  return validUnits.includes(unit) ? unit : '';
};

const extractPaymentDetails = payload => {
  const [paymentMethod] = payload.paymentMethods;
  const mobilePaymentTypes = ['ApplePay', 'GooglePay'];

  const response = { card: null, directDebit: null, mobile: null };
  if (mobilePaymentTypes.includes(paymentMethod.paymentType)) {
    return { ...response, mobile: paymentMethod };
  }

  if (paymentMethod?.paymentType === 'DirectDebit') {
    return { ...response, directDebit: { accountNumber: paymentMethod.accountNumber } };
  }

  return {
    ...response,
    card: payload.paymentMethods[0].creditCardInfo[0],
  };
};

export default (state = defaultState, action = {}) => {
  if (action.error) {
    return {
      ...state,
      fetchingProRataPrice: false,
      loading: false,
      challengeUrl: null,
      error: true,
    };
  }

  const prevState = {
    ...state,
  };

  // Must ensure certain values, such as next billing date, are fresh to avoid misleading the customer
  Object.keys(billingObject)
    .filter(key => billingObject[key].mustBeFresh)
    .forEach(key => delete prevState[key]);

  switch (action.type) {
    case BILLING_FETCHED:
      return {
        ...state,
        freeTrialTerms: {
          remaining: {
            unit: mapTrialUnit(get(action.payload, 'terms[0].trialDurationType', '')),
            value: get(action.payload, 'terms[0].trialDuration', undefined),
          },
        },
        endDate: action.payload.endDate,
        nextBillingDate: action.payload.nextBillDt,
        signupPrice: get(action.payload, 'terms[0].price', undefined),
        billingStateRecycle: action.payload.billingState === 'InRetry',
        lastRefreshed: new Date(),
        error: false,
      };
    case BILLING_FETCHED_FAILED:
      return {
        ...prevState,
        fetchFailed: true,
        error: true,
      };
    case PAYMENT_METHODS_FETCHED:
      return {
        ...state,
        ...extractPaymentDetails(action.payload),
      };
    case NO_PAYMENT_METHODS:
      return {
        ...state,
        hasSavedCard: false,
      };
    case RESET_CARD_SAVED:
      return {
        ...state,
        cardSaved: false,
      };
    case AUTHORIZE_CARD:
    case ADD_PAYMENT_METHOD:
    case ADD_DD_PAYMENT_METHOD:
      return {
        ...state,
        loading: true,
      };
    case CARD_AUTHORIZED:
      return {
        ...state,
        authorized: action.payload.authorized,
        loading: false,
      };
    case PAYMENT_METHOD_SAVED:
      return {
        ...state,
        ...action.payload,
        loading: false,
        challengeUrl: null,
      };
    case DD_PAYMENT_METHOD_SAVED:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case FETCH_PRO_RATA_PRICE:
      return {
        ...state,
        fetchingProRataPrice: true,
      };
    case PRO_RATA_PRICE_FETCHED:
      return {
        ...state,
        nextBillingDate: action.payload.nextBillDt,
        proRataPrice: action.payload.estimatedPrice,
      };
    case PAYMENT_METHOD_CHALLENGED:
    case CHALLENGE_PRESENTED:
      return {
        ...state,
        challengeUrl: action.payload.challengeUrl,
        loading: false,
      };
    case RESET_CHALLENGE:
      return {
        ...state,
        challengeUrl: null,
      };
    default:
      return state;
  }
};
