import React from 'react';
import { InfoHeading, InfoText, InfoWrapper } from './index.styles';

const Privacy = () => (
  <InfoWrapper>
    <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.491 0C30.1157 0 34.695 4.4843 34.8211 10.0688L34.8237 10.3054V17.4001C34.8237 17.51 34.8141 17.6176 34.7956 17.722L41.222 17.7223C43.5273 17.7223 45.4106 19.5452 45.4969 21.8312L45.5 21.9952V44.2475C45.5 46.5553 43.6705 48.432 41.3859 48.518L41.222 48.5211H7.778C5.47257 48.5211 3.58943 46.6981 3.50309 44.4116L3.5 44.2475V21.9952C3.5 19.688 5.32965 17.8115 7.61407 17.7254L7.778 17.7223L14.1954 17.722C14.1831 17.6524 14.1747 17.5814 14.1704 17.5092L14.1672 17.4001V10.3054C14.1672 4.78715 18.5203 0.269715 24.0128 0.0114705L24.2489 0.00302005L24.491 0ZM41.222 19.2386H7.778C6.29098 19.2386 5.0804 20.3969 5.00384 21.8491L5 21.9952V44.2475C5 45.7189 6.16253 46.9247 7.63024 47.001L7.778 47.0048H41.222C42.7091 47.0048 43.9196 45.8464 43.9962 44.3937L44 44.2475V21.9952C44 20.5245 42.8374 19.3187 41.3697 19.2425L41.222 19.2386ZM24.2766 1.51902L24.5004 1.51622L24.7156 1.51907C29.492 1.63766 33.3237 5.52855 33.3237 10.3054V17.4001L33.3186 17.4557C33.2914 17.6004 33.1601 17.7132 32.9982 17.7132H15.992L15.933 17.7081C15.7802 17.6812 15.6672 17.5517 15.6672 17.4001V10.3054L15.67 10.0825C15.787 5.41565 19.5657 1.63494 24.2766 1.51902Z"
        fill="#1D4F91"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.1092 31.03C28.1092 29.0361 26.4892 27.4189 24.4905 27.4189C22.4925 27.4189 20.8725 29.0361 20.8725 31.03C20.8725 32.4613 21.7132 33.6865 22.923 34.271V37.6508C22.923 38.5598 23.6617 39.2975 24.573 39.2975C25.4842 39.2975 26.2222 38.5598 26.2222 37.6508V34.1816C27.342 33.5682 28.1092 32.3946 28.1092 31.03Z"
        fill="#E63888"
      />
    </svg>
    <InfoHeading>Your data is safe</InfoHeading>
    <InfoText>Data is our business, and security is our priority. Your payment details are secure.</InfoText>
  </InfoWrapper>
);

export default Privacy;
