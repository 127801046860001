import styled from 'styled-components';
import { rem } from 'polished';
import { AlertContainer, AlertMessage } from './Alerts.styles';

export const BreachAlert = styled(AlertContainer)`
  background: ${({ messageType }) => (messageType === 'info' && '#f8f7ff') || (messageType === 'success' && '#F1FBF4')};
  border: 1px solid
    ${({ messageType }) => (messageType === 'info' && '#cbc6fc') || (messageType === 'success' && '#92DCAE')};
  text-align: left;
  display: inline-flex;
  padding: ${rem(16)};
`;

export const AlertContent = styled.div`
  padding-left: ${rem(8)};
`;

export const AlertText = styled(AlertMessage)`
  line-height: ${rem(24)};
  margin: 0;
  font-weight: 400;
`;

export const AlertHeading = styled.h2`
  font-size: ${rem(16)};
  font-weight: 500;
  line-height: ${rem(24)};
  margin: 0;
  padding-bottom: ${rem(4)};
`;
