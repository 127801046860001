import styled from 'styled-components';
import { Link } from '@experian-uk/corvetteuk-common-ui';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  background: linear-gradient(180deg, #ccefee 0%, #cce6ed 100%);
  padding: 21px 24px;
  border: 0.5px solid #e6e9ee;
  border-radius: 12px;
  margin-bottom: 2rem;
`;

export const IconContainer = styled.div`
  display: flex;

  @media (min-width: 769px) {
    margin-right: 1rem;
  }
`;

export const IconImage = styled.img`
  width: 4rem;
  height: 4rem;

  @media (max-width: 768px) {
    margin-right: 0.5rem;
  }
`;

export const ContentContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex: 1 1 0%;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const TextContainer = styled.div`
  flex: 1 1 0%;

  @media (min-width: 1280px) {
    padding: 0rem 1rem;
  }
`;

export const MobileTitleContainer = styled.div`
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  flex-direction: row;

  @media (min-width: 1280px) {
    margin-bottom: 0.5rem;
    margin-top: 0px;
  }

  @media (min-width: 769px) {
    display: none;
  }
`;

export const MobileTitle = styled.p`
  flex: 1 1 0%;
  margin: 0px;
  line-height: 1.5rem;
  font-size: 1rem;
  font-weight: 500;
  color: #001633;

  @media (min-width: 1280px) {
    line-height: 1.5rem;
    font-size: 1.125rem;
  }
`;

export const TitleContainer = styled.div`
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  flex-direction: row;

  @media (min-width: 1280px) {
    margin-bottom: 0.5rem;
    margin-top: 0px;
  }

  @media (max-width: 769px) {
    display: none;
  }
`;

export const Title = styled.p`
  flex: 1 1 0%;
  margin: 0px;
  line-height: 1.75rem;
  font-size: 1.125rem;
  font-weight: 500;
  color: #001633;

  @media (min-width: 1280px) {
    font-size: 1.125rem;
  }
`;

export const Description = styled.p`
  line-height: 1.25rem;
  font-size: 0.876rem;
  font-weight: 400;
  margin-bottom: 1.15rem;
  color: #001633;
  margin-top: 1rem;

  @media (min-width: 769px) {
    line-height: 1.5rem;
    font-size: 1rem;
  }
`;

export const DescriptionMobileSeparator = styled.span`
  display: inline;

  @media (max-width: 768px) {
    display: block;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
`;

export const DescriptionIDPlus = styled.span`
  font-weight: 600;
  display: inline;
`;

export const IdentityLink = styled(Link.Button)`
  line-height: 1.25rem;
  font-size: 0.876rem;
  text-decoration-line: none;
  font-weight: 500;
  color: #0076dc;
  text-align: left;
  padding: 0px;

  @media (min-width: 769px) {
    line-height: 1.5rem;
    font-size: 1rem;
  }
`;
