import React from 'react';
import { ThemeProvider } from 'styled-components';
import nebulaTheme from '@nebula/theme';
import Tooltip from '@nebula/tooltip';

import { clickECDEvent } from '../../pages/home/homePageEcd2';
import { BenefitTooltipValues } from './cardContent';
import { TooltipContainer } from './tooltipForBenefit.styles';

export default benefit => {
  const benefitHasTooltip = BenefitTooltipValues.find(b => b.benefit === benefit);
  if (!benefitHasTooltip) {
    return null;
  }

  const ecdBenefitText = `prodmove_tooltip_${benefit.toLowerCase().split(' ').join('_')}`;

  const tooltipECDEvent = ecdText => {
    if (!window.$ECD2?.some(existingEcdEvent => existingEcdEvent?.interaction?.component === ecdText)) {
      clickECDEvent(ecdText);
    }
  };

  return (
    <ThemeProvider theme={nebulaTheme}>
      <TooltipContainer data-automation-test-element="benefit-tooltip-container">
        <Tooltip
          text={benefitHasTooltip.tooltipValue}
          className="benefit-tooltip"
          onOpen={() => tooltipECDEvent(ecdBenefitText)}
        />
      </TooltipContainer>
    </ThemeProvider>
  );
};
