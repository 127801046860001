import styled from 'styled-components';

export const StyledContainer = styled.div`
  padding-top: 1.25rem;
  margin: 0 auto;
  width: 460px;
  max-width: 90%;
  @media (min-width: 700px) {
    max-width: 56%;
    padding-top: 1.875rem;
  }
  font-family: 'Roboto', 'Arial', sans-serif;
`;

export const StyledStepMessage = styled.p`
  margin: 0 8px;
  padding-top: 5px;
  color: #63666a;
  font-size: 20px;
  font-weight: 200;
  width: 100%;
`;

export const StyledTrialMessage = styled.a`
  font-size: 16px;
  color: #0076dc;
  display: inline-block;
  margin: 8px 0 0 8px;
  font-weight: 300;
  cursor: pointer;
`;

export const StyledFormWrapper = styled.div`
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
  padding: 14px;
  min-height: 56px;
  width: 100%;
  @media only screen and (min-width: 325px) {
    padding: 16px;
  }
`;
