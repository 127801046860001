import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { PropTypes } from 'prop-types';

import { FormFieldWrapper, FormLabel, DoubleField } from './FormFields.styles';
import { fields } from './FormFields';
import Tooltip from './Tooltip';

const FullCardForm = ({ change }) => (
  <React.Fragment>
    <FormFieldWrapper key={fields.name.id}>
      <FormLabel htmlFor={fields.name.id}>
        <span>{fields.name.label}</span>
      </FormLabel>
      <Field
        dataAutomationTestElement={fields.name.dataAutomationTestElement}
        component={fields.name.component}
        errorMessageProps={fields.name.errorMessageProps}
        inputProps={fields.name.props}
        name={fields.name.id}
        normalize={fields.name.normalize}
        validate={fields.name.validate}
      />
    </FormFieldWrapper>
    <FormFieldWrapper key={fields.ccNumber.id}>
      <FormLabel htmlFor={fields.ccNumber.id}>
        <span>{fields.ccNumber.label}</span>
      </FormLabel>
      <Field
        dataAutomationTestElement={fields.ccNumber.dataAutomationTestElement}
        component={fields.ccNumber.component}
        errorMessageProps={fields.ccNumber.errorMessageProps}
        inputProps={fields.ccNumber.props}
        name={fields.ccNumber.id}
        normalize={fields.ccNumber.normalize}
        validate={fields.ccNumber.validate}
        inputMode={fields.ccNumber.inputMode}
        onChange={event => fields.ccNumber.onChange(event, change)}
      />
    </FormFieldWrapper>
    <DoubleField>
      <FormFieldWrapper key={fields.expiry.id}>
        <FormLabel htmlFor={fields.expiry.id}>
          <span>{fields.expiry.label}</span>
        </FormLabel>
        <Field
          dataAutomationTestElement={fields.expiry.dataAutomationTestElement}
          component={fields.expiry.component}
          errorMessageProps={fields.expiry.errorMessageProps}
          inputProps={fields.expiry.props}
          name={fields.expiry.id}
          normalize={fields.expiry.normalize}
          validate={fields.expiry.validate}
          inputMode={fields.expiry.inputMode}
        />
      </FormFieldWrapper>
      <FormFieldWrapper key={fields.cvv.id}>
        <FormLabel htmlFor={fields.cvv.id}>
          <span>{fields.cvv.label}</span>
          <Tooltip />
        </FormLabel>
        <Field
          dataAutomationTestElement="cvv-full-card-form"
          component={fields.cvv.component}
          errorMessageProps={fields.cvv.errorMessageProps}
          inputProps={fields.cvv.props}
          name={fields.cvv.id}
          normalize={fields.cvv.normalize}
          validate={fields.cvv.validate}
          inputMode={fields.cvv.inputMode}
        />
      </FormFieldWrapper>
    </DoubleField>
  </React.Fragment>
);

FullCardForm.propTypes = { change: PropTypes.func.isRequired };

export default reduxForm({
  form: 'productMovement/newCard', // a unique identifier for this form
  onSubmit: () => {},
})(FullCardForm);
