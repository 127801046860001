import { getOfferById } from '../../../reducers/offers';
import { isFreeTrial } from '../../../helpers/subscription';

export default state => {
  if (!state.selection || !state.selection.offerId) {
    return null;
  }
  const selectedId = state.selection.offerId;
  const selectedOffer = getOfferById(state.offers, selectedId);

  // User no longer has access to the selected offer (due to upgrade) - nothing to set here
  if (!selectedOffer) {
    return null;
  }

  return selectedOffer.descriptors && isFreeTrial(selectedOffer.descriptors);
};
