import Analytics, { InteractionInputEvent, MilestoneInputEvent } from '@experian-uk/web-common-analytics';

export const publishChangePaymentMethodECD = (paymentOptionsAvailable, currentPaymentMethod) => {
  const changePaymentMethodECD = InteractionInputEvent.fromObject({
    component: 'change_payment_method',
    action: 'click',
    status: 'success',
    data: {
      payment_options_available: paymentOptionsAvailable,
      current_payment_method: currentPaymentMethod,
    },
  });
  Analytics.publishOnce(changePaymentMethodECD);
};

export const publishOnClickApplePayECD = () => {
  const onClickApplePayECD = InteractionInputEvent.fromObject({
    component: 'apple_pay_button',
    action: 'click',
    status: 'success',
  });
  Analytics.publish(onClickApplePayECD);
};

export const publishOnClickCancelECD = () => {
  const onClickCancelECD = InteractionInputEvent.fromObject({
    component: 'cancel_payment_update_button',
    action: 'click',
    status: 'success',
  });
  Analytics.publish(onClickCancelECD);
};

export const publishPaymentMethodUpdatedECD = ({ updateStatus, attemptedUpdateMethod }) => {
  const paymentMethodUpdatedECD = MilestoneInputEvent.fromObject({
    status: updateStatus,
    data: {
      attempted_method: attemptedUpdateMethod,
    },
  });
  Analytics.publish(paymentMethodUpdatedECD);
};

export const publishOnErrorECD = ({ error }) => {
  const onErrorECD = MilestoneInputEvent.fromObject({
    component: 'payment_update',
    application: 'product_movement',
    error_state: error,
  });
  Analytics.publish(onErrorECD);
};
