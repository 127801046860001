import get from 'lodash/get';
import Analytics, { MilestoneInputEvent } from '@experian-uk/web-common-analytics';
import fetch from '../fetch';
import errorMessages from './errorMessages';
import { defined } from '../../helpers/defined';
import CardSaveError from '../../exceptions/cardSaveError';
import { getEnv } from '../../components/Context/env';
import addEvent from '../ecd/addEvent';
import initializeEcd from '../ecd/initialize';
import { resolveChallengeStatus } from './resolveChallengeStatus';
import DirectDebitSaveError from '../../exceptions/directDebitSaveError';
import { maxTimeout, pollingInterval } from '../../constants/polling3ds';

export const ADD_PAYMENT_METHOD = 'ADD_PAYMENT_METHOD';
export const ADD_DD_PAYMENT_METHOD = 'ADD_DD_PAYMENT_METHOD';
export const FETCH_PAYMENT_METHODS = 'FETCH_PAYMENT_METHODS';
export const NO_PAYMENT_METHODS = 'NO_PAYMENT_METHODS';
export const paymentMethodsEndpoint = 'billingpaymentmethods';
export const paymentAuthEndpoint = 'billingpaymentauthorizations';
export const PAYMENT_METHODS_FETCHED = 'PAYMENT_METHODS_FETCHED';
export const PAYMENT_METHOD_SAVED = 'PAYMENT_METHOD_SAVED';
export const DD_PAYMENT_METHOD_SAVED = 'DD_PAYMENT_METHOD_SAVED';
export const PAYMENT_METHOD_CHALLENGED = 'PAYMENT_METHOD_CHALLENGED';
export const RESET_CARD_SAVED = 'RESET_CARD_SAVED';

export const getPaymentMethods = () => async dispatch => {
  dispatch({ type: FETCH_PAYMENT_METHODS });

  const dispatchAction = {
    type: NO_PAYMENT_METHODS,
    payload: {},
  };

  try {
    const paymentMethods = await dispatch(fetch(`/${paymentMethodsEndpoint}`));

    if (paymentMethods && paymentMethods.data) {
      dispatchAction.type = PAYMENT_METHODS_FETCHED;
      dispatchAction.payload = { paymentMethods: paymentMethods.data };
    }
  } catch (error) {
    dispatchAction.type = PAYMENT_METHODS_FETCHED;
    dispatchAction.error = true;
    dispatchAction.payload = new Error(JSON.stringify(errorMessages.getPaymentMethods));
    dispatchAction.meta = { critical: false };
  }

  return dispatch(dispatchAction);
};

export const addPaymentMethod = (card, subscriptionId) => async dispatch => {
  dispatch({ type: ADD_PAYMENT_METHOD });

  const env = getEnv();

  if (!defined('object', card)) {
    return dispatch({
      type: PAYMENT_METHOD_SAVED,
      error: true,
      payload: new CardSaveError(JSON.stringify(errorMessages.validation)),
    });
  }

  if (!env.REACT_APP_USE_3DS || env.REACT_APP_USE_3DS === 'false') {
    const request = {
      method: 'POST',
      body: {
        creditCardInfo: {
          ...card,
          isDefaultCard: true,
        },
      },
    };
    try {
      const result = await dispatch(fetch(`/${paymentMethodsEndpoint}`, request));
      if (get(result, 'data[0].success', false)) {
        return dispatch({
          type: PAYMENT_METHOD_SAVED,
          payload: {
            card: {
              ...card,
              expirationMonth: parseInt(card.expirationMonth, 10),
              expirationYear: parseInt(card.expirationYear, 10),
            },
            cardSaved: true,
          },
        });
      }
      return dispatch({
        error: true,
        payload: new CardSaveError(JSON.stringify(errorMessages.cardAuthorization(null, 'addPaymentMethod'))),
        type: PAYMENT_METHOD_SAVED,
      });
    } catch (error) {
      return dispatch({
        error: true,
        payload: new CardSaveError(JSON.stringify(errorMessages.cardAuthorization(error.status, 'addPaymentMethod'))),
        type: PAYMENT_METHOD_SAVED,
      });
    }
  } else {
    const request = {
      method: 'PUT',
      body: {
        creditCardInfo: {
          ...card,
          isDefaultCard: true,
        },
        browser: {
          acceptHeader: '',
          screenColourDepth: window.screen.colorDepth,
          javaScriptEnabled: true,
          language: 'en',
          remoteAddress: '',
          screenResolution: `${window.screen.availWidth}X${window.screen.availHeight}`,
          timezone: new Date().getTimezoneOffset().toString(),
          userAgentHeader: navigator.userAgent,
        },
        offerId: subscriptionId,
        paymentType: 'creditcard',
        paymentMethodChange: true,
        returnurl: env.REACT_APP_RETURN_URL_3DS,
      },
      meta: {
        require3dsHeaders: true,
      },
    };
    try {
      const authRes = await dispatch(fetch(`/${paymentAuthEndpoint}`, request));

      const challengeUrl = authRes.data[0].challengeRedirectUrl;
      const success = !!get(authRes, 'data[0].success', false);
      const getECDAction = label =>
        addEvent('/', {
          category: 'Product Movement',
          action: 'Add New Card',
          label,
        });

      // 3DS Challenge not required where challengeUrl is empty or not returned
      if (success && !challengeUrl) {
        Analytics.publishOnce(
          MilestoneInputEvent.fromObject({
            product_movement: {
              journey_type: 'Add New Card',
              page_funnel_step: 'Save - Step 1',
            },
          })
        );
        // Remove GA3 event in Q2FY25
        dispatch(getECDAction('Save'));
        dispatch(initializeEcd());
        return dispatch({
          type: PAYMENT_METHOD_SAVED,
          payload: {
            card: {
              ...card,
              expirationMonth: parseInt(card.expirationMonth, 10),
              expirationYear: parseInt(card.expirationYear, 10),
            },
            cardSaved: true,
          },
        });
      }
      Analytics.publishOnce(
        MilestoneInputEvent.fromObject({
          product_movement: {
            journey_type: 'Add New Card',
            page_funnel_step: '3DS Challenge - Step 1',
          },
        })
      );
      // Remove GA3 event in Q2FY25
      dispatch(getECDAction('3DS Challenge'));
      dispatch(initializeEcd());
      dispatch({
        type: PAYMENT_METHOD_CHALLENGED,
        payload: { challengeUrl },
      });

      // Begin polling challenge status
      const challengeResponse = await resolveChallengeStatus(
        paymentAuthEndpoint,
        dispatch,
        maxTimeout,
        pollingInterval
      );
      switch (challengeResponse) {
        case 'ServiceError':
          return dispatch({
            error: true,
            payload: new CardSaveError(JSON.stringify(errorMessages.cardAuthorization(null, 'threeDSValidationError'))),
            type: PAYMENT_METHOD_SAVED,
          });
        case 'Success':
          return dispatch({
            type: PAYMENT_METHOD_SAVED,
            payload: {
              card: {
                ...card,
                expirationMonth: parseInt(card.expirationMonth, 10),
                expirationYear: parseInt(card.expirationYear, 10),
              },
              cardSaved: true,
            },
          });
        case 'Stopped':
          return false;
        default:
          // For 'Fail' response from timeout or unauthorised
          return dispatch({
            error: true,
            payload: new CardSaveError(JSON.stringify(errorMessages.cardAuthorization(null, 'threeDSValidationError'))),
            type: PAYMENT_METHOD_SAVED,
          });
      }
    } catch (error) {
      return dispatch({
        type: NO_PAYMENT_METHODS,
        error: true,
        payload: new CardSaveError(JSON.stringify(errorMessages.cardAuthorization(error.status, 'addPaymentMethod'))),
      });
    }
  }
};

export const addDDPaymentMethod = (directDebitDetails, offerId) => async dispatch => {
  dispatch({ type: ADD_DD_PAYMENT_METHOD });

  if (!defined('object', directDebitDetails) || !offerId) {
    return dispatch({
      type: DD_PAYMENT_METHOD_SAVED,
      error: true,
      payload: new DirectDebitSaveError(JSON.stringify(errorMessages.ddValidation)),
    });
  }

  const request = {
    method: 'PUT',
    body: {
      AccountHolderName: directDebitDetails.accountHolderName,
      AccountNumber: directDebitDetails.accountNumber,
      SortCode: directDebitDetails.sortCode,
      OfferId: offerId,
    },
  };

  try {
    const result = await dispatch(fetch(`/${paymentMethodsEndpoint}/directdebit`, request));
    if (get(result, 'data[0].success', false)) {
      return dispatch({
        type: DD_PAYMENT_METHOD_SAVED,
        payload: {
          directDebitDetails,
          directDebitSaved: true,
        },
      });
    }
    return dispatch({
      error: true,
      payload: new CardSaveError(JSON.stringify(errorMessages.ddAuthorization(null, 'addDDPaymentMethod'))),
      type: DD_PAYMENT_METHOD_SAVED,
    });
  } catch (error) {
    return dispatch({
      error: true,
      payload: new CardSaveError(JSON.stringify(errorMessages.ddAuthorization(error.status, 'addDDPaymentMethod'))),
      type: DD_PAYMENT_METHOD_SAVED,
    });
  }
};

export const resetCardSaved = () => async dispatch =>
  dispatch({
    type: RESET_CARD_SAVED,
  });
