import { FETCH_SCORE_DATA, SCORE_DATA_FETCHED } from '../actions/scores/getScore';

export const defaultState = {
  fetching: false,
  data: null,
};

export default (state = defaultState, action = {}) => {
  if (action.error) {
    return { ...state, error: true };
  }

  switch (action.type) {
    case FETCH_SCORE_DATA:
      return {
        ...defaultState,
        fetching: true,
      };
    case SCORE_DATA_FETCHED:
      return {
        ...defaultState,
        data: action.payload?.data,
      };
    default:
      return state;
  }
};
