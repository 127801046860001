import {
  UPDATING_ACTIVE_STATUS,
  ACTIVE_STATUS_UPDATED,
  STATUS_UPDATE_FAILED,
} from '../actions/customers/updateActiveStatus';

const defaultState = {
  error: null,
  fetching: false,
  statusUpdated: null,
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case UPDATING_ACTIVE_STATUS:
      return {
        ...state,
        fetching: true,
      };
    case ACTIVE_STATUS_UPDATED:
      return {
        ...state,
        fetching: false,
        error: null,
        statusUpdated: true,
      };
    case STATUS_UPDATE_FAILED:
      return {
        ...state,
        fetching: false,
        error: true,
      };
    default:
      return state;
  }
};
