import PropTypes from 'prop-types';

export default PropTypes.shape({
  REACT_APP_AUTH_URL: PropTypes.string.isRequired,
  REACT_APP_C2_URL: PropTypes.string.isRequired,
  REACT_APP_IDAAS_URL: PropTypes.string.isRequired,
  REACT_APP_INS_URL: PropTypes.string.isRequired,
  REACT_APP_GATEWAY_URL: PropTypes.string.isRequired,
  REACT_APP_EXPERIAN_URL: PropTypes.string.isRequired,
  REACT_APP_INTERNAL_API: PropTypes.string.isRequired,
  REACT_APP_JWT_PATH: PropTypes.string.isRequired,
  REACT_APP_SESSION_TIMEOUT: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  REACT_APP_ENV: PropTypes.oneOf(['local', 'dev', 'int', 'stg', 'prod']),
});
