import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import nebulaTheme from '@nebula/theme';
import StepByStep from '@nebula/step-by-step';
import { dateFormats, familyName } from '../../constants';
import eligibleForCeTrial from '../../actions/ecd/resolvers/eligibleForCeTrial';
import { Title, StepperContainer } from './index.styles';
import WidescreenInformationAlert from '../Alerts/WidescreenInformationAlert';

dayjs.extend(advancedFormat);

const BreachStepper = ({ selectedOffer, billing, title }) => {
  const requiredDataIsNull = !selectedOffer?.family || !selectedOffer?.terms[0]?.price || !billing?.endDate;

  if (requiredDataIsNull) {
    return <WidescreenInformationAlert text="Sorry, something went wrong. Please try again later." />;
  }

  const isEligibleForTrial = useSelector(state => eligibleForCeTrial(state));
  const nextBillDate = isEligibleForTrial ? dayjs(billing.endDate).add(30, 'day') : dayjs(billing.endDate);

  const mySteps = [
    {
      title: isEligibleForTrial
        ? `Your free trial of CreditExpert would start on ${dayjs(billing.endDate).format(dateFormats.long)}.`
        : `Your complimentary subscription ends on ${dayjs(billing.endDate).format(dateFormats.long)}.`,
      key: 'step-1',
    },
    {
      title: `Your ${familyName[selectedOffer.family]} subscription would start on ${nextBillDate.format(
        dateFormats.long
      )}.`,
      key: 'step-2',
    },
    {
      title: `You would be billed £${selectedOffer.terms[0].price} on the ${nextBillDate.format(
        'Do'
      )} of every month from ${nextBillDate.format('MMMM')}.`,
      key: 'step-3',
    },
  ];

  return (
    <ThemeProvider theme={nebulaTheme}>
      <StepperContainer>
        <Title>{title}</Title>
        <StepByStep steps={mySteps} />
      </StepperContainer>
    </ThemeProvider>
  );
};

BreachStepper.propTypes = {
  selectedOffer: PropTypes.shape({
    family: PropTypes.string,
    terms: PropTypes.arrayOf(
      PropTypes.shape({
        price: PropTypes.number,
      })
    ),
  }).isRequired,
  billing: PropTypes.shape({
    endDate: PropTypes.string,
  }).isRequired,
  title: PropTypes.string,
};

BreachStepper.defaultProps = {
  title: 'What happens now',
};

export default BreachStepper;
