import fetch from '../fetch';
import initializeEcd from '../ecd/initialize';
import { errorMessages } from '../../constants';

export const FETCH_BILLING = 'FETCH_BILLING';
export const BILLING_FETCHED = 'BILLING_FETCHED';
export const BILLING_FETCHED_FAILED = 'BILLING_FETCHED_FAILED';

export default () => async dispatch => {
  dispatch({ type: FETCH_BILLING });

  const fetched = {
    type: BILLING_FETCHED,
  };

  try {
    const billing = await dispatch(fetch('/billingsubscriptions'));

    fetched.payload = { ...billing.data[0] };
  } catch (error) {
    const errorMessage = {
      heading: errorMessages.someDataMissing,
      messages: [errorMessages.technicalIssues],
    };

    fetched.error = true;
    fetched.payload = new Error(JSON.stringify(errorMessage));
    dispatch({ type: BILLING_FETCHED_FAILED });
  }

  dispatch(fetched);
  dispatch(initializeEcd());
};
