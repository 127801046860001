import styled from 'styled-components';
import { Typography } from '@experian-uk/corvetteuk-common-ui';

const { Headings } = Typography;

export const Heading = styled(Headings.Primary)`
  text-align: center;
  padding: 1.375rem 0 2rem;
  ${({ theme }) => theme.media('20', 'text-align: left;')}
`;

export const BreachUpsellWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  ${({ theme }) => theme.media('20', 'flex-direction: row;')}
  > div {
    flex: 1;
  }
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  p {
    margin-bottom: 0;
  }
  h3 {
    margin-top: 0.5rem;
    + hr {
      margin: 1.125rem 0;
    }
  }
`;

export const Disclaimer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  padding: ${({ spacing }) => (spacing ? '0 2.125rem' : '0 2.125rem 1.5rem')};
  font-size: 1rem;
  font-weight: 400;
  p {
    margin: 0;
  }
`;

export const ButtonContainer = styled.div`
  text-align: center;
  padding: 0 0 1.25rem;
  // Needed because user agent styles are overriding on specificity
  button {
    font-family: 'Roboto';
  }
`;
