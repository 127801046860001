import React from 'react';
import { Route, Switch } from 'react-router';
import { ThemeProvider } from 'styled-components';
import { withRouter } from 'react-router-dom';
import { baseTheme } from '@experian-uk/corvetteuk-common-ui';

import DefaultLayout from './defaultLayoutRoutes';
import ActivationPage from './activation';
import ChallengeFinished from './challenge-finished';
import ErrorPage from './error';
import AcquisitionIndex from './acquisition';

@withRouter
export default class PagesIndex extends React.Component {
  render() {
    return (
      <ThemeProvider theme={baseTheme}>
        <Switch>
          <Route exact path="/activation" component={ActivationPage} />
          <Route exact path="/error/:errorCode?" component={ErrorPage} />
          {(process.env.REACT_APP_ENV === 'local' || window.Cypress?.isIntegrationTest) && (
            <Route exact path="/acquisition" component={AcquisitionIndex} />
          )}
          <Route exact path="/challenge-finished" component={ChallengeFinished} />
          <Route component={DefaultLayout} />
        </Switch>
      </ThemeProvider>
    );
  }
}
