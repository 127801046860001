import styled from 'styled-components';
import { Typography, Breadcrumbs } from '@experian-uk/corvetteuk-common-ui';

const { Headings } = Typography;

const Row = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 73.25rem;

  ${props =>
    props.theme.media(
      '15',
      `
    flex-direction: row;   
  `
    )}
`;

const Section = styled.div`
  position: relative;
  margin: 0 auto;
  padding-top: 3rem;

  ${props =>
    props.theme.media(
      '20',
      `
    max-width: 73.25rem;
    padding-top: 5rem;
  `
    )}
`;

const ProductOverviewContainer = styled(Row)`
  padding: 1rem 0;
`;

const HeaderTextWrapper = styled.div`
  width: 100%;
  flex: 1;
  padding: 0 1rem;
  margin-top: 1rem;
  ${props =>
    props.theme.media(
      '15',
      `
    width: 60%;
    margin-top:0;
  `
    )}
`;

const FeatureImg = styled.img`
  position: initial;
  width: 140%;
  margin-left: -20%;
  margin-bottom: -20%;
  ${props =>
    props.theme.media(
      '10',
      `
    width: 120%;
    margin-left: -10%;
    margin-bottom: -10%;
  `
    )}

  ${props =>
    props.theme.media(
      '15',
      `
    position: absolute;
    left: 60%;
    top: 50px;
    max-width: 1000px;
  `
    )}

  ${props =>
    props.theme.media(
      '20',
      `
    top: 25px;
  `
    )}
`;

const SectionTitle = styled(Headings.Secondary)`
  text-align: center;
  margin: 1rem 0 1rem 0;
`;

const HeaderContainer = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-bottom: 5rem;
  background-image: radial-gradient(circle at 50% 50%, #ffffff, #f7f7f8);

  ${Row} {
    ${props =>
      props.theme.media(
        '15',
        `
      flex-direction: column;
    `
      )}
  }

  ${props =>
    props.theme.media(
      '15',
      `
    min-height:457px;
  `
    )}
`;

const ProductOverview = styled.div`
  flex: 1;
  display: flex;
  margin: 0 auto;
  margin-bottom: 1.5rem;
  padding: 0 1rem;
  padding-bottom: 0;
  flex-direction: column;
  text-align: center;

  ${props =>
    props.theme.media(
      '15',
      `
    width: 55%;
  `
    )}

  ${props =>
    props.theme.media(
      '20',
      `
      flex-direction: row;
      text-align: left;
  `
    )}
`;

const ProductImage = styled.img`
  width: 120px;
  height: 120px;
  flex-shrink: 0;
  margin: 0 auto;

  ${props =>
    props.theme.media(
      '20',
      `
      margin-right: 1.75rem;
      width: 160px;
      height: 160px;
  `
    )}
`;

const ExperianUsersContainer = styled.div`
  position: relative;
  z-index: 1;
  margin: 0 auto 2rem auto;
  padding: 1rem 2.5rem;
  box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.05);
  background-image: linear-gradient(343deg, rgba(109, 32, 119, 0.97), #af1685);
  color: #ffffff;
  text-align: center;

  ${props =>
    props.theme.media(
      '10',
      `
    border-radius: 4px;
    max-width: 490px;
    padding: 1rem 3rem;
  `
    )}

  ${props =>
    props.theme.media(
      '5',
      `
    padding: 1rem 1rem;
  `
    )}

  p, h1 {
    color: #ffffff;
    margin: 0;
  }
`;

const Triangle = styled.div`
  width: 120px;
  height: 120px;
  position: absolute;
  bottom: -20px;
  left: 50%;
  margin-left: -60px;
  clip: rect(auto, 180px, auto, 100px);
  transform: rotate(90deg);

  &::after {
    content: '';
    position: absolute;
    top: 10px;
    bottom: 10px;
    left: 10px;
    right: 10px;
    background: -webkit-gradient(linear, right top, left bottom, color-stop(0%, #7f1a7c), color-stop(100%, #8c2789));
    transform: rotate(45deg);
  }
`;

const intersect = 100;
const ProductTrialContainer = styled.div`
  text-align: center;
  background-color: #f5f8fb;
  margin-top: -${intersect}px;
  padding-top: ${intersect}px;
  height: 500px;
`;

const ProductPanelsContainer = styled.div`
  max-width: 71.25rem;
  padding: 1rem;
  margin: 0 auto;
  margin-top: -270px;
`;

const HideOverflow = styled.div`
  overflow-x: hidden;
`;

const BoldText = styled.span`
  font-weight: 400;
`;

const ResponsiveBr = styled.br`
  display: none;
  ${({ theme }) =>
    theme.media(
      '15',
      `
      display: inline;
  `
    )}
`;

const BreadcrumbsStyled = styled(Breadcrumbs)`
  display: none;
  ${({ theme }) =>
    theme.media(
      '15',
      `
      display: block;
  `
    )}
`;

export {
  SectionTitle,
  HeaderContainer,
  HeaderTextWrapper,
  FeatureImg,
  ProductOverview,
  ProductImage,
  ExperianUsersContainer,
  Triangle,
  ProductTrialContainer,
  Row,
  ProductOverviewContainer,
  Section,
  ProductPanelsContainer,
  HideOverflow,
  BoldText,
  ResponsiveBr,
  BreadcrumbsStyled,
};
