import fetch from '../fetch';

export const UPDATING_ACTIVE_STATUS = 'UPDATING_ACTIVE_STATUS';
export const ACTIVE_STATUS_UPDATED = 'ACTIVE_STATUS_UPDATED';
export const STATUS_UPDATE_FAILED = 'STATUS_UPDATE_FAILED';

export const endpoint = '/customers';
export const errorMessage = `Could not PATCH ${endpoint}`;

export default () => async dispatch => {
  dispatch({ type: UPDATING_ACTIVE_STATUS });

  const fetched = {};

  const request = {
    method: 'PATCH',
    body: [
      {
        value: 'Active',
        path: '/customerstatus',
        op: 'replace',
      },
    ],
  };

  try {
    const updateActiveStatus = await dispatch(fetch(endpoint, request));
    fetched.type = ACTIVE_STATUS_UPDATED;
    fetched.payload = { ...updateActiveStatus.data[0] };
    fetched.error = false;
  } catch (error) {
    fetched.error = true;
    fetched.payload = errorMessage;
    fetched.type = STATUS_UPDATE_FAILED;
  }

  return dispatch(fetched);
};
