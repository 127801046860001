import Analytics, { InteractionInputEvent } from '@experian-uk/web-common-analytics';
import isServer from '../../helpers/isServer';

const windowInt = () => {
  if (!isServer()) {
    window.$ECD2 = window.$ECD2 || [];
  }
};

const clickECDEvent = componentName => {
  windowInt();

  Analytics.publish(
    InteractionInputEvent.fromObject({
      component: componentName,
      action: 'click',
      status: 'success',
    })
  );
};

const clickInteractionECD = (component, data = {}) => {
  windowInt();

  Analytics.publish(
    InteractionInputEvent.fromObject({
      component,
      action: 'click',
      status: 'success',
      data,
    })
  );
};

export { clickECDEvent, clickInteractionECD };
