import fetch from '../fetch';

export const REWARD_SENT = 'REWARD_SENT';
export const REWARD_ERROR = 'REWARD_ERROR';

const sendTargetingGoal = async (dispatch, goalCode) =>
  dispatch(
    fetch(
      '/targeting/goals',
      {
        method: 'POST',
        body: {
          application: 'prodmove',
          goals: [{ id: goalCode }],
        },
      },
      { isRemote: false }
    )
  );

const sendReward = conductricsGoalApiCode => async dispatch => {
  const returnAction = {};

  try {
    const response = await sendTargetingGoal(dispatch, conductricsGoalApiCode);

    if (response.status === 200) {
      returnAction.type = REWARD_SENT;
      returnAction.payload = response;
    } else {
      returnAction.type = REWARD_ERROR;
      returnAction.payload = response;
    }
  } catch (error) {
    const { status, statusText, url: errorUrl } = error;
    returnAction.type = REWARD_ERROR;
    returnAction.payload = {
      status,
      statusText,
      url: errorUrl,
    };
  }

  return dispatch(returnAction);
};

export default sendReward;
