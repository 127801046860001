import styled, { keyframes } from 'styled-components';
import { BodyLarge400, TitleSmall500, TitleMedium500 } from '@nebula/global-styles';
import Button from '@nebula/button';
import TextInput from '@nebula/text-input';

export const AccordionWrapper = styled.div`
  color: ${({ theme }) => theme.color.brand.darkBlue};
  > div {
    color: ${({ theme }) => theme.color.brand.darkBlue};
    padding: 0 1rem 0 1rem;
    margin-bottom: 1rem;
  }
`;

export const AccordionTitle = styled(TitleSmall500)`
  color: ${({ theme }) => theme.color.brand.darkBlue};
`;

export const ModalCopy = styled(BodyLarge400)`
  margin-right: 1rem;
`;

const modalSpinnerKeyframes = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

export const ModalSpinner = styled.div`
  animation: ${modalSpinnerKeyframes} 0.75s infinite linear;
  background-color: transparent;
  border-radius: 50%;
  border: 0.15rem solid ${p => p.theme.color.ui.grey[30]};
  border-top-color: ${p => p.theme.color.ui.grey[100]};
  display: block;
  height: 1.5rem;
  width: 1.5rem;
`;

export const ModalSpinnerLargeWrapper = styled.div`
  display: none;

  @media ${({ theme }) => theme.media.tabletPortraitMin} {
    display: block;
  }
`;

export const ModalSpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;

  @media ${({ theme }) => theme.media.tabletPortraitMin} {
    display: none;
  }
`;

export const ModalTitle = styled(TitleMedium500)`
  margin: 0 1rem 1rem 0;
`;

export const ModalContentWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media ${({ theme }) => theme.media.tabletPortraitMin} {
    height: 4rem;
    overflow: hidden;
  }
`;

export const VoucherButton = styled(Button)`
  background-color: transparent;
  color: #426da9;
  border-width: 1px;
  border-color: #426da9;
  justify-content: center;
  font-weight: 400;
  font-size: 1rem;
  :hover {
    background-color: #406eb3;
    color: white;
  }
`;

export const VoucherButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
`;

export const VoucherCopy = styled(BodyLarge400)`
  color: #575757;
  font-weight: 300;
`;

export const VoucherTextInput = styled(TextInput)`
  font-size: 1.5rem;
`;
