import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@nebula/modal';
import Spinner from '@nebula/spinner';
import {
  ModalCopy,
  ModalContentWrapper,
  ModalSpinner,
  ModalSpinnerLargeWrapper,
  ModalSpinnerWrapper,
} from './breachVoucherExtension.styles';

export const BillingSubsModal = ({ isShowing = false }) => (
  <Modal canSkipModal={false} isShowing={isShowing} header="We're checking your voucher" zIndex={6}>
    <ModalContentWrapper>
      <ModalCopy data-testid="billing-subs-modal">Please wait - this often takes less than 20 seconds.</ModalCopy>
      <ModalSpinnerWrapper>
        <ModalSpinner />
      </ModalSpinnerWrapper>
      <ModalSpinnerLargeWrapper>
        <Spinner />
      </ModalSpinnerLargeWrapper>
    </ModalContentWrapper>
  </Modal>
);

BillingSubsModal.propTypes = {
  isShowing: PropTypes.bool.isRequired,
};
