import errors from '../errors';

// A variation on PropTypes.oneof([]) that allows us to match case insensitive values
export default args => {
  const { props, propName, componentName, permittedValues } = args;
  const propValue = props[propName];
  const valid =
    !!propValue &&
    typeof propValue === 'string' &&
    !!permittedValues &&
    new RegExp(permittedValues.filter(value => typeof value === 'string').join('|'), 'i').exec(propValue);
  if (!valid) {
    throw new Error(errors.oneOf(propValue, propName, componentName, permittedValues));
  }
};
