import React from 'react';
import PropTypes from 'prop-types';
import { Button, setAutomationElement } from '@experian-uk/corvetteuk-common-ui';
import InViewComponent from '../InView';
import offerProps from '../../propTypes/offer';
import { products } from '../../constants';
import { ModalUpsell } from '../../components/ModalUpsell';
import { DownloadIcon } from '../Icons/DownloadIcon';
import {
  CardBenefits,
  CardContainer,
  CardContent,
  PricePoint,
  ProductFactSheet,
} from '../ProductPanels/productPanel.styles';
import { HeaderNumber, OptionsCardHeader, OptionsCardHeaderContent } from '../ProductPanels/productOptionsCards.styles';
import useBreachUpsellHelpers from './useBreachUpsellHelpers';
import getOffersForUpsell from './getOffersForUpsell';
import useProductDetails from './useProductDetails';
import { BreachUpsellWrapper, Heading, ButtonContainer } from './BreachUpsell.styles';

const BreachUpsell = ({ offers, currentSubscription }) => {
  const { showModalUpsell, ceOffer, credExClick, breachUpsellButtonClick, toggleModal } =
    useBreachUpsellHelpers(offers);
  const offersForUpsell = getOffersForUpsell(offers, currentSubscription);
  const productDetails = useProductDetails(offers, currentSubscription);

  return (
    <React.Fragment>
      <Heading as="h2">Your options</Heading>
      <BreachUpsellWrapper {...setAutomationElement('breach-upsell')}>
        {offersForUpsell.map((upsellOffer, index) => {
          const { label, titleText, priceSummary, benefits, disclaimer, showButton, link, product } = productDetails(
            upsellOffer.family
          );
          return (
            <InViewComponent
              label={`subscription_panel_${upsellOffer.family === 'breach_plus' ? 'free' : upsellOffer.family}`}
            >
              <CardContainer {...setAutomationElement(`panel-${upsellOffer.family}`)} isViewEventCaptured>
                <OptionsCardHeader>
                  <HeaderNumber>{index + 1}</HeaderNumber>
                  <OptionsCardHeaderContent>
                    {titleText}
                    <PricePoint {...setAutomationElement(`${upsellOffer.family}-price-summary`)} color="#002556">
                      {priceSummary || <React.Fragment>&nbsp;</React.Fragment>}
                    </PricePoint>
                  </OptionsCardHeaderContent>
                </OptionsCardHeader>
                <CardContent>
                  <CardBenefits>
                    <h3 {...setAutomationElement('benefits-included-title')}>Benefits include:</h3>
                    {benefits}
                  </CardBenefits>
                </CardContent>
                {disclaimer}
                {showButton && (
                  <ButtonContainer>
                    <Button
                      type={upsellOffer.family === products.identity ? 'primary' : 'secondary'}
                      label={label}
                      isButton
                      onClick={() => breachUpsellButtonClick(upsellOffer.id, upsellOffer.family)}
                    />
                  </ButtonContainer>
                )}
                <ProductFactSheet>
                  <DownloadIcon />
                  <a {...setAutomationElement(`${upsellOffer.family}-panel-product-factsheet-link`)} href={link}>
                    {product} product fact sheet
                  </a>
                </ProductFactSheet>
              </CardContainer>
            </InViewComponent>
          );
        })}
        <ModalUpsell
          isShowing={showModalUpsell}
          toggleHide={toggleModal}
          credExClick={credExClick(ceOffer.id, ceOffer.family)}
        />
      </BreachUpsellWrapper>
    </React.Fragment>
  );
};

export default BreachUpsell;

BreachUpsell.propTypes = {
  breachUpsellButtonClick: PropTypes.func,
  credExClick: PropTypes.func,
  currentSubscription: offerProps.isRequired,
  modalUpsellShowing: PropTypes.bool,
  offers: PropTypes.arrayOf(
    PropTypes.shape({
      family: PropTypes.string.isRequired,
    })
  ).isRequired,
  toggleModal: PropTypes.func,
};

BreachUpsell.defaultProps = {
  breachUpsellButtonClick: null,
  credExClick: null,
  modalUpsellShowing: null,
  toggleModal: null,
};
