import { subscriptionActions } from '../../../constants';

export default state => {
  if (!state.subscriptions.current) {
    return undefined;
  }
  return !!(
    state.subscriptions.current.defermentInfo &&
    state.subscriptions.current.defermentInfo.type === subscriptionActions.cancellation
  );
};
