import { errorMessages } from '../../constants';

export const CONFIRM_SELECT = 'CONFIRM_SELECT';

export default input => async dispatch => {
  const { cancel, complimentary, family, price, freeTrial, oldProduct } = input;
  if (!family || (!complimentary && !price) || typeof freeTrial !== 'boolean') {
    return dispatch({
      type: CONFIRM_SELECT,
      error: true,
      payload: new Error(errorMessages.default),
    });
  }

  return dispatch({
    type: CONFIRM_SELECT,
    payload: {
      cancel,
      family,
      price,
      freeTrial,
      oldProduct,
    },
    error: false,
  });
};
