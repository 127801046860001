import styled from 'styled-components';
import { rem } from 'polished';

export const Title = styled.h2`
  color: #1d4f91;
  margin-bottom: ${rem(24)};

  font-size: ${rem(20)};
  font-weight: 700;
  line-height: ${rem(28)};

  @media ${({ theme }) => theme.media.tabletPortraitMin} {
    font-size: ${rem(24)};
    font-weight: 300;
    line-height: ${rem(30)};
  }
`;

export const StepperContainer = styled.div`
  margin-top: ${rem(32)};
  ol {
    margin: 0;
    padding: 0;
  }
`;
