import React, { Fragment } from 'react';
import moment from 'moment';
import { setAutomationElement } from '@experian-uk/corvetteuk-common-ui';
import { Bolder, BolderLink } from './index.styles';
import { fixedUrls } from '../../constants';

const sensitiveData = {
  id: 'sensitiveData',
  content:
    'Due to the sensitive nature of the information and data within this site, please close your browser whenever you wish to leave Experian.',
};
const creditBroker = {
  id: 'creditBroker',
  content: (
    <Fragment>
      &#42; Experian acts as a credit broker and not a lender in the provision of its credit cards and personal, car
      finance and guarantor loans comparison services, meaning it will show you products offered by lenders and other
      brokers
    </Fragment>
  ),
};
const independentExperian = {
  id: 'independentExperian',
  content: (
    <Fragment>
      Experian acts independently and although your free Experian account shows products for a range of lenders and
      other brokers it does not cover the whole of the market, meaning other products may be available to you. Experian
      comparison services are provided free however we will receive commission payments from lenders or brokers we
      introduce you to. Information about the commission we receive from brokers for mortgages and secured loans can be
      found in our{' '}
      <BolderLink href={fixedUrls.helpSection} inline {...setAutomationElement('linkToHelpSection')}>
        help section
      </BolderLink>
      .
    </Fragment>
  ),
};
const carFinance = {
  id: 'carFinance',
  content:
    'Car finance: Your car may be required as security and you could lose your vehicle if you don’t keep up with repayments.',
};
const eligibilityRatings = {
  id: 'eligibilityRatings',
  content: 'Eligibility ratings available for credit cards and personal loans only.',
};
const experianConsumerServices = {
  id: 'experianConsumerServices',
  content:
    'All free and paid for Experian consumer services are provided by Experian Ltd (registered number 653331). Experian Ltd is authorised and regulated by the Financial Conduct Authority (firm reference number 738097). Experian Ltd is registered in England and Wales with registered office at The Sir John Peace Building, Experian Way, NG2 Business Park, Nottingham, NG80 1ZZ. The web monitoring feature and its alerts within Identity Plus is not Financial Conduct Authority regulated activity.',
};
const runPath = {
  id: 'runPath',
  content:
    'Runpath Group Limited, a registered company in England & Wales. Company Reg No: 06824947. Registered address: Ground & Mezzanine Floor, White Collar Factory, 1 Old Street Yard, London, EC1Y 8AF.',
};
const copyright = {
  id: 'copyright',
  content: <Bolder>Copyright &copy; {moment().year()}, Experian Ltd. All rights reserved.</Bolder>,
};

const breachCopy = [
  sensitiveData,
  creditBroker,
  independentExperian,
  carFinance,
  eligibilityRatings,
  experianConsumerServices,
  runPath,
  copyright,
];

export default {
  breach: breachCopy,
  breach_plus: breachCopy,
  switch: {
    breach_plus: breachCopy,
  },
};
