import styled from 'styled-components';
import { Button } from '@experian-uk/corvetteuk-common-ui';

export const Container = styled.div`
  background: ${props => props.theme.colors.baseLightGrey};
  border: 1px solid ${props => props.theme.colors.baseMediumGrey};
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 1.5rem;
  padding: 0 1.125rem 2rem;
  width: 100%;

  ${({ theme, isError }) =>
    isError &&
    `
    border-color: ${theme.colors.alertRed};
  `}

  ${({ theme: { media } }) =>
    media(
      '10',
      `
    margin-top: auto;
  `
    )}

  ${({ theme: { media } }) =>
    media(
      '15',
      `
    max-width: 454px;
    padding: 0 2.25rem 2rem;
    width: auto;
  `
    )}
`;

export const ActionButton = styled(Button)`
  display: flex;
  margin: 0 auto;
  background: transparent;
`;

export const CardImg = styled.img`
  height: 2rem;
  width: 3.25rem;
  padding: 0;
  margin: 0;
`;
