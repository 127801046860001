import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { ContentContainer, CVVInputContainer, FieldContainer, StyledCVV } from './CVVInput.styles';
import { validateField, normalizeField } from '../../helpers/forms';
import { textInput } from '../../helpers/formStyledComponent';

export default class CVVInput extends React.Component {
  static propTypes = {
    automation: PropTypes.string,
    inputName: PropTypes.string,
    setCvv: PropTypes.func,
  };

  static defaultProps = {
    automation: 'newCardCvv',
    inputName: 'CVV',
    setCvv: () => {},
  };

  setCvv = e => {
    this.props.setCvv(e.target.value);
  };

  render() {
    const { automation, inputName } = this.props;

    const inputProps = {
      maxLength: 3,
    };

    const errorMessageProps = {
      required: ['your CVV number'],
      format: ['CVV number'],
    };

    return (
      <CVVInputContainer>
        <FieldContainer>
          <Field
            automation={automation}
            component={textInput}
            errorMessageProps={errorMessageProps}
            inputProps={inputProps}
            key={inputName}
            name={inputName}
            onChange={this.setCvv}
            normalize={normalizeField.numeric}
            validate={validateField.cvvNumber}
          />
        </FieldContainer>
        <ContentContainer>
          <StyledCVV />
          <span>The last 3 digits on the signature strip on the back of your card</span>
        </ContentContainer>
      </CVVInputContainer>
    );
  }
}
