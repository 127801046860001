const abTests = {
  PRODMOVE_AA_TEST_HP: {
    apiCode: 'a-XrSc3Pgi8P8L',
    name: 'UKFOX-251 Product Movement Home Targeting AA',
  },
  PRODMOVE_AA_TEST_SWITCH: {
    apiCode: 'a-HoTuA4SuRF',
    name: 'UKFOX-251 Product Movement Switch Targeting AA',
  },
  REPORT_INTO_FREE_DAY_1_PLUS: {
    apiCode: 'a-6ZWIfECgQHhO',
    name: 'UKFDM-183 Report into Free - Day 1+',
  },
  REPORT_INTO_FREE_DAY_0: {
    apiCode: 'a-z46Zh0DgAHqN',
    name: 'UKFDM-183 Report into Free - Day 0',
  },
};

export default abTests;
