import React from 'react';
import { uniqueId } from '@epl/helpers';
import styled from 'styled-components';
import { rem } from 'polished';
import PropTypes from 'prop-types';
import { breakpoints } from '../ModalUpsell.styles';

const CECreditFutureSVG = styled.svg`
  margin: ${rem(20)} auto ${rem(-14)};
  display: block;
  width: ${props => (props.isInactive ? '100%' : '80%')};
  max-width: ${props => (props.isInactive ? `${rem(110)}` : `${rem(112)}`)};
  height: auto;

  @media only screen and (min-width: ${breakpoints.mobile}) {
    width: ${props => (props.isInactive ? '100%' : `${rem(80)}`)};
  }
`;

const CECreditFuture = ({ className, isInactive = false }) => {
  const uniqueIdSuffix = uniqueId('feature');

  return isInactive ? (
    <CECreditFutureSVG
      className={className}
      viewBox="0 0 124 87"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      isInactive={isInactive}
    >
      <title>Credit future icon</title>
      <g opacity=".6">
        <g filter="url(#a)">
          <path
            fill="#B3BECC"
            fillRule="evenodd"
            d="M91.535 18.692a4 4 0 0 0-4-4H76.944a4 4 0 0 0-4 4v50.385a4 4 0 0 0 4 4h10.591a4 4 0 0 0 4-4V18.692Z"
            clipRule="evenodd"
          />
        </g>
        <g filter="url(#b)">
          <path
            fill="#B3BECC"
            fillRule="evenodd"
            d="M45.831 40.692a4 4 0 0 0-4-4H31.24a4 4 0 0 0-4 4v28.385a4 4 0 0 0 4 4h10.592a4 4 0 0 0 4-4V40.692Z"
            clipRule="evenodd"
          />
        </g>
        <g filter="url(#c)">
          <rect width="18.591" height="66" fill="#B3BECC" rx="4" transform="matrix(-1 0 0 1 114 7.077)" />
        </g>
        <g filter="url(#d)">
          <path
            fill="#B3BECC"
            fillRule="evenodd"
            d="M22.592 6a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v63.077a4 4 0 0 0 4 4h10.592a4 4 0 0 0 4-4V6Z"
            clipRule="evenodd"
          />
        </g>
        <g filter="url(#e)">
          <path
            fill="#B3BECC"
            fillRule="evenodd"
            d="M69.07 30.538a4 4 0 0 0-4-4H54.48a4 4 0 0 0-4 4v38.539a4 4 0 0 0 4 4H65.07a4 4 0 0 0 4-4V30.539Z"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <filter
          id="a"
          width="26.592"
          height="66.385"
          x="68.943"
          y="12.692"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.145098 0 0 0 0 0.337255 0 0 0 0.16 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1480_78555" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_1480_78555" result="shape" />
        </filter>
        <filter
          id="b"
          width="26.592"
          height="44.385"
          x="23.239"
          y="34.692"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.145098 0 0 0 0 0.337255 0 0 0 0.16 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1480_78555" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_1480_78555" result="shape" />
        </filter>
        <filter
          id="c"
          width="26.592"
          height="74"
          x="91.408"
          y="5.077"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.145098 0 0 0 0 0.337255 0 0 0 0.16 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1480_78555" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_1480_78555" result="shape" />
        </filter>
        <filter
          id="d"
          width="26.592"
          height="79.077"
          x="0"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.145098 0 0 0 0 0.337255 0 0 0 0.16 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1480_78555" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_1480_78555" result="shape" />
        </filter>
        <filter
          id="e"
          width="26.592"
          height="54.538"
          x="46.478"
          y="24.538"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.145098 0 0 0 0 0.337255 0 0 0 0.16 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1480_78555" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_1480_78555" result="shape" />
        </filter>
      </defs>
    </CECreditFutureSVG>
  ) : (
    <CECreditFutureSVG
      className={className}
      viewBox="0 0 84 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      isInactive={isInactive}
    >
      <title>Credit future icon</title>
      <g filter={`url(#a-${uniqueIdSuffix})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M66.296 13.478a4 4 0 0 0-4-4h-5.883a4 4 0 0 0-4 4v35.599a4 4 0 0 0 4 4h5.883a4 4 0 0 0 4-4V13.478Z"
          fill="#8CC33F"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.166 29.907a4 4 0 0 0-4-4h-5.883a4 4 0 0 0-4 4v19.17a4 4 0 0 0 4 4h5.883a4 4 0 0 0 4-4v-19.17Z"
        fill="#F79836"
      />
      <rect width="13.883" height="49.286" rx="4" transform="matrix(-1 0 0 1 83.072 3.791)" fill="#049D4A" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.812 4a4 4 0 0 0-4-4H4.929a4 4 0 0 0-4 4v45.077a4 4 0 0 0 4 4h5.883a4 4 0 0 0 4-4V4Z"
        fill="#049D4A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.52 22.324a4 4 0 0 0-4-4h-5.883a4 4 0 0 0-4 4v26.753a4 4 0 0 0 4 4h5.883a4 4 0 0 0 4-4V22.324Z"
        fill="#FBDD00"
      />
      <defs>
        <filter
          id={`a-${uniqueIdSuffix}`}
          x="42.413"
          y="9.478"
          width="33.883"
          height="63.599"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="10" />
          <feGaussianBlur stdDeviation="5" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1329_76780" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_1329_76780" result="shape" />
        </filter>
      </defs>
    </CECreditFutureSVG>
  );
};

CECreditFuture.defaultProps = {
  className: undefined,
  isInactive: undefined,
};

CECreditFuture.propTypes = {
  className: PropTypes.string,
  isInactive: PropTypes.bool,
};

export default CECreditFuture;
