import React from 'react';
import styled from 'styled-components';
import Tick from './blueTickIcon.svg';

const StyledImage = styled.img`
  width: 1.56rem;
  height: 1.5rem;
  align-self: self-start;
`;

export default props => <StyledImage {...props} src={Tick} alt="" />;
