import moment from 'moment';

const CURRENCY_PRECISION = 2;
const LOCALE = 'en-GB';
const DEFAULT_CURRENCY_CODE = 'GBP';

export const currency = (value, includeDecimal = true, currencyCode = DEFAULT_CURRENCY_CODE) => {
  const decimalDigits = (includeDecimal && CURRENCY_PRECISION) || 0;

  if (value !== null) {
    return value.toLocaleString(LOCALE, {
      style: 'currency',
      currency: currencyCode,
      maximumFractionDigits: decimalDigits,
      minimumFractionDigits: decimalDigits,
    });
  }

  return null;
};

export const formatDate = date => moment(date).format('Do MMMM, YYYY');

export const dateInTheFuture = (days = 0) => moment(new Date()).add(days, 'days');
