import styled from 'styled-components';
import { lighten } from 'polished';

import { Link } from '@experian-uk/corvetteuk-common-ui';

export const FooterContent = styled.footer`
  align-items: center;
  background-color: ${props => lighten(0.45, props.theme.colors.experianLightBlue)};
  display: flex;
  padding: 1.25rem 1rem;
  margin-top: auto;
  text-align: ${({ fewerPanels }) => (fewerPanels ? 'center' : 'left')};
  width: 100%;

  ${({ fewerPanels }) =>
    fewerPanels &&
    `
    justify-content: center;
  `}

  img {
    width: 2.8125rem;
    height: 2.8125rem;
    vertical-align: middle;
    margin-right: ${props => props.theme.base.expSpacingXSmall};
  }
`;

export const DownloadLink = styled(Link.Anchor)`
  font-size: 1rem;
  vertical-align: middle;
`;
