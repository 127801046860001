import pickBy from 'lodash/pickBy';
import memoize from 'lodash/memoize';
import isServer from '../../../helpers/isServer';

// Override keys for automation testing
const cypressOverrides = {
  REACT_APP_AUTH_URL: '/acquisition',
};

const selectEnv = (env, isCypress) =>
  pickBy(env, (val, key) => key.startsWith('REACT_APP_') && (!isCypress || !cypressOverrides[key]));

export default memoize(() => {
  const isJestRunner = !!process.env.JEST_WORKER_ID;

  if (isServer() || isJestRunner) {
    // If this is a server side render or we're running the unit tests
    return selectEnv(process.env);
  }

  // automation
  if (window.Cypress?.isIntegrationTest) {
    const env = selectEnv(process.env, true);
    return {
      ...env,
      ...cypressOverrides,
    };
  }

  return window._ENV_ || selectEnv(process.env) || {}; // eslint-disable-line no-underscore-dangle
});
