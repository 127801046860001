import Button from '@nebula/button';
import { BodyLarge400, TitleMedium500, TitleMedium400, TitleXSmall500 } from '@nebula/global-styles';
import styled from 'styled-components';

export const MobilePayContainer = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: inherit;
  gap: 24px;

  ${BodyLarge400} {
    margin: 0;
  }
  ${TitleXSmall500} {
    margin: 0;
  }
`;
export const CardAndDDContainer = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: inherit;

  ${TitleXSmall500} {
    margin: 0;
  }
  ${BodyLarge400} {
    margin: 0;
  }
`;
export const CardLabel = styled(TitleMedium500)`
  margin: 12px 0;
`;
export const CardValue = styled(TitleMedium400)`
  margin: 12px 0;
  text-align: right;
`;

export const CardImg = styled.img`
  height: 2rem;
  width: 3.25rem;
  padding: 0;
  margin: 12px 0;
  justify-self: end;
`;

export const ChangePaymentMethodButton = styled(Button)`
  margin-top: 12px;

  span {
    font-size: 16px;
  }
`;
