import React from 'react';
import PropTypes from 'prop-types';
import { ModalContentWrapper, FeatureCard } from './ModalUpsell.styles';
import { firstTabFeatures, secondTabFeatures, firstTabFeaturesForTest, secondTabFeaturesForTest } from './data';

const getFeatureList = (tabNumber, showTestCards) => {
  if (tabNumber === 1) {
    return !showTestCards ? firstTabFeatures : firstTabFeaturesForTest;
  }
  if (tabNumber === 2) {
    return !showTestCards ? secondTabFeatures : secondTabFeaturesForTest;
  }
  return null;
};

const ContentWrapper = ({ tabNumber, showTestCards = false }) => {
  const featureList = getFeatureList(tabNumber, showTestCards);
  return (
    <ModalContentWrapper>
      {featureList &&
        featureList.map(card => {
          const RenderFeatureComponent = card?.content;
          return (
            <FeatureCard key={card?.title}>
              <h6>{card?.title}</h6>
              <RenderFeatureComponent isInactive={card?.isInactive} />
            </FeatureCard>
          );
        })}
    </ModalContentWrapper>
  );
};

ContentWrapper.defaultProps = {
  showTestCards: false,
};

ContentWrapper.propTypes = {
  tabNumber: PropTypes.number.isRequired,
  showTestCards: PropTypes.bool,
};

export default ContentWrapper;
