import { SETUP_RETRY } from '../actions/actionRetry';

const defaultState = {
  path: null,
};

export default (state = defaultState, action = {}) => {
  if (action.error) {
    return state;
  }

  switch (action.type) {
    case SETUP_RETRY:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
