import dayjs from 'dayjs';
import { subscriptionActions } from '../../constants';

export default (subscriptionCurrent, billing) => {
  const breachSubscriptionExpiryDate = billing?.endDate;

  const isLessThan30DaysToExpiry = () => {
    if (!breachSubscriptionExpiryDate) return false;

    if (dayjs(breachSubscriptionExpiryDate).isBefore(dayjs())) return false;

    return dayjs(breachSubscriptionExpiryDate).diff(dayjs().startOf('day'), 'day') <= 30;
  };

  const daysUntilExpiry = () => {
    const today = dayjs().startOf('day');
    const expiryDate = dayjs(breachSubscriptionExpiryDate);
    if (!expiryDate.isValid()) return null;
    return breachSubscriptionExpiryDate && expiryDate.isAfter(today) ? expiryDate.diff(today, 'day') : null;
  };

  // check for ACTIVATION on subscription type as Breach+ customers will have a deferment date and type of cancellation because they would move to a 'Free' subscription
  // after the Breach+ subscription has finished.
  const hasDeferredActivation = !!(
    subscriptionCurrent?.breachDefermentInfo?.defermentDt &&
    subscriptionCurrent?.breachDefermentInfo?.type === subscriptionActions.activation
  );

  const productMovingTo = subscriptionCurrent?.breachDefermentInfo?.family || null;

  return {
    isLessThan30DaysToExpiry: isLessThan30DaysToExpiry(),
    hasDeferredActivation,
    productMovingTo,
    daysUntilExpiry: daysUntilExpiry(),
  };
};
