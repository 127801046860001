import React from 'react';
import PropTypes from 'prop-types';
import ChangePaymentRadioButtons from '../paymentOptions/changePaymentRadioButtons';

const ChangePaymentMethod = ({ validCard, mobilePay, setPaymentMethodOption }) => (
  <ChangePaymentRadioButtons
    setPaymentMethodOption={setPaymentMethodOption}
    cardDefault={validCard}
    applePayDefault={!!mobilePay}
  />
);

ChangePaymentMethod.propTypes = {
  validCard: PropTypes.bool.isRequired,
  mobilePay: PropTypes.shape({
    paymentType: PropTypes.string,
  }),
  setPaymentMethodOption: PropTypes.func.isRequired,
};

ChangePaymentMethod.defaultProps = {
  mobilePay: null,
};

export default ChangePaymentMethod;
