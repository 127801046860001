import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(advancedFormat);

const getSubscriptionDetails = (
  nextBillingDate,
  currentPrice,
  deferredProductBillDate,
  hasDeferredBreachProduct,
  deferredProductPrice,
  isBreachUser,
  billing,
  basicToBreach
) => {
  const formatLongDate = date => dayjs(date).format('DD MMMM YYYY');
  const formatDayOfMonth = date => dayjs(date).format('Do');
  const formatMonth = date => dayjs(date).format('MMMM');

  let nextPayment = '';
  let billingDay = '';

  nextPayment = dayjs(nextBillingDate).isValid() ? `${currentPrice} on ${formatLongDate(nextBillingDate)}` : null;
  billingDay = dayjs(nextBillingDate).isValid() ? `${formatDayOfMonth(nextBillingDate)} of each month` : null;

  let subscriptionDetails;

  if (hasDeferredBreachProduct) {
    subscriptionDetails = [
      {
        content: deferredProductPrice ? `£${deferredProductPrice}` : null,
        id: deferredProductPrice ? 'subPrice' : null,
        label: deferredProductPrice ? 'Price' : null,
      },
      {
        content: deferredProductBillDate ? `${formatDayOfMonth(deferredProductBillDate)} of each month` : null,
        id: deferredProductBillDate ? 'subEndDate' : null,
        label: deferredProductBillDate ? 'Your billing date' : null,
      },
      {
        content: deferredProductBillDate
          ? `£${deferredProductPrice} on ${formatDayOfMonth(deferredProductBillDate)} of ${formatMonth(
              deferredProductBillDate
            )}`
          : null,
        id: deferredProductBillDate ? 'subNextPayment' : null,
        label: deferredProductBillDate ? 'Next payment' : null,
      },
    ];
  } else if (!hasDeferredBreachProduct && isBreachUser) {
    subscriptionDetails = [
      {
        content: '£0.00',
        id: currentPrice ? 'subPrice' : null,
        label: currentPrice ? 'Price' : null,
      },
      {
        content: billing.endDate ? `${formatLongDate(billing.endDate)}` : null,
        id: billing.endDate ? 'subEndDate' : null,
        label: billing.endDate ? 'Subscription expires' : null,
      },
    ];
  } else if (!isBreachUser && !basicToBreach) {
    subscriptionDetails = [
      {
        content: currentPrice,
        id: currentPrice ? 'subPrice' : null,
        label: currentPrice ? 'Price' : null,
      },
      {
        content: billingDay,
        id: billingDay ? 'subBillingDate' : null,
        label: billingDay ? 'Your billing date' : null,
      },
      {
        content: nextPayment,
        id: nextPayment ? 'subNextPayment' : null,
        label: nextPayment ? 'Next payment' : null,
      },
    ];
  } else {
    subscriptionDetails = [
      {
        content: '£0.00',
        id: currentPrice ? 'subPrice' : null,
        label: currentPrice ? 'Price' : null,
      },
      {
        content: nextBillingDate ? `${formatLongDate(nextBillingDate)}` : null,
        id: nextBillingDate ? 'subEndDate' : null,
        label: nextBillingDate ? 'Subscription expires' : null,
      },
    ];
  }

  return subscriptionDetails;
};

export default getSubscriptionDetails;
