import styled from 'styled-components';

export const Container = styled.div`
  ${({ theme }) =>
    theme.media(
      '20',
      `
    display: flex;
    justify-content: space-between;
    vertical-align: top;
    align-items: start;
  `
    )};

  margin-bottom: ${props => props.theme.base.expSpacingSmall};
`;
