import React from 'react';
import styled from 'styled-components';
import { fixedUrls } from '../../../constants';

const TermsOuterWrapper = styled.div`
  width: fit-content;
  margin: 0 auto;
`;

const TermsInnerWrapper = styled.p`
  text-align: left;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #63666a;
  max-width: 354px;
  margin: 24px 16px;
  text-align: left;

  a {
    text-decoration: none;
    color: #0058e6;
  }

  ${({ theme }) =>
    theme.media(
      '10',
      `
    font-size: 12px;
    text-align: center;
    max-width: 654px;
    margin: 32px 53px;
  `
    )};

  ${({ theme }) =>
    theme.media(
      '20',
      `
    font-size: 14px;
    margin: 32px 48px;
  `
    )};
`;

const Break = styled.span`
  display: block;
  margin-top: 14px;

  ${({ theme }) =>
    theme.media(
      '10',
      `
    display: inline;
    margin: 0;
  `
    )};
`;

const Terms = () => (
  <TermsOuterWrapper>
    <TermsInnerWrapper>
      It&apos;s important you understand the terms of our contract with you, which are in the{' '}
      <a href={`${fixedUrls.experian}/consumer/terms.html`} target="_blank" rel="noopener noreferrer">
        Experian Terms &amp; Conditions
      </a>{' '}
      and{' '}
      <a href={`${fixedUrls.experian}/consumer/privacy.html`} target="_blank" rel="noopener noreferrer">
        Privacy Policy
      </a>{' '}
      and by starting your free trial, you&apos;ll be accepting these. You should read these documents and contact us if
      you have any questions.{' '}
      <Break>
        You&apos;ll also continue to get free access to our comparison services. Discover more about your{' '}
        <a
          href={`${fixedUrls.experian}/consumer/product-factsheets/CreditExpertwithAccount.pdf`}
          target="_blank"
          rel="noopener noreferrer"
        >
          features and rights.
        </a>
      </Break>
    </TermsInnerWrapper>
  </TermsOuterWrapper>
);

export default Terms;
