import styled from 'styled-components';

export const FormWrapper = styled.div`
  margin: 0 16px;
  max-width: 354px;
  margin: 0 auto;

  hr {
    border: 1px solid #a1a3a6;
    max-width: 300px;
    margin: 24px auto;
  }

  ${({ theme }) =>
    theme.media(
      '10',
      `
    hr {
      margin: 32px auto;
    }
  `
    )};
`;

export const FormLabel = styled.label`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #575757;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
`;

export const FormFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 32px;
`;

export const DoubleField = styled.div`
  display: flex;

  ${FormFieldWrapper} {
    flex: 1;

    &:last-of-type {
      margin-left: 26px;
    }
  }
`;

export const CardSelectorRadio = styled.div`
  color: #63666a;
  font-size: 18px;

  input {
    display: none;

    &:checked + label {
      &:before {
        box-shadow: 0px 0px 0px 3px white inset;
        background: ${({ theme }) => theme.colors.experianLightBlue};
      }
    }
  }

  label {
    display: flex;
    justify-content: center;
    align-items: center;

    &:before {
      border: 1px solid ${({ theme }) => theme.colors.baseMediumGrey};
      border-radius: 50%;
      content: '';
      display: inline-block;
      height: 18px;
      margin: 0.15rem 5px 0 0;
      min-height: 18px;
      min-width: 18px;
      transition: box-shadow 0.2s ease-in-out;
      width: 1.125rem;
    }

    .dots {
      height: 9px;
      margin-right: 8px;
      margin-left: 10px;
    }
  }
`;

export const StyledHideForm = styled.div`
  display: ${({ visible }) => (visible ? 'unset' : 'none')};
`;
