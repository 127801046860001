import styled from 'styled-components';
import { Typography } from '@experian-uk/corvetteuk-common-ui';

export const AlertContainer = styled.div`
  background: ${({ theme }) => theme.color.ui.red50};
  border: 1px solid ${({ theme }) => theme.color.ui.red200};
  color: ${({ theme }) => theme.color.ui.blueGrey900};
  font-size: 1.25rem;
  line-height: 1.625rem;
  margin-bottom: 1.625rem;
  padding: 32px 24px;
  border-radius: 12px;
  text-align: center;
  width: 100%;

  a,
  small {
    font-weight: ${({ theme }) => theme.font.weightRegular};
  }

  a {
    margin: 1.25rem 0 0.5rem;
  }
`;

export const AlertHeading = styled.h2`
  color: ${({ critical, theme }) => (critical ? theme.color.ui.red600 : theme.color.ui.blueGrey800)};
  font-weight: ${({ theme }) => theme.font.weightMedium};
  font-size: 1.25rem;
  margin: 0;
`;

export const AlertMessage = styled.p`
  color: ${props => props.theme.color.ui.blueGrey800};
  margin: 4px 0 0;
  display: block;
  font-size: 1rem;
`;

export const InlineAlert = styled(Typography.Copy.SmallText)`
  color: ${props => props.theme.colors.alertError};
  display: block;
  font-weight: 500;
  padding: 0.5rem 0;
`;

export const ParagraphAlert = styled(InlineAlert)`
  display: block;
  padding-bottom: 0;
`;
