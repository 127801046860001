import fetch from '../fetch';
import { products, errorMessages } from '../../constants';

export const FETCH_SCORE_DATA = 'FETCH_SCORE_DATA';
export const SCORE_DATA_FETCHED = 'SCORE_DATA_FETCHED';

export default () => async (dispatch, getState) => {
  const state = getState();

  // Don't fetch scores if breach customer
  if (state.subscriptions?.current?.family === products.breach) {
    return;
  }
  dispatch({ type: FETCH_SCORE_DATA });

  const fetched = {
    type: SCORE_DATA_FETCHED,
  };

  try {
    const scoreData = await dispatch(fetch('/scores'));
    fetched.payload = scoreData;
  } catch (error) {
    const errorMessage = {
      heading: errorMessages.someDataMissing,
      messages: [errorMessages.technicalIssues],
    };

    fetched.error = true;
    fetched.payload = new Error(JSON.stringify(errorMessage));
  }

  dispatch(fetched);
};
