import React from 'react';
import nebulaTheme from '@nebula/theme';
import { setAutomationElement } from '@experian-uk/corvetteuk-common-ui';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { InfoIcon, TickCircleIcon } from '@nebula/icons/ui';
import { BreachAlert, AlertText, AlertHeading, AlertContent } from './WidescreenInformationAlert.styles';

const WidescreenInformationAlert = ({ heading, text, type }) => (
  <ThemeProvider theme={nebulaTheme}>
    <BreachAlert {...setAutomationElement('wide-alert')} messageType={type}>
      <div>
        {type === 'info' && (
          <div {...setAutomationElement('wide-alert-info-icon')}>
            <InfoIcon iconColor="#6270F3" iconSize="medium" />
          </div>
        )}
        {type === 'success' && (
          <div {...setAutomationElement('wide-alert-tick-icon')}>
            <TickCircleIcon iconColor="#189057" iconSize="medium" />
          </div>
        )}
      </div>
      <AlertContent>
        {heading && <AlertHeading {...setAutomationElement('wide-alert-heading')}>{heading}</AlertHeading>}
        <AlertText {...setAutomationElement('wide-alert-text')}>{text}</AlertText>
      </AlertContent>
    </BreachAlert>
  </ThemeProvider>
);

WidescreenInformationAlert.propTypes = {
  heading: PropTypes.string,
  text: PropTypes.string.isRequired,
  type: PropTypes.string,
};

WidescreenInformationAlert.defaultProps = {
  heading: '',
  type: 'info',
};

export default WidescreenInformationAlert;
