export default {
  authorized: {
    defaultValue: false,
    mustBeFresh: false,
  },
  card: {
    defaultValue: {},
    mustBeFresh: false,
  },
  cardSaved: {
    defaultValue: false,
    mustBeFresh: false,
  },
  challengeUrl: {
    defaultValue: null,
    mustBeFresh: false,
  },
  fetchFailed: {
    defaultValue: false,
    mustBeFresh: false,
  },
  fetchingProRataPrice: false,
  hasSavedCard: {
    defaultValue: true,
    mustBeFresh: false,
  },
  loading: {
    defaultValue: false,
    mustBeFresh: false,
  },
  nextBillingDate: {
    defaultValue: null,
    mustBeFresh: true,
  },
  proRataPrice: {
    defaultValue: null,
    mustBeFresh: true,
  },
  price: {
    defaultValue: null,
    mustBeFresh: true,
  },
  lastRefreshed: {
    defaultValue: null,
    mustBeFresh: false,
  },
  error: {
    defaultValue: false,
    mustBeFresh: false,
  },
};
