import errors from '../errors';

// A variation on PropTypes.oneof([]) that allows us to pass null, undefined, or an empty string
// in the event the prop is optional
export default args => {
  const { props, propName, componentName, permittedValues } = args;
  const propValue = props[propName];
  if (!!propValue && !permittedValues.includes(propValue)) {
    throw new Error(errors.oneOf(propValue, propName, componentName, permittedValues));
  }
};
