import React from 'react';
import styled from 'styled-components';
import { Field } from 'redux-form';

import { cardUtility } from '../../../../../../helpers/forms';
import FormInputValidationWrapper from '../FormInputValidationWrapper';

const StyledFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledFieldLabel = styled.div`
  color: #63666a;
  font-weight: 300;
  margin-bottom: 10px;
`;

const fieldAttributes = {
  name: 'nameOnCreditCard',
  component: FormInputValidationWrapper,
  inputProps: { maxLength: 26 },
  validate: cardUtility.verifyCardName,
  normalize: cardUtility.formatName,
  errorMessageProps: {
    required: 'Please enter the name on your card',
  },
};

const NameField = () => (
  <StyledFieldContainer>
    <StyledFieldLabel>Name as shown on your card</StyledFieldLabel>
    <Field
      name={fieldAttributes.name}
      dataAutomationTestElement={fieldAttributes.name}
      component={fieldAttributes.component}
      inputProps={fieldAttributes.inputProps}
      validate={fieldAttributes.validate}
      normalize={fieldAttributes.normalize}
      errorMessageProps={fieldAttributes.errorMessageProps}
    />
  </StyledFieldContainer>
);

export default NameField;
