import styled from 'styled-components';
import { rem } from 'polished';

const Button = styled.button`
  width: 100%;
  height: ${rem(50)};
  max-width: ${rem(184)};
  border: none;
  border-radius: ${rem(4)};
  text-align: center;
  font-size: ${rem(16)};
  line-height: 1.5;
  transition: ease all 300ms;
  outline: none;
  &:hover,
  &:focus {
    text-decoration: none;
    cursor: pointer;
  }
`;

const CTA = styled.a`
  height: ${rem(50)};
  width: fit-content;
  padding: 1rem;
  display: block;
  border: none;
  border-radius: ${rem(4)};
  font-size: ${rem(16)};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: ease all 300ms;
  outline: none;
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: none;
    color: white;
    cursor: pointer;
  }
`;

const PrimaryButton = styled(Button)`
  background-color: #e63888;
  color: white;
  &:hover,
  &:focus {
    background-color: #ce3079;
    box-shadow: 0 0 0 ${rem(4)} rgba(230, 56, 136, 0.4);
  }
`;

const SecondaryButton = styled(Button)`
  background-color: #426da9;
  color: white;
  :hover,
  :focus {
    background-color: #015cae;
    box-shadow: 0 0 0 ${rem(4)} rgba(38, 71, 142, 0.4);
  }
`;

const TertiaryButton = styled(Button)`
  max-width: none;
  width: auto;
  padding: 0 ${rem(30)};
  background-color: #ecf0f6;
  color: #1d4f91;
  :hover,
  :focus {
    background-color: white;
    border: ${rem(1)} solid #0076dc;
    box-shadow: 0 0 0 ${rem(4)} rgba(0, 118, 220, 0.4);
  }
`;

const TextButton = styled(Button)`
  height: ${rem(32)};
  max-width: none;
  margin: ${props => (props.inlineBlock ? 0 : `${rem(8)} 0`)};
  padding: 0;
  background-color: transparent;
  color: #0076dc;
  text-align: ${props => props.alignLeft && 'left'};
  width: ${props => props.inlineBlock && 'initial'};
  text-decoration: none;

  :hover,
  :focus {
    color: #26478d;
    text-decoration: underline;
    cursor: pointer;
  }

  display: ${props => (props.inlineBlock ? 'inline' : 'block')};
`;

const PrimaryCTA = styled(CTA)`
  background-color: #e63888;
  color: white;
  &:hover,
  &:focus {
    background-color: #ce3079;
    box-shadow: 0 0 0 ${rem(4)} rgba(230, 56, 136, 0.4);
  }
`;

export { Button, PrimaryButton, SecondaryButton, TertiaryButton, TextButton, CTA, PrimaryCTA };
