import React from 'react';
import PropTypes from 'prop-types';
import offerProps from '../../../propTypes/offer';
import { isObjectEmpty } from '../../../helpers';
import BreachCurrentSubscriptionCard from './breachCurrentSubscriptionCard';
import BreachProductOptionCard from './breachProductOptionCard';
import { CardsContainer } from '../productPanel.styles';
import { CardContent } from '../cardContent';

const BreachProductPanel = ({ currentSubscription, offers, shouldShowBreachRetentionComponent: showSmallerCard }) => {
  const availableOffers = offers.filter(offer => offer.family !== currentSubscription.family);

  return (
    <CardsContainer fewerPanels>
      <BreachCurrentSubscriptionCard
        showSmallerCard={showSmallerCard}
        currentSubscription={{
          ...currentSubscription,
          ...CardContent.breach,
        }}
      />
      {!isObjectEmpty(offers) && (
        <BreachProductOptionCard
          showSmallerCard={showSmallerCard}
          offer={{
            ...availableOffers[0],
            ...CardContent.breach,
          }}
          currentSubscription={currentSubscription}
        />
      )}
    </CardsContainer>
  );
};

BreachProductPanel.propTypes = {
  currentSubscription: offerProps.isRequired,
  offers: PropTypes.arrayOf(offerProps).isRequired,
  shouldShowBreachRetentionComponent: PropTypes.bool,
};

BreachProductPanel.defaultProps = {
  shouldShowBreachRetentionComponent: false,
};

export default BreachProductPanel;
