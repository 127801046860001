import styled from 'styled-components';
import { Typography } from '@experian-uk/corvetteuk-common-ui';

const RecycleMessage = styled(Typography.Copy.SmallText)`
  color: ${props => props.theme.colors.alertRed};
  text-align: center;
  margin: -0.3125rem auto 0.9375rem auto;
  font-weight: normal;

  ${Typography.Copy.SmallText} {
    display: block;
  }
`;
RecycleMessage.displayName = 'RecycleMessage';
export { RecycleMessage };
