import { products } from '../../constants';

const getOffersForUpsell = (offers, currentSubscription) => {
  const offersForUpsell = offers
    .filter(
      o =>
        [products.identity, products.credit_expert, products.breachPlus].includes(o.family) &&
        o.family !== currentSubscription.family
    )
    .sort((a, b) => {
      const order = [products.identity, products.credit_expert, products.breachPlus];
      return order.indexOf(a.family) - order.indexOf(b.family);
    });

  // add products.breachPlus if it is not included
  const hasBreachPlus = offersForUpsell.some(o => o.family === products.breachPlus);
  if (!hasBreachPlus) {
    const breachPlusOffer = {
      family: products.breachPlus,
    };
    offersForUpsell.push(breachPlusOffer);
  }

  return offersForUpsell;
};

export default getOffersForUpsell;
