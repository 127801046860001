import styled from 'styled-components';
import { rem } from 'polished';
import { Link } from '@experian-uk/corvetteuk-common-ui';

export const Hr = styled.hr`
  border: ${rem(1)} solid ${props => props.theme.colors.baseWhite};
  width: 80%;
`;

export const MidlineBreak = styled.span`
  display: inline-block;

  > span {
    font-weight: 500;
  }
`;

export const HeaderContainer = styled.div`
  z-index: 2;
  color: white;
  position: relative;
  max-width: ${rem(338)};
  margin: 0 auto;
  padding: ${rem(24)} 0;

  h1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    font-size: ${rem(32)};
    font-weight: 300;
    line-height: ${rem(40)};
    width: fit-content;
    min-width: fit-content;
    padding: 0;
  }

  p {
    font-size: ${rem(18)};
    font-weight: 300;
    margin: 0;
    padding-top: ${rem(8)};
    line-height: ${rem(28)};
  }

  ${({ theme: { media } }) =>
    media(
      '10',
      `
    max-width: 90%;

    h1 {
      font-size: ${rem(40)};
      line-height: ${rem(50)};
    }
  `
    )}
`;

export const WrapperContainer = styled.div`
  width: 100%;
  background: linear-gradient(179.94deg, #0e2846 -5.32%, #1d4f91 18.2%, #e3e7eb 55.91%, #f0f1f1 82.04%);
  text-align: center;
  position: relative;

  ${({ theme }) =>
    theme.media(
      '10',
      `
        background: transparent;
    `
    )};
`;

export const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;

  ${({ theme }) =>
    theme.media(
      '10',
      `
        background: linear-gradient(191.56deg, #0E2846 15.75%, #1D4F91 51.64%);
        height: ${rem(316)};
    `
    )};

  ${({ theme }) =>
    theme.media(
      '20',
      `
        background: linear-gradient(191.56deg, #0E2846 15.75%, #1D4F91 51.64%);
        height: ${rem(338)};
    `
    )};
`;

export const MainContainer = styled.div`
  background: transparent;
  margin: 0 auto;
  z-index: 2;
  position: relative;
  overflow: auto;
  max-width: 95%;

  ${({ theme }) =>
    theme.media(
      '10',
      `
        width: fit-content;
        display: block;
        background: #F7F7F8;
        border-radius: ${rem(20)} ${rem(20)} 0 0;
        padding: ${rem(33)} ${rem(39)} 0;
    `
    )};
`;

export const InfoLink = styled(Link.Anchor)`
  color: #0058e6;
`;

export const GoCardlessFragment = styled.div`
  margin-top: ${rem(20)};
  margin-bottom: ${rem(23)};
`;
