import { FETCH_LOCK_STATUS, LOCK_STATUS_FETCHED } from '../actions/creditLock/getStatus';

export const defaultState = {
  fetching: false,
  lockStatus: null,
};

export default (state = defaultState, action = {}) => {
  if (action.error) {
    return state;
  }

  const creditLockStatuses = ['ON', 'ON_AND_UPDATING', 'ON_PINNING_PROBLEM'];
  const isLocked = creditLockStatuses.includes(action.payload);

  switch (action.type) {
    case FETCH_LOCK_STATUS:
      return {
        ...defaultState,
        fetching: true,
      };
    case LOCK_STATUS_FETCHED:
      return {
        ...defaultState,
        lockStatus: action.payload,
        isLocked,
      };
    default:
      return state;
  }
};
