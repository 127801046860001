import getEnv from '../Context/env/getEnv';
import fixedUrls from '../../constants/fixedUrls';
import isServer from '../../helpers/isServer';

export const generateLinkConfig = (env = getEnv()) => [
  {
    automationKey: 'footerPrivacy',
    href: `${fixedUrls.experian}/consumer/privacy.html`,
    title: 'Privacy Policy',
  },
  {
    automationKey: 'footerCookies',
    title: 'Cookies & Online Marketing',
    href: `${fixedUrls.experian}/consumer/cookie-policy.html`,
    onClick: e => {
      if (!isServer() && window.gateway) {
        e.preventDefault();
        window.gateway.openModal();
      }
    },
  },
  {
    automationKey: 'footerTermsConditions',
    href: `${fixedUrls.experian}/consumer/terms.html`,
    title: 'Terms & Conditions',
  },
  {
    automationKey: 'footerStatement',
    href: `${env.REACT_APP_INS_URL}/statement-of-rights`,
    title: 'Statement of Rights',
  },
  {
    automationKey: 'footerContactUs',
    href: `${env.REACT_APP_INS_URL}/contact`,
    title: 'Contact Us',
  },
  {
    automationKey: 'footerMarketingServices',
    href: `${fixedUrls.experian}/privacy/consumer-information-portal`,
    title: 'Experian Marketing Services',
  },
];

export default (linksToSkip = [], mockLinkConfig = generateLinkConfig()) =>
  mockLinkConfig.filter(link => !linksToSkip.includes(link.automationKey));
