import styled from 'styled-components';

const CardLogo = styled.img`
  height: 25px;
  margin-right: 0.5rem;
`;

CardLogo.displayName = 'CardLogo';

export { CardLogo };
