import products from './products';

const defaultBreachOfferId = 'eb535534-5bf4-4c7e-b752-5fa984fc8fdb';
const defaultBreachPlusOfferId = '77dfeaf8-34f4-489d-9956-0623ec939ea5';
const defaultPrimeOffer = 'offer022';

const offerTags = {
  [products.credit_expert]: 'cever6',
  [products.identity]: 'identityver3',
  [products.basic]: 'cmver5',
  [products.breach]: 'breachver2',
  [products.breachPlus]: 'breachplusver2',
};

export default {
  defaultBreachOfferId,
  defaultBreachPlusOfferId,
  defaultPrimeOffer,
  offerTags,
};
