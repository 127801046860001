import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { push, goBack } from 'connected-react-router';
import { submit } from 'redux-form';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';

import { Button, Typography, setAutomationElement } from '@experian-uk/corvetteuk-common-ui';

import Analytics, { MilestoneInputEvent, OptimisationInputEvent } from '@experian-uk/web-common-analytics';
import startSecureMode from '../../actions/app/startSecureMode';

import { authorizePaymentMethod, getProRataPrice } from '../../actions/billing';
import { skipAuthorization } from '../../actions/billing/authorize';
import resetChallenge from '../../actions/billing/resetChallenge';
import throwError from '../../actions/error/throw';

import initializeEcd from '../../actions/ecd/initialize';
import fulfillSubscription from '../../actions/fulfillments/fulfill';
import confirmSelect from '../../actions/offers/confirmSelect';
import addEvent from '../../actions/ecd/addEvent';
import selectOffer from '../../actions/offers/select';
import updateSession from '../../actions/session/update';
import syncSession from '../../actions/session/sync';
import setCancellationReason from '../../actions/cancellationReasons/setCancellationReason';
import formatPaymentCard from './formatPaymentCard';
import billingErrorMessages from '../../actions/billing/errorMessages';
import validateVoucher from '../../actions/voucherValidation';
import trackStartUpsell from '../../actions/upsellstarttrackerservice/trackStartUpsell';

import Container from '../../components/Container';
import { Feature, SavedCard, Challenge } from '../../components/switch';
import { RadioInput } from '../../components/Inputs';
import Errors from '../../components/Alerts/Errors';
import ErrorAlert from '../../components/Alerts/ErrorAlert';
import TermsConditions from './termsConditions';
import { getBenefitCopy, getDynamicString, getUpgradeType, isObjectEmpty, upgradeTypes } from '../../helpers';
import { number } from '../../helpers/forms';
import { mapFamilyToProduct, isFreeTrial, getFreeTrialData } from '../../helpers/subscription';
import isServer from '../../helpers/isServer';
import {
  cta,
  commonUi,
  errorCodes,
  errorMessages,
  familyName,
  products,
  relativeUrls,
  splitsList,
  stateVariables,
  subscriptionActions,
} from '../../constants';
import {
  BackButton,
  BodyContent,
  BorderTopSection,
  BorderTopSectionPadded,
  BenefitsCaption,
  BreadcrumbsContainer,
  ButtonContainer,
  ConfirmAndPay,
  ContentContainer,
  ContentToggle,
  ErrorContainer,
  FeatureList,
  FormContainer,
  InlineLink,
  NonCardWarningCopy,
  NonCardWarningDiv,
  NonCardWarningIcon,
  PayButton,
  ToggleSavedCard,
  VoucherCode,
  ReducedButtonContainer,
} from './index.styles';
import PanelContainer from '../../components/PanelContainer';
import PanelHeader from '../../components/PanelHeader';
import { getCardNumberEnding, shouldGetProRataPrice } from '../../helpers/billing';

import UpsellVariantB from '../../components/switchUpsellVariants/UpsellVariantB';
import UpsellVariantD from '../../components/switchUpsellVariants/UpsellVariantD';
import UpsellVariantE from '../../components/switchUpsellVariants/UpsellVariantE';
import CreditLockDownsellBanner from '../../components/CreditLockDownsellBanner/CreditLockDownsellBanner';
import InViewComponent from '../../components/InView';

import { getOfferById } from '../../reducers/offers';
import getAvailableOfferIds from '../../reducers/selectors/getAvailableOfferIds';
import NewCard from '../../components/NewCard';
import Loading from '../../components/Loading';
import subscriptionType from '../../schemas/subscription';
import customerType from '../../schemas/customer';
import { withEnv, envPropTypes } from '../../components/Context/env';
import { getSplitStatus } from '../../reducers/selectors';
import getSelections from '../../actions/conductrics/getSelections';
import clearSession from '../../helpers/clearSession';
import abTests from '../../constants/abtests';
import { onSwitchPageLoadECD, submitInteractionECD, submitSwitchPageClickECD } from './switchPageECD2';
import { clickECDEvent } from '../home/homePageEcd2';
import getIdentityPriceFromOffers from '../../reducers/selectors/getIdentityPriceFromOffers';
import sendReward from '../../actions/conductrics/sendReward';
import GOALS from '../../constants/abTestGoals';
import BreachStepper from '../../components/BreachStepper/index';
import breachRetainingExistingCustomersCheck from '../../helpers/breachRetainingExistingCustomersCheck';
import getCommonECDData from '../../reducers/selectors/getCommonECDData';
import { commonEcdSchema } from '../../schemas';
import getBreachDeferredStatus from '../../helpers/subscription/getBreachDeferredStatus';
import { trackUpsellStage } from '../../helpers/trackUpsell/trackUpsellStage';
import { PAYMENT_LOG_STEPS } from '../../constants/paymentLogSteps';

const { Copy } = Typography;

const newCardFormName = 'productMovement/newCard';
const savedCardFormName = 'productMovement/savedCard';

const mobilePay = mobile => mobile && 'paymentType' in mobile;
const ddPay = dd => dd && 'accountNumber' in dd;

const breachProducts = [products.breach, products.breachPlus];

const getSelectedOffer = (state, overrideOfferId) => {
  if (!state.offers?.data) {
    return {};
  }
  let offerId = overrideOfferId || state.selection.offerId;
  if (!offerId) {
    offerId = state.router.location.query.offerId;
  }
  // Switch Page Deeplinking - infer the offerId from the product family in the url
  if (!offerId) {
    const queryFamily = state.router.location.query.switchingTo;
    const customerIsEligibleForTrial = isFreeTrial(
      state.offers?.data[state.offers?.credit_expert.trialId ?? state.offers?.credit_expert.offerId].descriptors
    );
    const offerData = customerIsEligibleForTrial
      ? Object.values(state.offers.data).find(
          data => data.family === queryFamily && data.descriptors.includes('free_trial')
        )
      : Object.values(state.offers.data).find(data => data.family === queryFamily);
    if (offerData) {
      offerId = offerData.id;
    }
  }
  const offerIdsAvailable = getAvailableOfferIds(state.offers.data);
  if (offerIdsAvailable && offerIdsAvailable.includes(offerId)) {
    return {
      ...getOfferById(state.offers, offerId),
      loaded: true,
    };
  }
  return {
    loaded: true,
  };
};

const determineSelectOffer = (userSelectedOffer = {}, sessionOffer = {}) => {
  if (!userSelectedOffer.loaded) {
    return { loaded: false };
  }

  if (userSelectedOffer.id) {
    return userSelectedOffer;
  }

  if (sessionOffer.id) {
    return sessionOffer;
  }

  return { loaded: true };
};

const getUpgradeTypeFromFamily = state => {
  if (!state.subscriptions.current) {
    return undefined;
  }
  let family = state.selection.family || state.session.selectedOfferFamily;
  if (!family) {
    family = state.router.location.query.switchingTo;
  }
  return getUpgradeType(state.subscriptions.current.family, family);
};

const evaluateVoucherCode = state => {
  if (!state.subscriptions.current) {
    return undefined;
  }
  const enabled = getSplitStatus(state.split, [splitsList.breachPlus])[splitsList.breachPlus];
  const voucherCodeNotNeeded = breachProducts.includes(state.subscriptions.current.family);
  if (!enabled || voucherCodeNotNeeded) {
    return null;
  }
  return state.router.location.query.voucherCode;
};

const switchAATest = conductrics => {
  if (isServer() || !conductrics?.sels) return;

  const { apiCode, name } = abTests.PRODMOVE_AA_TEST_SWITCH;

  if (window.$ECD2?.some(existingEcdEvent => existingEcdEvent?.optimisation_test_triggered?.test_name === name)) return;

  const testVariant = conductrics.sels[apiCode];

  if (['A', 'B'].includes(testVariant)) {
    const event = OptimisationInputEvent.fromObject({
      testName: name,
      testVariant,
      testArea: 'Product Movement',
    });
    Analytics.publishOnce(event, apiCode);
  }
};

@withEnv
@connect(
  state => ({
    billing: state.billing,
    fulfillments: state.fulfillments.success,
    commonEcdData: getCommonECDData(state),
    criticalApiError: !![state.subscriptions, state.productDetails, state.offers, state.auth].find(data => data?.error),
    customer: state.auth.currentUser,
    errors: state.error,
    fetchingProRataPrice: state.billing.fetchingProRataPrice,
    loading:
      state.fulfillments.loading ||
      state.billing.loading ||
      state.voucherValidationResult.fetching ||
      state.fulfillments.success,
    newCardForm: state.form[newCardFormName],
    savedCardForm: state.form[savedCardFormName],
    newJourney: state.fulfillments.newJourney,
    offers: state.offers,
    offerIdsAvailable: getAvailableOfferIds(state.offers),
    proRataPrice: state.billing.proRataPrice ? state.billing.proRataPrice.toFixed(2) : null,
    query: state.router.location.query,
    session: state.session,
    selectedOffer: getSelectedOffer(state) || {},
    sessionOffer: getSelectedOffer(state, state.session.selectedOfferId),
    splitReady: state.split.fetched,
    subscription: state.subscriptions.current,
    upgradeType: getUpgradeTypeFromFamily(state),
    validSavedCard: !!state.billing.card && !isObjectEmpty(state.billing.card),
    voucherCode: evaluateVoucherCode(state),
    validVoucher: !state.voucherValidationResult.fetching && !state.voucherValidationResult.error,
    voucherErrorCode: state.voucherValidationResult.errorCode,
    conductrics: state.conductrics,
    identityPrice: getIdentityPriceFromOffers(state),
    isLocked: state.creditLock?.isLocked,
    shouldShowBreachRetainingUI: breachRetainingExistingCustomersCheck(
      state.split,
      state.subscriptions?.current?.family,
      getSelectedOffer(state).family
    ),
    upsellCorrelationId: state.upsellProgress.upsellCorrelationId,
  }),
  dispatch =>
    bindActionCreators(
      {
        startSecureMode,
        fulfillSubscription,
        authorizePaymentMethod,
        push,
        goBack,
        confirmSelect,
        submit,
        initializeEcd,
        getProRataPrice,
        skipAuthorization,
        throwError,
        addEvent,
        validateVoucher,
        selectOffer,
        updateSession,
        syncSession,
        resetChallenge,
        getSelections,
        trackStartUpsell,
        sendReward,
        setCancellationReason,
      },
      dispatch
    )
)
export default class SwitchIndex extends React.Component {
  static createEcdEvent = (from, to) => ({
    category: 'Product Movement',
    action: 'Prod Move Confirmation',
    label: `${from}>${to}`,
  });

  state = {
    cvv: undefined,
    initialized: false,
    isComplimentaryOffer: !!this.props.voucherCode,
    isECDInitialized: false,
    priceReady: this.props.proRataPrice,
    showContent: true,
    useSavedCard: true,
  };

  static propTypes = {
    commonEcdData: PropTypes.shape(commonEcdSchema),
    criticalApiError: PropTypes.bool,
    env: envPropTypes.isRequired,
    fulfillments: PropTypes.bool,
    fulfillSubscription: PropTypes.func.isRequired,
    addEvent: PropTypes.func.isRequired,
    initializeEcd: PropTypes.func,
    confirmSelect: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired,
    startSecureMode: PropTypes.func.isRequired,
    authorizePaymentMethod: PropTypes.func.isRequired,
    validSavedCard: PropTypes.bool,
    offers: PropTypes.shape({
      isLoaded: PropTypes.bool,
      identity: PropTypes.shape({
        offerId: PropTypes.string,
      }),
    }),
    selectedOffer: PropTypes.shape({
      descriptors: PropTypes.arrayOf(PropTypes.string),
      family: PropTypes.string,
      id: PropTypes.string,
      loaded: PropTypes.bool,
      terms: PropTypes.arrayOf(
        PropTypes.shape({
          price: PropTypes.number,
        })
      ),
    }),
    sessionOffer: PropTypes.shape({
      descriptors: PropTypes.arrayOf(PropTypes.string),
      family: PropTypes.string,
      id: PropTypes.string,
      loaded: PropTypes.bool,
      terms: PropTypes.arrayOf(
        PropTypes.shape({
          price: PropTypes.number,
        })
      ),
    }),
    session: PropTypes.shape({}),
    newCardForm: PropTypes.shape(),
    savedCardForm: PropTypes.shape(),
    billing: PropTypes.shape({
      authorized: PropTypes.bool,
      card: PropTypes.shape({
        creditCardNumber: PropTypes.string,
        billingProviderPaymentMethodId: PropTypes.string,
      }),
      challengeUrl: PropTypes.string,
      directDebit: PropTypes.shape({
        accountNumber: PropTypes.string,
      }),
      fetchFailed: PropTypes.bool,
      hasSavedCard: PropTypes.bool,
      mobile: PropTypes.shape({
        paymentType: PropTypes.string,
      }),
      proRataPrice: PropTypes.number,
      freeTrialTerms: PropTypes.shape({
        remaining: PropTypes.shape({
          value: PropTypes.number,
        }),
      }),
      nextBillingDate: PropTypes.string,
    }),
    customer: PropTypes.shape(customerType),
    subscription: PropTypes.shape(subscriptionType),
    loading: PropTypes.bool,
    offerIdsAvailable: PropTypes.arrayOf(PropTypes.string),
    getProRataPrice: PropTypes.func.isRequired,
    proRataPrice: PropTypes.string,
    selectOffer: PropTypes.func.isRequired,
    skipAuthorization: PropTypes.func.isRequired,
    updateSession: PropTypes.func.isRequired,
    syncSession: PropTypes.func.isRequired,
    upgradeType: PropTypes.oneOf([...Object.values(upgradeTypes), '']),
    throwError: PropTypes.func,
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string,
    }).isRequired,
    priceReady: PropTypes.bool,
    voucherCode: PropTypes.string,
    errors: PropTypes.arrayOf(
      PropTypes.shape({
        critical: PropTypes.bool,
        error: PropTypes.string,
      })
    ),
    validVoucher: PropTypes.bool,
    voucherErrorCode: PropTypes.oneOf([errorCodes.attributionError, errorCodes.serviceUnavailable]),
    validateVoucher: PropTypes.func.isRequired,
    newJourney: PropTypes.bool,
    splitReady: PropTypes.bool,
    fetchingProRataPrice: PropTypes.bool,
    query: PropTypes.shape({
      switchingTo: PropTypes.string,
      variant: PropTypes.string,
      reasonForCancel: PropTypes.string,
    }),
    resetChallenge: PropTypes.func.isRequired,
    getSelections: PropTypes.func.isRequired,
    conductrics: PropTypes.shape({
      error: PropTypes.bool,
      fetching: PropTypes.bool,
      sels: PropTypes.objectOf(PropTypes.string),
    }),
    trackStartUpsell: PropTypes.func.isRequired,
    identityPrice: PropTypes.number.isRequired,
    sendReward: PropTypes.func.isRequired,
    setCancellationReason: PropTypes.func.isRequired,
    isLocked: PropTypes.bool,
    shouldShowBreachRetainingUI: PropTypes.bool,
    upsellCorrelationId: PropTypes.string,
  };

  static defaultProps = {
    commonEcdData: null,
    criticalApiError: false,
    subscription: {},
    offers: {
      isLoaded: false,
    },
    validSavedCard: false,
    newCardForm: {
      syncErrors: null,
    },
    savedCardForm: {
      syncErrors: null,
    },
    offerIdsAvailable: null,
    selectedOffer: {},
    sessionOffer: {},
    billing: {},
    loading: false,
    customer: {},
    fulfillments: false,
    initializeEcd: () => ({}),
    proRataPrice: null,
    upgradeType: null,
    throwError: () => ({}),
    priceReady: false,
    voucherCode: null,
    errors: [],
    validVoucher: false,
    voucherErrorCode: null,
    newJourney: true,
    splitReady: false,
    fetchingProRataPrice: false,
    session: {},
    query: {},
    conductrics: {
      error: null,
      fetching: false,
      sels: {},
    },
    isLocked: false,
    shouldShowBreachRetainingUI: false,
    upsellCorrelationId: null,
  };

  componentDidMount() {
    this.initialize();
    const { conductrics } = this.props;

    switchAATest(conductrics);

    const reasonForCancelIsBreach = this.props.query.reasonForCancel === 'breach';

    if (reasonForCancelIsBreach) {
      this.props.setCancellationReason({
        reason: 'breach',
      });
    }

    window.onpopstate = e => {
      // Copy 'Back to Subscriptions' action when browser back button pushed during 3ds challenge
      if (e.target.location.href.indexOf('/switch') && this.props.billing.challengeUrl) {
        this.props.resetChallenge();
        this.props.push('/');
      }
    };
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.newJourney;
  }

  async componentDidUpdate(preProps) {
    if (this.props.conductrics?.fetching) return;
    if (!this.state.initialized) {
      await this.initialize();
    }

    await this.initializeAnalytics();

    const { conductrics } = this.props;

    const { conductrics: conductricsPrev } = preProps;

    if (conductrics?.sels && !isEqual(conductricsPrev, conductrics)) {
      switchAATest(conductrics);
    }
  }

  componentWillUnmount() {
    this.props.resetChallenge();
  }

  initialize = async () => {
    const { billing, selectedOffer: userSelectedOffer, sessionOffer, subscription, query } = this.props;
    const selectedOffer = determineSelectOffer(userSelectedOffer, sessionOffer);
    if (this.props.criticalApiError) {
      this.props.throwError({
        heading: getDynamicString('changeFailure.intro', ['subscription']),
        messages: [getDynamicString('changeFailure.default', ['subscription'])],
      });
      this.props.push('/', {
        [stateVariables.persistErrorsOnLocationChange]: true,
      });
      return;
    }

    if (this.state.initialized || !selectedOffer.loaded) {
      return;
    }

    const noOfferSelected = !selectedOffer.id;

    const billingError = !billing || billing.fetchFailed;

    // As it is possible to deep link in with query parameters for offerId and family, we need to handle the edge case
    // where the switchingTo query parameter doesn't match the family of the selected offer, or the offer ID specified is
    // for the user's current subscription
    const { switchingTo } = query;
    const isBreachUser = breachProducts.includes(subscription.family);
    const isBreachOffer = breachProducts.some(breachSubscription =>
      [switchingTo, selectedOffer.family].includes(breachSubscription)
    );
    const breachNotYetEnabled = !isBreachUser && isBreachOffer && this.props.splitReady && !this.props.voucherCode;
    const illogicalOfferState =
      switchingTo === subscription.family ||
      (switchingTo && selectedOffer.family && switchingTo !== selectedOffer.family) ||
      breachNotYetEnabled;

    if (noOfferSelected || billingError || illogicalOfferState) {
      this.props.throwError({
        heading: getDynamicString('changeFailure.intro', ['subscription']),
        messages: [getDynamicString('changeFailure.default', ['subscription'])],
      });
      this.props.push('/', {
        [stateVariables.persistErrorsOnLocationChange]: true,
      });
      return;
    }

    if (
      shouldGetProRataPrice(
        subscription.family,
        selectedOffer.family || switchingTo,
        this.props.proRataPrice,
        this.props.fetchingProRataPrice
      )
    ) {
      await this.props.getProRataPrice(selectedOffer.id);
      const { proRataPrice } = this.props;

      if (!proRataPrice) {
        this.props.push('/', {
          [stateVariables.persistErrorsOnLocationChange]: true,
        });
        return;
      }
    }

    this.setState({
      initialized: true,
      priceReady: true,
      isComplimentaryOffer: !!this.props.voucherCode || isBreachOffer,
    });

    if (this.props.upgradeType === upgradeTypes.downgrade || (isBreachUser && isBreachOffer)) {
      await this.props.skipAuthorization();
    }

    this.props.startSecureMode();

    await this.initializeAnalytics();

    if (isBreachOffer && this.state.isECDInitialized) {
      Analytics.publishOnce(
        MilestoneInputEvent.fromObject({
          product_movement_switch: {
            journey_type: 'Add Free Account',
            page_funnel_step: 'Breach+ Offer Activation - Step 2',
          },
        })
      );
      // Remove GA3 event in Q2FY25
      this.props.addEvent('/switch', {
        category: 'Authentication',
        action: 'Authenticated',
        label: 'Breach+ Offer Activation',
      });
    }

    this.props.initializeEcd();

    // Track customers who start an upsell journey
    if (
      this.props.upgradeType === upgradeTypes.upgrade &&
      ['credit_expert', 'identity'].includes(selectedOffer.family)
    ) {
      const eligibleForFreeTrial = isFreeTrial(selectedOffer.descriptors);
      await this.props.trackStartUpsell(selectedOffer.family, eligibleForFreeTrial);
    }

    this.props.initializeEcd();

    const { customer, upsellCorrelationId } = this.props;

    if (customer && upsellCorrelationId && selectedOffer) {
      trackUpsellStage(
        PAYMENT_LOG_STEPS.SWITCH_FORM_STARTED,
        customer.customerId,
        upsellCorrelationId,
        selectedOffer.id,
        selectedOffer.family
      );
    }
  };

  handleChange = () => {
    this.setState(prevState => {
      const newState = !prevState.useSavedCard;

      if (this.props.upgradeType === upgradeTypes.downgrade) {
        this.props.skipAuthorization(newState);
      }

      return {
        useSavedCard: newState,
      };
    });
  };

  setCvv = value => {
    this.setState({
      cvv: value,
    });
    submitInteractionECD('input', 'prodmove_switch_form_cvv', 'success');
  };

  validCard = () => {
    const { billing, newCardForm, upgradeType, validSavedCard } = this.props;
    const { cvv, useSavedCard } = this.state;
    const canUseSavedCard = useSavedCard && !!validSavedCard;
    if (!canUseSavedCard) {
      return newCardForm && !newCardForm.syncErrors;
    }
    return (upgradeType === upgradeTypes.downgrade || !number(cvv, 3)) && !!billing.card.billingProviderPaymentMethodId;
  };

  cancel = async () => {
    const selectedOffer = determineSelectOffer(this.props.selectedOffer, this.props.sessionOffer);
    await this.props.fulfillSubscription(selectedOffer.id);
    if (this.props.fulfillments === true) {
      const selectionData = {
        cancel: true,
        family: selectedOffer.family,
        price: selectedOffer.terms[0].price,
        freeTrial: false,
      };
      await this.props.confirmSelect(selectionData);
      await clearSession(this.props.session, this.props.updateSession, this.props.syncSession);
      this.props.push(relativeUrls.thanks);
    } else {
      this.setState({
        showContent: false,
      });
    }
  };

  checkout = async () => {
    const selectedOffer = determineSelectOffer(this.props.selectedOffer, this.props.sessionOffer);
    const offerId = selectedOffer.id;

    const { voucherCode, upsellCorrelationId, customer } = this.props;

    if (voucherCode) {
      await this.props.validateVoucher(voucherCode);
      if (!this.props.validVoucher) {
        if ([errorCodes.attributionError].includes(this.props.voucherErrorCode)) {
          this.props.push(`/error/${this.props.voucherErrorCode}`);
        }
        this.props.throwError({
          heading: errorMessages.oopsHeading,
          messages: [errorMessages.oopsMessage],
        });
      }
    }

    const { card, hasSavedCard, mobile, directDebit } = this.props.billing;
    const { cvv, useSavedCard } = this.state;
    const hasNonCardPay = mobilePay(mobile) || ddPay(directDebit);
    let cardToUse;
    let isPaymentMethodChange;
    if (hasSavedCard && useSavedCard && !hasNonCardPay) {
      cardToUse = {
        ...card,
        CVV: cvv,
      };
      await this.props.submit(savedCardFormName);
      const { savedCardForm } = this.props;
      if (savedCardForm.submitFailed) {
        return;
      }
    } else if (!this.props.voucherCode) {
      await this.props.submit(newCardFormName);
      const { newCardForm } = this.props;
      if (newCardForm.submitFailed) {
        return;
      }
      // We're effectively changing payment method from a voucher; this field will ensure billing runs a 0p auth instead of taking immediate payment.
      isPaymentMethodChange = breachProducts.includes(this.props.subscription.family);

      cardToUse = newCardForm.values;
    }
    submitInteractionECD('click', 'prodmove_confirm_purchase_button', 'success');
    trackUpsellStage(
      PAYMENT_LOG_STEPS.SWITCH_FORM_COMPLETE,
      customer.customerId,
      upsellCorrelationId,
      selectedOffer.id,
      selectedOffer.family
    );

    if ((hasNonCardPay || !this.props.billing.authorized) && !voucherCode) {
      trackUpsellStage(
        PAYMENT_LOG_STEPS.AUTHORISING_PAYMENT_METHOD,
        customer.customerId,
        upsellCorrelationId,
        selectedOffer.id,
        selectedOffer.family
      );
      await this.props.authorizePaymentMethod(
        formatPaymentCard(hasSavedCard && useSavedCard && !hasNonCardPay, cardToUse),
        offerId,
        isPaymentMethodChange
      );
    }

    if (this.props.billing.authorized === true || (voucherCode && this.props.validVoucher)) {
      trackUpsellStage(
        PAYMENT_LOG_STEPS.PAYMENT_AUTHORISED,
        customer.customerId,
        upsellCorrelationId,
        selectedOffer.id,
        selectedOffer.family
      );
      const fulfillmentErrorMessageKey = this.props.voucherCode ? 'complimentary' : 'default';
      await this.props.fulfillSubscription(offerId, undefined, fulfillmentErrorMessageKey);
      trackUpsellStage(
        PAYMENT_LOG_STEPS.SUBSCRIPTION_FULFILLMENT_STARTED,
        customer.customerId,
        upsellCorrelationId,
        selectedOffer.id,
        selectedOffer.family
      );
      await this.props.sendReward(GOALS.UPSELL_REDESIGN_CTA_GOAL);
      submitSwitchPageClickECD('prodmove_billing_auth_success');
      if (
        this.props.upgradeType === upgradeTypes.upgrade &&
        ['credit_expert', 'identity'].includes(selectedOffer.family)
      ) {
        Analytics.publishOnce(
          MilestoneInputEvent.fromObject({
            product_movement_fulfillment_upsell: {
              interaction_status: this.props.fulfillments,
            },
          })
        );
      } else {
        Analytics.publishOnce(
          MilestoneInputEvent.fromObject({
            product_movement_fulfillment: {
              interaction_status: this.props.fulfillments,
            },
          })
        );
      }
      if (this.props.fulfillments === true) {
        trackUpsellStage(
          PAYMENT_LOG_STEPS.SUBSCRIPTION_FULFILLED,
          customer.customerId,
          upsellCorrelationId,
          selectedOffer.id,
          selectedOffer.family
        );
        const selectionData = {
          cancel: false,
          complimentary: this.props.validVoucher,
          family: mapFamilyToProduct(selectedOffer.family),
          price: selectedOffer.terms[0].price,
          freeTrial: isFreeTrial(selectedOffer.descriptors),
          oldProduct: this.props.subscription.family,
        };
        await this.props.confirmSelect(selectionData);
        Analytics.publishOnce(
          MilestoneInputEvent.fromObject({
            product_movement_switch: {
              journey_type: 'Confirmation',
              page_funnel_step: `${this.props.subscription.family} > ${selectedOffer.family} - Step 4`,
            },
          })
        );

        // Remove GA3 event in Q2FY25
        this.props.addEvent(
          relativeUrls.thanks,
          SwitchIndex.createEcdEvent(this.props.subscription.family, selectedOffer.family)
        );

        if (
          ['A', 'B'].includes(this.props.conductrics?.sels?.[abTests?.PRODMOVE_AA_TEST_SWITCH.apiCode]) &&
          this.props.upgradeType === 'downgrade' &&
          selectionData.oldProduct === 'credit_expert' &&
          selectionData.family === 'identity'
        )
          await this.props.sendReward(GOALS.CONFIRM_PURCHASE_DOWNGRADE_CE_CLICKED);

        await clearSession(this.props.session, this.props.updateSession, this.props.syncSession);
        this.props.push(relativeUrls.thanks);
      }
    }
  };

  getConfirmButtonLabel = upgradeType => {
    if (this.state.isComplimentaryOffer) {
      if (this.props.voucherCode) {
        return 'Redeem voucher code';
      }
      return `${upgradeType === upgradeTypes.upgrade ? 'Add a' : 'Remove'} Free Experian account`;
    }
    return 'Confirm purchase';
  };

  async initializeAnalytics() {
    if (!isServer() && this.props.commonEcdData && !this.state.isECDInitialized) {
      this.setState(state => ({ ...state, isECDInitialized: true }));

      const { billing, commonEcdData, query, selectedOffer, subscription } = this.props;

      const isBreachOrBreachPlus = [products.breach, products.breachPlus].includes(subscription.family);

      const { hasDeferredActivation, daysUntilExpiry } = getBreachDeferredStatus(subscription, billing);

      const breachDeferrmentInformation = {
        isDeferred: hasDeferredActivation,
        subscriptionSwitchedTo: selectedOffer.family,
        expiresIn: daysUntilExpiry,
        trialist: isFreeTrial(selectedOffer.descriptors),
      };

      await Analytics.init(
        {
          ...commonEcdData,
          breachDeferrmentInformation: isBreachOrBreachPlus ? breachDeferrmentInformation : null,
          productChange: { switchingTo: selectedOffer.family, fromDeepLink: !!query?.switchingTo },
        },
        'ProdMoveSwitchPage'
      );
    }
  }

  render() {
    const {
      billing,
      customer,
      query,
      selectedOffer,
      splitReady,
      subscription,
      upgradeType,
      validSavedCard,
      voucherCode,
      conductrics,
      isLocked,
      shouldShowBreachRetainingUI,
    } = this.props;

    if (!selectedOffer.loaded || !this.state.priceReady || !splitReady) {
      return null;
    }
    const { card, hasSavedCard, mobile, directDebit } = billing;
    let savedCard = {};
    let cardNumberEnding = '';
    if (card) {
      cardNumberEnding = getCardNumberEnding(card.creditCardNumber);
      savedCard = {
        ...card,
        cardNumberEnding,
      };
    }
    const hasNonCardPayment = mobilePay(mobile) || ddPay(directDebit);

    const product = subscription.family;
    let benefitCopy = {};
    let benefitsCaption;
    let title;
    const freeTrial = isFreeTrial(selectedOffer.descriptors);
    const family = familyName[selectedOffer.family];
    let paymentConfirmationMessage = `You're just one click away from your ${
      freeTrial ? `free ${family} trial` : `${family} subscription`
    }`;

    if (freeTrial && family === familyName.identity) {
      paymentConfirmationMessage += ' with additional CreditExpert features.';
    }

    let selectedOfferFamily = selectedOffer.family;
    if (this.state.isComplimentaryOffer) {
      selectedOfferFamily = mapFamilyToProduct(selectedOffer.family);
    }
    if (voucherCode) {
      paymentConfirmationMessage = `Start your complimentary ${familyName[selectedOfferFamily]} subscription`;
    }

    const selectedOfferPrice = get(selectedOffer, 'terms[0].price', null);
    const shouldShowCardDetails = !voucherCode && !this.state.isComplimentaryOffer;
    const shouldShowConfirmation = upgradeType !== upgradeTypes.cancel;

    if (upgradeType) {
      let sourceProduct;
      let targetProduct;
      if (this.state.isComplimentaryOffer) {
        if (breachProducts.includes(selectedOffer.family)) {
          if (voucherCode) {
            targetProduct = products.identity;
          } else if (breachProducts.includes(product)) {
            if (upgradeType === upgradeTypes.upgrade) {
              sourceProduct = mapFamilyToProduct(product);
              targetProduct = products.basic;
            } else {
              sourceProduct = products.basic;
              targetProduct = mapFamilyToProduct(selectedOffer.family);
            }
          }
        }
      }
      if (upgradeType === upgradeTypes.cancel) {
        sourceProduct = mapFamilyToProduct(product);
      }
      try {
        benefitCopy = getBenefitCopy[upgradeType]({
          sourceProduct: sourceProduct || product,
          targetProduct: targetProduct || selectedOffer.family,
          customer: customer.firstName,
          billingDate: billing.nextBillingDate,
          proRata: this.props.proRataPrice,
          endDate: subscription.defermentInfo ? subscription.defermentInfo.defermentDt : billing.nextBillingDate,
          price: upgradeType !== upgradeTypes.cancel ? selectedOfferPrice : null,
          trial: freeTrial,
          isDeferred: ![products.basic, ...breachProducts].includes(product),
          isComplimentary: breachProducts.includes(product),
          voucherCode,
          isBreachUpgradeSplit: shouldShowBreachRetainingUI,
        });

        ({ benefitsCaption, title } = benefitCopy);
      } catch (e) {
        this.props.throwError({
          heading: getDynamicString('changeFailure.intro', ['subscription']),
          messages: [getDynamicString('changeFailure.default', ['subscription'])],
        });
        this.props.push('/', {
          [stateVariables.persistErrorsOnLocationChange]: true,
        });
      }
    } else {
      this.props.push('/');
    }

    const testExperienceData = {
      savedCard,
      useSavedCard: this.state.useSavedCard,
      hasSavedCard,
      handleRadioSwitch: this.handleChange,
      setCvv: this.setCvv,
      trial: freeTrial,
      priceInfo: {
        price: upgradeType !== upgradeTypes.cancel ? selectedOfferPrice : null,
        trialDuration: 30,
      },
      submitDisabled:
        this.props.loading ||
        ((!this.state.useSavedCard || !hasSavedCard) && !!this.props.newCardForm?.syncErrors) ||
        (this.state.useSavedCard && hasSavedCard && (!this.state.cvv || this.state.cvv?.toString().length < 3)),
      submitFunction: this.checkout,
    };

    const { variant: upsellTestVariant } = query;

    let UpsellFormTestComponent;

    switch (upsellTestVariant) {
      case 'B':
        UpsellFormTestComponent = UpsellVariantB;
        break;
      case 'C':
        UpsellFormTestComponent = null;
        break;
      case 'D':
        UpsellFormTestComponent = UpsellVariantD;
        break;
      case 'E':
        UpsellFormTestComponent = UpsellVariantE;
        break;
      default:
        break;
    }

    const freeTrialData = getFreeTrialData(billing?.freeTrialTerms, subscription.freeTrialEndDt);
    const isInFreeTrial = freeTrialData.ends !== undefined;
    const isPendingCancellation = subscription.defermentInfo?.type === subscriptionActions.cancellation;

    if (upgradeType === upgradeTypes.cancel && subscription.family === products.credit_expert) {
      if (!isServer()) {
        onSwitchPageLoadECD(isInFreeTrial, isPendingCancellation, freeTrialData.remaining);
      }
    }

    const confirmCECancelClickHandler = async () => {
      clickECDEvent('prodmove_close_experian_account');
      submitSwitchPageClickECD('prodmove_switch_confirm_cancellation_ce');
      if (['A', 'B'].includes(conductrics?.sels?.[abTests?.PRODMOVE_AA_TEST_SWITCH.apiCode])) {
        await this.props.sendReward(GOALS.CONFIRM_CANCEL_CE_CLICKED);
      }
      this.cancel();
    };

    const linkToIdentitySwitch = async e => {
      e.preventDefault();
      submitSwitchPageClickECD('prodmove_switch_id_banner');
      const identityOfferId = this.props.offers[products.identity].offerId;
      const identityOfferFamily = 'identity';
      await this.props.selectOffer(identityOfferId, identityOfferFamily);
      this.props.updateSession({
        selectedOfferId: identityOfferId,
        selectedOfferFamily: identityOfferFamily,
      });
      await this.props.syncSession();
      this.props.push('/switch');
    };

    const submitKeepCEClick = async e => {
      e.preventDefault(); // This ensures the goal is sent before redirect clears the call-stack
      submitSwitchPageClickECD('prodmove_switch_keep_ce');
      if (['A', 'B'].includes(conductrics?.sels?.[abTests?.PRODMOVE_AA_TEST_SWITCH.apiCode])) {
        await this.props.sendReward(GOALS.KEEP_CE_CTA_CLICKED);
      }
      this.props.push('/');
    };

    return (
      <Fragment>
        <Container as="main" id="main" upsellFormTest={!!UpsellFormTestComponent}>
          {this.props.loading && <Loading solidBackground />}
          {!UpsellFormTestComponent && (
            <BreadcrumbsContainer>
              <BackButton
                {...setAutomationElement('breadcrumbBackButton')}
                onClick={() => {
                  submitSwitchPageClickECD('back_to_your_subscriptions');
                  if (
                    upgradeType === upgradeTypes.cancel ||
                    this.props.location.search ||
                    this.props.billing.challengeUrl
                  ) {
                    this.props.resetChallenge();
                    return this.props.push('/');
                  }
                  return this.props.goBack();
                }}
              >
                &lt; {cta.goBack} {cta.subscriptionsPage}
              </BackButton>
            </BreadcrumbsContainer>
          )}
          {selectedOffer.loaded &&
            !this.state.isComplimentaryOffer &&
            upgradeType &&
            upgradeType !== upgradeTypes.cancel &&
            hasSavedCard &&
            !validSavedCard &&
            !hasNonCardPayment && (
              <ErrorContainer>
                <ErrorAlert {...billingErrorMessages.getPaymentMethods} />
              </ErrorContainer>
            )}
          {this.props.errors.length > 0 && (
            <ErrorContainer>
              <Errors {...setAutomationElement('switchError')} />
            </ErrorContainer>
          )}
          {!UpsellFormTestComponent && this.state.showContent && !billing.challengeUrl && (
            <ContentContainer>
              {title && (
                <PanelHeader
                  headingStart={title.start}
                  styledSubscription={title.product}
                  headingEnd={title.end}
                  subtitle={benefitCopy.subtitle}
                  setAutomationTitle="switchTitle"
                  setAutomationSubtitle="switchSubtitle"
                />
              )}
              <BodyContent>
                {!shouldShowBreachRetainingUI && (
                  <Fragment>
                    {benefitsCaption && (
                      <BenefitsCaption {...setAutomationElement('switchCopy')}>
                        {benefitsCaption.start}
                        <InlineLink
                          href={benefitsCaption.href}
                          target="_blank"
                          {...setAutomationElement('switchProductFactsheet')}
                        >
                          {benefitsCaption.linkText}
                        </InlineLink>
                        {benefitsCaption.end}
                      </BenefitsCaption>
                    )}
                    <FeatureList>
                      {benefitCopy.benefits?.map(benefit => (
                        <Feature
                          isLosingFeature={this.state.isComplimentaryOffer && upgradeType !== upgradeTypes.upgrade}
                          key={benefit}
                          numberOfItems={benefitCopy.benefits.length}
                          layout={benefitCopy.layout}
                        >
                          {benefit}
                        </Feature>
                      ))}
                    </FeatureList>
                  </Fragment>
                )}
                {upgradeType === upgradeTypes.cancel && (
                  <div>
                    {subscription.family !== products.credit_expert ? (
                      <React.Fragment>
                        <div {...setAutomationElement('switchDeactivate')}>
                          If you do not wish to enjoy the benefits of your free Experian account, you can{' '}
                          <InlineLink href={`${this.props.env.REACT_APP_INS_URL}/account/close`}>
                            deactivate your Experian account
                          </InlineLink>
                        </div>
                        <ButtonContainer>
                          <Button
                            {...setAutomationElement('confirmButton')}
                            type="primary"
                            label="Confirm Cancellation"
                            href="#"
                            onClick={this.cancel}
                            disabled={this.props.loading}
                          />
                          <Button
                            {...setAutomationElement('returnButton')}
                            type="secondary"
                            label={`Keep ${familyName[mapFamilyToProduct(subscription.family)]}`}
                            href="/"
                            onClick={() => this.onClickBenefitLostTestECD()}
                            disabled={this.props.loading}
                          />
                        </ButtonContainer>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        {upgradeType === upgradeTypes.cancel && isLocked && (
                          <CreditLockDownsellBanner
                            linkToIdentitySwitch={linkToIdentitySwitch}
                            identityPrice={this.props.identityPrice}
                          />
                        )}
                        <ReducedButtonContainer>
                          <Button
                            {...setAutomationElement('returnButton')}
                            type="secondary"
                            label={`Keep ${familyName[mapFamilyToProduct(subscription.family)]}`}
                            href="/"
                            onClick={submitKeepCEClick}
                            disabled={this.props.loading}
                          />
                          <Button
                            {...setAutomationElement('confirmButton')}
                            type="secondary"
                            label="Confirm Cancellation"
                            href="#"
                            onClick={confirmCECancelClickHandler}
                            disabled={this.props.loading}
                          />
                        </ReducedButtonContainer>
                      </React.Fragment>
                    )}
                  </div>
                )}
                {shouldShowBreachRetainingUI && (
                  <InViewComponent label="how_this_works">
                    <BreachStepper
                      selectedOffer={selectedOffer}
                      billing={billing}
                      title={freeTrial ? 'How this works' : 'What happens next'}
                    />
                  </InViewComponent>
                )}
                {shouldShowConfirmation && (
                  <BorderTopSection>
                    {voucherCode && (
                      <VoucherCode {...setAutomationElement('activationCode')}>
                        The voucher code you entered is: {voucherCode}
                      </VoucherCode>
                    )}
                    {shouldShowCardDetails && hasSavedCard && validSavedCard && (
                      <Fragment>
                        <PanelContainer>
                          <Container>
                            <ContentToggle>
                              <ToggleSavedCard>
                                <RadioInput
                                  checked={this.state.useSavedCard}
                                  id="use-saved-card"
                                  label={`Use my previously supplied card ending ${cardNumberEnding}`}
                                  onChange={this.handleChange}
                                />
                              </ToggleSavedCard>
                              {this.state.useSavedCard && (
                                <SavedCard
                                  {...savedCard}
                                  setCvv={this.setCvv}
                                  showCvv={upgradeType !== upgradeTypes.downgrade}
                                />
                              )}
                            </ContentToggle>
                          </Container>
                        </PanelContainer>
                        <PanelContainer>
                          <Container>
                            <ContentToggle>
                              <ToggleSavedCard>
                                <RadioInput
                                  checked={!this.state.useSavedCard}
                                  id="add-new-card"
                                  label="I want to provide new card details"
                                  onChange={this.handleChange}
                                />
                                {!this.state.useSavedCard && (
                                  <FormContainer>
                                    <NewCard />
                                  </FormContainer>
                                )}
                              </ToggleSavedCard>
                            </ContentToggle>
                          </Container>
                        </PanelContainer>
                      </Fragment>
                    )}
                    {shouldShowCardDetails && (!hasSavedCard || !validSavedCard) && (
                      <BorderTopSectionPadded>
                        {hasNonCardPayment && (
                          <NonCardWarningDiv>
                            <NonCardWarningIcon />
                            <NonCardWarningCopy>
                              Unfortunately you cannot move to a new subscription with your current payment method,
                              please provide new card details below.
                            </NonCardWarningCopy>
                          </NonCardWarningDiv>
                        )}
                        <NewCard />
                      </BorderTopSectionPadded>
                    )}
                    <TermsConditions
                      selectedOffer={{
                        ...selectedOffer,
                        family: selectedOfferFamily,
                      }}
                      currentSubscription={subscription.family}
                      isComplimentaryOffer={this.state.isComplimentaryOffer}
                      isUpgrade={upgradeType === upgradeTypes.upgrade}
                      nextBillingDate={billing.nextBillingDate}
                      proRata={this.props.proRataPrice}
                      voucherCode={voucherCode}
                    />
                    <ConfirmAndPay>
                      {(voucherCode || !this.state.isComplimentaryOffer) && !shouldShowBreachRetainingUI && (
                        <Copy.Text {...setAutomationElement('pay-confirm-message')}>
                          {paymentConfirmationMessage}
                        </Copy.Text>
                      )}
                      <PayButton
                        {...setAutomationElement('confirmPurchaseButton')}
                        type="primary"
                        label={this.getConfirmButtonLabel(upgradeType)}
                        href="#"
                        onClick={async () => this.checkout()}
                        disabled={this.props.loading || (shouldShowCardDetails && !this.validCard())}
                        svgIcon={this.state.isComplimentaryOffer ? null : commonUi.icons.lock}
                      />
                    </ConfirmAndPay>
                  </BorderTopSection>
                )}
              </BodyContent>
            </ContentContainer>
          )}
          {billing.challengeUrl && <Challenge challengeUrl={billing.challengeUrl} />}
        </Container>

        {UpsellFormTestComponent && this.state.showContent && !billing.challengeUrl && (
          <UpsellFormTestComponent testExperienceData={testExperienceData} />
        )}
      </Fragment>
    );
  }
}
