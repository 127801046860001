import styled from 'styled-components';
import { Field } from 'redux-form';

export const VoucherWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) =>
    theme.media(
      '20',
      `
    margin-bottom: 1.25rem;
  `
    )};
`;

export const VoucherHeader = styled.h3`
  font-weight: 300;
  font-size: 1.5rem;
  line-height: 2.5rem;
  margin: 0.75rem 0 1rem;
`;

export const VoucherField = styled(Field)`
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-size: 1.25rem;
  width: 100%;
  max-width: 15.86rem;
  text-align: center;
  margin-top: 1rem;
  border-radius: 0.25rem;
  border: 1px solid ${({ $breachVoucherError }) => ($breachVoucherError ? '#eb0200' : '#b9c3db')};
  height: 3.25rem;
`;

export const VoucherCopy = styled.p`
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  margin: 0;
`;

export const VoucherErrorMessage = styled.p`
  margin: 0.75rem 0 0.25rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: #eb0200;
`;
