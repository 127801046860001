import styled from 'styled-components';
import { Forms } from '@experian-uk/corvetteuk-common-ui';

import { CardLogo } from '../Icons/CardLogo';

const Form = styled.form`
  display: block;
  margin-bottom: 3rem;
  max-width: 370px;
  width: 100%;
`;

Form.displayName = 'Form';

export { Form };

const FieldContainer = styled.div`
  cursor: pointer;
  flex: 2;
  margin-left: 0.9375rem;
  position: relative;

  &:first-of-type {
    flex: 3;
    margin-left: 0;
  }

  &:after,
  &:before {
    content: ' ';
    height: 0;
    position: absolute;
    width: 0;
    border: 7px solid transparent;
    top: 43.5%;
    right: 0.5rem;
  }

  &:after {
    border-top-color: ${props => (props.inputType === 'select' ? props.theme.colors.experianDarkBlue : 'transparent')};
  }

  ${props =>
    props.theme.media(
      '5',
      `
    &:after, &:before {
      right: 1rem;
    }
  `
    )}
`;

FieldContainer.displayName = 'FieldContainer';

export { FieldContainer };

const FieldSet = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

FieldSet.displayName = 'FieldSet';

export { FieldSet };

const FieldSetContainer = styled.div`
  margin-bottom: 1.8125rem;
`;

FieldSetContainer.displayName = 'FieldSetContainer';

export { FieldSetContainer };

const ImageContainer = styled.span`
  align-self: flex-end;
`;

ImageContainer.displayName = 'ImageContainer';

export { ImageContainer };

const Label = styled(Forms.Label)`
  align-items: center;
  display: flex;
  flex-basis: 371px;
  justify-content: space-between;
  margin-bottom: 0.6em;
`;

Label.displayName = 'Label';

export { Label };

const NewCardLogo = styled(CardLogo)`
  height: 20px;
  margin-top: 0;
  width: auto;

  &:last-of-type {
    margin-right: 0;
  }

  opacity: ${props => (props.disable ? '0.4' : '1')};

  ${props =>
    props.theme.media(
      '5',
      `
    height: inherit;
    width: inherit;
  `
    )}
`;

NewCardLogo.displayName = 'NewCardLogo';

export { NewCardLogo };

const Select = styled(Forms.Select)`
  background: transparent;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0.0625rem solid ${({ theme }) => theme.colors.borderBlue};
  border-radius: 0.3125rem;
  font-size: 1rem;
  height: 50px;
  padding: 0.5rem;
  position: relative;
  transition: 0.5s ease-in-out;
  flex: 2;
  z-index: 2;

  &::-ms-expand {
    display: none;
  }

  ${props =>
    props.theme.media(
      '5',
      `
    padding: 0.75rem;
  `
    )}
`;

Select.displayName = 'Select';

export { Select };
