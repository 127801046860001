import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import PropTypes from 'prop-types';

const Box = styled.div`
  font-size: ${rem(10)};
  line-height: ${rem(12)};
  font-weight: 500;
  box-shadow: ${props =>
    props.isInactive ? `0 ${rem(8)} ${rem(8)} rgba(0, 0, 0, 0.05)` : `0 ${rem(2)} ${rem(10)} rgba(0, 0, 0, 0.12)`};
  border-radius: ${props => (props.isInactive ? `${rem(4)}` : `${rem(3)}`)};
  padding: ${rem(6)};
  color: ${props => (props.isInactive ? '#b9baba' : '#4a4a4a')};
  margin-top: ${rem(20)};
  padding-left: ${rem(30)};
  &:before {
    content: '\\2022';
    color: ${props => (props.isInactive ? '#99a8bb' : '#e63888')};
    float: left;
    position: relative;
    top: ${rem(2)};
    left: ${rem(-20)};
    font-size: ${rem(24)};
    margin-right: ${rem(-16)};
  }
`;

const CEAlert = ({ isInactive = false }) => <Box isInactive={isInactive}>A new credit account has been added</Box>;

CEAlert.defaultProps = {
  isInactive: undefined,
};

CEAlert.propTypes = {
  isInactive: PropTypes.bool,
};

export default CEAlert;
