import React from 'react';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { setAutomationElement } from '@experian-uk/corvetteuk-common-ui';
import InViewComponent from '../../InView';
import { BreachContainer, BreachActionButton } from './breachProductPanels.styles';
import updateSession from '../../../actions/session/update';
import syncSession from '../../../actions/session/sync';
import selectOffer from '../../../actions/offers/select';
import getBreachDeferredStatus from '../../../helpers/subscription/getBreachDeferredStatus';
import DeferredPanel from './deferredPanel';
import FreeAccountPanel from './freeAccountPanel';
import { clickInteractionECD } from '../../../pages/home/homePageEcd2';

const BreachProductOptionCard = ({ offer, currentSubscription, showSmallerCard }) => {
  const dispatch = useDispatch();

  const billing = useSelector(state => state?.billing);
  const { hasDeferredActivation } = getBreachDeferredStatus(currentSubscription, billing);

  const isBreach = currentSubscription.family === 'breach';

  const goToSwitch = async e => {
    e.preventDefault();
    const productOptionOfferId = offer.id;
    const productOptionFamily = offer.family;

    const ECDComponentName = isBreach ? 'free_account_card_add_account' : 'free_account_card_cancel_account';

    clickInteractionECD(ECDComponentName);

    dispatch(selectOffer(productOptionOfferId, productOptionFamily));

    dispatch(
      updateSession({
        selectedOfferId: productOptionOfferId,
        selectedOfferFamily: productOptionFamily,
      })
    );
    dispatch(syncSession());

    dispatch(push('/switch'));
  };

  const getCardActionButton = () => (
    <BreachActionButton {...setAutomationElement('panel-button')} onClick={goToSwitch} isBreach={isBreach}>
      {isBreach ? 'Add Free account' : 'Cancel Free account'}
    </BreachActionButton>
  );

  return (
    <InViewComponent label={!hasDeferredActivation ? 'free_account_card' : 'youre_moving_card'}>
      <BreachContainer gridLocation="right" {...setAutomationElement(`panel-${offer.family}`)}>
        {!hasDeferredActivation ? (
          <FreeAccountPanel
            getCardActionButton={getCardActionButton}
            isBreach={isBreach}
            offer={offer.family}
            showSmallerCard={showSmallerCard}
          />
        ) : (
          <DeferredPanel currentSubscription={currentSubscription} />
        )}
      </BreachContainer>
    </InViewComponent>
  );
};

BreachProductOptionCard.propTypes = {
  showSmallerCard: PropTypes.bool.isRequired,
  currentSubscription: PropTypes.shape({
    family: PropTypes.string,
    breachDefermentInfo: PropTypes.shape({
      family: PropTypes.string,
    }),
  }).isRequired,
  offer: PropTypes.shape({
    family: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
};

export default BreachProductOptionCard;
