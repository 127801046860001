import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import PropTypes from 'prop-types';
import { breakpoints } from '../ModalUpsell.styles';

const Box = styled.div`
  background: #fff;
  color: ${props => (props.isInactive ? '#b9baba' : '#575756')};
  font-size: ${rem(12)};
  line-height: ${props => (props.isInactive ? `${rem(14)}` : `${rem(10)}`)};
  box-shadow: 0 ${rem(2)} ${rem(9)} rgba(0, 0, 0, 0.08);
  border-radius: ${rem(3)};
  padding: ${rem(12)} ${rem(10)};
  display: flex;
  align-items: center;
  + div {
    margin-top: ${rem(4)};
  }
  svg {
    margin-right: ${rem(6)};
  }

  @media only screen and (min-width: ${breakpoints.mobile}) {
    font-size: ${rem(9)};
    padding: ${rem(8)} ${rem(10)};
  }
`;

const PostiveSvgIcon = ({ isInactive }) =>
  isInactive ? (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>Score influencer positive icon</title>
      <circle cx="9.81641" cy="9.37109" r="8.25" stroke="#B3BECC" strokeWidth="1.5" />
      <path d="M5.31641 9.37109L8.31641 12.3711L14.3164 6.37109" stroke="#B3BECC" strokeWidth="1.5" />
    </svg>
  ) : (
    <svg width="13" height="13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>Score influencer positive icon</title>
      <circle cx="6.508" cy="6.369" r="5.838" stroke="#00AA4B" strokeWidth="1.062" />
      <path d="m3.323 6.37 2.123 2.122 4.246-4.246" stroke="#00AA4B" strokeWidth="1.062" />
    </svg>
  );

const NegativeSvgIcon = ({ isInactive = false }) =>
  isInactive ? (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>Score influencer negative icon</title>
      <path
        d="M10.4284 7.12109L10.3314 12.5613H9.29856L9.20162 7.12109H10.4284ZM9.81312 14.8283C9.60929 14.8283 9.43404 14.7562 9.28738 14.612C9.14071 14.4654 9.06738 14.2889 9.06738 14.0826C9.06738 13.8787 9.14071 13.7047 9.28738 13.5605C9.43404 13.4139 9.60929 13.3406 9.81312 13.3406C10.017 13.3406 10.1922 13.4139 10.3389 13.5605C10.4855 13.7047 10.5589 13.8787 10.5589 14.0826C10.5589 14.2889 10.4855 14.4654 10.3389 14.612C10.1922 14.7562 10.017 14.8283 9.81312 14.8283Z"
        fill="#B3BECC"
      />
      <path
        d="M17.1319 16.8711H2.50095C2.31118 16.8711 2.1906 16.668 2.28147 16.5014L9.59693 3.08971C9.69169 2.91599 9.94113 2.916 10.0359 3.08971L17.3513 16.5014C17.4422 16.668 17.3216 16.8711 17.1319 16.8711Z"
        stroke="#B3BECC"
        strokeWidth="1.5"
      />
    </svg>
  ) : (
    <svg width="13" height="13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>Score influencer negative icon</title>
      <path
        d="m6.94 4.777-.068 3.85h-.73l-.07-3.85h.869Zm-.435 5.454a.512.512 0 0 1-.372-.153.51.51 0 0 1-.155-.374.5.5 0 0 1 .155-.37.508.508 0 0 1 .372-.156c.145 0 .269.052.372.156a.5.5 0 0 1 .156.37.51.51 0 0 1-.156.374.512.512 0 0 1-.372.153Z"
        fill="#E21C77"
      />
      <path
        d="M11.685 11.677H1.33a.177.177 0 0 1-.156-.262l5.177-9.491a.177.177 0 0 1 .311 0l5.177 9.491a.177.177 0 0 1-.155.262Z"
        stroke="#E21C77"
        strokeWidth="1.062"
      />
    </svg>
  );

const CEScoreInfluencers = ({ isInactive = false }) => (
  <React.Fragment>
    <Box className="positive" isInactive={isInactive}>
      <PostiveSvgIcon isInactive={isInactive} />
      Positive
    </Box>
    <Box className="negative" isInactive={isInactive}>
      <NegativeSvgIcon isInactive={isInactive} />
      Negative
    </Box>
  </React.Fragment>
);

PostiveSvgIcon.defaultProps = {
  isInactive: undefined,
};

NegativeSvgIcon.defaultProps = {
  isInactive: undefined,
};

CEScoreInfluencers.defaultProps = {
  isInactive: undefined,
};

PostiveSvgIcon.propTypes = {
  isInactive: PropTypes.bool,
};

NegativeSvgIcon.propTypes = {
  isInactive: PropTypes.bool,
};

CEScoreInfluencers.propTypes = {
  isInactive: PropTypes.bool,
};

export default CEScoreInfluencers;
