import validExpiryDate from './validExpiryDate';
import { defined } from '../../helpers/defined';
import { cardTypes } from '../../constants';

const validateCardExpiryDate = values => {
  const errors = {};
  const { creditCardType: cardType, expirationMonth, expirationYear } = values;
  if (!defined('string', expirationMonth)) {
    errors.expirationMonth = 'required';
  }
  if (!defined('string', expirationYear)) {
    errors.expirationYear = 'required';
  }
  if (!validExpiryDate(expirationMonth, expirationYear)) {
    errors.expirationMonth = 'format';
  }
  if (!defined('string', cardType) || !cardTypes.includes(cardType.toLowerCase())) {
    errors.creditCardNumber = 'format';
  }
  return errors;
};

export default validateCardExpiryDate;
