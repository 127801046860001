import { type as UPDATE_ECD } from '../actions/ecd/update';
import { type as DELETE_ECD } from '../actions/ecd/delete';
import { type as ADD_EVENT } from '../actions/ecd/addEvent';
import { type as CLEAR_EVENT } from '../actions/ecd/clearPageEvents';

export const defaultState = {
  events: {},
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case ADD_EVENT:
      if (!(action.meta.page in state.events)) {
        state.events[action.meta.page] = []; // eslint-disable-line no-param-reassign
      }

      return {
        ...state,
        events: {
          ...state.events,
          [action.meta.page]: [...state.events[action.meta.page], action.payload],
        },
      };
    case CLEAR_EVENT: {
      const events = { ...state.events };

      delete events[action.payload];

      return {
        ...state,
        events,
      };
    }
    case UPDATE_ECD:
      return {
        ...state,
        ...action.payload,
      };
    case DELETE_ECD:
      return Object.keys(state)
        .filter(key => !action.payload.includes(key))
        .reduce(
          (acc, cur) => ({
            ...acc,
            [cur]: state[cur],
          }),
          {}
        );
    default:
      return state;
  }
};
