import { SELECT_OFFER } from '../actions/offers/select';

const defaultState = '';

export default (state = defaultState, action = {}) => {
  if (action.error) {
    return state;
  }

  switch (action.type) {
    case SELECT_OFFER:
      return action.payload;
    default:
      return state;
  }
};
