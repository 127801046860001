import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { StyledStepMessage, StyledTrialMessage, StyledContainer, StyledFormWrapper } from './UpsellForm.style';

import Header from './upsellFormComponents/Header';
import PaymentStepsCard from './upsellFormComponents/PaymentStepsCard';
import ModalTransitionWrapper from '../../../ModalTransitionWrapper/';
import WhyProvidePaymentDetailsModal from './upsellFormComponents/WhyProvidePaymentDetailsModal';
import PaymentRadioSwitch from './upsellFormComponents/PaymentRadioSwitch';
import FullCardForm from './upsellFormComponents/FullCardForm';
import ReviewYourOrder from './upsellFormComponents/ReviewYourOrder';

export const StepOneMessage = ({ setIsModalOpen, offerFreeTrial }) => (
  <div>
    <StyledStepMessage>Your payment details</StyledStepMessage>
    {offerFreeTrial && (
      <StyledTrialMessage onClick={() => setIsModalOpen(true)}>
        Why do we need this for a free trial?
      </StyledTrialMessage>
    )}
  </div>
);

const StepTwoMessage = () => <StyledStepMessage>Review your order</StyledStepMessage>;

const UpsellForm = ({
  testExperienceData: {
    savedCard,
    useSavedCard,
    hasSavedCard,
    handleRadioSwitch,
    setCvv,
    trial,
    priceInfo: { price, trialDuration },
    submitFunction,
  },
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <StyledContainer>
      <Header offerFreeTrial={trial} price={price} trialDuration={trialDuration} />
      <PaymentStepsCard
        stepNumber={1}
        stepMessage={<StepOneMessage setIsModalOpen={setIsModalOpen} offerFreeTrial={trial} />}
      >
        {hasSavedCard && (
          <PaymentRadioSwitch
            savedCardDetails={savedCard}
            useSavedCard={useSavedCard}
            handleRadioSwitch={handleRadioSwitch}
            setCvv={setCvv}
          />
        )}
        {!hasSavedCard && (
          <StyledFormWrapper>
            <FullCardForm setCvv={setCvv} />
          </StyledFormWrapper>
        )}
      </PaymentStepsCard>

      <PaymentStepsCard stepNumber={2} stepMessage={StepTwoMessage()}>
        <ReviewYourOrder
          price={price}
          submitFunction={submitFunction}
          offerFreeTrial={trial}
          trialDuration={trialDuration}
        />
      </PaymentStepsCard>

      {isModalOpen && (
        <ModalTransitionWrapper setIsModalOpen={setIsModalOpen} positionFromTop="50%">
          <WhyProvidePaymentDetailsModal />
        </ModalTransitionWrapper>
      )}
    </StyledContainer>
  );
};

UpsellForm.propTypes = {
  testExperienceData: PropTypes.shape({
    savedCard: PropTypes.shape({
      billingProviderPaymentMethodId: PropTypes.string,
      cardNumberEnding: PropTypes.string,
      creditCardNumber: PropTypes.string,
      creditCardType: PropTypes.string,
      cvv: PropTypes.number,
      expirationMonth: PropTypes.number,
      expirationYear: PropTypes.number,
      isDefaultCard: PropTypes.bool,
      nameOnCreditCard: PropTypes.string,
      source: PropTypes.string,
    }).isRequired,
    useSavedCard: PropTypes.bool.isRequired,
    hasSavedCard: PropTypes.bool,
    handleRadioSwitch: PropTypes.func.isRequired,
    setCvv: PropTypes.func.isRequired,
    trial: PropTypes.bool.isRequired,
    priceInfo: PropTypes.shape({
      price: PropTypes.number,
      trialDuration: PropTypes.number,
    }).isRequired,
    submitFunction: PropTypes.func.isRequired,
  }).isRequired,
};

StepOneMessage.propTypes = {
  setIsModalOpen: PropTypes.func.isRequired,
  offerFreeTrial: PropTypes.bool.isRequired,
};

export default UpsellForm;
