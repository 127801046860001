import Analytics, { MilestoneInputEvent } from '@experian-uk/web-common-analytics';
import fetch from '../../actions/fetch';
import { verifyChallengeStatus } from './verifyChallengeStatus';

const challengeStatusRoute = 'challengestatus';

const submitMilestoneECD = milestone => {
  Analytics.publishOnce(MilestoneInputEvent.fromObject(milestone));
};

const getChallengeStatus = async (billingUrl, dispatch) => {
  try {
    const statusRes = await dispatch(
      fetch(`/${billingUrl}/${challengeStatusRoute}`, {
        method: 'GET',
      })
    );
    return statusRes.data[0].status;
  } catch {
    return 'ServiceError';
  }
};

// eslint-disable-next-line no-promise-executor-return
const awaitableTimeout = n => new Promise(resolve => setTimeout(resolve, n));

const getStatusWithDelay = async (billingUrl, dispatch, pollingInterval) => {
  await awaitableTimeout(pollingInterval);
  return getChallengeStatus(billingUrl, dispatch);
};

export const resolveChallengeStatus = async (billingUrl, dispatch, maxTimeout, pollingInterval) => {
  window.verifyChallengeTimeoutBreaker = false;
  window.challengeCancelledPollingBreaker = false;

  const startTime = new Date();
  let status = 'Pending';
  // Repeatedly call until response is not pending or timeout is reached
  // Disable the lint rule that protects against functionality we want in this case
  /* eslint-disable no-await-in-loop */
  while (status === 'Pending') {
    // Trigger error message and stop polling if verifychallengestatus fails
    if (window.verifyChallengeTimeoutBreaker === true) {
      return 'Fail';
    }

    if (window.challengeCancelledPollingBreaker === true) {
      return 'Stopped';
    }

    if (new Date() - startTime > maxTimeout) {
      submitMilestoneECD({ product_movement_3ds_timeout: {} });
      const response = await verifyChallengeStatus()(dispatch);
      const event = response ? { product_movement_3ds_timeout_success: {} } : { product_movement_3ds_timeout_fail: {} };
      submitMilestoneECD(event);
      return response ? 'Success' : 'Fail';
    }

    status = await getStatusWithDelay(billingUrl, dispatch, pollingInterval);

    if (status !== 'Pending') {
      return status;
    }
  }

  return 'ServiceError';
};
