import { benefitsDocument, products } from '../../../constants';

const productData = [
  {
    productKey: products.credit_expert,
    productInfo:
      'CreditExpert puts the latest and most accurate credit information at your fingertips. Check your daily Experian Credit Report, get important alerts to certain changes, and use our tools to improve and maintain your Experian Credit Score.',
    readMoreContent:
      'Our premium subscription, CreditExpert includes all the features from Identity Plus and the free Experian account.',
    pdfDownloadLink: `${benefitsDocument.baseUrl}/${benefitsDocument[products.credit_expert]}`,
  },
  {
    productKey: products.identity,
    productInfo:
      'Identity Plus helps protect you from fraud and identity theft by monitoring your details for suspicious activity. Check your Experian Fraud Report and get alerted about suspicious activity.',
    readMoreContent:
      'Web monitoring scans the internet for your personal information and Experian Credit Report monitoring looks for potentially fraudulent activity on your credit report. With enhanced fraud support if you do become a victim of fraud.',
    buttonLabel: 'Identity Plus',
    pdfDownloadLink: `${benefitsDocument.baseUrl}/${benefitsDocument[products.identity]}`,
  },
  {
    productKey: products.basic,
    productInfo:
      'Free for everyone who joins Experian, see your Experian Credit Score updated every 30 days, if you log in. And, see if you could boost your score with Experian Boost by securely connecting your current account to your Experian account.',
    readMoreContent:
      'Compare products like credit cards and personal loans to see the ones you’re more likely to be accepted for before you apply.',
    pdfDownloadLink: `${benefitsDocument.baseUrl}/${benefitsDocument[products.basic]}`,
  },
];

export default productData;
