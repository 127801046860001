import styled from 'styled-components';
import { Button, Typography, Link } from '@experian-uk/corvetteuk-common-ui';
import { rem } from 'polished';

const { Copy, Headings } = Typography;

export const DDCopy = styled(Copy.Text)`
  color: #575757;
  font-size: ${rem(16)};
  line-height: ${rem(24)};
  margin: 0 0 1.5rem 0;
  font-weight: 400;
`;
export const ContentHeading = styled(Headings.Secondary)`
  color: #1d4f91;
  font-weight: 500;
`;

export const Container = styled.div`
  padding: 1.5rem;
  border-radius: 0.25rem;
  background-color: white;
  box-shadow: 0 0.25rem 0.5rem rgb(0 37 86 / 2%);
  margin: ${rem(10)};
  text-align: left;
`;

export const ConfirmButton = styled(Button)`
  background-color: rgb(216, 43, 128);
  border: none;
  ${({ disabled }) =>
    disabled &&
    `
    background-color: #EEEEEE;
    color: #797979;
  `}
`;

export const EditDetailsButton = styled(Button)`
  color: #426da9;
`;

export const DetailsContainer = styled.dl`
  margin: 0;
`;

export const DetailLabel = styled.dt`
  color: #63666a;
  margin: 0 0 0.3rem 0;
  font-size: ${rem(16)};
  line-height: ${rem(24)};
  font-weight: 500;
`;
export const DetailValue = styled.dd`
  color: #575757;
  font-size: ${rem(18)};
  line-height: ${rem(28)};
  margin: 0 0 1.5rem 0;
  font-weight: 500;
`;

export const InlineLinkAnchor = styled(Link.Anchor).attrs({
  target: '_blank',
})`
  font-size: inherit;
`;

export const StyledParagraph = styled(Copy.SmallText)`
  font-size: ${rem(12)};
  color: #63666a;
  line-height: ${rem(18)};
  font-weight: 300;
  margin: 0 0 1.2rem 0;
`;

export const Divider = styled.hr`
  border-top: 1px solid #dddddd;
  border-bottom: none;
  margin: 1.5rem 0;
`;

export const ConfirmForm = styled.form`
  display: grid;
`;

export const DirectDebitOverlayTrigger = styled.button`
  background: none;
  border: none;
  padding: 0;
  color: #0058e6;
  font-size: ${rem(16)};
  cursor: pointer;
  line-height: ${rem(24)};
`;
