import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Typography } from '@experian-uk/corvetteuk-common-ui';

import Container from '../../../components/Container';
import CustomUser from './customUser';
import RealUser from './realUser';
import { selectPage } from '../../../reducers/uiState';
import changeStateAction from '../../../actions/uiChangeState';
import { tabKeys } from '../constants';

const { Headings } = Typography;
const changeState = changeStateAction.bind(null, 'login');

@connect(
  state => ({
    ui: selectPage(state.uiState, 'login'),
  }),
  dispatch => bindActionCreators({ changeState }, dispatch)
)
export default class LoginPage extends React.Component {
  static propTypes = {
    changeState: PropTypes.func.isRequired,
    ui: PropTypes.shape({
      activeTab: PropTypes.string,
    }),
  };

  static defaultProps = {
    ui: {},
  };

  componentDidMount() {
    this.props.changeState({
      activeTab: tabKeys.mockUser,
    });
  }

  render() {
    const { ui } = this.props;
    const { activeTab } = ui;

    return (
      <Container as="main" id="main">
        <Headings.Primary>Login</Headings.Primary>
        {activeTab === tabKeys.mockUser && <CustomUser />}
        {activeTab === tabKeys.realUser && <RealUser />}
      </Container>
    );
  }
}
