import styled from 'styled-components';

export default styled.div`
  border: 1px ${props => props.theme.colors.baseMediumGrey} solid;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 ${props => props.theme.colors.baseMediumGrey};
  color: ${props => props.theme.colors.baseDarkGrey};
  line-height: 1.4;
  margin: 1.875rem 0;
  font-size: 1.2rem;
`;
