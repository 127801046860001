import React from 'react';
import { reduxForm } from 'redux-form';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';

import CardNumberField from './formFields/CardNumberField';
import NameField from './formFields/NameField';
import DateFieldContainer from './DateFieldContainer';
import CvvNumberField from './formFields/CvvNumberField';

const StyledFormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
`;

const FullCardForm = ({ change, setCvv }) => (
  <StyledFormWrapper method="post">
    <CardNumberField change={change} />
    <NameField />
    <DateFieldContainer />
    <CvvNumberField dataAutomationTestElement="cvv-full-card-form" setCvv={setCvv} />
  </StyledFormWrapper>
);

export default reduxForm({
  form: 'productMovement/newCard',
  onSubmit: () => {},
})(FullCardForm);

FullCardForm.propTypes = {
  change: PropTypes.func.isRequired,
  setCvv: PropTypes.func,
};

FullCardForm.defaultProps = {
  setCvv: undefined,
};
