import { upgradeTypes } from '../../helpers';
import { products } from '../../constants';

const identityMessage = options =>
  `You’re${
    options.movementType === upgradeTypes.downgrade ? ' still' : ''
  } safeguarding your security. Identity Plus helps protect you from fraud by monitoring your details and alerting you to suspicious activity.`;

const message = options => {
  if (options.moveToBreach) {
    return 'You’re still safeguarding your security. Identity Plus helps protect you from fraud by monitoring your details and alerting you to suspicious activity.';
  }
  if (options.moveToBreachPlus) {
    return 'You’re still safeguarding your security and are now able to see your updated credit score every 30 days. Identity Plus helps protect you from fraud by monitoring your details and alerting you to suspicious activity.';
  }

  return identityMessage(options);
};

const messaging = {
  thankYouMessage: options => ({
    [products.credit_expert]: `We’ve got you covered. All your credit details in one place, important alerts and tailored tips will help you get the best out of your score.`,
    [products.identity]: message(options),
    [products.basic]: '',
  }),
  subscriptionConfirmation: {
    default: 'You are now subscribed to',
    deferred: 'You are changing your subscription to',
  },
};

export default messaging;
