import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTheme } from 'styled-components';
import { Typography, setAutomationElement, Breadcrumbs } from '@experian-uk/corvetteuk-common-ui';
import Analytics from '@experian-uk/web-common-analytics';
import { subscriptionSchema } from '../../schemas';
import { getFreeTrialData } from '../../helpers/subscription';
import { getAvailableOffers } from '../../reducers/offers';
import isServer from '../../helpers/isServer';
import { matchDate } from '../../helpers';
import { products } from '../../constants';
import getAllOffers from '../../actions/offers/getAll';
import IDPlusPhonesIMG from './assets/IDPlusPhones.png';
import IDPlusPadlockIMG from './assets/icon-padlock.svg';
import CEMagnifyingGlassIMG from './assets/icon-magnify.svg';
import {
  SectionTitle,
  HeaderContainer,
  FeatureImg,
  ProductOverview,
  ProductImage,
  ExperianUsersContainer,
  Triangle,
  ProductTrialContainer,
  Row,
  ProductPanelsContainer,
  HideOverflow,
  ProductOverviewContainer,
  HeaderTextWrapper,
  BoldText,
  ResponsiveBr,
} from './index.styles';
import { getReturnUrl } from './utils/returnUrlUtils';
import ProductPanels from '../../components/ProductPanels';

const { Headings, Copy } = Typography;

@withTheme
@connect(
  state => ({
    customer: state.auth.currentUser,
    currentSubscription: state.subscriptions.current,
    offers: getAvailableOffers(state.offers, state.subscriptions),
    billing: state.billing,
    billingDate: state.billing.nextBillingDate,
  }),
  dispatch =>
    bindActionCreators(
      {
        getAllOffers,
      },
      dispatch
    )
)
class WhichProduct extends React.PureComponent {
  static propTypes = {
    offers: PropTypes.arrayOf(PropTypes.shape({})),
    currentSubscription: PropTypes.shape(subscriptionSchema),
    getAllOffers: PropTypes.func.isRequired,
    billing: PropTypes.shape({
      authorized: PropTypes.bool,
      freeTrialTerms: PropTypes.shape({}),
      nextBillingDate: PropTypes.string,
    }).isRequired,
    customer: PropTypes.shape({
      customerId: PropTypes.string,
    }),
    whitelistedDomains: PropTypes.arrayOf(PropTypes.string),
    match: PropTypes.shape({
      params: PropTypes.shape({
        returnUrl: PropTypes.string,
      }),
    }).isRequired,
  };

  static defaultProps = {
    currentSubscription: {},
    offers: [],
    customer: {},
    whitelistedDomains: ['experiancs.co.uk', 'experian.co.uk', 'experian.com', 'creditexpert.co.uk'],
  };

  componentDidMount() {
    this.props.getAllOffers();

    // eslint-disable-next-line func-names
    (async function () {
      if (!isServer()) {
        await Analytics.init({}, 'ProdMoveOurProducts');
      }
    })();
  }

  render() {
    const { currentSubscription, customer, offers } = this.props;

    const { freeTrialTerms, nextBillingDate } = this.props.billing;
    const { defermentInfo, freeTrial, freeTrialEndDt } = currentSubscription;
    const billingDateToday = currentSubscription.family !== products.basic && matchDate(nextBillingDate);
    const switchPermitted = !defermentInfo && !billingDateToday;
    const customerDataLoaded = !!customer.customerId;
    const freeTrialData =
      freeTrial && freeTrialTerms && freeTrialEndDt ? getFreeTrialData(freeTrialTerms, freeTrialEndDt) : {};
    const returnUrl = getReturnUrl(this.props.match.params.returnUrl, this.props.whitelistedDomains);

    return (
      <HideOverflow as="main" id="main">
        <HeaderContainer>
          <Row>
            <HeaderTextWrapper>
              <Breadcrumbs
                {...setAutomationElement('breadcrumbs')}
                home={returnUrl}
                steps={[{ text: 'Our products', url: '/our-products' }]}
              />
              <Headings.Primary>Pick the perfect product to get your finances in focus</Headings.Primary>
              <Copy.Text>
                With CreditExpert, alerts will keep you up-to-date while tailored tips help you get the best out of your
                Experian Credit Score, or you can choose to focus on safeguarding your security with Identity Plus.
              </Copy.Text>
            </HeaderTextWrapper>
            <FeatureImg src={IDPlusPhonesIMG} />
          </Row>
        </HeaderContainer>

        <SectionTitle>
          We can help boost your score
          <br />
          and protect your identity
        </SectionTitle>
        <ProductOverviewContainer>
          <ProductOverview>
            <ProductImage src={CEMagnifyingGlassIMG} />
            <div>
              <Headings.Tertiary>
                Credit<BoldText>Expert</BoldText> is the <ResponsiveBr /> full experience
              </Headings.Tertiary>
              <Copy.Text>
                Including unlimited access to your daily report and score, the Credit Score Builder tool and so much
                more.
              </Copy.Text>
            </div>
          </ProductOverview>
          <ProductOverview>
            <ProductImage src={IDPlusPadlockIMG} />
            <div>
              <Headings.Tertiary>
                Get protected and stay <ResponsiveBr />
                safe with Identity <BoldText>Plus</BoldText>
              </Headings.Tertiary>
              <Copy.Text>
                Helping protect you with Web Monitoring, fraud report, fraud alerts and dedicated experts to support you
                when you need it.
              </Copy.Text>
              <Copy.SmallText>CreditExpert includes all Identity Plus services.</Copy.SmallText>
            </div>
          </ProductOverview>
        </ProductOverviewContainer>

        <ExperianUsersContainer>
          <Headings.Primary>Over 8 million people</Headings.Primary>
          <Copy.Text>
            have used Experian to understand <br /> their finances better.*
          </Copy.Text>
          <Triangle />
        </ExperianUsersContainer>

        <ProductTrialContainer>
          <SectionTitle>Start your free, 30-day trial</SectionTitle>
          <Copy.Text>No commitment. Cancel anytime.</Copy.Text>
        </ProductTrialContainer>

        <ProductPanelsContainer>
          <ProductPanels
            currentSubscription={currentSubscription}
            offers={offers}
            switchPermitted={switchPermitted && customerDataLoaded}
            freeTrial={freeTrialData}
          />
        </ProductPanelsContainer>
      </HideOverflow>
    );
  }
}

export default WhichProduct;
