import React from 'react';
import styled from 'styled-components';
import { setAutomationElement } from '@experian-uk/corvetteuk-common-ui';
import PropTypes from 'prop-types';

import { TextInput } from '../components/Inputs/TextInput';
import { InlineAlert } from '../components/Alerts/Alerts.styles';
import { getDynamicString } from './';

export default Component =>
  // eslint-disable-next-line react/prop-types
  ({ input, ...rest }) =>
    <Component {...rest} {...input} />;

const Input = styled(TextInput)`
  flex: 1;
  width: 100%;
`;

Input.displayName = 'Input';

// Separate implementation required for text Inputs because the generic 'Component' function
// causes re-rendering which means the input will lose focus as soon as the user starts typing
// This is not a problem for other input types such as Selects.
const textInput = ({ automation, errorMessageProps, input, inputProps, meta: { touched, error } }) => (
  <div>
    <Input {...input} {...inputProps} {...setAutomationElement(automation)} />
    {touched && error && (
      <InlineAlert {...setAutomationElement(`${automation}Validation`)}>
        {errorMessageProps && errorMessageProps[error]
          ? getDynamicString(`forms.${error}`, errorMessageProps[error])
          : error}
      </InlineAlert>
    )}
  </div>
);

textInput.propTypes = {
  automation: PropTypes.string,
  errorMessageProps: PropTypes.shape({}),
  input: PropTypes.shape({}),
  inputProps: PropTypes.shape({}),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

textInput.defaultProps = {
  automation: '',
  errorMessageProps: null,
  input: {},
  inputProps: {},
  meta: {
    touched: false,
    error: '',
  },
};

const hiddenInput = ({ input, inputName }) => <input type="hidden" name={inputName} {...input} />;

hiddenInput.propTypes = {
  input: PropTypes.shape({}),
  inputName: PropTypes.shape({}),
};

hiddenInput.defaultProps = {
  input: {},
  inputName: {},
};

export { Input, textInput, hiddenInput };
