import PropTypes from 'prop-types';

export default {
  customerId: PropTypes.string,
  customerNumber: PropTypes.string,
  tenantId: PropTypes.string,
  title: PropTypes.string,
  firstName: PropTypes.string,
  middleName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
};
