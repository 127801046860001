/* eslint-disable max-len */
import moment from 'moment';
import indefinite from 'indefinite';

import { downgradeBenefitGroups, freeAccountBenefits, upgradeBenefitGroups } from './benefits';

import { benefitsDocument, dateFormats, familyName, products } from '../../constants';
import { featureLayout } from '../../components/switch/Feature';

const getBenefitsList = (sourceProduct, targetProduct, isComplimentary) => {
  if (isComplimentary && upgradeBenefitGroups[`${targetProduct}Complimentary`]) {
    return upgradeBenefitGroups[`${targetProduct}Complimentary`];
  }
  if (sourceProduct !== products.basic && !!downgradeBenefitGroups[targetProduct]) {
    return downgradeBenefitGroups[targetProduct];
  }
  const sourceBenefits = upgradeBenefitGroups[sourceProduct];
  const targetBenefits = upgradeBenefitGroups[targetProduct];
  if (!sourceBenefits) {
    return targetBenefits;
  }
  return targetBenefits.filter(benefit => !sourceBenefits.includes(benefit));
};

const getUpgradePricingCopy = (trial, price, targetProduct) =>
  trial
    ? `Free for 30 days, then it’s £${price} ${
        targetProduct === products.identity
          ? 'every month. After your free 30-day trial, you’ll no longer have access to CreditExpert features.'
          : 'every month from then on.'
      }`
    : `Pay £${price} today and monthly from then on.`;

const complimentarySubscriptionTitle = (customer, targetProduct) => ({
  start: `${customer}, you’re changing to a complimentary `,
  product: targetProduct,
  end: 'subscription',
});

export const complimentarySubscriptionSubtitle =
  'You will not be charged for your subscription and it will start once we have verified your voucher code.';
export const removingFreeSubtitle = 'You will not be charged for your subscription';
const freeToComplimentarySubscriptionSubtitle = sourceProduct =>
  `You’ll keep your complimentary ${familyName[sourceProduct]} subscription`;

const linkTextMap = {
  basic: 'your Free account',
};

const getUpgradeSubtitle = (
  voucherCode,
  proRata,
  billingDate,
  trial,
  price,
  targetProduct,
  sourceProduct,
  isBreachUpgradeSplit
) => {
  if (voucherCode) {
    return complimentarySubscriptionSubtitle;
  }
  if (targetProduct === products.basic) {
    return freeToComplimentarySubscriptionSubtitle(sourceProduct);
  }
  if (isBreachUpgradeSplit) {
    return trial
      ? `Free for 30 days, then it’s £${price} ${
          targetProduct === products.identity
            ? 'every month. After your free 30-day trial, you’ll no longer have access to CreditExpert features.'
            : 'every month from then on.'
        }`
      : `Pay £${price} at the end of your complimentary subscription and monthly from then on.`;
  }
  return proRata
    ? `You’ll pay £${proRata} today, then £${price} on ${moment(billingDate).format(
        dateFormats.short
      )} and monthly from then on.`
    : getUpgradePricingCopy(trial, price, targetProduct);
};

const getUpgradeTitle = (customer, targetProduct, trial, voucherCode) => {
  if (voucherCode) {
    return complimentarySubscriptionTitle(customer, targetProduct);
  }
  if (targetProduct === products.basic) {
    return {
      start: `${customer}, you’re adding a `,
      product: targetProduct,
      end: ' account',
    };
  }
  return {
    start: trial ? 'Start your free ' : `${customer}, you’re adding `,
    product: targetProduct,
    end: trial
      ? `${targetProduct === products.identity ? ' trial with complimentary CreditExpert features' : ' trial'}`
      : '',
  };
};

const getUpgradeBenefitsCaption = (sourceProduct, targetProduct, voucherCode) => {
  if (voucherCode || sourceProduct !== products.basic) {
    const isComplimentaryAddOn = targetProduct === products.basic;
    const isComplimentary = voucherCode || isComplimentaryAddOn;
    const productFactsheetKey = `${sourceProduct}${isComplimentaryAddOn ? '_complimentary' : ''}`;
    return {
      start: `You’ll ${isComplimentary ? 'still ' : ''}enjoy all the `,
      end: isComplimentary ? ' and will also receive the following benefits:' : ' but also get:',
      linkText: `benefits of${isComplimentaryAddOn ? ' your complimentary' : ''} ${
        linkTextMap[sourceProduct] || familyName[sourceProduct]
      }${isComplimentaryAddOn ? ' subscription' : ''}`,
      href: `${benefitsDocument.baseUrl}/${benefitsDocument[productFactsheetKey]}`,
    };
  }
  return {};
};

const getDowngradeTitle = (customer, targetProduct, isRemovingFree) => {
  if (isRemovingFree) {
    return {
      start: `${customer}, you’re removing your `,
      product: products.basic,
      end: ' Experian account',
    };
  }
  return {
    start: `${customer}, you’re changing to ${indefinite(familyName[targetProduct]).split(' ')[0]} `,
    product: targetProduct,
    end: ' subscription',
  };
};

const getDowngradeSubtitle = (billingDate, sourceProduct, price, isRemovingFree) => {
  if (isRemovingFree) {
    return removingFreeSubtitle;
  }
  return `You’ll keep ${familyName[sourceProduct]} until ${moment(billingDate).format(
    dateFormats.short
  )} and will then pay £${price} monthly from then on.`;
};

const getDowngradeBenefitsCaption = (targetProduct, isRemovingFree) => {
  const productFactsheetKey = `${targetProduct}${isRemovingFree ? '_complimentary' : ''}`;
  return {
    start: 'You’ll still enjoy all the ',
    end: ` but will lose access to${isRemovingFree ? ' the following benefits' : ''}:`,
    linkText: `benefits of${isRemovingFree ? ' your complimentary' : ''} ${
      linkTextMap[targetProduct] || familyName[targetProduct]
    }${isRemovingFree ? ' subscription' : ''}`,
    href: `${benefitsDocument.baseUrl}/${benefitsDocument[productFactsheetKey]}`,
  };
};

const getCancellationSubtitle = (endDate, isDeferred) => {
  const baseSubtitle = 'You’ll move to a free Experian account';
  if (isDeferred) {
    return `${baseSubtitle}${
      endDate ? ` on ${moment(endDate).format(dateFormats.long)}` : ''
    }. We’ll cancel any future subscription payments.`;
  }
  return `${baseSubtitle}.`;
};

export default {
  upgrade: ({
    sourceProduct,
    targetProduct,
    price,
    customer,
    billingDate,
    proRata,
    trial,
    voucherCode,
    isBreachUpgradeSplit,
  }) => ({
    title: getUpgradeTitle(customer, targetProduct, trial, voucherCode),
    subtitle: getUpgradeSubtitle(
      voucherCode,
      proRata,
      billingDate,
      trial,
      price,
      targetProduct,
      sourceProduct,
      isBreachUpgradeSplit
    ),
    benefits: getBenefitsList(sourceProduct, targetProduct, !!voucherCode),
    benefitsCaption: !isBreachUpgradeSplit
      ? getUpgradeBenefitsCaption(sourceProduct, targetProduct, voucherCode)
      : null,
  }),
  downgrade: ({ sourceProduct, targetProduct, price, customer, billingDate }) => ({
    title: getDowngradeTitle(customer, targetProduct, sourceProduct === products.basic),
    subtitle: getDowngradeSubtitle(billingDate, sourceProduct, price, sourceProduct === products.basic),
    benefits: downgradeBenefitGroups[sourceProduct],
    benefitsCaption: getDowngradeBenefitsCaption(targetProduct, sourceProduct === products.basic),
  }),
  cancel: ({ sourceProduct, endDate, isDeferred, isComplimentary }) => {
    const isCECancellation = sourceProduct === products.credit_expert;
    return {
      title: {
        start: 'You are about to cancel your ',
        product: sourceProduct,
        end: ' subscription',
      },
      subtitle: getCancellationSubtitle(endDate, isDeferred),
      benefits: isCECancellation ? freeAccountBenefits.creditExpertToFree : freeAccountBenefits.basic,
      benefitsCaption: {
        start: 'You’ll no longer enjoy all the ',
        end: isCECancellation ? ':' : ' but will still get:',
        linkText: `benefits ${sourceProduct ? `of ${familyName[sourceProduct]}` : ''}`,
        href: `${benefitsDocument.baseUrl}/${
          benefitsDocument[`${sourceProduct}${isComplimentary ? '_complimentary' : ''}`]
        }`,
      },
      layout: isCECancellation ? featureLayout.double : featureLayout.flex,
    };
  },
};
