import { CALL_HISTORY_METHOD, LOCATION_CHANGE } from 'connected-react-router';
import { SET_SECURE_MODE } from '../actions/app/startSecureMode';

export const LOADING = 'app/LOADING';
export const APP_STARTED = 'app/STARTED';

export const initialState = {
  isLoading: false,
  hasStarted: false,
  isSecurePage: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case APP_STARTED:
      return {
        ...state,
        hasStarted: true,
      };
    case SET_SECURE_MODE:
      return {
        ...state,
        isSecurePage: true,
      };
    case CALL_HISTORY_METHOD:
    case LOCATION_CHANGE:
      return {
        ...state,
        isSecurePage: false,
      };
    case LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    default:
      return state;
  }
};

export const applicationStarted = () => dispatch =>
  dispatch({
    type: APP_STARTED,
    hasStarted: true,
  });

export const setApplicationLoading = isLoading => dispatch =>
  dispatch({
    type: LOADING,
    isLoading,
  });
