import styled from 'styled-components';
import { CVV } from '../Icons/CardSecurity';

export const StyledCVV = styled(CVV)`
  width: 60px;
  height: 37px;

  flex: 1;
  height: 2.6875rem;
  margin: 0 0.5rem;
  padding: 0.2rem 0;
  width: 100%;

  &:hover {
    cursor: pointer;
    transform: scale(2.3);
  }
`;

const ContentContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-grow: 4;
  width: auto;

  ${({ theme: { media } }) =>
    media(
      '10',
      `
     align-items: center;
     left: 90px;
     position: absolute;
     max-width: 250px;
     width: 100%;
  `
    )}

  span {
    flex: 3;
    font-size: 0.75rem;

    ${({ theme: { media } }) =>
      media(
        '15',
        `
      margin-left: -6px;
    `
      )}
  }
`;

ContentContainer.displayName = 'ContentContainer';

export { ContentContainer };

const CVVInputContainer = styled.div`
  align-items: flex-start;
  font-size: 1rem;
  display: flex;
  position: relative;
  width: auto;

  ${({ theme: { media } }) =>
    media(
      '15',
      `
      font-size: 1.25rem;
      max-width: 250px;
      width: 100%;
    `
    )}
`;

CVVInputContainer.displayName = 'CVVInput';

export { CVVInputContainer };

const FieldContainer = styled.div`
  width: auto;

  input {
    width: 90px;
  }
`;

FieldContainer.displayName = 'FieldContainer';

export { FieldContainer };
