import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { StyledFormWrapper } from '../UpsellForm.style';
import CvvForm from './CvvForm';
import FullCardForm from './FullCardForm';
import SavedCardDetailsLabel from './SavedCardDetailsLabel';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

const StyledRadioContainer = styled.div`
  position: relative;
  width: 100%;
  height: ${({ checked }) => (checked ? 'fit-contents' : '24px')};
  .input {
    z-index: 2;
    cursor: pointer;
    opacity: 0;
  }

  .input,
  .checkmark,
  .placeholder {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    margin: 0;
  }

  .placeholder {
    border: 1px solid #1d4f91;
  }

  p {
    font-size: 16px;
    margin: 0;
  }

  .label {
    padding-left: 36px;
    min-height: 24px;
  }
`;

const StyledHideForm = styled.div`
  width: 100%;
  margin-top: 16px;
  display: ${({ visible }) => (visible ? 'block' : 'none')};
`;

const BulletPoint = styled.div`
  width: 24px;
  position: absolute;
  left: 0;
  height: 24px;
  border-radius: 50%;
  border: solid 2px #1d4f91;
  background-color: #ffffff;
  display: ${({ checked }) => (checked ? 'block' : 'none')};
  &::before {
    content: '';
    display: block;
    border-radius: 50%;
    left: 50%;
    width: 16px;
    height: 16px;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #26478d;
  }
`;

const StyledUseDifferentCardLabel = styled.div`
  display: flex;
  min-height: 24px;
  align-items: center;
  font-size: 1rem;
  font-weight: 200;
  color: #63666a;
`;

const PaymentRadioSwitch = ({ savedCardDetails, useSavedCard, handleRadioSwitch, setCvv }) => (
  <StyledContainer data-automation-test-element="payment-radio-switch">
    <StyledFormWrapper>
      <StyledRadioContainer checked={useSavedCard}>
        <input
          className="input"
          data-automation-test-element="use-saved-card"
          type="radio"
          value={{ usingSavedCard: useSavedCard }}
          checked={useSavedCard}
          onChange={handleRadioSwitch}
        />
        <BulletPoint checked={useSavedCard} />
        <span className="placeholder" />
        <div className="label">
          <SavedCardDetailsLabel savedCardDetails={savedCardDetails} />
        </div>
        <StyledHideForm visible={useSavedCard} data-automation-test-element="saved-card-form-hider">
          <CvvForm setCvv={setCvv} />
        </StyledHideForm>
      </StyledRadioContainer>
    </StyledFormWrapper>
    <StyledFormWrapper>
      <StyledRadioContainer checked={!useSavedCard}>
        <input
          className="input"
          data-automation-test-element="use-different-card"
          type="radio"
          value={{ usingSavedCard: useSavedCard }}
          checked={!useSavedCard}
          onChange={handleRadioSwitch}
        />
        <BulletPoint checked={!useSavedCard} />
        <span className="placeholder" />
        <StyledUseDifferentCardLabel className="label">
          <p>Use a different card</p>
        </StyledUseDifferentCardLabel>
        <StyledHideForm visible={!useSavedCard} data-automation-test-element="full-card-form-hider">
          <FullCardForm setCvv={setCvv} />
        </StyledHideForm>
      </StyledRadioContainer>
    </StyledFormWrapper>
  </StyledContainer>
);

PaymentRadioSwitch.propTypes = {
  savedCardDetails: PropTypes.shape({
    billingProviderPaymentMethodId: PropTypes.string,
    cardNumberEnding: PropTypes.string,
    creditCardNumber: PropTypes.string,
    creditCardType: PropTypes.string,
    cvv: PropTypes.number,
    expirationMonth: PropTypes.number,
    expirationYear: PropTypes.number,
    isDefaultCard: PropTypes.bool,
    nameOnCreditCard: PropTypes.string,
    source: PropTypes.string,
  }),
  useSavedCard: PropTypes.bool.isRequired,
  handleRadioSwitch: PropTypes.func.isRequired,
  setCvv: PropTypes.func.isRequired,
};

PaymentRadioSwitch.defaultProps = {
  savedCardDetails: undefined,
};

export default PaymentRadioSwitch;
