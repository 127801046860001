import { products } from '../constants';
import { getEnv } from '../components/Context/env';

export default (currentSubscription, freeTrial, newSignUp = false) => {
  const env = getEnv();

  if (currentSubscription === products.credit_expert || freeTrial) {
    return env.REACT_APP_INS_URL;
  }

  if ([products.breach, products.breachPlus, products.identity].includes(currentSubscription)) {
    return `${env.REACT_APP_IDENTITY_URL}${newSignUp ? '/?newSignUp=1' : ''}`;
  }

  return env.REACT_APP_C2_URL;
};
