import React from 'react';
import PropTypes from 'prop-types';
import { TitleMedium500 } from '@nebula/global-styles';
import { getCardLogo } from '../../../../helpers/billing';
import icons from '../../../../components/Icons';
import { cardDetailsSchema } from '../../../../schemas';
import { CardImg, CardLabel, CardValue } from './currentPaymentMethod.styles';
import { Detail, DetailValue } from '../paymentInformation.styles';
import getVendorInfo from '../../../../helpers/billing/getVendorInfo';

export const CardDetails = ({ cardType, endingNumbers, expiryDate }) => {
  const cardLogo = getCardLogo(cardType.toLowerCase(), icons.cardLogos, 1);

  return (
    <React.Fragment>
      <Detail>
        <CardLabel>Card type:</CardLabel>
        <CardImg data-testid="Card logo" src={cardLogo} alt={cardType} />
      </Detail>
      <Detail>
        <CardLabel>Card ending:</CardLabel>
        <CardValue data-testid="Card ending">{endingNumbers}</CardValue>
      </Detail>
      <Detail>
        <CardLabel>Expires:</CardLabel>
        <CardValue data-testid="Expires">{expiryDate}</CardValue>
      </Detail>
    </React.Fragment>
  );
};
CardDetails.propTypes = {
  cardType: cardDetailsSchema.creditCardType.isRequired,
  endingNumbers: PropTypes.string.isRequired,
  expiryDate: PropTypes.string.isRequired,
};

export const DirectDebitDetails = ({ accountNumber }) => (
  <React.Fragment>
    <Detail>
      <TitleMedium500>Payment method:</TitleMedium500>
      <DetailValue>Direct debit</DetailValue>
    </Detail>
    <Detail>
      <TitleMedium500>Debit details:</TitleMedium500>
      <DetailValue data-testid="Debit details">{accountNumber}</DetailValue>
    </Detail>
    <p>Payments powered by GoCardless.</p>
  </React.Fragment>
);
DirectDebitDetails.propTypes = {
  accountNumber: PropTypes.string.isRequired,
};

export const MobilePayDetails = ({ vendor }) => {
  const { Logo } = getVendorInfo(vendor) || {};

  if (!Logo) {
    return null;
  }

  return (
    <Detail>
      <TitleMedium500>Payment method:</TitleMedium500>
      <Logo data-testid="paymentMethodLogo" aria-hidden />
    </Detail>
  );
};
MobilePayDetails.propTypes = {
  vendor: PropTypes.string.isRequired,
};
