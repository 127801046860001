import Analytics, { InteractionInputEvent, MilestoneInputEvent } from '@experian-uk/web-common-analytics';

import isServer from '../../helpers/isServer';

const onSwitchPageLoadECD = (isWithinFreeTrial, isPendingCancellation, daysLeftOfTrialInCurrentProductSubscription) => {
  if (isServer()) return;

  window.$ECD2 = window.$ECD2 || [];

  const onCreditExpertSwitchPageLoad = MilestoneInputEvent.fromObject({
    product_movement_intent: {
      family: 'credit_expert',
      productSubscriptionLookingToMoveTo: 'basic',
      isWithinFreeTrial,
      isPendingCancellation,
      daysLeftOfTrialInCurrentProductSubscription,
    },
  });

  Analytics.publishOnce(onCreditExpertSwitchPageLoad);
};

const submitInteractionECD = (action, component, status) => {
  Analytics.publishOnce(
    InteractionInputEvent.fromObject({
      component,
      action,
      status,
    })
  );
};

const submitSwitchPageClickECD = componentName => {
  submitInteractionECD('click', componentName, 'success');
};

export { onSwitchPageLoadECD, submitSwitchPageClickECD, submitInteractionECD };
