import moment from 'moment';

export default (numberOfYears = 20, maxYear = moment().year()) =>
  [...Array(numberOfYears)].map((_, index) => {
    const year = maxYear + index;
    return {
      key: year,
      description: year,
    };
  });
