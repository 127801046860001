import React from 'react';
import PropTypes from 'prop-types';
import { setAutomationElement } from '@experian-uk/corvetteuk-common-ui';
import { CheckboxContainer } from './Checkbox.styles';

const Checkbox = ({ checked, id, label, onChange, className }) => (
  <CheckboxContainer {...setAutomationElement(id)} className={className} htmlFor={id}>
    {label}
    <input type="checkbox" id={id} checked={checked} onChange={onChange} />
    <span className="checkmark" />
  </CheckboxContainer>
);

Checkbox.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

Checkbox.defaultProps = {
  className: '',
};

export default Checkbox;
