import React from 'react';
import PropTypes from 'prop-types';
import { StandardJourneyAction, CancelActionJourney } from './possibleOutcomes';

const ActionDisplay = ({
  isFree,
  isBreachPlus = false,
  isCoolingOffEligible,
  switchPermitted,
  idCeFreeTrial,
  type,
  label,
  onClick,
  isCurrentSubscription,
  decisionMap,
}) => {
  const shouldDoNothing = [true];
  const shouldUseStandardJourney = [
    !isCoolingOffEligible || !isCurrentSubscription,
    switchPermitted,
    idCeFreeTrial || !isCurrentSubscription,
    isBreachPlus || !isCurrentSubscription,
  ];
  const shouldUseImmediateCancel = [!isFree, isCoolingOffEligible, switchPermitted];

  const decider =
    decisionMap ||
    new Map([
      [shouldUseImmediateCancel, () => <CancelActionJourney type={type} label={label} />],
      [shouldUseStandardJourney, () => <StandardJourneyAction type={type} onClick={onClick} label={label} />],
      [shouldDoNothing, () => null],
    ]);

  const finalComponent = Array.from(decider.entries()).find(([condition]) => condition.every(x => x))?.[1];

  return finalComponent ? finalComponent() : null;
};

ActionDisplay.propTypes = {
  isBreachPlus: PropTypes.bool,
  isFree: PropTypes.bool.isRequired,
  isCoolingOffEligible: PropTypes.bool.isRequired,
  switchPermitted: PropTypes.bool.isRequired,
  idCeFreeTrial: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isCurrentSubscription: PropTypes.bool.isRequired,
  decisionMap: PropTypes.instanceOf(Map),
};

ActionDisplay.defaultProps = {
  isBreachPlus: false,
  decisionMap: null,
};

export default ActionDisplay;
