import psl from 'psl';

import { getEnv } from '../../../components/Context/env';

export const conditionalLog = (msg, environment = 'development') => {
  if (getEnv().NODE_ENV === environment) {
    // eslint-disable-next-line no-console
    console.log(msg);
  }
};

export const encodeUrl = urlString => {
  const urlEncoded = encodeURIComponent(urlString);
  const base64Encoded = btoa(urlEncoded);
  return base64Encoded;
};

export const decodeUrl = urlString => {
  const base64Decoded = atob(urlString);
  const urlDecoded = decodeURIComponent(base64Decoded);
  return urlDecoded;
};

export const isReturnUrlValid = (url, whitelistedDomains) => {
  if (!whitelistedDomains || whitelistedDomains.length === 0) {
    conditionalLog('No whitelist was provided');
    return false;
  }

  let domain;
  try {
    const parsedUrl = new URL(url);
    const parsedDomain = psl.parse(parsedUrl.host);
    domain = parsedDomain.domain;
  } catch (error) {
    conditionalLog(`The url ${url} could not be parsed. The protocol might be missing.\nInner exception: ${error}`);
    return false;
  }

  const isWhitelisted = whitelistedDomains.includes(domain);

  if (!isWhitelisted) {
    conditionalLog(`The domain ${domain} is not allowed to be used as a ReturnUrl.`);
    return false;
  }

  return true;
};

export const getReturnUrl = (encodedReturnUrl, whitelistedDomains) => {
  const fallbackUrl = new URL(window.location.origin).href;
  if (!encodedReturnUrl) {
    return fallbackUrl;
  }

  let decodedReturnUrl;
  try {
    decodedReturnUrl = decodeUrl(encodedReturnUrl);
  } catch (error) {
    conditionalLog('ReturnUrl is not base64 encoded');
    return fallbackUrl;
  }

  const isValid = isReturnUrlValid(decodedReturnUrl, whitelistedDomains);

  if (!isValid) {
    return fallbackUrl;
  }

  return decodedReturnUrl;
};
