import styled from 'styled-components';
import { rem } from 'polished';
import { Link } from '@experian-uk/corvetteuk-common-ui';
import Container from '../../components/Container';
import PanelContainer from '../../components/PanelContainer';
import PanelHeader from '../../components/PanelHeader';

export const ErrorMessage = styled(Container)`
  padding: 2.5rem 0 3.75rem;

  ${({ theme }) =>
    theme.media(
      '20',
      `
    padding: 3.75rem 0 7.5rem;
  `
    )}
`;

export const InlineLink = styled(Link.Anchor)`
  color: #0076dc;
  font-size: ${rem(18)};
  font-weight: 700;

  ${({ theme }) =>
    theme.media(
      '20',
      `

  font-size: ${rem(20)};
  line-height: ${rem(40)};
`
    )}
`;

export const ContentContainer = styled(PanelContainer)`
  margin: 1.25rem 0;
  box-shadow: 0px 1.5px 4px rgba(0, 0, 0, 0.1);

  ${({ theme }) =>
    theme.media(
      '20',
      `
    margin-top: 1.875rem;
  `
    )}
`;

export const ContentHeader = styled(PanelHeader)`
  ${props =>
    props.theme.media(
      '20',
      `
      padding: ${rem(45)} ${rem(16)} ${rem(45)} ${rem(63)};
  `
    )}
`;

export const BodyContent = styled.div`
  margin: ${rem(20)};
  color: #495765;
  font-size: ${rem(18)};
  line-height: ${rem(30)};
  margin-right: ${rem(5)};

  ${({ theme }) =>
    theme.media(
      '20',
      `
    margin: ${theme.base.expSpacingSmall} ${rem(20)};
    font-size: ${rem(20)};
    line-height: ${rem(40)};
  `
    )}
`;

export const OrderedList = styled.ol`
  display: grid;
  row-gap: 1.5rem;
  line-height: ${rem(30)};
  margin: ${rem(20)} 0 ${rem(40)};
  padding-inline-start: ${rem(20)};

  ${({ theme }) =>
    theme.media(
      '20',
      `
    line-height: ${rem(40)};
    margin: ${rem(30)} 0;
    row-gap: 1rem;
  `
    )}
}
`;

export const InnerBodyContent = styled.div`
  margin-bottom: ${rem(60)};

  ${({ theme }) =>
    theme.media(
      '20',
      `
  margin-top: ${rem(59)};
  margin-left: ${rem(43)};
  margin-bottom: ${rem(60)};
`
    )};
`;
