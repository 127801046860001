import { CONFIRM_SELECT } from '../actions/offers/confirmSelect';

const defaultState = null;

export default (state = defaultState, action = {}) => {
  if (action.error) {
    return state;
  }

  switch (action.type) {
    case CONFIRM_SELECT:
      return action.payload;
    default:
      return state;
  }
};
