import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import ExpiryMonthField from './formFields/ExpiryMonthField';
import ExpiryYearField from './formFields/ExpiryYearField';

const StyledDateFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledFieldLabel = styled.span`
  color: #63666a;
  font-weight: 300;
  margin-bottom: 10px;
`;

const StyledDateInputWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const StyledErrorMessage = styled.div`
  color: #ec0000;
  font-size: 14px;
  font-weight: 600;
  padding: 8px 0;
  overflow: visible;
`;

const errors = {
  month: {
    required: 'Please enter your card expiry month',
    format: 'Please enter a valid expiry date',
  },
  year: {
    required: 'Please enter your card expiry year',
  },
};

const DateFieldContainer = () => {
  const expirationMonthError = useSelector(
    state => state?.form['productMovement/newCard']?.syncErrors?.expirationMonth
  );
  const expirationYearError = useSelector(state => state?.form['productMovement/newCard']?.syncErrors?.expirationYear);
  const submitError = useSelector(state => state?.form['productMovement/newCard']?.submitFailed);
  const monthTouched = useSelector(state => state?.form['productMovement/newCard']?.fields?.expirationMonth?.touched);
  const yearTouched = useSelector(state => state?.form['productMovement/newCard']?.fields?.expirationYear?.touched);

  return (
    <StyledDateFieldWrapper>
      <StyledFieldLabel>Card expiry date</StyledFieldLabel>
      <StyledDateInputWrapper>
        <ExpiryMonthField />
        <ExpiryYearField />
      </StyledDateInputWrapper>
      {!!expirationMonthError && (monthTouched || submitError) && (
        <StyledErrorMessage data-automation-test-element="expirationMonthValidationError">
          {errors.month[expirationMonthError]}
        </StyledErrorMessage>
      )}
      {!!expirationYearError && (yearTouched || submitError) && (
        <StyledErrorMessage data-automation-test-element="expirationYearValidationError">
          {errors.year[expirationYearError]}
        </StyledErrorMessage>
      )}
    </StyledDateFieldWrapper>
  );
};

export default DateFieldContainer;
