// The basics
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter, Route } from 'react-router';
import PropTypes from 'prop-types';
import { AnimatedLogo } from '@experian-uk/corvetteuk-common-ui';

// Action creators and helpers
import establishCurrentUser from './actions/auth/establishCurrentUser';
import initializeEcd from './actions/ecd/initialize';
import { applicationStarted } from './reducers/app';

import { FullPageContainer } from './components/Container';
import Routes from './pages';

@withRouter
@connect(
  state => ({
    hasAppStarted: state.app.hasStarted,
  }),
  dispatch =>
    bindActionCreators(
      {
        initializeEcd,
        establishCurrentUser,
        applicationStarted,
      },
      dispatch
    )
)
export default class App extends Component {
  static propTypes = {
    applicationStarted: PropTypes.func.isRequired,
    initializeEcd: PropTypes.func.isRequired,
    establishCurrentUser: PropTypes.func,
    hasAppStarted: PropTypes.bool.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  };

  static defaultProps = {
    establishCurrentUser: () => {},
    location: {
      pathname: '',
    },
  };

  async componentDidMount() {
    await Promise.all([this.props.establishCurrentUser(), this.props.applicationStarted()]);
    this.props.initializeEcd();
  }

  render() {
    return (
      <div id="app">
        {this.props.hasAppStarted ? (
          <Routes />
        ) : (
          <Route>
            <FullPageContainer>
              <AnimatedLogo />
            </FullPageContainer>
          </Route>
        )}
      </div>
    );
  }
}
