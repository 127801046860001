import styled from 'styled-components';
import FreeProductPanel from './FreeProduct/FreeProductPanel';

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;

  ${({ theme }) =>
    theme.media(
      '20',
      `
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
  `
    )};
  ${({ theme, showBreachVoucherInput }) =>
    showBreachVoucherInput &&
    theme.media(
      '30',
      `
    align-items: stretch;
  `
    )};
`;

export const ProductPanelContainer = styled(FreeProductPanel)`
  margin-bottom: 1.625rem;

  ${({ selected }) =>
    selected &&
    `
    order: -1;
  `}

  ${({ fewerPanels, theme }) =>
    theme.media(
      '20',
      `
      order: 0;
      margin-left: ${fewerPanels ? '1.25rem' : theme.base.expSpacingXSmall};
      margin-right: ${fewerPanels ? '1.25rem' : theme.base.expSpacingXSmall};

      flex: 1;

      &:first-of-type {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      ${
        fewerPanels &&
        `
        footer {
          align-items: center;
        }
      `
      }
  `
    )};
`;
