import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import PricingInfo from './PricingInfo';
import Benefits from './Benefits';
import Terms from './Terms';
import FormFields from './FormFields';

const Hr = styled.hr`
  border: 1px solid #e63888;
  width: 80%;
`;

const FormContainer = styled.form`
  background: white;
  width: 100%;
  min-height: 300px;
  border-radius: 10px;
  padding: 16px 0;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.05);

  ${({ theme }) =>
    theme.media(
      '10',
      `
    border-radius: 20px;
    padding: 16px 0 45px;
  `
    )};
`;

const SubmitButton = styled.button`
  background: #d82b80;
  color: #fff;
  border-radius: 4px;
  width: calc(100% - 16px);
  max-width: 354px;
  padding: 12px;
  font-size: 16px;
  border: none;
  margin-top: 24px;
  line-height: 24px;
  cursor: pointer;

  &:disabled {
    color: #797979;
    background: #eeeeee;
    cursor: default;
  }

  ${({ theme }) =>
    theme.media(
      '10',
      `
    margin-top: 32px;
  `
    )};
`;

const Form = ({
  savedCard,
  useSavedCard,
  hasSavedCard,
  handleRadioSwitch,
  setCvv,
  variant,
  priceInfo,
  trial,
  submitDisabled,
  submitFunction,
}) => (
  <FormContainer method="post">
    {variant === 'E' && <Benefits trial={trial} />}
    <FormFields
      savedCard={savedCard}
      useSavedCard={useSavedCard}
      hasSavedCard={hasSavedCard}
      handleRadioSwitch={handleRadioSwitch}
      setCvv={setCvv}
    />
    <Terms />
    <Hr />
    <PricingInfo priceInfo={priceInfo} trial={trial} />
    <SubmitButton
      onClick={e => {
        e.preventDefault();
        submitFunction();
      }}
      disabled={submitDisabled}
      type="submit"
      data-automation-test-element="upsellFormSubmitButton"
    >
      {trial && (variant === 'D' ? 'Start your free trial today' : 'Redeem my 30-day trial')}
      {!trial && 'Upgrade to CreditExpert'}
    </SubmitButton>
  </FormContainer>
);

Form.propTypes = {
  variant: PropTypes.string.isRequired,
  savedCard: PropTypes.shape({
    billingProviderPaymentMethodId: PropTypes.string,
    cardNumberEnding: PropTypes.string,
    creditCardNumber: PropTypes.string,
    creditCardType: PropTypes.string,
    cvv: PropTypes.number,
    expirationMonth: PropTypes.number,
    expirationYear: PropTypes.number,
    isDefaultCard: PropTypes.bool,
    nameOnCreditCard: PropTypes.string,
    source: PropTypes.string,
  }).isRequired,
  useSavedCard: PropTypes.bool.isRequired,
  hasSavedCard: PropTypes.bool,
  handleRadioSwitch: PropTypes.func.isRequired,
  setCvv: PropTypes.func.isRequired,
  priceInfo: PropTypes.shape({
    price: PropTypes.number,
    trialDuration: PropTypes.number,
  }).isRequired,
  trial: PropTypes.bool.isRequired,
  submitDisabled: PropTypes.bool.isRequired,
  submitFunction: PropTypes.func.isRequired,
};

Form.defaultProps = {
  hasSavedCard: undefined,
};

export default Form;
