import React from 'react';
import styled from 'styled-components';
import { Field } from 'redux-form';

import FormSelectValidationWrapper from '../FormSelectValidationWrapper';

const StyledDateContainer = styled.div`
  flex-grow: 1;
`;

const fieldAttributes = {
  name: 'expirationYear',
  component: FormSelectValidationWrapper,
  validate: value => (value ? undefined : 'required'),
  years: [...Array(20)].map((_, index) => `${new Date().getFullYear() + index}`),
};

const ExpiryYearField = () => (
  <StyledDateContainer name="date-container">
    <Field
      name={fieldAttributes.name}
      dataAutomationTestElement={fieldAttributes.name}
      component={fieldAttributes.component}
      validate={fieldAttributes.validate}
    >
      <option disabled defaultValue hidden value="">
        Year
      </option>
      {fieldAttributes.years.map(year => (
        <option value={year} key={year}>
          {year}
        </option>
      ))}
    </Field>
  </StyledDateContainer>
);

export default ExpiryYearField;
