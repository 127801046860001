import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { setAutomationElement } from '@experian-uk/corvetteuk-common-ui';

const RadioInputContainer = styled.div`
  input {
    display: none;

    &:checked + label {
      &:before {
        box-shadow: 0px 0px 0px 0.15625rem white inset;
        background: ${({ theme }) => theme.colors.experianLightBlue};
      }
    }
  }

  label {
    cursor: pointer;
    display: flex;
    font-size: 1rem;
    font-weight: 400;

    &:before {
      border: 1px solid ${({ theme }) => theme.colors.baseMediumGrey};
      border-radius: 50%;
      content: '';
      display: inline-block;
      height: 1.125rem;
      margin: 0.15rem 1rem 0 0;
      min-height: 18px;
      min-width: 18px;
      transition: box-shadow 0.2s ease-in-out;
      width: 1.125rem;
    }
  }
`;

const RadioInput = ({ checked, id, label, onChange, className }) => (
  <RadioInputContainer {...setAutomationElement(id)} className={className}>
    <input type="radio" id={id} checked={checked} onChange={onChange} />
    <label htmlFor={id} data-hook="fs-exclude-element">
      {label}
    </label>
  </RadioInputContainer>
);

RadioInput.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

RadioInput.defaultProps = {
  className: '',
};

export default RadioInput;
