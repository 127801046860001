import styled from 'styled-components';
import { Forms } from '@experian-uk/corvetteuk-common-ui';

export const Label = styled(Forms.Label)`
  display: block;
  margin: 1rem 0;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
`;

export const Select = styled(Forms.Select)`
  margin-bottom: 1rem;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
`;
