import { isObjectEmpty } from './';

export const definedError = new Error('Could not check value passed to defined() because no valid type was specified');

const isObject = (type, value) => type === 'object' && typeof value === 'object' && !isObjectEmpty(value);

const isString = (type, value) => type === 'string' && typeof value === 'string' && value.length > 0;

const isArray = value => Array.isArray(value) && value.length > 0;

export const defined = (type, value) => {
  if (!type || !['array', 'object', 'string'].includes(type)) {
    throw definedError;
  }
  return !!value && (isArray(value) || isObject(type, value) || isString(type, value));
};
