import styled from 'styled-components';

const NegativeValidationIcon = styled.div`
  height: 24px;
  width: 24px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  border: 2px solid #ec0000;
  color: #ec0000;
  font-weight: 400;
  font-size: 17px;
  border-radius: 50%;
  text-align: center;

  :before {
    content: '!';
  }
`;

export default NegativeValidationIcon;
