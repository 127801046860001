import { CREDIT_SUMMARY_FETCHED, FETCH_CREDIT_SUMMARY } from '../actions/creditSummary/getCreditSummary';

export const defaultState = {
  fetching: false,
  data: null,
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case FETCH_CREDIT_SUMMARY:
      return {
        ...state,
        fetching: true,
      };
    case CREDIT_SUMMARY_FETCHED:
      if (action.error) {
        return {
          ...state,
          error: true,
        };
      }

      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};
