import React from 'react';
import PropTypes from 'prop-types';
import { products } from '../../../constants';
import WidescreenInformationAlert from '../WidescreenInformationAlert';

const copy = Object.freeze({
  [products.breach]: 'your account will close.',
  [products.breachPlus]: 'you’ll move to a free account with fewer benefits.',
});

const BreachRetentionAlert = ({ currentFamily }) => {
  const retentionCopy = copy[currentFamily];

  return retentionCopy ? (
    <WidescreenInformationAlert
      text={`Choose what you would like to happen after it ends or ${retentionCopy}`}
      heading="Your subscription is ending soon"
    />
  ) : null;
};

BreachRetentionAlert.propTypes = {
  currentFamily: PropTypes.string.isRequired,
};

export default BreachRetentionAlert;
