import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';

import { validateField, normalizeField } from '../../../../../../helpers/forms';
import HowToFindCvvDiagram from '../../assets/how-to-find-cvv.svg';
import FormInputValidationWrapper from '../FormInputValidationWrapper';

const StyledFormFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledFormLabel = styled.label`
  font-weight: 300;
  font-size: 15px;
  line-height: 20px;
  color: #63666a;
  margin-bottom: 10px;
`;

const StyledCvvFieldWrapper = styled.div`
  display: flex;
  justify-content: start;
`;

const StyledField = styled.div`
  max-width: 93px;
`;

const StyledCvvLocationInfo = styled.div`
  display: flex;
  margin-left: 15px;
  height: 50px;
  align-items: center;

  .cvv-diagram {
    margin-right: 8px;
  }

  .CVV-location-text {
    max-width: 128px;
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
    color: #575755;
  }
`;

const fieldAttributes = {
  name: 'CVV',
  inputMode: 'numeric',
  component: FormInputValidationWrapper,
  inputProps: { maxLength: 3 },
  validate: validateField.cvvNumber,
  normalize: normalizeField.numeric,
  errorMessageProps: {
    required: 'Please enter your CVV number',
    format: 'Please enter a valid CVV number',
  },
};

const CvvNumberField = ({ setCvv, dataAutomationTestElement }) => (
  <StyledFormFieldWrapper>
    <StyledFormLabel htmlFor={fieldAttributes.name}>
      <span>CVV Number</span>
    </StyledFormLabel>
    <StyledCvvFieldWrapper>
      <StyledField>
        <Field
          name={fieldAttributes.name}
          dataAutomationTestElement={dataAutomationTestElement}
          component={fieldAttributes.component}
          inputProps={fieldAttributes.inputProps}
          validate={fieldAttributes.validate}
          normalize={fieldAttributes.normalize}
          errorMessageProps={fieldAttributes.errorMessageProps}
          onChange={event => setCvv && setCvv(event.target.value)}
          inputMode={fieldAttributes.inputMode}
        />
      </StyledField>
      <StyledCvvLocationInfo>
        <SVG className="cvv-diagram" alt="CVV Diagram" src={HowToFindCvvDiagram} />
        <p className="CVV-location-text">The last 3 digits on the back of your card</p>
      </StyledCvvLocationInfo>
    </StyledCvvFieldWrapper>
  </StyledFormFieldWrapper>
);

CvvNumberField.propTypes = {
  setCvv: PropTypes.func,
  dataAutomationTestElement: PropTypes.string,
};

CvvNumberField.defaultProps = {
  setCvv: undefined,
  dataAutomationTestElement: undefined,
};

export default CvvNumberField;
