import styled from 'styled-components';
import { Link } from '@experian-uk/corvetteuk-common-ui';
import { rem } from 'polished';

export const StyledParagraph = styled.div`
  margin-bottom: ${({ theme }) => theme.base.expSpacingSmall};
`;

export const InlineLinkAnchor = styled(Link.Anchor).attrs({
  target: '_blank',
})`
  font-size: inherit;
  font-weight: 500;
`;

export const Container = styled.div`
  ${({ isComplimentaryOffer }) => (isComplimentaryOffer ? 'margin-top: 60px' : '')};
`;

export const Title = styled.h2`
  color: #575756;
  font-size: ${rem(24)};
  font-weight: 300;
  line-height: ${rem(26)};
`;
