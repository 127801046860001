import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import getBreachDeferredStatus from '../../../helpers/subscription/getBreachDeferredStatus';
import { getAvailableOffers } from '../../../reducers/offers';
import { getBreachBillingDatePrice } from '../../../helpers';
import { products } from '../../../constants';

import DeferredBreachStepper from '../../BreachStepper/DeferredBreachStepper';
import {
  DeferredContainer,
  DeferredCopy,
  DeferredTitle,
  StepperTitle,
  DeferredStepperContainer,
  Break,
} from './breachProductPanels.styles';

const DeferredPanel = ({ currentSubscription }) => {
  const billing = useSelector(state => state?.billing);
  const { productMovingTo } = getBreachDeferredStatus(currentSubscription, billing);

  const offers = useSelector(state => state.offers);
  const subscriptions = useSelector(state => state.subscriptions);
  const availableOffers = getAvailableOffers(offers, subscriptions);
  const currentOffer = availableOffers.find(o => o.family === currentSubscription?.breachDefermentInfo?.family);
  const selection = { offerId: currentOffer.id };

  const { deferredProductPrice, deferredProductBillDate } =
    getBreachBillingDatePrice(currentSubscription, availableOffers, selection) || {};

  const deferredProductTitle =
    productMovingTo === products.identity ? { light: 'Identity ', bold: 'Plus' } : { light: 'Credit', bold: 'Expert' };

  return (
    <DeferredContainer>
      <DeferredCopy>You’re moving to</DeferredCopy>
      <DeferredTitle>
        {deferredProductTitle.light}
        <b>{deferredProductTitle.bold}</b>
      </DeferredTitle>
      <Break />
      <StepperTitle>What happens now</StepperTitle>
      <DeferredStepperContainer>
        <DeferredBreachStepper
          billingEndDate={billing.endDate}
          deferredFreeTrialEligibility={currentSubscription?.breachDefermentInfo?.descriptors}
          deferredProductPrice={deferredProductPrice}
          deferredProduct={productMovingTo}
          deferredProductBillDate={deferredProductBillDate}
        />
      </DeferredStepperContainer>
    </DeferredContainer>
  );
};

DeferredPanel.propTypes = {
  currentSubscription: PropTypes.shape({
    breachDefermentInfo: PropTypes.shape({
      family: PropTypes.string,
      descriptors: PropTypes.arrayOf(PropTypes.string),
    }),
  }).isRequired,
};

export default DeferredPanel;
