import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

export const flexDirections = {
  column: 'column',
  columnReverse: 'column-reverse',
  row: 'row',
  rowReverse: 'row-reverse',
};

const applyBreakpointColumns = (breakpointColumns, media) => {
  let styles = '';
  breakpointColumns.forEach(bc => {
    styles += `${media(
      bc.breakpoint,
      `
    flex-direction: ${bc.flexDirection};
    > * {
      flex-basis: auto;
    }`
    )}`;
  });
  return css`
    ${styles}
  `;
};

const Container = styled.section`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection};
  justify-content: space-between;
  margin: 1rem 0;
  flex-wrap: ${({ wrap }) => (wrap ? 'wrap' : 'nowrap')};

  > * {
    flex-basis: auto;
  }

  ${({ breakpointColumns, theme: { media } }) => `
    ${applyBreakpointColumns(breakpointColumns, media)};
  `}
`;

const FlexContainer = ({ breakpointColumns, columns, flexDirection, gutter, wrap, children, className }) => (
  <Container
    breakpointColumns={breakpointColumns}
    columns={columns}
    flexDirection={flexDirection}
    gutter={gutter}
    wrap={wrap ? 1 : 0}
    className={className}
  >
    {children}
  </Container>
);

FlexContainer.propTypes = {
  breakpointColumns: PropTypes.arrayOf(
    PropTypes.shape({
      breakpoint: PropTypes.number,
      columns: PropTypes.number,
      flexDirection: PropTypes.oneOf(Object.values(flexDirections)),
      gutter: PropTypes.number,
    })
  ),
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.node])),
  ]).isRequired,
  columns: PropTypes.number,
  flexDirection: PropTypes.oneOf(Object.values(flexDirections)),
  gutter: PropTypes.number,
  wrap: PropTypes.bool,
  className: PropTypes.string,
};

FlexContainer.defaultProps = {
  breakpointColumns: [],
  flexDirection: flexDirections.row,
  columns: 1,
  gutter: 0,
  wrap: false,
  className: '',
};

export default FlexContainer;
