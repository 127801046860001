import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Tick from '../../Icons/Tick';
import Cross from '../../Icons/CrossIcon';

const calculateFlex = (numberOfItems = 1) => (numberOfItems > 4 ? '30%' : '45%');
export const featureLayout = { double: 'double', flex: 'flex' };

const Feature = styled.li`
  align-items: flex-start;
  display: flex;
  flex: 1 1 100%;
  padding-bottom: 1.2rem;
  max-width: 420px;

  ${props =>
    props.theme.media(
      '10',
      `
    flex: 1 1 50%;
    margin-right: 1rem;
    max-width: 45%;
  `
    )}

  ${({ theme, columnWidth }) =>
    theme.media(
      '30',
      `
    max-width: ${columnWidth};
    min-width: ${columnWidth};
  `
    )}

  &:last-of-type {
    padding-bottom: 0.5rem;
  }
`;

const FeatureInDoubleColumn = styled(Feature)`
  ${({ theme }) =>
    theme.media(
      '30',
      `
    max-width: 35%;
    min-width: 35%;`
    )}
`;

const CheckMark = styled(Tick).attrs({
  'aria-hidden': true,
})`
  margin: 0.45rem 0.8rem 0 0;
`;

const CrossIcon = styled(Cross).attrs({
  'aria-hidden': true,
})`
  margin: 0.2rem 0.8rem 0 0;
`;

const FeatureItem = ({ children, isLosingFeature, numberOfItems, layout }) =>
  layout === featureLayout.double ? (
    <FeatureInDoubleColumn>
      <CrossIcon />
      {children}
    </FeatureInDoubleColumn>
  ) : (
    <Feature columnWidth={calculateFlex(numberOfItems)}>
      {isLosingFeature ? <CrossIcon /> : <CheckMark />}
      {children}
    </Feature>
  );

FeatureItem.propTypes = {
  children: PropTypes.node.isRequired,
  isLosingFeature: PropTypes.bool,
  numberOfItems: PropTypes.number,
  layout: PropTypes.string,
};

FeatureItem.defaultProps = {
  isLosingFeature: false,
  numberOfItems: 1,
  layout: featureLayout.flex,
};

export default FeatureItem;
