const GOALS = {
  UPSELL_REDESIGN_CTA_GOAL: 'g-ePqUyJpx0Z',
  SWITCH_TO_FREE_ACCOUNT: 'g-bB1Hoc2ZAc',
  SWITCH_TO_IDPLUS_ACCOUNT: 'g-Q06It0BYum',
  CLOSE_ACCOUNT: 'g-1G6RCw76dO',
  KEEP_CE_CTA_CLICKED: 'g-l9dGqYVybR',
  CONFIRM_CANCEL_CE_CLICKED: 'g-7vGs9JirqZ',
  CONFIRM_PURCHASE_DOWNGRADE_CE_CLICKED: 'g-6RvYZE6iK8',
};

export default GOALS;
