import icons from '../../components/Icons';

export default (cardName, availableLogos = icons.cardLogos, index = 0) => {
  if (!cardName) {
    return null;
  }
  const lowerCaseCardName = cardName.toLowerCase();
  const validTypes = Object.keys(availableLogos);
  const logoFound = validTypes.includes(lowerCaseCardName);
  const logoGroup = logoFound && availableLogos[lowerCaseCardName];
  return logoGroup && index >= 0 && index <= logoGroup.length + 1 ? availableLogos[lowerCaseCardName][index] : null;
};
