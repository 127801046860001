import { rem } from 'polished';
import styled from 'styled-components';

export const GuaranteeList = styled.ul`
  text-align: left;
  padding: ${rem(24)};
`;

export const GuaranteeTitle = styled.h2`
  position: absolute;
  left: 0;
  max-width: 70%;
  margin: 0;

  font-size: ${rem(24)};
  line-height: ${rem(30)};
  color: #1d4f91;
  font-weight: 300;
  text-align: left;
`;

export const GuaranteePoint = styled.li`
  font-size: ${rem(16)};
  color: #575757;
  line-height: ${rem(20)};
  margin-top: ${rem(16)};
`;
