import { months, monthsAsDigits, years } from '../../helpers/billing';
import { validateField, normalizeField } from '../../helpers/forms';
import { textInput } from '../../helpers/formStyledComponent';

export default [
  {
    id: 'creditCardNumber',
    automation: 'newCardNumber',
    component: textInput,
    props: { maxLength: 16 },
    type: 'text',
    validate: validateField.cardNumber,
    normalize: normalizeField.numeric,
    errorMessageProps: {
      required: ['your Visa or Mastercard number'],
      format: ['Visa or Mastercard number'],
    },
    label: 'Card number',
    images: ['mastercard', 'visa'],
  },
  {
    id: 'nameOnCreditCard',
    automation: 'newCardName',
    component: textInput,
    props: { maxLength: 150 },
    type: 'text',
    validate: validateField.required,
    errorMessageProps: {
      required: ['the name on your card'],
    },
    label: 'Name shown on card',
  },
];

export const expiryDateFields = (useDigits = false) => {
  const monthsFormat = useDigits ? monthsAsDigits : months;
  return {
    blanks: {
      expirationMonth: 'Month',
      expirationYear: 'Year',
    },
    options: {
      expirationMonth: [...monthsFormat],
      expirationYear: [...years()],
    },
    props: {
      expirationMonth: {
        automation: 'newCardMonth',
        errorMessage: {
          required: ['your card expiry month'],
          format: ['end date'],
        },
      },
      expirationYear: {
        automation: 'newCardYear',
        errorMessage: {
          required: ['your card expiry year'],
        },
      },
    },
    label: 'Card expiry date',
  };
};
