import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { Field } from 'redux-form';
import FormSelectValidationWrapper from '../FormSelectValidationWrapper';

const StyledDateContainer = styled.div`
  flex-grow: 2;
`;

const validateMonth = (value, formValues) => {
  const currentYear = moment().format('YYYY');
  const currentMonth = moment().format('MM');

  if (!value) return 'required';
  if (formValues.expirationYear && formValues.expirationYear === currentYear && value < currentMonth) {
    return 'format';
  }
  // setDisplayError({ error: undefined });
  return undefined;
};

const fieldAttributes = {
  name: 'expirationMonth',
  component: FormSelectValidationWrapper,
  validate: validateMonth,
  months: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
};

const ExpiryMonthField = () => (
  <StyledDateContainer name="date-container">
    <Field
      name={fieldAttributes.name}
      dataAutomationTestElement={fieldAttributes.name}
      component={fieldAttributes.component}
      validate={fieldAttributes.validate}
    >
      <option disabled defaultValue hidden value="">
        Month
      </option>
      {fieldAttributes.months.map((month, index) => (
        <option value={`0${index + 1}`.slice(-2)} key={month}>
          {month}
        </option>
      ))}
    </Field>
  </StyledDateContainer>
);

export default ExpiryMonthField;
