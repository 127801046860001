import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import getSubscriptionData from '../../helpers/subscription/getSubscriptionData';
import { Heading, CardError, Container } from './index.styles';
import CurrentCard from './CurrentCard';
import CurrentMobilePay from './CurrentMobilePay';
import ErrorAlert from '../Alerts/ErrorAlert';
import { errorMessages, familyName, splitsList } from '../../constants';
import { cardDetailsSchema } from '../../schemas';
import { getCardNumberEnding, getFormattedExpiryDate } from '../../helpers/billing';
import { isObjectEmpty } from '../../helpers';
import { getSplitStatus } from '../../reducers/selectors';
import CurrentDDPay from './CurrentDDPay';
import getCurrentSubscriptionPrice from '../../reducers/selectors/currentSubscriptionPrice';
import PaymentInformation from '../../experimental/nebulaAccountPaymentInformationSection/nebulaPaymentInformation/paymentInformation';
import Details from '../Details';

@connect(state => ({
  nextBillDate: state.billing.nextBillingDate,
  card: state.billing.card,
  mobilePay: state.billing.mobile,
  directDebit: state.billing.directDebit,
  currentSubscription: state.subscriptions.current.family,
  noFeeSubscription: state.billing.signupPrice === 0,
  pendingCancellation: !!state.subscriptions.current.defermentInfo,
  currentSubscriptionPrice: getCurrentSubscriptionPrice(state),
  nextBillingPrice: getCurrentSubscriptionPrice(state),
  splits: getSplitStatus(state.split, [splitsList.showRecycleBanner, splitsList.applePay]),
  isRecycle: state.billing.billingStateRecycle,
}))
export default class CurrentBillingDetails extends React.Component {
  static propTypes = {
    card: PropTypes.shape(cardDetailsSchema),
    mobilePay: PropTypes.shape({
      paymentType: PropTypes.string,
    }),
    directDebit: PropTypes.shape({
      accountNumber: PropTypes.string,
    }),
    nextBillDate: PropTypes.string,
    currentSubscription: PropTypes.string,
    noFeeSubscription: PropTypes.bool,
    pendingCancellation: PropTypes.bool,
    currentSubscriptionPrice: PropTypes.number,
    nextBillingPrice: PropTypes.number,
    splits: PropTypes.shape({
      showRecycleBanner: PropTypes.bool,
    }).isRequired,
    isRecycle: PropTypes.bool,
  };

  static defaultProps = {
    card: null,
    mobilePay: null,
    directDebit: null,
    nextBillDate: '',
    currentSubscription: '',
    noFeeSubscription: false,
    pendingCancellation: false,
    currentSubscriptionPrice: null,
    nextBillingPrice: null,
    isRecycle: false,
  };

  render() {
    const {
      nextBillDate,
      card,
      mobilePay,
      directDebit,
      currentSubscription,
      noFeeSubscription,
      pendingCancellation,
      currentSubscriptionPrice,
      nextBillingPrice,
      isRecycle,
    } = this.props;

    const validCard = !!card && !isObjectEmpty(card);

    let expirationDate = null;
    if (validCard) {
      const { expirationMonth, expirationYear } = card;
      expirationDate = getFormattedExpiryDate(`${expirationMonth}/${expirationYear}`);
    }

    const isRecycleSplit = isRecycle && this.props.splits[splitsList.showRecycleBanner];
    const isApplePaySplit = this.props.splits[splitsList.applePay];

    const subsData = getSubscriptionData(
      nextBillDate,
      noFeeSubscription,
      pendingCancellation,
      currentSubscriptionPrice,
      nextBillingPrice,
      familyName[currentSubscription],
      isApplePaySplit
    );

    if (isApplePaySplit) {
      return (
        <PaymentInformation
          validCard={validCard}
          mobilePay={mobilePay}
          directDebit={directDebit}
          card={card}
          expirationDate={expirationDate}
          isRecycleSplit={isRecycleSplit}
          subscriptionsData={subsData}
        />
      );
    }

    return (
      <Fragment>
        <Heading id="paymentInformation">Your payment information</Heading>
        <Container>
          <Details data={subsData} />
          {!validCard && !mobilePay && !directDebit && (
            <CardError>
              <ErrorAlert messages={[errorMessages.billing.paymentDetails]} />
            </CardError>
          )}
          {validCard && !mobilePay && !directDebit && (
            <CurrentCard
              cardType={card.creditCardType}
              endingNumbers={getCardNumberEnding(card.creditCardNumber)}
              expiryDate={expirationDate}
              isRecycle={isRecycleSplit}
            />
          )}
          {!validCard && mobilePay && !directDebit && (
            <CurrentMobilePay vendor={mobilePay.paymentType} isRecycle={isRecycleSplit} />
          )}
          {!validCard && !mobilePay && directDebit && (
            <CurrentDDPay
              accountNumber={`xxxx${getCardNumberEnding(directDebit.accountNumber)}`}
              isRecycle={isRecycleSplit}
            />
          )}
        </Container>
      </Fragment>
    );
  }
}
