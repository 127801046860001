import fetch from '../fetch';

export const FETCH_VERIFY_CHALLENGE_STATUS = 'FETCH_VERIFY_CHALLENGE_STATUS';
export const VERIFY_CHALLENGE_STATUS_FETCHED = 'VERIFY_CHALLENGE_STATUS_FETCHED';
export const VERIFY_CHALLENGE_STATUS_FETCHED_FAILED = 'VERIFY_CHALLENGE_STATUS_FETCHED_FAILED';

export default () => async dispatch => {
  dispatch({ type: FETCH_VERIFY_CHALLENGE_STATUS });

  const fetched = {
    type: VERIFY_CHALLENGE_STATUS_FETCHED,
  };

  try {
    await dispatch(fetch('/billingpaymentauthorizations/verifychallengestatus'));
  } catch (error) {
    // Set flag for error to be caught by getchallengestatus polling
    window.verifyChallengeTimeoutBreaker = true;
    fetched.error = true;
    dispatch({ type: VERIFY_CHALLENGE_STATUS_FETCHED_FAILED });
  }

  dispatch(fetched);
};
