import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import PropTypes from 'prop-types';

const PricingInfoWrapper = styled.div`
  font-size: 18px;
  color: #63666a;
  font-weight: 300;
  line-height: 28px;
  margin-top: 24px;

  small {
    font-size: 14px;
    font-weight: 200;
    display: block;
  }

  P {
    margin: 0;
  }

  .desktop-window {
    display: none;
  }

  .tablet-and-desktop-window {
    display: none;
  }

  ${({ theme }) =>
    theme.media(
      '10',
      `
    font-size: 20px;
    margin-top: 32px;

    small {
      font-size: 16px;
      font-weight: 300;
    }

    .mobile-window {
      display: none;
    }

    .tablet-and-desktop-window {
      display: inline;
    }
  `
    )};

  ${({ theme }) =>
    theme.media(
      '20',
      `
    small {
      font-size: 14px;
      font-weight: 200;
    }
  `
    )};

  ${({ theme }) =>
    theme.media(
      '30',
      `
    .tablet-and-mobile-window {
      display: none;
    }

    .desktop-window {
      display: inline;
    }
  `
    )}
`;

const PricingInfo = ({ trial, priceInfo }) => {
  const nextPaymentDate = trial
    ? moment().add(priceInfo.trialDuration, 'days').format('Do MMMM')
    : moment().add(1, 'month').format('Do MMMM');

  if (trial) {
    return (
      <PricingInfoWrapper>
        <p className="tablet-and-mobile-window">
          Pay today: <b>&pound;0</b> | Pay {nextPaymentDate}: <b>£14.99</b>
          <br />
          Cancel before {nextPaymentDate}: <b>£0</b>
          <small>Subscription will auto renew each month.</small>
        </p>
        <p className="desktop-window">
          Pay today: <b>&pound;0</b> | Pay {nextPaymentDate}: <b>£14.99</b> | Cancel before {nextPaymentDate}: <b>£0</b>
          <small>Subscription will auto renew each month.</small>
        </p>
      </PricingInfoWrapper>
    );
  }

  return (
    <PricingInfoWrapper>
      <p className="mobile-window">
        Pay today: <b>&pound;{priceInfo.price}</b>
      </p>
      <p className="mobile-window">
        Next payment: <b>{nextPaymentDate}</b>
        <small>Subscription will auto renew each month.</small>
      </p>
      <p className="tablet-and-desktop-window">
        Pay today: <b>&pound;{priceInfo.price}</b> | Next payment: <b>{nextPaymentDate}</b>
        <small>Subscription will auto renew each month.</small>
      </p>
    </PricingInfoWrapper>
  );
};

PricingInfo.propTypes = {
  trial: PropTypes.bool.isRequired,
  priceInfo: PropTypes.shape({
    price: PropTypes.number,
    trialDuration: PropTypes.number,
  }).isRequired,
};

export default PricingInfo;
