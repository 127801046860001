import React from 'react';
import PropTypes from 'prop-types';
import Wrapper from './components/Wrapper';

const UpsellVariatE = ({ testExperienceData }) => <Wrapper {...testExperienceData} variant="E" />;

UpsellVariatE.propTypes = {
  testExperienceData: PropTypes.shape({}).isRequired,
};

export default UpsellVariatE;
