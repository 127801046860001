import luhn from 'fast-luhn';
import payment from 'payment';
import moment from 'moment';
import { defined } from '../defined';

const supportedCards = ['visa', 'mastercard'];

export const number = (value, length) => {
  if (!defined('string', value)) {
    return 'required';
  }
  return value.length === length && !/[^0-9]/.exec(value) ? undefined : 'format';
};

const validExpiryDate = value =>
  moment(value, 'MMYY').endOf('month').isAfter(moment()) && value?.toString().length === 4 ? undefined : 'format';

const validCardNumber = value => (luhn(value) ? undefined : 'format');

export const validateField = {
  cardNumber: value => number(value?.replace(/\s/g, ''), 16) || validCardNumber(value?.replace(/\s/g, '')),
  cvvNumber: value => number(value, 3),
  expiryDate: value => validExpiryDate(value?.replace(/\//g, '')),
  required: value => (defined('string', value) ? undefined : 'required'),
};

export const normalizeField = {
  date: value =>
    value
      .replace(/[^\dA-Z]/g, '')
      .replace(/(.{2})/g, '$1/')
      .replace(/\/+$/, ''),
  numeric: value => value.replace(/\D/g, ''),
  formatName: value => value.replace(/[\d\r\n\t\f\v§±~!@#£$%^&*()_+=[\]{};:"\\|<>/?]/g, ''),
  uppercase: value => (value && typeof value === 'string' ? value.toUpperCase() : value),
  cardNumber: value =>
    value
      .replace(/\D/gi, '')
      .replace(/(.{4})/g, '$1 ')
      .trim(),
};

const formatCardNumber = value => {
  const matches = value
    .replace(/\D/g, '')
    .slice(0, 16)
    .match(/.{1,4}/g);
  return matches ? matches.join(' ') : '';
};

export const cardUtility = {
  formatNumber: cardNumber => formatCardNumber(cardNumber),
  isCardNumberValid: cardNumber => {
    if (!cardNumber || cardNumber.length === 0) return 'required';
    if (!payment.fns.validateCardNumber(cardNumber)) return 'format';
    if (!supportedCards.includes(payment.fns.cardType(cardNumber))) return payment.fns.cardType(cardNumber);
    return undefined;
  },
  returnCardType: cardNumber => payment.fns.cardType(cardNumber),
  verifyCardName: cardName => (!cardName || cardName.length === 0 ? 'required' : undefined),
  formatName: cardName => cardName.replace(/[\d\r\n\t\f\v§±~!@#£$%^&*()_+=[\]{};:"\\|<>/?]/g, ''),
};
