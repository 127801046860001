import React from 'react';
import { Button } from '@experian-uk/corvetteuk-common-ui';
import { reduxForm, Field, propTypes as formProps } from 'redux-form';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import Cookies from 'js-cookie';

import FlexContainer from '../../../components/FlexContainer';
import { TextInput } from '../../../components/TextInput';
import formStyledComponent from '../../../helpers/formStyledComponent';
import { Label } from '../index.styles';
import setUser from '../../../actions/auth/setCurrentUser';
import changeStateAction from '../../../actions/uiChangeState';
import { tabKeys } from '../constants';

const showMockUserLogin = changeStateAction.bind(null, 'login', { activeTab: tabKeys.mockUser });

@reduxForm({
  form: 'acquisition/real',
})
@connect(null, dispatch => bindActionCreators({ setUser, push, showMockUserLogin }, dispatch))
export default class RealUserLogin extends React.Component {
  static propTypes = {
    ...formProps,
    setUser: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    showMockUserLogin: PropTypes.func.isRequired,
  };

  submit = ({ token }) => {
    this.props.setUser(token);
    Cookies.set('authId', token);
    this.props.push('/');
  };

  render() {
    return (
      <form method="post" onSubmit={this.props.handleSubmit(this.submit)}>
        <FlexContainer columns={1} wrap>
          <Label htmlFor="subscriptionType">Token</Label>
          <Field component={formStyledComponent(TextInput)} name="token" />
        </FlexContainer>
        <FlexContainer columns={2} gutter={12}>
          <Button isButton label="Login" />
          <Button
            isButton
            htmlType="button"
            type="secondary"
            label="Use a mock user"
            onClick={this.props.showMockUserLogin}
          />
        </FlexContainer>
      </form>
    );
  }
}
