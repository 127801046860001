import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { reduxForm, Field } from 'redux-form';
import { fields } from './FormFields';
import Tooltip from './Tooltip';

import { FormLabel } from './FormFields.styles';

const SavedCardWrapper = styled.div`
  margin: 24px auto;
  max-width: 164px;
  text-align: left;

  ${({ theme }) =>
    theme.media(
      '10',
      `
        margin: 24px auto 32px;
    `
    )};

  ${({ theme }) =>
    theme.media(
      '20',
      `
        margin: 32px auto;
    `
    )};
`;

const SavedCardForm = ({ setCvv }) => (
  <SavedCardWrapper>
    <FormLabel htmlFor={fields.cvv.id}>
      <span>{fields.cvv.label}</span>
      <Tooltip />
    </FormLabel>
    <Field
      dataAutomationTestElement="cvv-saved-card-form"
      component={fields.cvv.component}
      errorMessageProps={fields.cvv.errorMessageProps}
      inputProps={fields.cvv.props}
      name={fields.cvv.id}
      normalize={fields.cvv.normalize}
      validate={fields.cvv.validate}
      onChange={event => setCvv && setCvv(event.target.value)}
      inputMode={fields.cvv.inputMode}
    />
  </SavedCardWrapper>
);

SavedCardForm.propTypes = {
  setCvv: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'productMovement/savedCard',
  onSubmit: () => {},
})(SavedCardForm);
