import React from 'react';
import PropTypes from 'prop-types';

import { setAutomationElement } from '@experian-uk/corvetteuk-common-ui';

import { isObjectEmpty } from '../../helpers';
import { DownloadIcon } from '../Icons/DownloadIcon';
import BlueTick from './blueTick';
import tooltipForBenefit from './tooltipForBenefit';
import { FreeTrialText } from './FreeProduct/FreeProductPanel.styles';
import {
  CardBenefits,
  CardContent,
  CardContainer,
  CurrentSubscriptionHeader,
  PricePoint,
  ProductFactSheet,
} from './productPanel.styles';
import { products } from '../../constants';

const CurrentSubscriptionCard = ({ freeTrial, switchPermitted, currentSubscription }) => {
  const userIsInFreeTrial = !isObjectEmpty(freeTrial) && !!freeTrial.remaining;

  const isDeferred = !!currentSubscription?.defermentInfo;

  const doNotShowPrice = isDeferred && currentSubscription.family === 'credit_expert';

  const getFreeTrialText = () => {
    let identityText = '';
    if (currentSubscription.family === products.identity) {
      identityText = 'with complimentary CreditExpert features ';
    }
    return `You have a free ${freeTrial.remaining.value} ${freeTrial.remaining.unit} trial ${identityText}until ${freeTrial.ends}`;
  };

  return (
    <CardContainer
      gridLocation="main"
      isCurrentProduct
      {...setAutomationElement(`panel-${currentSubscription.family}`)}
    >
      <CurrentSubscriptionHeader>
        <p>Your current subscription</p>
        <h2>{currentSubscription.titleText}</h2>
        {!doNotShowPrice && (
          <React.Fragment>
            <hr />
            <PricePoint {...setAutomationElement(`${currentSubscription.family}-price-summary`)}>
              {currentSubscription.priceSummary}
            </PricePoint>
          </React.Fragment>
        )}
      </CurrentSubscriptionHeader>
      <CardContent>
        <CardBenefits>
          <h3>Benefits include:</h3>
          <ul>
            {currentSubscription.benefitCopy.map(txt => (
              <li key={txt}>
                <BlueTick />
                {txt}
                {tooltipForBenefit(txt)}
              </li>
            ))}
          </ul>
        </CardBenefits>
      </CardContent>
      {userIsInFreeTrial && switchPermitted && (
        <FreeTrialText {...setAutomationElement('free-trial-text')}>{getFreeTrialText()}</FreeTrialText>
      )}
      <ProductFactSheet>
        <DownloadIcon />
        <a
          {...setAutomationElement(`${currentSubscription.family}-panel-product-factsheet-link`)}
          href={currentSubscription.copyLink}
        >
          View {currentSubscription.productName} product fact sheet
        </a>
      </ProductFactSheet>
    </CardContainer>
  );
};

CurrentSubscriptionCard.propTypes = {
  freeTrial: PropTypes.shape({
    ends: PropTypes.string,
    remaining: PropTypes.shape({
      unit: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  }),
  currentSubscription: PropTypes.shape({
    copyLink: PropTypes.string.isRequired,
    benefitCopy: PropTypes.arrayOf(PropTypes.string).isRequired,
    titleText: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
    priceSummary: PropTypes.string.isRequired,
    productName: PropTypes.string.isRequired,
    family: PropTypes.string.isRequired,
    defermentInfo: PropTypes.shape({}),
  }).isRequired,
  switchPermitted: PropTypes.bool.isRequired,
  isComplimentary: PropTypes.bool,
};

CurrentSubscriptionCard.defaultProps = {
  freeTrial: {},
  isComplimentary: false,
};

export default CurrentSubscriptionCard;
