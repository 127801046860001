import { type as UPDATE_ALERTS_COUNT } from '../actions/alerts/getCount';

export const defaultState = {
  unread: null,
  error: false,
};

export default (state = defaultState, action = {}) => {
  if (action.error) {
    return {
      ...state,
      unread: null,
      error: true,
    };
  }

  switch (action.type) {
    case UPDATE_ALERTS_COUNT:
      return {
        ...state,
        unread: action.payload.count,
        error: false,
      };
    default:
      return state;
  }
};
