import get from 'lodash/get';
import fetch from '../fetch';
import { fulfillmentErrorMessages } from './errors';
import { channels, products } from '../../constants';
import { defined } from '../../helpers/defined';

export const FULFILL_SUBSCRIPTION = 'FULFILL_SUBSCRIPTION';
export const SUBSCRIPTION_FULFILLED = 'SUBSCRIPTION_FULFILLED';
export const fulfillmentEndpoint = 'fulfillments';

const defaultChannel = channels.online;

export default (offerId, channel = defaultChannel, errorMessageKey = undefined) =>
  async (dispatch, getState) => {
    const currentState = getState();

    dispatch({ type: FULFILL_SUBSCRIPTION, meta: { clearErrors: true } });

    const result = {
      type: SUBSCRIPTION_FULFILLED,
    };

    const errorMessage = JSON.stringify(fulfillmentErrorMessages[errorMessageKey] || fulfillmentErrorMessages.default);

    if (!defined('string', offerId)) {
      result.error = true;
      result.payload = new Error(errorMessage);
      return dispatch(result);
    }

    const isAmend =
      currentState.productDetails.isBreach &&
      [products.breach, products.breachPlus].includes(currentState.selection.family);
    const request = {
      method: `${isAmend ? 'PUT' : 'POST'}`,
      body: {
        channel,
        offerId,
      },
    };
    const url = `/${fulfillmentEndpoint}${isAmend ? '/amend' : ''}`;

    try {
      const fulfilled = await dispatch(fetch(url, request));
      const succeeded = !!get(fulfilled, 'data[0].subscriptionId', false);

      result.payload = {
        success: succeeded,
      };
    } catch (error) {
      result.error = true;
      result.payload = new Error(errorMessage);
    }

    return dispatch(result);
  };
