import { products } from '../constants';
import { matchDate } from './';

export default (billing, currentSubscription) => {
  const { fetchFailed: billingError, nextBillingDate } = billing;
  const billingDateToday =
    ![products.basic, products.breach, products.breachPlus].includes(currentSubscription.family) &&
    matchDate(nextBillingDate);
  const { defermentInfo } = currentSubscription;
  const cardSavePermitted = !defermentInfo && !billingDateToday;
  const switchPermitted = cardSavePermitted && !billingError;
  const switchInfo = {
    billingDateToday,
    cardSavePermitted,
    switchPermitted,
  };
  return switchInfo;
};
