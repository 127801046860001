import styled from 'styled-components';
import { Typography } from '@experian-uk/corvetteuk-common-ui';

const { Headings } = Typography;

export const HeadingSecondary = styled(Headings.Secondary)`
  color: ${props => props.theme.colors.experianDarkGrey};
  flex-grow: 1;
  margin-bottom: 0;
  margin-top: ${props => props.theme.base.expSpacingXXSmall};

  ${({ theme, selected }) => `
    ${
      selected &&
      `
      color: ${theme.colors.baseWhite};
    `
    };
  `};
`;

export const HeaderContent = styled.header`
  display: flex;
  flex-direction: row;
  padding: 1.25rem ${props => props.theme.base.expSpacingXSmall};
  text-align: center;

  ${({ theme, selected }) => `
    ${
      selected &&
      `
      background: linear-gradient(to top, ${theme.colors.experianDarkBlue}, ${theme.colors.experianLightBlue});
      background-color: ${theme.colors.experianDarkBlue};
      color: ${theme.colors.baseWhite};
    `
    };
  `};
`;

export const SubscriptionDescription = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;
