import fetch from '../fetch';
import { errorMessages } from '../../constants';
import getOneOffer from '../offers/getOne';
import initializeEcd from '../ecd/initialize';

export const FETCH_SUBSCRIPTIONS = 'FETCH_SUBSCRIPTIONS';
export const SUBSCRIPTIONS_FETCHED = 'SUBSCRIPTIONS_FETCHED';

export default () => async dispatch => {
  dispatch({ type: FETCH_SUBSCRIPTIONS });

  const fetched = {
    type: SUBSCRIPTIONS_FETCHED,
  };

  try {
    const subscriptions = await dispatch(fetch('/subscriptions'));
    fetched.payload = { subscriptions: subscriptions.data };

    // The price returned by the Subscriptions API is no longer accurate so we use the offerId to call the Offers
    // service, which is still accurate
    await getOneOffer(dispatch, subscriptions.data.find(s => s.active).offerId);
  } catch (error) {
    const errorMessage = {
      messages: [errorMessages.default],
    };
    fetched.error = true;
    fetched.payload = new Error(JSON.stringify(errorMessage));
  }
  dispatch(fetched);
  dispatch(initializeEcd());
};
