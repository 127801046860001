import styled from 'styled-components';

export const Price = styled.div`
  font-size: ${props => props.theme.typography.baselineHeight};
`;

export const PriceDescription = styled.div`
  font-size: ${props => props.theme.typography.smallFontSize};
  font-weight: 400;
`;
