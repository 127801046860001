import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import nebulaTheme from '@nebula/theme';
import { ThemeProvider } from 'styled-components';
import { getEnv } from '../../Context/env';
import { dateFormats, products } from '../../../constants';
import {
  BreachBanner,
  DeferredMessage,
  DeferredHeading,
  DeactivationLink,
} from './BreachDeferredSubscriptionBanner.styles';

const productName = Object.freeze({
  [products.identity]: 'Identity Plus',
  [products.credit_expert]: 'Credit Expert',
});

const BreachDeferredSubscriptionBanner = ({ breachDefermentInfo }) => {
  if (!dayjs(breachDefermentInfo?.defermentDt).isValid() || !breachDefermentInfo?.family) {
    return null;
  }

  const newSubStartDate = dayjs(breachDefermentInfo.defermentDt).format(dateFormats.short);

  const newSubscription = productName[breachDefermentInfo.family];

  return (
    <ThemeProvider theme={nebulaTheme}>
      <BreachBanner>
        <DeferredHeading>Want to change your subscription?</DeferredHeading>
        <DeferredMessage>
          You can change online from {newSubStartDate} after you move to {newSubscription}. You can{' '}
          <DeactivationLink href={`${getEnv().REACT_APP_INS_URL}/account/close`}>
            deactivate your account
          </DeactivationLink>{' '}
          any time.
        </DeferredMessage>
      </BreachBanner>
    </ThemeProvider>
  );
};

BreachDeferredSubscriptionBanner.propTypes = {
  breachDefermentInfo: PropTypes.shape({
    defermentDt: PropTypes.string,
    family: PropTypes.string,
  }),
};

BreachDeferredSubscriptionBanner.defaultProps = {
  breachDefermentInfo: {},
};

export default BreachDeferredSubscriptionBanner;
