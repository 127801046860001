import { getAvailableOffers } from '../../../reducers/offers';
import { products } from '../../../constants';
import { isFreeTrial } from '../../../helpers/subscription';

export default state => {
  if (!state.offers.data) {
    return undefined;
  }

  const availableOffers = getAvailableOffers(state.offers, state.subscriptions);

  const identityOffer = availableOffers.find(offer => offer.family === products.identity);

  if (!identityOffer || !identityOffer.descriptors) {
    return false;
  }

  return isFreeTrial(identityOffer.descriptors);
};
