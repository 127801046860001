import React from 'react';
import PropTypes from 'prop-types';
import { setAutomationElement } from '@experian-uk/corvetteuk-common-ui';
import { Header, Title, Subtitle } from './index.styles';
import StyledSubscription from '../StyledSubscription';

const PanelHeader = ({
  headingStart,
  styledSubscription,
  headingEnd,
  subtitle,
  setAutomationTitle,
  setAutomationSubtitle,
  className,
}) => (
  <Header className={className}>
    {(headingStart || styledSubscription || headingEnd) && (
      <Title {...setAutomationElement(setAutomationTitle)}>
        {headingStart} {styledSubscription && <StyledSubscription product={styledSubscription} />} {headingEnd}
      </Title>
    )}
    <Subtitle {...setAutomationElement(setAutomationSubtitle)}>{subtitle}</Subtitle>
  </Header>
);

PanelHeader.propTypes = {
  className: PropTypes.string,
  headingStart: PropTypes.string,
  styledSubscription: PropTypes.string,
  headingEnd: PropTypes.string,
  subtitle: PropTypes.string,
  setAutomationTitle: PropTypes.string,
  setAutomationSubtitle: PropTypes.string,
};

PanelHeader.defaultProps = {
  className: null,
  headingStart: null,
  styledSubscription: null,
  headingEnd: null,
  subtitle: null,
  setAutomationTitle: null,
  setAutomationSubtitle: null,
};

export default PanelHeader;
