import { products } from '../../constants';
import { defined } from '../defined';

const subscriptions = {
  [products.basic]: {
    paid: false,
    subsets: [],
  },
  [products.credit_expert]: {
    paid: true,
    subsets: [products.basic, products.identity],
  },
  [products.identity]: {
    paid: true,
    subsets: [products.basic],
  },
};

export default (
  currentSubscription,
  selectedSubscription,
  priceFetched,
  fetchingPrice,
  subscriptionData = subscriptions
) => {
  if (priceFetched || fetchingPrice) {
    return false;
  }
  return (
    !!currentSubscription &&
    !!selectedSubscription &&
    defined('object', subscriptionData) &&
    !!subscriptionData[currentSubscription] &&
    !!subscriptionData[selectedSubscription] &&
    subscriptionData[currentSubscription].paid &&
    subscriptionData[selectedSubscription].paid &&
    subscriptionData[selectedSubscription].subsets.includes(currentSubscription)
  );
};
