import React from 'react';
import PropTypes from 'prop-types';

import { currency, formatDate, dateInTheFuture } from '../helpers/formatting';
import TermsAndConditions from './TermsAnsConditions';

import { StyledSubmitButton, StyledCard, StyledReviewBoxContainer } from './ReviewYourOrder.style';

const FreeTrialSignUpInfo = ({ trialExpiryDate, price }) => (
  <ul id="free-trial-sign-up-info">
    <li>Your free trial will begin immediately and end on {trialExpiryDate}.</li>
    <li>
      Your selected card will be charged {currency(price)} on {trialExpiryDate}, unless you cancel prior to this date.
    </li>
    <li>
      Your subscription will automatically renew every month. To avoid charges, cancel before the renewal date. You can
      easily cancel online, by email or by phone.
    </li>
  </ul>
);

const ReactSignUpInfo = ({ price }) => (
  <ul id="react-sign-up-info">
    <li>Your subscription will begin immediately and your selected card will be charged {currency(price)}.</li>
    <li>We’ll automatically renew your subscription every month unless you cancel before your renewal date.</li>
    <li>It’s easy to cancel - you can cancel online, by email or by phone.</li>
  </ul>
);

const SubmitButton = ({ handleSubmit, label }) => (
  <StyledSubmitButton
    data-automation-test-element="upsellFormSubmitButton"
    type="submit"
    onClick={event => {
      event.preventDefault();
      handleSubmit();
    }}
  >
    {label}
  </StyledSubmitButton>
);

const ReviewYourOrder = ({ price, submitFunction, offerFreeTrial, trialDuration }) => (
  <StyledCard>
    <StyledReviewBoxContainer>
      {offerFreeTrial ? (
        <FreeTrialSignUpInfo trialExpiryDate={formatDate(dateInTheFuture(trialDuration))} price={price} />
      ) : (
        <ReactSignUpInfo price={price} />
      )}
    </StyledReviewBoxContainer>
    <TermsAndConditions offerFreeTrial={offerFreeTrial} price={price} />
    <SubmitButton
      handleSubmit={submitFunction}
      label={offerFreeTrial ? 'Start your free trial today' : 'Upgrade to CreditExpert'}
    />
  </StyledCard>
);

FreeTrialSignUpInfo.propTypes = {
  price: PropTypes.number.isRequired,
  trialExpiryDate: PropTypes.string.isRequired,
};

ReactSignUpInfo.propTypes = {
  price: PropTypes.number.isRequired,
};

SubmitButton.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

ReviewYourOrder.propTypes = {
  price: PropTypes.number.isRequired,
  submitFunction: PropTypes.func.isRequired,
  offerFreeTrial: PropTypes.bool.isRequired,
  trialDuration: PropTypes.number,
};
ReviewYourOrder.defaultProps = {
  trialDuration: undefined,
};

export default ReviewYourOrder;
