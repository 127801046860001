import React from 'react';
import { InView } from 'react-intersection-observer';
import PropTypes from 'prop-types';
import Analytics, { InteractionInputEvent } from '@experian-uk/web-common-analytics';
import isServer from '../../helpers/isServer';

const INTERSECTION_OBSERVER_THRESHOLD = 0.8;

const windowInt = () => {
  if (!isServer()) {
    window.$ECD2 = window.$ECD2 || [];
  }
};

const InViewComponent = ({ label, children, data, multi }) => {
  windowInt();
  return (
    <InView
      onChange={inView => {
        if (inView) {
          const event = {
            component: label,
            action: 'view',
            status: 'success',
            data: data || {},
          };
          if (multi) {
            Analytics.publish(InteractionInputEvent.fromObject(event));
          } else {
            Analytics.publishOnce(InteractionInputEvent.fromObject(event));
          }
        }
      }}
      threshold={INTERSECTION_OBSERVER_THRESHOLD}
      triggerOnce
    >
      {children}
    </InView>
  );
};
InViewComponent.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  // disabling the lint check on this as the keys are dynamic, more info at:
  // https://stackoverflow.com/questions/34362898/proptypes-check-of-object-with-dynamic-keys
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object,
  multi: PropTypes.bool,
};
InViewComponent.defaultProps = {
  data: null,
  multi: false,
};

export default InViewComponent;
