import React from 'react';
import { uniqueId } from '@epl/helpers';
import styled from 'styled-components';
import { rem } from 'polished';
import PropTypes from 'prop-types';
import { breakpoints } from '../ModalUpsell.styles';

const CEExpertSupportSVG = styled.svg`
  margin: ${rem(20)} auto ${rem(-10)};
  display: block;
  max-width: ${rem(130)};

  @media only screen and (min-width: ${breakpoints.mobile}) {
    margin: ${rem(2)} auto ${rem(-10)};
    max-width: ${props => (props.isInactive ? `${rem(120)}` : `${rem(108)}`)};
  }
`;

const CEExpertSupport = ({ className, isInactive = false }) => {
  const uniqueIdSuffix = uniqueId('feature');

  return isInactive ? (
    <CEExpertSupportSVG
      className={className}
      viewBox="0 0 207 132"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      isInactive={isInactive}
    >
      <title>Expert support icon</title>
      <svg width="207" height="132" viewBox="0 0 207 132" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Group">
          <g id="Rectangle 18 Copy 11" filter="url(#filter0_d_1480_78565)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M178.384 53.6538C178.384 50.3401 175.698 47.6538 172.384 47.6538H34.615C31.3013 47.6538 28.615 50.3401 28.615 53.6538V96.5459C28.615 99.8596 31.3013 102.546 34.615 102.546H47.2571V110.77C47.2571 113.025 49.653 114.474 51.6506 113.426L72.3937 102.546H172.384C175.698 102.546 178.384 99.8596 178.384 96.5459V53.6538Z"
              fill="white"
            />
          </g>
          <g id="Rectangle 18 Copy 13" filter="url(#filter1_d_1480_78565)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15 5.99999C15 2.68629 17.6863 0 21 0H186C189.314 0 192 2.68629 192 6V58.2949C192 61.6086 189.314 64.2949 186 64.2949H169.969V75.3022C169.969 77.5736 167.542 79.0211 165.543 77.9421L140.262 64.2949H21C17.6863 64.2949 15 61.6086 15 58.2949V5.99999Z"
              fill="white"
            />
          </g>
          <g id="Rectangle 18 Copy 12" filter="url(#filter2_d_1480_78565)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15 5.99999C15 2.68629 17.6863 0 21 0H186C189.314 0 192 2.68629 192 6V58.2949C192 61.6086 189.314 64.2949 186 64.2949H169.969V75.3022C169.969 77.5736 167.542 79.0211 165.543 77.9421L140.262 64.2949H21C17.6863 64.2949 15 61.6086 15 58.2949V5.99999Z"
              fill="url(#paint0_linear_1480_78565)"
              fillOpacity="0.1"
            />
          </g>
        </g>
        <defs>
          <filter
            id="filter0_d_1480_78565"
            x="13.6157"
            y="35.6538"
            width="179.769"
            height="96.1198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2" />
            <feGaussianBlur stdDeviation="5" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1480_78565" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1480_78565" result="shape" />
          </filter>
          <filter
            id="filter1_d_1480_78565"
            x="0"
            y="0"
            width="207"
            height="108.306"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="10" />
            <feGaussianBlur stdDeviation="5" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1480_78565" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1480_78565" result="shape" />
          </filter>
          <filter
            id="filter2_d_1480_78565"
            x="0"
            y="0"
            width="207"
            height="108.306"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="10" />
            <feGaussianBlur stdDeviation="5" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1480_78565" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1480_78565" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear_1480_78565"
            x1="-164.49"
            y1="-33.8674"
            x2="-114.601"
            y2="146.761"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#1D4F91" />
            <stop offset="1" stopColor="#426DA9" />
          </linearGradient>
        </defs>
      </svg>
    </CEExpertSupportSVG>
  ) : (
    <CEExpertSupportSVG
      className={className}
      viewBox="0 0 108 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      isInactive={isInactive}
    >
      <title>Expert support icon</title>
      <g filter={`url(#a-${uniqueIdSuffix})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M92.923 27.6a2.83 2.83 0 0 0-2.83-2.83H17.906a2.83 2.83 0 0 0-2.83 2.83v22.87a2.83 2.83 0 0 0 2.83 2.83h6.86v4.513a1.415 1.415 0 0 0 2.072 1.254L37.832 53.3h52.26a2.83 2.83 0 0 0 2.83-2.831V27.6Z"
          fill="#fff"
        />
      </g>
      <g filter={`url(#b-${uniqueIdSuffix})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 2.83A2.83 2.83 0 0 1 10.83 0h86.34A2.83 2.83 0 0 1 100 2.83v27.758a2.83 2.83 0 0 1-2.83 2.83h-8.621v5.963a1.415 1.415 0 0 1-2.088 1.246l-13.353-7.208H10.83A2.83 2.83 0 0 1 8 30.588V2.831Z"
          fill={`url(#c-${uniqueIdSuffix})`}
        />
      </g>
      <defs>
        <filter
          id={`a-${uniqueIdSuffix}`}
          x="8"
          y="19.108"
          width="92"
          height="48.615"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="1.415" />
          <feGaussianBlur stdDeviation="3.538" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1326_71365" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_1326_71365" result="shape" />
        </filter>
        <filter
          id={`b-${uniqueIdSuffix}`}
          x=".923"
          y="0"
          width="106.154"
          height="54.953"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="7.077" />
          <feGaussianBlur stdDeviation="3.538" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1326_71365" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_1326_71365" result="shape" />
        </filter>
        <linearGradient
          id={`c-${uniqueIdSuffix}`}
          x1="-85.294"
          y1="-17.603"
          x2="-59.363"
          y2="76.283"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1D4F91" />
          <stop offset="1" stopColor="#426DA9" />
        </linearGradient>
      </defs>
    </CEExpertSupportSVG>
  );
};

CEExpertSupport.defaultProps = {
  className: undefined,
  isInactive: undefined,
};

CEExpertSupport.propTypes = {
  className: PropTypes.string,
  isInactive: PropTypes.bool,
};

export default CEExpertSupport;
