import React, { useEffect, useState } from 'react';
import { Modal } from '@epl/modal';
import PropTypes from 'prop-types';
import Analytics, { InteractionInputEvent } from '@experian-uk/web-common-analytics';
import { ModalStyles } from './ModalUpsell.styles';
import ModalUpsellContent from './ModalUpsellContent';

const ModalUpsell = ({ isShowing, toggleHide, credExClick }) => {
  const [tabIndex, setTabIndex] = useState(0);

  const hideModal = () => {
    toggleHide();
    if (isShowing) {
      setTabIndex(0);
    }
  };

  useEffect(() => {
    if (isShowing) {
      Analytics.publishOnce(
        InteractionInputEvent.fromObject({
          component: 'upsell_modal_open',
          action: 'view',
          status: 'success',
        })
      );
    }
  }, [isShowing]);

  return (
    <Modal
      ariaLabelledby="credExUpsellHeadingRedesign"
      ariaDescribedby="credExUpsellDescRedesign"
      isShowing={isShowing}
      toggleHide={hideModal}
      dataHook="credit-expert-upsell-modal"
      extraStyles={ModalStyles()}
      showDefaultCloseBtn
      zIndex={6}
    >
      <ModalUpsellContent credExClick={credExClick} setTabIndex={setTabIndex} tabIndex={tabIndex} />
    </Modal>
  );
};

ModalUpsell.defaultProps = {
  credExClick: () => undefined,
  customEcdEvent: undefined,
};

ModalUpsell.propTypes = {
  isShowing: PropTypes.bool.isRequired,
  toggleHide: PropTypes.func.isRequired,
  credExClick: PropTypes.func,
  customEcdEvent: PropTypes.shape({}),
};

export default ModalUpsell;
