import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';

import VisaLogoSVG from '../assets/visa-inc-logo.svg';
import MastercardLogoSVG from '../assets/mc-symbol.svg';

const CardDetailsContainer = styled.div`
  display: flex;
  justify-content: space-between;

  .num-and-exp-container {
    min-height: 24px;
    color: #63666a;
    font-size: 1rem;
    font-weight: 300;
    margin-left: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .details {
    text-align: center;
  }

  .divider {
    padding: 0 5px;
  }

  .card-icon {
    align-self: center;
  }
`;

const SavedCardDetailsLabel = ({ savedCardDetails }) => (
  <CardDetailsContainer>
    {savedCardDetails.creditCardType?.toLowerCase() === 'visa' ? (
      <SVG className="card-icon" alt="visa logo" src={VisaLogoSVG} data-automation-test-element="visa-logo" />
    ) : (
      <SVG className="card-icon" alt="mastercard logo" src={MastercardLogoSVG} data-automation-test-element="mc-logo" />
    )}
    <div className="num-and-exp-container">
      <div className="details" data-automation-test-element="card-number-ending">
        <span>•••• </span>
        <span>{savedCardDetails.creditCardNumber?.slice(-4)}</span>
      </div>
      <span className="divider">|</span>
      <div className="details" data-automation-test-element="expiry-date">
        <span>Exp. </span>
        <span>
          {savedCardDetails.expirationMonth?.toString().padStart(2, '0')}/
          {savedCardDetails.expirationYear?.toString().slice(-2)}
        </span>
      </div>
    </div>
  </CardDetailsContainer>
);

SavedCardDetailsLabel.propTypes = {
  savedCardDetails: PropTypes.shape({
    billingProviderPaymentMethodId: PropTypes.string,
    cardNumberEnding: PropTypes.string,
    creditCardNumber: PropTypes.string,
    creditCardType: PropTypes.string,
    cvv: PropTypes.number,
    expirationMonth: PropTypes.number,
    expirationYear: PropTypes.number,
    isDefaultCard: PropTypes.bool,
    nameOnCreditCard: PropTypes.string,
    source: PropTypes.string,
  }),
};

SavedCardDetailsLabel.defaultProps = {
  savedCardDetails: undefined,
};

export default SavedCardDetailsLabel;
