import fetch from '../fetch';

export const type = 'UPDATE_ALERTS_COUNT';

export default () => async dispatch => {
  try {
    const alertCountResponse = await dispatch(fetch("/alerts/count?filter='search=unread'"));
    const [res] = alertCountResponse.data;
    const { count } = res;

    return dispatch({
      type,
      payload: {
        count,
      },
    });
  } catch (e) {
    return dispatch({
      type,
      payload: null,
      error: true,
    });
  }
};
