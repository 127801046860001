import styled from 'styled-components';
import { Typography } from '@experian-uk/corvetteuk-common-ui';

const { Copy, Headings } = Typography;

export const Header = styled.div`
  color: ${props => props.theme.colors.baseWhite};
  border-radius: 4px 4px 0 0;
  padding: 20px;
  background-image: linear-gradient(
    to top,
    ${props => props.theme.colors.experianDarkBlue},
    ${props => props.theme.colors.experianLightBlue}
  );

  ${props =>
    props.theme.media(
      '20',
      `
    padding: 1.875rem 20px;
  `
    )}
`;

export const Title = styled(Headings.Secondary)`
  color: ${props => props.theme.colors.baseWhite};
  font-weight: 100;
  font-size: 1.5rem;
  line-height: 2.125rem;
  text-align: center;

  ${props =>
    props.theme.media(
      '20',
      `
    font-size: 2rem;
    line-height: 2rem;
    text-align: left;
  `
    )}

  span {
    font-weight: 400;
  }
`;

export const Subtitle = styled(Copy.Text)`
  color: ${props => props.theme.colors.baseWhite};
  font-size: 1.125rem;
  margin: 0.625rem 0 0;
  text-align: center;

  ${props =>
    props.theme.media(
      '20',
      `
    text-align: left;
  `
    )}
`;
