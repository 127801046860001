import React from 'react';
import PropTypes from 'prop-types';
import { RadioButtonState, RadioButtonType, RadioGroup } from '@nebula/radio-button';
import { ApplePay } from '@epl/icons';
import MastercardLogoCurrent from '../../../../components/Icons/currentCard/mastercard.svg';
import VisaLogoCurrent from '../../../../components/Icons/currentCard/visa.svg';
import { RadioGroupContainer, CardImg } from './changePaymentRadioButtons.styles';

const ChangePaymentRadioButtons = ({ cardDefault, applePayDefault, setPaymentMethodOption }) => (
  <RadioGroupContainer>
    <RadioGroup
      testId="apple-pay-options"
      onChange={value => {
        setPaymentMethodOption(value);
      }}
      groupType={RadioButtonType.Standard}
      groupState={RadioButtonState.Standard}
      radioButtons={[
        {
          label: <ApplePay />,
          value: 'ApplePay',
          isDefaultOption: applePayDefault,
        },
        {
          label: (
            <div>
              <CardImg src={MastercardLogoCurrent} />
              <CardImg src={VisaLogoCurrent} />
            </div>
          ),
          value: 'Card',
          isDefaultOption: cardDefault,
        },
      ]}
    />
  </RadioGroupContainer>
);

ChangePaymentRadioButtons.propTypes = {
  setPaymentMethodOption: PropTypes.func,
  applePayDefault: PropTypes.bool,
  cardDefault: PropTypes.bool,
};

ChangePaymentRadioButtons.defaultProps = {
  setPaymentMethodOption: {},
  applePayDefault: false,
  cardDefault: false,
};

export default ChangePaymentRadioButtons;
