import styled from 'styled-components';

export const Detail = styled.div`
  align-items: flex-start;
  display: flex;
  font-size: 1.125rem;
  justify-content: space-between;
  line-height: 1.75rem;
  margin-top: 1rem;
  padding-right: 1.5rem;

  &:first-of-type {
    margin-top: 0.25rem;
  }

  &:last-of-type {
    margin-bottom: 0.5rem;
  }

  ${({ theme: { media } }) =>
    media(
      '15',
      `
    font-size: 1.25rem;
  `
    )}
`;

export const DetailLabel = styled.dt`
  flex: 1 0 0;
  font-weight: 400;
`;

export const DetailsContainer = styled.dl`
  width: 100%;

  ${({ theme: { media } }) =>
    media(
      '20',
      `
    padding-left: 0;
  `
    )}
`;

export const DetailValue = styled.dd`
  display: flex;
  flex: 1 0 0;
  height: fit-content;
  margin: 0;
  padding: 0;
`;
