import React from 'react';
import Analytics, { MilestoneInputEvent } from '@experian-uk/web-common-analytics';
import Loading from '../../components/Loading';
import { withEnv, getEnv } from '../../components/Context/env';
import isServer from '../../helpers/isServer';

export const DONE_MESSAGE = '3bd6492d-e6d1-486d-8166-76d72edf3898';
export const interval = 3000; // 3 seconds
export const timeout = 180000; // 3 minutes

@withEnv
export default class ChallengeFinished extends React.Component {
  state = {
    postMessageId: null,
  };

  componentDidMount() {
    // eslint-disable-next-line func-names
    (async function () {
      if (!isServer()) {
        await Analytics.init({}, 'ProdMoveChallengeFinishedPage');
      }
    })();

    Analytics.publishOnce(
      MilestoneInputEvent.fromObject({
        product_movement_challenge_redirect: {
          interaction_status: true,
        },
      })
    );

    const host = getEnv().REACT_APP_PUBLIC_HOST;
    // Raise multiple messages for redundancy to check the postMessage is not being missed
    const postDoneMessage = setInterval(() => {
      window.parent.postMessage(DONE_MESSAGE, host);
    }, interval);
    this.setState({ postMessageId: postDoneMessage });
    setTimeout(() => clearInterval(postDoneMessage), timeout);
  }

  componentWillUnmount() {
    clearInterval(this.state.postMessageId);
  }

  render() {
    return <Loading />;
  }
}
