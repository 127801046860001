import PropTypes from 'prop-types';

export default {
  id: PropTypes.string,
  brand: PropTypes.string,
  cancellationDt: PropTypes.string,
  channel: PropTypes.string,
  customerId: PropTypes.string,
  descriptors: PropTypes.arrayOf(PropTypes.string),
  endDt: PropTypes.string,
  family: PropTypes.string,
  startDt: PropTypes.string,
  freeTrialEndDt: PropTypes.string,
};
