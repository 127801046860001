const checkClientValidVoucher = (voucherCode, env) => {
  // voucher code used in automation testing, will fail for special characters so return before check
  if (voucherCode === 'CVT-AUTOMATION-TESTING' && env !== 'prod') {
    return {
      shouldShowClientSideError: false,
      voucherErrorMessage: null,
    };
  }

  if (voucherCode.length === 0) {
    return {
      shouldShowClientSideError: true,
      voucherErrorMessage: 'Please enter your voucher code.',
      ecdFailureReason: 'empty_field',
    };
  }

  const invalidCharacters = /[^\w -]+|_/g;

  // Check here on client side if the voucher format is valid before sending off to validation service
  if (invalidCharacters.test(voucherCode)) {
    return {
      shouldShowClientSideError: true,
      voucherErrorMessage: 'Your voucher should only contain letters and numbers. Please check and try again.',
      ecdFailureReason: 'invalid_char',
    };
  }

  if (voucherCode.length < 20) {
    return {
      shouldShowClientSideError: true,
      voucherErrorMessage: 'Your voucher is too short. Please check and try again.',
      ecdFailureReason: 'too_short',
    };
  }

  if (voucherCode.length > 21) {
    return {
      shouldShowClientSideError: true,
      voucherErrorMessage: 'Your voucher is too long. Please check and try again.',
      ecdFailureReason: 'too_long',
    };
  }

  return {
    shouldShowClientSideError: false,
    voucherErrorMessage: null,
  };
};

export default checkClientValidVoucher;
