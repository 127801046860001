import React from 'react';

const ValidationErrorIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" fill="white" />
    <circle cx="12" cy="12" r="12" fill="#E4002B" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.9497 8.46443C17.3402 8.07391 17.3402 7.44074 16.9497 7.05022C16.5592 6.65969 15.926 6.65969 15.5355 7.05022L11.9999 10.5858L8.46443 7.05029C8.07391 6.65976 7.44074 6.65976 7.05022 7.05029C6.65969 7.44081 6.65969 8.07398 7.05022 8.4645L10.5857 12L7.05022 15.5355C6.65969 15.926 6.65969 16.5592 7.05022 16.9497C7.44074 17.3402 8.07391 17.3402 8.46443 16.9497L11.9999 13.4142L15.5355 16.9498C15.926 17.3403 16.5592 17.3403 16.9497 16.9498C17.3402 16.5593 17.3402 15.9261 16.9497 15.5356L13.4141 12L16.9497 8.46443Z"
      fill="white"
    />
  </svg>
);

export default ValidationErrorIcon;
