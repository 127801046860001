import { products, splitsList } from '../constants';
import { getSplitStatus } from '../reducers/selectors';

export default (split, family, selectedOfferFamily = null) => {
  const inBreachPlusSplit = getSplitStatus(split, [splitsList.breachPlus])[splitsList.breachPlus];
  const isBreachOrBreachPlus = [products.breach, products.breachPlus].includes(family);

  if (selectedOfferFamily) {
    return (
      isBreachOrBreachPlus &&
      inBreachPlusSplit &&
      [products.credit_expert, products.identity].includes(selectedOfferFamily)
    );
  }
  return isBreachOrBreachPlus && inBreachPlusSplit;
};
