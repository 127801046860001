import { errorMessages } from '../../constants';

export const SELECT_OFFER = 'SELECT_OFFER';

export default (offerId, family) => async dispatch => {
  if (!offerId || offerId === '') {
    return dispatch({
      type: SELECT_OFFER,
      error: true,
      payload: new Error(errorMessages.default),
    });
  }

  return dispatch({
    type: SELECT_OFFER,
    payload: {
      offerId,
      family,
    },
  });
};
