import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import PropTypes from 'prop-types';
import { breakpoints } from '../ModalUpsell.styles';

const ImproveScore = styled.svg`
  margin: ${rem(24)} auto ${rem(-2)};
  display: inherit;
  height: auto;
  @media only screen and (min-width: ${breakpoints.mobile}) {
    max-width: ${rem(128)};
    margin-top: ${rem(8)};
  }
`;

const CEImproveScore = ({ isInactive = false }) =>
  !isInactive ? (
    <ImproveScore xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" viewBox="0 0 192 90">
      <title>Improve your credit score</title>
      <g id="illustration">
        <g id="Group 3167">
          <g id="Group 28 Copy 2">
            <g id="Group 27">
              <g id="Group 3164">
                <rect
                  id="Rectangle"
                  width="22.2091"
                  height="46.0714"
                  rx="4"
                  transform="matrix(-1.5 0 0 1.5 152.273 20.2009)"
                  fill="#E8EDF4"
                />
                <path
                  id="Rectangle_2"
                  d="M152.273 26.2009C152.273 22.8872 149.587 20.2009 146.273 20.2009H124.96C121.646 20.2009 118.96 22.8872 118.96 26.2009V29.7696H152.273V26.2009Z"
                  fill="#8CC33F"
                />
              </g>
              <g id="Group 3163">
                <path
                  id="Rectangle Copy"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M112.657 42.1479C112.657 38.8342 109.97 36.1479 106.657 36.1479H85.343C82.0293 36.1479 79.343 38.8342 79.343 42.1479V83.3073C79.343 86.621 82.0293 89.3073 85.343 89.3073H106.657C109.97 89.3073 112.657 86.621 112.657 83.3073V42.1479Z"
                  fill="#E8EDF4"
                />
                <path
                  id="Rectangle_3"
                  d="M112.657 42.1479C112.657 38.8342 109.97 36.1479 106.657 36.1479H85.343C82.0293 36.1479 79.343 38.8342 79.343 42.1479V45.7166H112.657V42.1479Z"
                  fill="#FBDD00"
                />
              </g>
              <g id="Group 3165">
                <rect
                  id="Rectangle_4"
                  width="22.206"
                  height="59.5385"
                  rx="4"
                  transform="matrix(-1.5 0 0 1.5 191.889 0)"
                  fill="#E8EDF4"
                />
                <path
                  id="Rectangle_5"
                  d="M191.889 6C191.889 2.68629 189.203 0 185.889 0H164.576C161.262 0 158.576 2.68629 158.576 6V9.56868H191.889V6Z"
                  fill="#049D4A"
                />
              </g>
              <g id="Group 3162">
                <path
                  id="Rectangle Copy 2"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M73.0408 54.9064C73.0408 51.5927 70.3545 48.9064 67.0408 48.9064H45.727C42.4133 48.9064 39.7271 51.5927 39.7271 54.9064V83.3075C39.7271 86.6212 42.4133 89.3075 45.7271 89.3075H67.0408C70.3545 89.3075 73.0408 86.6212 73.0408 83.3075V54.9064Z"
                  fill="#E8EDF4"
                />
                <path
                  id="Rectangle_6"
                  d="M73.0408 54.9064C73.0408 51.5927 70.3545 48.9064 67.0408 48.9064H45.727C42.4133 48.9064 39.7271 51.5927 39.7271 54.9064V58.4751H73.0408V54.9064Z"
                  fill="#F79836"
                />
              </g>
              <g id="Group 3161">
                <path
                  id="Rectangle Copy 3"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M33.4241 65.5382C33.4241 62.2245 30.7378 59.5382 27.4241 59.5382H6.11035C2.79664 59.5382 0.110351 62.2245 0.110351 65.5382V83.3074C0.110351 86.6211 2.79664 89.3074 6.11035 89.3074H27.4241C30.7378 89.3074 33.4241 86.6211 33.4241 83.3074V65.5382Z"
                  fill="#E8EDF4"
                />
                <path
                  id="Rectangle_7"
                  d="M33.4241 65.5382C33.4241 62.2245 30.7378 59.5382 27.4241 59.5382H6.11035C2.79664 59.5382 0.110352 62.2245 0.110352 65.5382V69.1069H33.4241V65.5382Z"
                  fill="#BE2228"
                />
              </g>
            </g>
          </g>
          <g id="Group 3166">
            <path
              id="Fill 2"
              d="M145.353 41.9779L149.877 45.5454L166.677 58.7926L170.175 61.5508C170.633 61.9123 171.307 61.5451 171.22 60.9809L168.122 40.736L145.749 40.8686C145.144 40.8723 144.882 41.6064 145.353 41.9779Z"
              fill="#E21C77"
            />
            <path
              id="Vector 6"
              d="M70.9983 75.7172C124.869 74.4414 133.488 67.2117 150.727 45.4164L165.272 57.1114C139.832 83.752 95.5214 80.5883 70.9984 77.0811C69.7954 76.9091 69.921 75.7172 70.9983 75.7172Z"
              fill="#E21C77"
            />
          </g>
        </g>
      </g>
    </ImproveScore>
  ) : (
    <ImproveScore xmlns="http://www.w3.org/2000/svg" width="128" height="60" viewBox="0 0 128 60" fill="none">
      <rect width="22.2091" height="46.0714" rx="4" transform="matrix(-1 0 0 1 101.516 13.4673)" fill="#E8EDF4" />
      <path
        d="M101.516 17.4673C101.516 15.2581 99.7248 13.4673 97.5156 13.4673H83.3065C81.0973 13.4673 79.3065 15.2581 79.3065 17.4673V19.8464H101.516V17.4673Z"
        fill="#B3BECC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M75.1045 28.0986C75.1045 25.8895 73.3136 24.0986 71.1045 24.0986H56.8953C54.6862 24.0986 52.8953 25.8895 52.8953 28.0986V55.5382C52.8953 57.7473 54.6862 59.5382 56.8953 59.5382H71.1045C73.3136 59.5382 75.1045 57.7473 75.1045 55.5382V28.0986Z"
        fill="#E8EDF4"
      />
      <path
        d="M75.1045 28.0986C75.1045 25.8895 73.3136 24.0986 71.1045 24.0986H56.8953C54.6862 24.0986 52.8953 25.8895 52.8953 28.0986V30.4778H75.1045V28.0986Z"
        fill="#B3BECC"
      />
      <rect width="22.206" height="59.5385" rx="4" transform="matrix(-1 0 0 1 127.926 0)" fill="#E8EDF4" />
      <path
        d="M127.926 4C127.926 1.79086 126.135 0 123.926 0H109.717C107.508 0 105.717 1.79086 105.717 4V6.37912H127.926V4Z"
        fill="#B3BECC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.6938 36.6042C48.6938 34.3951 46.903 32.6042 44.6938 32.6042H30.4847C28.2756 32.6042 26.4847 34.3951 26.4847 36.6042V55.5383C26.4847 57.7475 28.2756 59.5383 30.4847 59.5383H44.6939C46.903 59.5383 48.6938 57.7475 48.6938 55.5383V36.6042Z"
        fill="#E8EDF4"
      />
      <path
        d="M48.6938 36.6042C48.6938 34.3951 46.903 32.6042 44.6938 32.6042H30.4847C28.2756 32.6042 26.4847 34.3951 26.4847 36.6042V38.9834H48.6938V36.6042Z"
        fill="#B3BECC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.2827 43.6921C22.2827 41.483 20.4919 39.6921 18.2827 39.6921H4.07356C1.86443 39.6921 0.0735683 41.483 0.0735683 43.6921V55.5383C0.0735683 57.7474 1.86443 59.5383 4.07357 59.5383H18.2827C20.4919 59.5383 22.2827 57.7474 22.2827 55.5383V43.6921Z"
        fill="#E8EDF4"
      />
      <path
        d="M22.2827 43.6921C22.2827 41.483 20.4919 39.6921 18.2827 39.6921H4.07356C1.86443 39.6921 0.0735683 41.483 0.0735683 43.6921V46.0713H22.2827V43.6921Z"
        fill="#B3BECC"
      />
      <path
        d="M96.902 27.9853L99.9181 30.3636L111.118 39.195L113.45 41.0339C113.756 41.2748 114.205 41.03 114.147 40.6539L112.081 27.1573L97.166 27.2458C96.7627 27.2482 96.5879 27.7376 96.902 27.9853Z"
        fill="#B3BECC"
      />
      <path
        d="M47.3322 50.4781C83.2459 49.6276 88.9921 44.8078 100.484 30.2776L110.181 38.0743C93.2215 55.8347 63.681 53.7256 47.3323 51.3874C46.5302 51.2727 46.614 50.4781 47.3322 50.4781Z"
        fill="#B3BECC"
      />
    </ImproveScore>
  );

CEImproveScore.defaultProps = {
  isInactive: undefined,
};

CEImproveScore.propTypes = {
  isInactive: PropTypes.bool,
};

export default CEImproveScore;
