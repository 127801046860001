import React from 'react';
import PropTypes from 'prop-types';
import { TextInputState } from '@nebula/text-input';
import { StyledTextInput } from './textInputFields.styles';

const handleState = (touched, error) => {
  if (!touched) {
    return TextInputState.Standard;
  }
  if (error) {
    return TextInputState.Error;
  }
  return TextInputState.Success;
};

const TextFieldInput = ({ automation, errorMessageProps, input, inputProps, meta: { touched, error } }) => (
  <StyledTextInput
    uniqueId={automation}
    {...input}
    {...inputProps}
    helper={touched ? errorMessageProps[error] : ''}
    state={handleState(touched, error)}
  />
);

TextFieldInput.propTypes = {
  automation: PropTypes.string.isRequired,
  errorMessageProps: PropTypes.shape({}),
  input: PropTypes.shape({}),
  inputProps: PropTypes.shape({}),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

TextFieldInput.defaultProps = {
  errorMessageProps: {},
  input: {},
  inputProps: {},
  meta: {
    touched: false,
    error: '',
  },
};

export default TextFieldInput;
