import styled from 'styled-components';
import { Button, Link, Typography } from '@experian-uk/corvetteuk-common-ui';

const { Copy } = Typography;

export const ActionDisplayContainer = styled.div``;

export const Container = styled.div`
  border: 2px solid ${props => props.theme.colors.baseMediumGrey};
  border-radius: 4px;
  box-shadow: 12px 20px 40px 0 rgba(0, 0, 0, 0.07);
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  position: relative;
  text-align: center;
  background: white;

  ${({ selected, theme }) =>
    selected &&
    `
    border: 2px solid ${theme.colors.experianLightBlue};
    box-shadow: none;
  `};

  ${({ isComplimentary, theme, userIsInFreeTrial }) =>
    !isComplimentary &&
    theme.media(
      '30',
      `
    min-height: ${userIsInFreeTrial ? '720px' : '650px'};
  `
    )}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  padding: ${({ theme }) => `0 ${theme.base.expSpacingXSmall} ${theme.base.expSpacingSmall}`};
  ${({ theme }) =>
    theme.media(
      '20',
      `
    justify-content: space-between;
    padding: ${theme.base.expSpacingXXSmall} ${theme.base.expSpacingXSmall} ${theme.base.expSpacingSmall};
  `
    )};

  ${({ isComplimentary, theme, userIsInFreeTrial, showBreachVoucherInput }) =>
    theme.media(
      '30',
      `
    ${
      showBreachVoucherInput
        ? 'height: 100%'
        : `min-height: ${isComplimentary ? '384px' : `${userIsInFreeTrial ? '530px' : '460px'}`}`
    };
  `
    )};
`;

const ActionButton = styled(Button)`
  align-self: center;
  margin: 14px 0 0 0;

  ${props =>
    props.theme.media(
      '20',
      `
    margin-top: auto;
  `
    )}
`;

ActionButton.displayName = 'ActionButton';

export { ActionButton };

const CreditBrokerText = styled(Copy.SmallText)`
  align-items: ${({ hideSwitchButton }) => (hideSwitchButton ? 'flex-end' : 'center')};
  display: flex;
  flex-direction: row;
  justify-content: center;

  sup {
    transform: translateY(-1px);
  }

  ${props =>
    props.theme.media(
      '20',
      `
    min-height: 65px;
  `
    )}
`;

CreditBrokerText.displayName = 'CreditBrokerText';

export { CreditBrokerText };

export const ReadMoreContent = styled(Copy.Text)`
  margin: ${props => props.theme.base.expSpacingSmall} 0;
`;

const FreeTrialText = styled(Copy.Text)`
  color: ${({ theme }) => theme.colors.experianMagenta};
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.63;
  margin: auto auto 1.25rem auto;
  text-align: center;
  width: 80%;
`;

FreeTrialText.displayName = 'FreeTrialText';

export { FreeTrialText };

const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding-top: 1.25rem;

  ${({ allOffersAvailable, theme }) =>
    allOffersAvailable &&
    theme.media(
      '20',
      `
    align-items: flex-end;
    flex-direction: row;
    justify-content: center;
    min-height: 140px;
  `
    )}
`;

DetailContainer.displayName = 'DetailContainer';

export { DetailContainer };

const ProductDescription = styled.div`
  ${({ allOffersAvailable, theme }) =>
    allOffersAvailable &&
    theme.media(
      '20',
      `
    min-height: 280px;
  `
    )}
`;

ProductDescription.displayName = 'ProductDescription';

export { ProductDescription };

const FreeTrialTriangle = styled.div`
  border-color: ${props => props.theme.colors.experianMagenta} transparent transparent transparent;
  border-style: solid;
  border-width: 88px 107px 0 0;
  height: 0;
  position: absolute;
  width: 0;

  ${props =>
    props.theme.media(
      '30',
      `
    border-width: 97px 116px 0 0;
  `
    )}

  span {
    color: ${props => props.theme.colors.baseWhite};
    font-size: 18px;
    font-weight: 300;
    left: 12px;
    line-height: 18px;
    position: absolute;
    top: -76px;
    transform: rotate(-42deg);

    ${props =>
      props.theme.media(
        '30',
        `
      font-size: 20px;
      left: 15px;
      line-height: 20px;
      top: -84px;
    `
      )}
  }
`;

FreeTrialTriangle.displayName = 'FreeTrialTriangle';

export { FreeTrialTriangle };

export const PriceContainer = styled.div`
  padding-top: 1.5rem;

  ${({ theme }) =>
    theme.media(
      '20',
      `
    min-height: 50px;
  `
    )}

  ${({ shouldPadBottom, theme }) =>
    shouldPadBottom &&
    theme.media(
      '30',
      `
    padding-bottom: 72px;
  `
    )}
`;

export const ReadMoreButton = styled(Link.Button)`
  font-size: 1rem;
  &:focus {
    text-decoration: underline;
  }
`;

const UpgradeBlockedExplanation = styled(Copy.Text)`
  color: ${props => props.theme.colors.experianLightBlue};
  font-size: ${props => props.theme.typography.smallerFontSize};
  font-weight: 400;
  line-height: 1.63;
  margin: auto;
  width: 217px;

  ${({ theme }) =>
    theme.media(
      '20',
      `
    font-size: ${theme.typography.smallFontSize};
  `
    )};
`;

UpgradeBlockedExplanation.displayName = 'UpgradeBlockedExplanation';

export { UpgradeBlockedExplanation };

export const EndDate = styled(Typography.Copy.SmallText)`
  display: block;
  margin: 0;
`;

export const ComplimentarySubscriptionText = styled(Typography.Copy.Text)`
  margin-bottom: 0;
`;
