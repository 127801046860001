import React from 'react';
import { setAutomationElement } from '@experian-uk/corvetteuk-common-ui';
import PropTypes from 'prop-types';

import { ActionButton } from '../../ProductPanels/FreeProduct/FreeProductPanel.styles';
import { getEnv } from '../../Context/env';

const outcomePropTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
};

const outcomeDefaultProps = {
  type: '',
  label: '',
  onClick: () => {},
};

const StandardJourneyAction = ({ type, label, onClick }) => (
  <ActionButton {...setAutomationElement('panel-button')} type={type} label={label} onClick={onClick} />
);

StandardJourneyAction.propTypes = outcomePropTypes;
StandardJourneyAction.defaultProps = outcomeDefaultProps;

const CancelActionJourney = ({ type, label }) => (
  <ActionButton
    {...setAutomationElement('panel-button')}
    href={`${getEnv().REACT_APP_INS_URL}/account/close`}
    type={type}
    label={label}
  />
);

CancelActionJourney.propTypes = outcomePropTypes;
CancelActionJourney.defaultProps = outcomeDefaultProps;

export { StandardJourneyAction, CancelActionJourney };
