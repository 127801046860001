import styled from 'styled-components';
import { Link, Typography } from '@experian-uk/corvetteuk-common-ui';
import Details from '../../components/Details';

export const Heading = styled(Typography.Headings.Primary)`
  text-align: center;

  ${props =>
    props.theme.media(
      '20',
      `
    text-align: left;
  `
    )}
`;

export const CopyText = styled(Typography.Copy.Text)`
  text-align: center;

  ${props =>
    props.theme.media(
      '20',
      `
    text-align: left;
  `
    )}
`;

export const BodyContent = styled.div`
  margin-bottom: 20px;
  padding: 0 1.4375rem;
`;

export const DetailsContainer = styled(Details)`
  margin-top: ${props => props.theme.base.expSpacingSmall};
  padding: 0.5rem 1.5rem;

  ${({ theme: { media } }) =>
    media(
      '20',
      `
      width: 65%;
  `
    )}
`;

export const ButtonContainer = styled.div`
  text-align: center;
  ${({ theme: { media } }) =>
    media(
      '10',
      `
      text-align: right;
  `
    )}
`;

export const FreeButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme: { media } }) =>
    media(
      '10',
      `
      flex-direction: row;
      justify-content: center;
  `
    )}

  ${({ theme: { media } }) =>
    media(
      '20',
      `
      justify-content: left;
  `
    )}
`;

export const InlineLink = styled(Link.Anchor)`
  font-size: inherit;
`;

export const Reminder = styled.div`
  margin: 2.5rem 0;
  padding: 1rem;
  max-width: 50rem;
  background-color: #f8f7ff;
  border: 1px solid #cbc6fc;
  border-radius: 0.75rem;
  color: #6f6f6d;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5rem;
  ${({ theme: { media } }) =>
    media(
      '10',
      `
      font-size: 1rem;
  `
    )}
  ${({ theme: { media } }) =>
    media(
      '20',
      `
      padding: 1rem 1.625rem;
      font-size: 1.25rem;
      line-height: 2.5rem;
  `
    )}
`;

export const ReminderList = styled.ul`
  margin: 0.625rem 0 0;
  padding-left: 1.625rem;
  word-break: break-word;
  ${({ theme: { media } }) =>
    media(
      '20',
      `
      margin: 0;
      padding: revert;
      list-style: initial;
      line-height: 2rem;
  `
    )}
`;
