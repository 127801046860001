import styled from 'styled-components';
import { rem } from 'polished';
import { AlertContainer, AlertMessage } from '../Alerts.styles';

export const BreachBanner = styled(AlertContainer)`
  background: #f8f7ff;
  border: 1px solid #cbc6fc;
  padding: ${rem(24)} ${rem(32)};
`;

export const DeferredMessage = styled(AlertMessage)`
  font-size: ${rem(14)};
  font-weight: 400;
  line-height: ${rem(24)};
`;

export const DeactivationLink = styled.a`
  color: inherit;
`;

export const DeferredHeading = styled.h2`
  font-size: ${rem(21)};
  font-weight: 600;
  line-height: ${rem(28)};
  margin: 0;
`;
