import { Overlay } from '@nebula/overlay';
import nebulaTheme from '@nebula/theme';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import { GuaranteePoint, GuaranteeTitle, GuaranteeList } from './index.styles';

const overlayPoints = [
  {
    key: 'guarantee-point-1',
    text: 'This Guarantee is offered by all banks and building societies that accept instructions to pay Direct Debits.',
  },
  {
    key: 'guarantee-point-2',
    text: 'If there are any changes to the amount, date or interval of your Direct Debit, goCardless will notify you 3 working days in advance of your account being debited or as otherwise agreed. If you request GoCardless to collect a payment, confirmation of the amount and date will be given to you at the time of the request.',
  },
  {
    key: 'guarantee-point-3',
    text: 'If an error is made in the payment of your Direct Debit, by GoCardless your bank or building society, you are entitled to a full and immediate refund of the amount paid from your bank or building society - If you receive a refund you are not entitled to, you must pay it back when GoCardless asks you to.',
  },
  {
    key: 'guarantee-point-4',
    text: 'You can cancel a Direct Debit at any time by simply contacting your bank or building society. Written confirmation may be required. Please also notify us.',
  },
];

const DirectDebitOverlay = ({ isShowing, toggleHide }) => (
  <ThemeProvider theme={nebulaTheme}>
    <Overlay
      isShowing={isShowing}
      showDefaultCloseBtn
      toggleHide={toggleHide}
      theme={nebulaTheme}
      zIndex={10}
      testId="ddoverlay"
      ariaLabelledby="direct-debit-guarantee"
    >
      <GuaranteeTitle id="direct-debit-guarantee">The Direct Debit Guarantee</GuaranteeTitle>
      <GuaranteeList>
        {overlayPoints.map(point => (
          <GuaranteePoint key={point.key}>{point.text}</GuaranteePoint>
        ))}
      </GuaranteeList>
    </Overlay>
  </ThemeProvider>
);

DirectDebitOverlay.propTypes = {
  isShowing: PropTypes.bool.isRequired,
  toggleHide: PropTypes.func.isRequired,
};

export default DirectDebitOverlay;
