import { LOCATION_CHANGE } from 'connected-react-router';

import { FULFILL_SUBSCRIPTION, SUBSCRIPTION_FULFILLED } from '../actions/fulfillments/fulfill';

const defaultState = {
  loading: false,
  newJourney: true,
};

export default (state = defaultState, action = {}) => {
  if (action.error) {
    return {
      ...state,
      loading: false,
    };
  }

  switch (action.type) {
    case FULFILL_SUBSCRIPTION:
      return {
        ...state,
        loading: true,
      };
    case SUBSCRIPTION_FULFILLED:
      return {
        ...action.payload,
        loading: false,
        newJourney: false,
      };
    case LOCATION_CHANGE:
      return {
        loading: false,
        newJourney: action.payload.location.pathname === '/switch',
      };
    default:
      return state;
  }
};
