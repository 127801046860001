import React from 'react';
import PropTypes from 'prop-types';

import { setAutomationElement } from '@experian-uk/corvetteuk-common-ui';
import { ContactUsContainer, CustomerNumber, StyledButton } from './contactUs.styles';
import { getEnv } from '../Context/env';

const ContactUs = ({ customerNumber }) => (
  <ContactUsContainer>
    {customerNumber !== '' && (
      <div>
        <div>Customer Number</div>
        <CustomerNumber {...setAutomationElement('customerNumber')} data-hook="fs-mask-element">
          {customerNumber}
        </CustomerNumber>
      </div>
    )}
    <StyledButton
      type="secondary"
      label="Contact us"
      onClick={() => {}}
      href={`${getEnv().REACT_APP_INS_URL}/contact`}
      {...setAutomationElement('contactUs')}
    />
  </ContactUsContainer>
);

ContactUs.propTypes = {
  customerNumber: PropTypes.string.isRequired,
};
export default ContactUs;
