import fetch from '../fetch';

export const FETCH_CREDIT_SUMMARY = 'FETCH_CREDIT_SUMMARY';
export const CREDIT_SUMMARY_FETCHED = 'CREDIT_SUMMARY_FETCHED';

const getCreditSummary = () => async dispatch => {
  dispatch({ type: FETCH_CREDIT_SUMMARY });

  const fetched = {
    type: CREDIT_SUMMARY_FETCHED,
  };

  try {
    const creditSummaryData = await dispatch(fetch('/creditsummary'));

    fetched.payload = creditSummaryData;
  } catch (error) {
    fetched.error = true;
  }

  dispatch(fetched);
};

export default getCreditSummary;
