import styled from 'styled-components';
import { Footer, Link, Typography } from '@experian-uk/corvetteuk-common-ui';

export const StyledFooter = styled(Footer)`
  ${({ theme }) =>
    theme.media(
      '30',
      `
    max-width: ${theme.base.largeContainerWidth};
    margin: 0 auto;
  `
    )}
`;

export const SmallText = styled(Typography.Copy.SmallText)`
  color: ${({ theme }) => theme.colors.experianDarkGrey};
  display: block;
  font-weight: 300;
  font-size: 0.875rem;
  margin: 1.25rem 0;

  &:last-of-type {
    margin-bottom: 0;
  }

  ${({ theme }) =>
    theme.media(
      '10',
      `
    font-size: 0.875rem;
  `
    )};
`;

export const Bolder = styled.span`
  font-weight: 400;
`;

export const BolderLink = styled(Link.Anchor)`
  font-weight: 400;
`;
