import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import get from 'lodash/get';
import { Typography, setAutomationElement } from '@experian-uk/corvetteuk-common-ui';
import { products, familyName, dateFormats, benefitsDocument } from '../../constants';

import { isFreeTrial, mapFamilyToProduct } from '../../helpers/subscription';

import { Container, InlineLinkAnchor, StyledParagraph, Title } from './termsConditions.styles';
import { EnvConsumer } from '../../components/Context/env';
import offerProps from '../../propTypes/offer';
import { isObjectEmpty } from '../../helpers';

const { Copy } = Typography;

const TermsConditions = ({
  selectedOffer,
  currentSubscription,
  isComplimentaryOffer,
  isUpgrade,
  nextBillingDate,
  proRata,
  voucherCode,
}) => {
  if (!selectedOffer || isObjectEmpty(selectedOffer)) {
    return null;
  }

  const nextBilling = moment(nextBillingDate).format(dateFormats.short);
  const selectedOfferPrice = get(selectedOffer, 'terms[0].price', null);
  const productFactSheetKey = `${selectedOffer.family}${isComplimentaryOffer ? '_complimentary' : ''}`;
  const productFactSheetLink = `${benefitsDocument.baseUrl}/${benefitsDocument[productFactSheetKey]}`;
  const isFreeTrialOffer = selectedOffer.descriptors && isFreeTrial(selectedOffer.descriptors);
  return (
    <Container isComplimentaryOffer={isComplimentaryOffer}>
      <Title>Terms & Conditions</Title>
      <StyledParagraph {...setAutomationElement('termsConditions')}>
        {voucherCode && (
          <Copy.SmallText {...setAutomationElement('voucherCodeActivation')}>
            Once we have confirmed your voucher code your complimentary subscription will start immediately. If for any
            reason you are not satisfied, you are free to cancel your subscription by contacting our customer service
            team.
          </Copy.SmallText>
        )}
        {!voucherCode && isComplimentaryOffer && (
          <Copy.SmallText {...setAutomationElement('complimentarySubscriptionTerms')}>
            You will retain access to your complimentary {familyName[mapFamilyToProduct(selectedOffer.family)]}{' '}
            subscription for the remainder of your complimentary period. When this expires your subscription will cancel
            automatically.
          </Copy.SmallText>
        )}
        {!isComplimentaryOffer && (
          <Fragment>
            {currentSubscription === products.credit_expert && selectedOffer.family === products.identity && (
              <Copy.SmallText {...setAutomationElement('billingTermsDowngrade')}>
                Retain access to your Experian {familyName[currentSubscription]} account until the {nextBilling}, then
                your selected card will be debited with the monthly subscription fee of &pound;{selectedOfferPrice} and
                every 30 days thereafter.
              </Copy.SmallText>
            )}
            {currentSubscription === products.basic && (
              <Copy.SmallText {...setAutomationElement('billingTermsUpgradeFromFree')}>
                To gain immediate access to your Experian {familyName[selectedOffer.family]} account your selected card
                will be debited
                {isFreeTrialOffer ? ' next month' : ' today'} with the monthly subscription fee of &pound;
                {selectedOfferPrice} and monthly thereafter.
              </Copy.SmallText>
            )}
            {currentSubscription === products.identity &&
              selectedOffer.family === products.credit_expert &&
              !isFreeTrialOffer && (
                <Copy.SmallText {...setAutomationElement('billingTermsUpgradeFromIdentity')}>
                  To gain immediate access to your Experian {familyName.credit_expert} account your selected card will
                  be debited today with &pound;{proRata} then the monthly subscription fee of &pound;
                  {selectedOfferPrice} will be debited on the {nextBilling} and monthly thereafter. If we can&rsquo;t
                  add {familyName.credit_expert} to your subscription, your initial payment will be recalculated when
                  your {familyName.credit_expert} subscription is activated. The amount you may pay could vary depending
                  on how long it takes to set up your subscription. You will not pay more than the amount shown unless
                  the set up of your new subscription occurs after your next billing day.
                </Copy.SmallText>
              )}
            <Copy.SmallText>
              {' '}
              If for any reason you are not satisfied, you are free to cancel your subscription by contacting our
              customer service team.
            </Copy.SmallText>
          </Fragment>
        )}
      </StyledParagraph>
      <StyledParagraph>
        <EnvConsumer>
          {env => (
            <Copy.SmallText>
              It is important you understand the terms of our contract with you, which are in the&nbsp;
              <InlineLinkAnchor href={`${env.REACT_APP_EXPERIAN_URL}/consumer/terms.html`}>
                Experian Terms &amp; Conditions{' '}
              </InlineLinkAnchor>
              and{' '}
              <InlineLinkAnchor href={`${env.REACT_APP_EXPERIAN_URL}/consumer/privacy.html`}>
                Privacy Policy.
              </InlineLinkAnchor>
            </Copy.SmallText>
          )}
        </EnvConsumer>
      </StyledParagraph>
      <StyledParagraph>
        <Copy.SmallText>
          For your own benefit and protection, you should read these documents before proceeding and contact us if you
          have any questions, because they apply to your use of this service. If for any reason you are not satisfied,
          you are free to cancel your subscription by contacting our customer service team.
        </Copy.SmallText>
      </StyledParagraph>
      {(isFreeTrialOffer || (isComplimentaryOffer && isUpgrade)) && (
        <StyledParagraph {...setAutomationElement('coolingOffTerms')}>
          <EnvConsumer>
            {env => (
              <Copy.SmallText>
                By continuing you accept the&nbsp;
                <InlineLinkAnchor href={`${env.REACT_APP_EXPERIAN_URL}/consumer/terms.html`}>
                  Experian Terms &amp; Conditions
                </InlineLinkAnchor>{' '}
                and&nbsp;
                <InlineLinkAnchor href={`${env.REACT_APP_EXPERIAN_URL}/consumer/privacy.html`}>
                  Privacy Policy
                </InlineLinkAnchor>{' '}
                AND request that Experian provide the service immediately (within 14 day cooling off period).
              </Copy.SmallText>
            )}
          </EnvConsumer>
        </StyledParagraph>
      )}
      <StyledParagraph>
        {isComplimentaryOffer && isUpgrade ? (
          <Copy.SmallText>
            To find out more about your features and rights, you can&nbsp;
            <InlineLinkAnchor
              {...setAutomationElement('complimentaryUpgradeProductFactSheetLink')}
              href={productFactSheetLink}
            >
              download more information
            </InlineLinkAnchor>{' '}
            about your {familyName[mapFamilyToProduct(selectedOffer.family)]} subscription.
          </Copy.SmallText>
        ) : (
          <Copy.SmallText>
            {!isComplimentaryOffer && 'You will also continue to get free access to our comparison services. '}To find
            out more about your features and rights, you can&nbsp;
            <InlineLinkAnchor {...setAutomationElement('productFactSheetLink')} href={productFactSheetLink}>
              download more information
            </InlineLinkAnchor>{' '}
            about your {voucherCode && 'complimentary'} {familyName[selectedOffer.family]} subscription.
          </Copy.SmallText>
        )}
      </StyledParagraph>
    </Container>
  );
};

TermsConditions.defaultProps = {
  currentSubscription: null,
  isComplimentaryOffer: false,
  isUpgrade: false,
  nextBillingDate: null,
  proRata: null,
  voucherCode: null,
};

TermsConditions.propTypes = {
  selectedOffer: offerProps.isRequired,
  currentSubscription: PropTypes.string,
  isComplimentaryOffer: PropTypes.bool,
  isUpgrade: PropTypes.bool,
  nextBillingDate: PropTypes.string,
  proRata: PropTypes.string,
  voucherCode: PropTypes.string,
};

export default TermsConditions;
