import fetch from '../fetch';
import { errorMessages } from '../../constants';

export const FETCH_CUSTOMER = 'FETCH_CUSTOMER';
export const CUSTOMER_FETCHED = 'CUSTOMER_FETCHED';
export const SPLIT_KEY_FETCHED = 'SPLIT_KEY_FETCHED';

export default () => async dispatch => {
  dispatch({ type: FETCH_CUSTOMER });

  const fetched = {
    type: CUSTOMER_FETCHED,
  };

  try {
    const customer = await dispatch(fetch('/customers'));

    fetched.payload = { ...customer.data[0] };

    dispatch({
      type: SPLIT_KEY_FETCHED,
      payload: fetched.payload.customerNumber,
    });
  } catch (error) {
    const errorMessage = {
      heading: errorMessages.someDataMissing,
      messages: [errorMessages.technicalIssues],
    };

    fetched.error = true;
    fetched.payload = new Error(JSON.stringify(errorMessage));
  }

  return dispatch(fetched);
};
