import MastercardLogo from './mastercard.svg';
import VisaLogo from './visa.svg';
import MastercardLogoCurrent from './currentCard/mastercard.svg';
import VisaLogoCurrent from './currentCard/visa.svg';

const Mastercard = [MastercardLogo, MastercardLogoCurrent];
const Visa = [VisaLogo, VisaLogoCurrent];

const cardLogos = {
  mastercard: Mastercard,
  mc: Mastercard,
  visa: Visa,
  vi: Visa,
};

export default {
  cardLogos: {
    ...cardLogos,
  },
};
