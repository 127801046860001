import React from 'react';

const ValidationPassIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="12" fill="#059D4C" />
    <path
      d="M18.1565 7.875L9.58512 16.125L6.90649 12.0907"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ValidationPassIcon;
