import PropTypes from 'prop-types';

export const termsProps = PropTypes.arrayOf(
  PropTypes.shape({
    price: PropTypes.number,
  })
);

export default PropTypes.shape({
  family: PropTypes.string,
  terms: termsProps,
  descriptors: PropTypes.arrayOf(PropTypes.oneOf(['free', 'free_trial', 'paid', 'annual'])),
});
