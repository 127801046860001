import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setAutomationElement } from '@experian-uk/corvetteuk-common-ui';

import uiChangeState from '../../actions/uiChangeState';

import RecycleContainer from './RecycleContainer';
import { DDCopy } from './index.styles';
import { Container, ActionButton } from './CurrentCard.styles';
import { DetailsContainer, DetailLabel, Detail, DetailValue } from '../Details/index.styles';
import { selectPage } from '../../reducers/uiState';

@connect(
  state => ({
    showCardForm: selectPage(state.uiState, window.location.pathname).showCardForm,
  }),
  dispatch => bindActionCreators({ uiChangeState }, dispatch)
)
export default class CurrentDDPay extends React.Component {
  static propTypes = {
    accountNumber: PropTypes.string,
    uiChangeState: PropTypes.func.isRequired,
    showCardForm: PropTypes.bool,
    isRecycle: PropTypes.bool,
  };

  static defaultProps = {
    accountNumber: null,
    showCardForm: false,
    isRecycle: false,
  };

  handleClick = e => {
    e.preventDefault();
    this.props.uiChangeState(window.location.pathname, { showCardForm: true });
  };

  render() {
    const { accountNumber, isRecycle } = this.props;
    return (
      <Container isError={isRecycle}>
        <DetailsContainer>
          <Detail>
            <DetailLabel>Payment type:</DetailLabel>
            <DetailValue>Direct debit</DetailValue>
          </Detail>
          <Detail>
            <DetailLabel>Debit details:</DetailLabel>
            <DetailValue {...setAutomationElement('subDDtype')}>{accountNumber}</DetailValue>
          </Detail>
        </DetailsContainer>
        <DDCopy>Payments powered by GoCardless.</DDCopy>
        {isRecycle && <RecycleContainer />}
        <ActionButton
          {...setAutomationElement('subUseDiffCard')}
          type="secondary"
          isButton
          onClick={this.handleClick}
          label="Change payment method"
          disabled={this.props.showCardForm}
        />
      </Container>
    );
  }
}
