import React from 'react';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';

import CvvIconSVG from './card-security.svg';

export const CVV = styled(props => <SVG alt="card security code" src={CvvIconSVG} {...props} />)`
  height: 33px;
  width: 27px;

  .cls-1 {
    stop-color: #8dc2ea;
  }
  .cls-2 {
    stop-color: #2c84c6;
  }

  .cls-3 {
    stop-color: #414142;
  }

  .cls-4 {
    stop-color: #363436;
  }

  .cls-5 {
    stop-color: #2e2b2c;
  }

  .cls-6 {
    stop-color: #272425;
  }

  .cls-7 {
    stop-color: #242021;
  }

  .cls-8 {
    stop-color: #231f20;
  }

  .cls-9 {
    stop-color: #ffffff;
  }

  .cls-10 {
    stop-color: #fcfcfc;
  }

  .cls-11 {
    stop-color: #f3f3f4;
  }

  .cls-12 {
    stop-color: #e6e7e9;
  }
`;
