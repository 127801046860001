import React from 'react';
import PropTypes from 'prop-types';
import { setAutomationElement } from '@experian-uk/corvetteuk-common-ui';
import CreditLockIcon from './CreditLockIcon.svg';
import {
  Container,
  ContentContainer,
  IconContainer,
  IconImage,
  MobileTitleContainer,
  TextContainer,
  TitleContainer,
  Title,
  MobileTitle,
  Description,
  DescriptionMobileSeparator,
  DescriptionIDPlus,
  IdentityLink,
} from './CreditLockDownsellBanner.styles';

const CreditLockDownsellBanner = ({ linkToIdentitySwitch, identityPrice }) => (
  <Container {...setAutomationElement('creditLockDownsellBanner')}>
    <ContentContainer>
      <IconContainer>
        <IconImage src={CreditLockIcon} alt="Credit Lock" />
        <MobileTitleContainer>
          <MobileTitle>You won’t have identity fraud protection if you cancel</MobileTitle>
        </MobileTitleContainer>
      </IconContainer>
      <TextContainer>
        <TitleContainer>
          <Title>You won’t have identity fraud protection if you cancel</Title>
        </TitleContainer>
        <Description>
          When your subscription comes to an end, you&#39;ll no longer be able to lock your Experian Credit Report. This
          helps stop fraudsters taking out credit in your name.{' '}
          <DescriptionMobileSeparator>
            But you can still lock your credit report and remain protected with{' '}
            <DescriptionIDPlus>IdentityPlus</DescriptionIDPlus>.
          </DescriptionMobileSeparator>
        </Description>
        <IdentityLink onClick={linkToIdentitySwitch} data-automation-test-element="downsell-link-button" type="button">
          Switch to IdentityPlus for £{identityPrice} per month
        </IdentityLink>
      </TextContainer>
    </ContentContainer>
  </Container>
);

CreditLockDownsellBanner.propTypes = {
  linkToIdentitySwitch: PropTypes.func.isRequired,
  identityPrice: PropTypes.number.isRequired,
};

export default CreditLockDownsellBanner;
