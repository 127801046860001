import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import PropTypes from 'prop-types';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import nebulaTheme from '@nebula/theme';
import StepByStep from '@nebula/step-by-step';
import { dateFormats, familyName, products } from '../../../constants';
import descriptors from '../../../constants/descriptors';

dayjs.extend(advancedFormat);

const DeferredBreachStepper = ({
  billingEndDate,
  deferredProduct,
  deferredProductBillDate,
  deferredProductPrice,
  deferredFreeTrialEligibility,
}) => {
  const isEligibleForTrial = deferredFreeTrialEligibility.includes(descriptors.freeTrial);
  const isCreditExpertUpsell = deferredProduct === products.credit_expert;

  const formattedEndDate = dayjs(billingEndDate).format(dateFormats.long);
  const formattedBillDate = dayjs(deferredProductBillDate).format(dateFormats.long);
  const billDateOfMonth = dayjs(deferredProductBillDate).format('Do');
  const firstBillMonth = dayjs(deferredProductBillDate).format('MMMM');

  const freeTrialStep = `Your free trial of ${
    isCreditExpertUpsell ? 'CreditExpert' : 'Identity Plus with complimentary CreditExpert features'
  } starts on ${formattedEndDate}.`;
  const initialStepArray = isEligibleForTrial ? [freeTrialStep] : [];

  const steps = [
    ...initialStepArray,
    `Your ${familyName[deferredProduct]} subscription starts on ${formattedBillDate}.`,
    `You will be billed £${deferredProductPrice} on the ${billDateOfMonth} of every month from ${firstBillMonth}.`,
  ];
  const stepsWithKeys = steps.map((title, index) => ({ title, key: `step-${index}` }));

  return (
    <ThemeProvider theme={nebulaTheme}>
      <StepByStep steps={stepsWithKeys} />
    </ThemeProvider>
  );
};

DeferredBreachStepper.propTypes = {
  billingEndDate: PropTypes.string.isRequired,
  deferredFreeTrialEligibility: PropTypes.arrayOf(PropTypes.string).isRequired,
  deferredProduct: PropTypes.string.isRequired,
  deferredProductBillDate: PropTypes.string.isRequired,
  deferredProductPrice: PropTypes.number.isRequired,
};

export default DeferredBreachStepper;
