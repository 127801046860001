import fetch from '../fetch';
import { defined } from '../../helpers/defined';

export const UPSELL_TRACKING_ATTEMPT = 'UPSELL_TRACKING_ATTEMPT';
export const UPSELL_TRACKING_SUCCESS = 'UPSELL_TRACKING_SUCCESS';
export const UPSELL_TRACKING_FAILURE = 'UPSELL_TRACKING_FAILURE';

export const endpoint = '/upsellstarttracker/track';
export const errorMessage = `Could not fetch ${endpoint}`;

export default (targetFamily, isEligibleForFreeTrial) => async dispatch => {
  dispatch({ type: UPSELL_TRACKING_ATTEMPT });
  const fetched = {};
  fetched.meta = { critical: false };

  if (!defined('string', targetFamily)) {
    fetched.error = true;
    fetched.payload = 'Target family must be supplied';
    fetched.type = UPSELL_TRACKING_FAILURE;
    return dispatch(fetched);
  }

  if (typeof isEligibleForFreeTrial !== 'boolean') {
    fetched.error = true;
    fetched.payload = 'Trial status must be supplied';
    fetched.type = UPSELL_TRACKING_FAILURE;
    return dispatch(fetched);
  }

  const request = {
    method: 'POST',
    body: {
      client: 'web',
      journey: 'prodmove',
      targetFamily,
      isEligibleForFreeTrial,
    },
  };

  try {
    await dispatch(fetch(endpoint, request));
    fetched.type = UPSELL_TRACKING_SUCCESS;
    fetched.error = false;
    fetched.payload = null;
  } catch (error) {
    fetched.error = true;
    fetched.payload = errorMessage;
    fetched.type = UPSELL_TRACKING_FAILURE;
  }
  return dispatch(fetched);
};
