import React from 'react';
import PropTypes from 'prop-types';
import { setAutomationElement } from '@experian-uk/corvetteuk-common-ui';
import { Detail, DetailLabel, DetailsContainer, DetailValue } from './index.styles';

const Details = ({ data, className }) => (
  <DetailsContainer className={className}>
    {data.map(
      item =>
        item.content && (
          <Detail key={item.id}>
            <DetailLabel>
              {item.label}
              {item.label && ':'}
            </DetailLabel>
            <DetailValue {...setAutomationElement(item.id)}>{item.content}</DetailValue>
          </Detail>
        )
    )}
  </DetailsContainer>
);

Details.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  className: PropTypes.string,
};

Details.defaultProps = {
  className: '',
};

export default Details;
