import styled from 'styled-components';

export const OptionsCardHeader = styled.div`
  position: relative;
  margin-bottom: 24px;

  hr {
    width: 80%;
    height: 0px;
    border: 1px solid #99a8bb;
    margin: 25px 23px 13px 23px;
  }
`;

export const OptionsCardHeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    display: block;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 1.938rem;
    line-height: 140%;
    color: #515c63;
    margin-bottom: 0px;
    margin-top: 18px;
    max-width: 250px;
    text-align: center;
    word-break: break-word;
    text-wrap: balance;
  }
  b {
    font-weight: 700;
  }
`;

export const HeaderNumber = styled.div`
  position: absolute;
  border-radius: 6px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;

  width: 51px;
  height: 56px;
  left: 0px;
  top: 0px;

  background: #f2f4f7;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  line-height: 40px;
  color: #1d4f91;
`;

export const HeaderMessageContainer = styled.div`
  border-radius: 6px;
  grid-area: header;
  background: #f2f4f7;
  width: 100%;
  color: #1d4f91;
  margin-top: 0;
  height: fit-content;
  line-height: 40px;
  padding: 10px 0 10px 23px;
  font-size: 20px;
  font-weight: 400;

  @media only screen and (max-width: 1200px) {
    font-size: 24px;
    line-height: 30px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ActionButtonMessage = styled.h3`
  color: #1d4f91;
  font-weight: 500;
  font-size: 0.875rem;
  text-align: center;
  width: 80%;
`;

export const UpgradeBlockedExplanation = styled(ActionButtonMessage)`
  max-width: 280px;
  margin: 1rem auto 2.75rem auto;
`;

export const UnableToSwitchErrorMessage = styled(ActionButtonMessage)`
  color: #e4002b;
`;

export const ActionButton = styled.button`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
  margin-bottom: 48px;
  padding: 16px;

  cursor: pointer;

  width: 12.875rem;
  height: 3rem;
  max-width: 334px;

  background: #ffffff;

  border: 1px solid #1d4f91;
  border-radius: 6px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #1d4f91;

  &:hover,
  &:focus,
  &:active {
    box-shadow: 0 0 0 4px #ddd;
  }

  &:disabled {
    opacity: 0.25;
    cursor: not-allowed;
    box-shadow: none;
  }
`;
