import { products } from '../../constants';

const getPriceSummary = billingInfo => {
  const { billingFrequency, price } = billingInfo;
  let billingPeriod = '';

  switch (billingFrequency?.toLowerCase()) {
    case 'monthly':
      billingPeriod = 'per month';
      break;
    case 'annually':
    case 'yearly':
      billingPeriod = 'per year';
      break;
    default:
  }

  const priceSummary = billingPeriod && price ? `£${price} ${billingPeriod}` : '';

  return priceSummary;
};

const getOfferPriceSummaries = offers => {
  const identityOffer = offers.find(offer => offer.family === products.identity);
  const creditExpertOffer = offers.find(offer => offer.family === products.credit_expert);

  const identityPriceSummary = identityOffer?.terms && getPriceSummary(identityOffer.terms[0]);
  const creditExpertPriceSummary = creditExpertOffer?.terms && getPriceSummary(creditExpertOffer.terms[0]);

  return {
    identityPriceSummary,
    creditExpertPriceSummary,
  };
};

export default getOfferPriceSummaries;
