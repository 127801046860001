import React from 'react';
import PropTypes from 'prop-types';
import { setAutomationElement } from '@experian-uk/corvetteuk-common-ui';

import {
  VoucherHeader,
  VoucherField,
  VoucherWrapper,
  VoucherCopy,
  VoucherErrorMessage,
} from './BreachVoucherInput.styles';

const BreachVoucherInput = ({
  breachVoucherFieldChange,
  voucherErrorMessage,
  shouldShowClientSideError,
  apiError,
  shouldShowApiError,
  normalize,
  onSubmit,
  disableInput,
}) => (
  <form method="post" onSubmit={onSubmit}>
    <VoucherWrapper>
      <VoucherHeader>Enter a voucher</VoucherHeader>
      <VoucherCopy>
        If you have received a voucher code for a complimentary Identity Plus subscription please enter it here:
      </VoucherCopy>
      <div>
        <VoucherField
          name="voucherCode"
          component="input"
          type="text"
          onChange={breachVoucherFieldChange}
          normalize={normalize}
          {...setAutomationElement('breach-voucher-input-field')}
          $breachVoucherError={shouldShowClientSideError || shouldShowApiError}
          props={{ disabled: disableInput }}
        />
      </div>
      {(shouldShowClientSideError || shouldShowApiError) && (
        <VoucherErrorMessage {...setAutomationElement('breach-voucher-error-message')}>
          {voucherErrorMessage || apiError}
        </VoucherErrorMessage>
      )}
    </VoucherWrapper>
  </form>
);

BreachVoucherInput.propTypes = {
  breachVoucherFieldChange: PropTypes.func,
  shouldShowClientSideError: PropTypes.bool,
  voucherErrorMessage: PropTypes.string,
  apiError: PropTypes.string,
  shouldShowApiError: PropTypes.bool,
  normalize: PropTypes.func,
  onSubmit: PropTypes.func,
  disableInput: PropTypes.bool,
};

BreachVoucherInput.defaultProps = {
  breachVoucherFieldChange: null,
  shouldShowClientSideError: false,
  voucherErrorMessage: null,
  apiError: null,
  shouldShowApiError: false,
  normalize: null,
  onSubmit: null,
  disableInput: false,
};

export default BreachVoucherInput;
