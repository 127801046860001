import styled from 'styled-components';

const TextInput = styled.input.attrs({
  type: 'text',
})`
  border: 0.0625rem solid ${({ theme }) => theme.colors.borderBlue};
  border-radius: 0.3125rem;
  font-size: 1rem;
  height: 50px;
  padding: 0.75rem;
  transition: 0.5s ease-in-out;
  width: 90px;
`;

TextInput.displayName = 'TextInput';

export { TextInput };
