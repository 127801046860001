import React from 'react';
import PropTypes from 'prop-types';
import Wrapper from './components/Wrapper';

const UpsellVariantD = ({ testExperienceData }) => <Wrapper {...testExperienceData} variant="D" />;

UpsellVariantD.propTypes = {
  testExperienceData: PropTypes.shape({}).isRequired,
};

export default UpsellVariantD;
