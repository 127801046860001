import styled from 'styled-components';
import { rem } from 'polished';
import { OptionsCardHeaderContent, ActionButton, OptionsCardHeader } from '../productOptionsCards.styles';
import { DownloadIcon } from '../../Icons/DownloadIcon';
import {
  CardContent,
  ProductFactSheet,
  CurrentSubscriptionHeader,
  CardBenefits,
  CardContainer,
} from '../productPanel.styles';
import { CreditBrokerText } from '../FreeProduct/FreeProductPanel.styles';
import { StepperContainer } from '../../BreachStepper/index.styles';

export const BreachContainer = styled(CardContainer)`
  ${({ isCurrentProduct }) => (isCurrentProduct ? 'border: 2px solid #406eb3' : 'border: 2px solid #E2E4E7')};
  border-radius: 4px;
  ${({ isCurrentProduct }) => (!isCurrentProduct ? `box-shadow: 0px 2px 4px #CBC9C9` : null)};
  height: 100%;
`;

export const BreachOptionsCardHeader = styled(OptionsCardHeader)`
  ${({ showSmallerCard }) => (showSmallerCard ? 'height: 100%;' : '')};
`;

export const BreachCardHeaderContent = styled(OptionsCardHeaderContent)`
  h3 {
    font-weight: 300;
    line-height: ${rem(36)};

    @media only screen and (min-width: 1200px) {
      font-size: ${rem(31)};
    }

    margin-top: ${({ isBreachPlus }) => (isBreachPlus ? '0' : `${rem(48)}`)};
    max-width: fit-content;
  }

  p {
    margin-top: ${rem(24)};
    margin-bottom: ${rem(8)};
    line-height: ${rem(21)};
  }
`;

export const BreachCardContent = styled(CardContent)`
  display: block;
  padding: 0 ${rem(24)};
  width: auto;
  margin-bottom: ${rem(72)};

  ul {
    padding-left: ${rem(24)};
    margin-top: ${rem(8)};
    @media only screen and (min-width) {
      padding-left ${rem(40)};
    }
  }

  li {
    line-height: ${rem(30)};
    font-size: ${rem(18)};
    @media only screen and (min-width: 1200px) {
      font-size: ${rem(20)};
    }
  }
`;

export const BreachCardBenefits = styled(CardBenefits)`
  margin: 1.5rem 0 0 1.5rem;

  h3 {
    font-size: ${rem(20)};
  }

  li {
    font-size: ${rem(16)};
    line-height: ${rem(28)};
  }

  span {
    padding-left: ${rem(8)};
  }
`;

export const BreachProductFactSheet = styled(ProductFactSheet)`
  justify-content: center;
  border-radius: 0 0 1px 1px;

  a {
    line-height: ${rem(26)};
  }
`;

export const BreachCurrentSubscriptionHeader = styled(CurrentSubscriptionHeader)`
  padding: 1.5rem 0 0 0;
  border-radius: 1px 1px 0 0;
  background: linear-gradient(#26478d, #406eb3);
  margin-bottom: 0;

  h2 {
    font-weight: 300;
    font-size: ${rem(24)};
    line-height: ${rem(28)};
    @media only screen and (min-width: 1200px) {
      line-height: ${rem(36)};
      font-size: ${rem(31)};
    }
  }

  b {
    font-weight: 400;
  }

  p {
    font-size: ${rem(18)};
    @media only screen and (min-width: 1200px) {
      font-size: ${rem(20)};
    }
  }
`;

export const BreachExpiryBanner = styled.div`
  background-color: #f8f8f9;
  color: #2a5595;
  padding: ${rem(24)};

  p {
    margin: 0 0 ${rem(9)};
  }

  b {
    font-weight: 500;
  }
`;

export const BreachActionButton = styled(ActionButton)`
  margin-bottom: ${rem(36)};
  background: ${({ isBreach }) => (isBreach ? '#406EB3' : '#ffffff')};
  color: ${({ isBreach }) => (isBreach ? '#ffffff' : '#426DA9')};
  ${({ isBreach }) => (isBreach ? 'border: none;' : '')}

  @media only screen and (min-width: 1200px) {
    margin-bottom: ${rem(36)};
    width: ${rem(304)};
    font-size: ${rem(20)};
    line-height: ${rem(24)};
  }
`;

export const BreachCreditBrokerText = styled(CreditBrokerText)`
  line-height: ${rem(20)};
  margin-bottom: ${rem(20)};

  @media only screen and (min-width: 900px) {
    min-height: auto;
  }

  @media only screen and (min-width: 1200px) {
    line-height: ${rem(27)};
  }
`;

export const BreachDownloadIcon = styled(DownloadIcon)`
  height: ${rem(43)};
  width: ${rem(41)};
`;

export const DeferredStepperContainer = styled(StepperContainer)`
  margin: 0 ${rem(24)};
  text-align: left;
`;

export const Break = styled.div`
  border-bottom: 1px solid #99a8bb;
  width: 80%;
  margin: auto;
`;

export const DeferredCopy = styled.p`
  color: #384553;
  line-height: ${rem(28)};
  padding-bottom: ${rem(8)};
  margin: 0;
`;

export const DeferredTitle = styled.h2`
  padding-bottom: ${rem(16)};
  font-size: ${rem(31)};
  font-weight: 400;
  line-height: ${rem(43)};
  margin: 0;
`;

export const DeferredContainer = styled.div`
  text-align: center;
  margin-top: ${rem(24)};
`;

export const StepperTitle = styled.h2`
  color: #002556;
  font-weight: 400;
  line-height: ${rem(28)};
  padding: ${rem(24)} 0 ${rem(32)} 0;
  margin: 0;
  font-size: ${rem(20)};
`;
