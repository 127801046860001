import styled from 'styled-components';

export const RadioGroupContainer = styled.div`
  fieldset {
    padding-left: 0;
  }
`;

export const CardImg = styled.img`
  height: 2rem;
  width: 3.25rem;
  padding: 0;
  margin: 0;
  margin-right: 1.5rem;
`;
