export default {
  fraudReport: 'Experian Fraud Report',
  emailAlerts: 'Fraud email alerts',
  webMonitoring: 'Web monitoring',
  phoneSupport: 'UK-based support to discuss your report and score',
  fraudSupport: 'Enhanced fraud support',
  dailyScore: 'Daily Experian Credit Score',
  dailyReport: 'Daily Experian Credit Report',
  influencers: 'Score influencers',
  creditAlerts: 'Credit alerts',
  allAlerts: 'Credit and fraud alerts',
  monthlyScore: 'Experian Credit Score updated every 30 days if you log in.',
  monthlyScore2: 'Monthly Experian Credit Score',
  productComparisonService:
    'See you eligibility ratings before you apply when you search for credit cards and personal loans',
  eligibilityRatings:
    'See your eligibility ratings before you apply when you search for credit cards and personal loans',
  brokerService: "Find credit card, mortgages and loan offers (we're a broker not a lender)",
  eligibilityUpdates: 'Get updates about your eligibility for credit cards and personal loans',
  ukBasedSupport: 'UK-based support to discuss your report and score',
  ukBasedSubscriptionSupport: 'UK-based support to discuss your subscription',
  simulator: 'Score Simulator',
  boost: 'The chance to boost your score by securely connecting your current account to your Experian account',
  dailyScoreUpdate: 'Daily score updates',
  personalisedTools: 'Personalised tools to improve your score',
  ScoreUpdateAlerts: 'Update alerts for your score and report',
  FraudAlert: 'Fraud alerts and enhanced fraud support',
  creditReport: 'Access to your daily Experian Credit Report',
  customerSupport: '1-2-1 expert customer support',
};
