import { rem } from 'polished';
import styled from 'styled-components';

export const CheckboxContainer = styled.label`
  position: relative;
  padding: 0 0 0 ${rem(45)};
  margin: 0 0 1.5rem 0;
  cursor: pointer;
  color: #575757;
  font-size: ${rem(14)};
  line-height: ${rem(20)};
  input {
    height: 0;
  }
  input:checked ~ .checkmark {
    background-color: #1d4f91;
  }
  input:checked ~ .checkmark:after {
    display: block;
    height: ${rem(17)};
    width: ${rem(7.5)};
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: ${rem(30)};
    width: ${rem(30)};
    background-color: #ffffff;
    border: 1px solid #a5b9d3;
    border-radius: ${rem(5)};
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
    left: ${rem(9)};
    top: ${rem(3)};
    width: ${rem(5)};
    height: ${rem(10)};
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`;
