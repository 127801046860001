import React from 'react';
import moment from 'moment';
import { setAutomationElement } from '@experian-uk/corvetteuk-common-ui';
import Privacy from '../../components/Privacy';
import { InfoText } from '../../components/Privacy/index.styles';

import {
  Hr,
  GoCardlessFragment,
  WrapperContainer,
  Background,
  MainContainer,
  MidlineBreak,
  HeaderContainer,
  InfoLink,
} from './index.styles';

import ConfirmDDCard from '../../components/ConfirmDDCard';

const mockedState = {
  directDebitDetails: {
    accountHolderName: 'John Smith',
    accountNumber: '55779911',
    sortCode: '200000',
  },
};
const goCardlessPrivacyLink = 'https://gocardless.com/privacy/';

export default () => {
  const firstPaymentDate = moment().add(30, 'days').format('Do MMM YYYY');
  return (
    <main role="main">
      <WrapperContainer>
        <Background />
        <HeaderContainer>
          <h1 {...setAutomationElement('ddConfirmHeader')}>
            <MidlineBreak>Try CreditExpert,&nbsp;</MidlineBreak>
            <MidlineBreak>
              <span>free for 30 days.</span>
            </MidlineBreak>
          </h1>
          <p>Then £14.99/month from {firstPaymentDate}</p>
        </HeaderContainer>
        <MainContainer>
          <ConfirmDDCard directDebitDetails={mockedState.directDebitDetails} />
          <Privacy />
          <Hr />
          <GoCardlessFragment>
            <InfoText>Payments by GoCardless.</InfoText>
            <InfoLink href={goCardlessPrivacyLink} target="_blank" {...setAutomationElement('goCardlessPrivacyLink')}>
              GoCardless Privacy Policy
            </InfoLink>
          </GoCardlessFragment>
        </MainContainer>
      </WrapperContainer>
    </main>
  );
};
