import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';

import { currency, formatDate, dateInTheFuture } from '../helpers/formatting';

const UpsellFormHeader = styled.header`
  padding: ${rem('20px')};
  @media (min-width: 700px) {
    margin: 0;
  }

  border-radius: 0.25rem 0.25rem 0 0;
  border-bottom: 1px solid #e6e6e6;
  color: ${({ primary }) => (primary ? '#fff' : '#575756')};
  background: linear-gradient(to right, #406eb3, #26478d);

  p:last-of-type {
    margin-bottom: 0;
  }
  p {
    margin-bottom: 0.75rem;
    margin-top: 0;
    font-size: 15px;
    font-weight: 400;
    text-align: left;
    letter-spacing: 0.5px;
    line-height: 1.5rem;
  }

  h2 {
    margin: 0 0 1rem 0;
    font-size: 20px;
    font-weight: 100;
    line-height: 1.2;
    color: ${({ primary }) => (primary ? '#fff' : '#575756')};
    text-rendering: optimizeLegibility;
    text-align: left;
  }
`;

const Header = ({ offerFreeTrial, price, trialDuration }) => (
  <UpsellFormHeader primary>
    {offerFreeTrial && (
      <Fragment>
        <h2 data-automation-test-element="upsell-title">Try CreditExpert, free for 30 days.</h2>
        <p data-automation-test-element="upsell-subtitle">
          Then {currency(price)}/month from {formatDate(dateInTheFuture(trialDuration))}.
        </p>
        <p>Cancel anytime, online or by phone.</p>
      </Fragment>
    )}
    {!offerFreeTrial && (
      <Fragment>
        <h2 data-automation-test-element="upsell-title">Reach your goals with CreditExpert.</h2>
        <p data-automation-test-element="upsell-subtitle">
          <strong>Only {currency(price)}/month. Cancel anytime, online or by phone.</strong>
        </p>
      </Fragment>
    )}
  </UpsellFormHeader>
);

Header.propTypes = {
  offerFreeTrial: PropTypes.bool.isRequired,
  price: PropTypes.number.isRequired,
  trialDuration: PropTypes.number,
};

Header.defaultProps = {
  trialDuration: undefined,
};

export default Header;
