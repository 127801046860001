import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';

import CvvNumberField from './formFields/CvvNumberField';

const CvvForm = ({ setCvv }) => <CvvNumberField setCvv={setCvv} dataAutomationTestElement="cvv-saved-card-form" />;

CvvForm.propTypes = {
  setCvv: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'productMovement/savedCard',
  onSubmit: () => {},
})(CvvForm);
