import React, { useEffect } from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { breakpoints } from '../ModalUpsell.styles';
import getCreditSummary from '../../../actions/creditSummary/getCreditSummary';

const Box = styled.div`
  background: ${props => (props.isInactive ? '#d5dbe3' : '#af1685')};
  color: #fff;
  border-radius: ${rem(4)};
  text-align: center;
  font-size: ${rem(12)};
  line-height: ${rem(14)};
  font-weight: 500;
  padding: ${rem(14)} ${rem(6)};
  position: relative;
  margin: ${rem(18)} auto 0;
  width: 100%;
  box-shadow: 0 22px 3px -8px #fff, 0 24px 5px -9px #ddd;

  @media (min-width: 364px) {
    box-shadow: 0 22px 3px -8px #fff, 0 24px 5px -9px #ddd, 0 42px 0 -12px #fff, 0 42px 7px -13px #ddd;
  }

  @media only screen and (min-width: ${breakpoints.smallMobile}) {
    padding: ${rem(15)} ${rem(10)};
    width: fit-content;
    line-height: ${rem(12)};
  }

  @media only screen and (min-width: ${breakpoints.mobile}) {
    font-size: ${rem(10)};
    box-shadow: 0 ${rem(22)} ${rem(3)} -8px #fff, 0 24px 5px -9px #ddd;
  }
`;

const CECreditReport = ({ isInactive }) => {
  const dispatch = useDispatch();

  const creditSummary = useSelector(state => state?.creditSummary);

  useEffect(() => {
    if (creditSummary && !creditSummary?.data && !creditSummary?.fetching && !creditSummary?.error) {
      dispatch(getCreditSummary());
    }
  }, [dispatch, creditSummary]);

  const data = creditSummary?.data;
  const activeAccount = (data?.cards?.count ?? 0) + (data?.loans?.count ?? 0) + (data?.mortgages?.count ?? 0) || 0;

  return <Box isInactive={isInactive}>Active accounts: ({activeAccount})</Box>;
};

CECreditReport.defaultProps = {
  isInactive: undefined,
};

CECreditReport.propTypes = {
  isInactive: PropTypes.bool,
};

export default CECreditReport;
