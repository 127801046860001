import fetch from '../fetch';
import { errorMessages } from '../../constants';
import initializeEcd from '../ecd/initialize';

export const FETCH_OFFER = 'FETCH_OFFER';
export const OFFER_FETCHED = 'OFFER_FETCHED';

export default async (dispatch, offerId) => {
  dispatch({ type: FETCH_OFFER });

  const fetched = {
    type: OFFER_FETCHED,
  };

  try {
    const offer = await dispatch(fetch(`/offers/${offerId}`));
    fetched.payload = { currentOffer: offer.data[0] };
  } catch (error) {
    const errorMessage = {
      messages: [errorMessages.default],
    };

    fetched.error = true;
    fetched.payload = new Error(JSON.stringify(errorMessage));
  }

  dispatch(fetched);
  return dispatch(initializeEcd());
};
