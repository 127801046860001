import React from 'react';

const PadlockIcon = () => (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.52361 0C9.25367 0 10.6585 1.48343 10.6585 3.31659V5.31273H12.256C13.04 5.31273 13.678 5.98653 13.678 6.82091V13.4916C13.678 14.3261 13.04 15 12.256 15H2.79706C2.01302 15 1.375 14.3261 1.375 13.4916V6.82091C1.375 5.98653 2.01307 5.31273 2.79706 5.31273H4.392V3.31659C4.392 1.48461 5.79499 0.0015758 7.52361 0ZM2.22348 6.82091C2.22348 6.49158 2.47894 6.22182 2.79706 6.22182H12.256C12.5741 6.22182 12.8295 6.49158 12.8295 6.82091V13.4916C12.8295 13.8211 12.5741 14.0909 12.256 14.0909H2.79706C2.47892 14.0909 2.22348 13.8211 2.22348 13.4916V6.82091ZM5.24048 3.31659V5.31H9.81V3.31659C9.81 1.98855 8.78791 0.909194 7.52415 0.909091C6.26124 0.910345 5.24048 1.98939 5.24048 3.31659ZM7.52382 8.44677C8.08912 8.44677 8.54731 8.93155 8.54731 9.52927C8.54731 9.93836 8.33031 10.2902 8.01361 10.474V11.514C8.01361 11.7865 7.80488 12.0077 7.54715 12.0077C7.28943 12.0077 7.08049 11.7865 7.08049 11.514V10.5009C6.73834 10.3256 6.50055 9.95836 6.50055 9.52927C6.50055 8.93155 6.95873 8.44677 7.52382 8.44677Z"
      fill="#1D4F91"
    />
  </svg>
);

export default PadlockIcon;
