export default state => {
  if (!(state.subscriptions?.current && state.offers?.currentOffer)) {
    return undefined;
  }

  const [currentTerm] = state.offers.currentOffer.terms || [];

  if (!currentTerm) {
    return 0;
  }

  return currentTerm.price;
};
