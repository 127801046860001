import React from 'react';
import { products } from '../../constants';
import { customPropValidators, getCustomPropValidator } from '../../helpers';
import { SubscriptionText, EmphasisedText } from './index.styles';

const copy = {
  basic: 'Free',
  breach: 'Breach',
  breach_plus: 'Breach Plus',
  credit_expert: {
    intro: 'Credit',
    emphasis: 'Expert',
  },
  identity: {
    intro: 'Identity ',
    emphasis: 'Plus',
  },
};

const StyledSubscription = ({ product }) =>
  copy[product] && copy[product].emphasis ? (
    <SubscriptionText>
      {copy[product].intro}
      <EmphasisedText>{copy[product].emphasis}</EmphasisedText>
    </SubscriptionText>
  ) : (
    <SubscriptionText>{copy[product]}</SubscriptionText>
  );

const validProduct = getCustomPropValidator({
  validator: customPropValidators.includesCaseInsensitive,
  permittedValues: Object.values(products),
});

StyledSubscription.propTypes = {
  product: validProduct.isRequired,
};

export default StyledSubscription;
