import { defined } from '../../helpers/defined';

export const ERROR_THROWN = 'ERROR_THROWN';

export default error => async dispatch => {
  if (!defined('object', error) || !error.messages || !Array.isArray(error.messages)) {
    return new Promise(resolve => {
      resolve();
    });
  }

  return dispatch({
    error: true,
    payload: new Error(JSON.stringify(error)),
    type: ERROR_THROWN,
  });
};
