import { getAvailableOffers } from '../../../reducers/offers';
import { products } from '../../../constants';
import { isFreeTrial } from '../../../helpers/subscription';

export default state => {
  if (!state.offers.data) {
    return undefined;
  }

  const availableOffers = getAvailableOffers(state.offers, state.subscriptions);

  const ceOffer = availableOffers.find(offer => offer.family === products.credit_expert);

  if (!ceOffer || !ceOffer.descriptors) {
    return false;
  }

  return isFreeTrial(ceOffer.descriptors);
};
