import styled from 'styled-components';
import { Typography } from '@experian-uk/corvetteuk-common-ui';

const { Headings } = Typography;

export const DeactivateAccountContainer = styled.div`
  text-align: center;
  padding: 3rem 0;

  ${({ theme: { media } }) =>
    media(
      '20',
      `
    text-align: left;
  `
    )};
`;

export const Heading = styled(Headings.Secondary)`
  color: ${props => props.theme.colors.experianLightBlue};
`;

export const ReportReminder = styled.div`
  background-color: ${props => props.theme.color.ui.blue50};
  padding: 2rem 1.5rem;
  border-radius: 1rem;
  margin-bottom: 1rem;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: ${props => props.theme.color.ui.blueGrey800};
`;

export const StatReportLink = styled.a`
  color: ${props => props.theme.color.ui.blue500};
  text-decoration: none;
`;
