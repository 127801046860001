import { cta, errorMessages } from '../../constants';
import { getDynamicString } from '../../helpers';

export const fulfillmentErrorMessages = {
  default: {
    heading: getDynamicString('changeFailure.intro', ['subscription']),
    messages: [getDynamicString('changeFailure.default', ['subscription'])],
    buttonPath: '/',
    buttonText: `${cta.goBack} ${cta.subscriptionsPage}`,
  },
  complimentary: {
    heading: errorMessages.oopsHeading,
    messages: [errorMessages.oopsMessage],
  },
};
