import CEAlert from './assets/CEAlert';
import CECreditFuture from './assets/CECreditFuture';
import CEExpertSupport from './assets/CEExpertSupport';
import CECreditReport from './assets/CECreditReport';
import CEScoreUpdates from './assets/CEScoreUpdates';
import CEScoreInfluencers from './assets/CEScoreInfluencers';
import CEImproveScore from './assets/CEImproveScore';

export const firstTabFeatures = [
  {
    title: 'Daily Experian Credit Report',
    content: CECreditReport,
  },
  {
    title: 'Daily credit score updates',
    content: CEScoreUpdates,
  },
  {
    title: 'Credit report alerts',
    content: CEAlert,
  },
  {
    title: 'Credit score influencers',
    content: CEScoreInfluencers,
  },
  {
    title: 'Manage your credit future',
    content: CECreditFuture,
  },
  {
    title: 'Our expert support',
    content: CEExpertSupport,
  },
];

export const secondTabFeatures = [
  {
    title: 'Your full Experian Credit Report',
    content: CECreditReport,
    isInactive: true,
  },
  {
    title: 'Daily credit score updates',
    content: CEScoreUpdates,
    isInactive: true,
  },
  {
    title: 'Credit report alerts',
    content: CEAlert,
    isInactive: true,
  },
  {
    title: 'Credit score influencers',
    content: CEScoreInfluencers,
    isInactive: true,
  },
  {
    title: 'Manage your credit future',
    content: CECreditFuture,
    isInactive: true,
  },
  {
    title: 'Our expert support',
    content: CEExpertSupport,
    isInactive: true,
  },
];

export const firstTabFeaturesForTest = [
  {
    title: 'Credit score influencers',
    content: CEScoreInfluencers,
  },
  {
    title: 'Improve your credit score',
    content: CEImproveScore,
  },
  {
    title: 'Credit report alerts',
    content: CEAlert,
  },
  {
    title: 'Daily credit score updates',
    content: CEScoreUpdates,
  },
  {
    title: 'Manage your credit future',
    content: CECreditFuture,
  },
  {
    title: 'Our expert support',
    content: CEExpertSupport,
  },
];

export const secondTabFeaturesForTest = [
  {
    title: 'Credit score influencers',
    content: CEScoreInfluencers,
    isInactive: true,
  },
  {
    title: 'Improve your credit score',
    content: CEImproveScore,
    isInactive: true,
  },
  {
    title: 'Credit report alerts',
    content: CEAlert,
    isInactive: true,
  },
  {
    title: 'Daily credit score updates',
    content: CEScoreUpdates,
    isInactive: true,
  },
  {
    title: 'Manage your credit future',
    content: CECreditFuture,
    isInactive: true,
  },
  {
    title: 'Our expert support',
    content: CEExpertSupport,
    isInactive: true,
  },
];
