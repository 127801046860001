import styled from 'styled-components';
import { Typography } from '@experian-uk/corvetteuk-common-ui';
import { DetailsContainer, Detail } from '../../Details/index.styles';

const { Copy } = Typography;

export const CardDetailsContainer = styled.div`
  display: block;

  ${({ theme: { media } }) =>
    media(
      '15',
      `
    max-width: 370px;
  `
    )}

  ${({ theme: { media } }) =>
    media(
      '10',
      `
    padding-left: 2.2rem;
  `
    )}

  ${({ theme: { media } }) =>
    media(
      '30',
      `
    padding-left: 3.1rem;
  `
    )}
`;

export const CardDetails = styled.dl`
  align-content: center;
  display: flex;
  font-size: ${({ theme }) => theme.typography.smallFontSize};
  line-height: 3rem;
  padding: 0;

  dt {
    font-weight: 500;
    font-size: ${({ theme }) => theme.typography.smallFontSize};
    flex: 1;
  }

  dd {
    flex: 1;
    font-size: ${({ theme }) => theme.typography.smallFontSize};
    margin: 0;
    padding: 0;

    ${({ theme: { media } }) =>
      media(
        '20',
        `
            flex: 2
        `
      )}
  }
`;

export const CVVLabel = styled(Copy.Text)`
  font-size: ${({ theme }) => theme.typography.smallFontSize};

  ${({ theme }) =>
    theme.media(
      '10',
      `
    font-size: ${theme.typography.smallFontSize};
  `
    )}
`;

export const CardDetailsList = styled(DetailsContainer)`
  padding-left: 0;
`;

export const CardDetail = styled(Detail)`
  font-size: ${({ theme }) => theme.typography.smallFontSize};

  ${({ theme }) =>
    theme.media(
      '10',
      `
    font-size: ${theme.typography.smallFontSize};
  `
    )}
`;
