import { getFreeTrialData } from './subscription';

const getFormattedFreeTrialData = (currentSubscription, freeTrialTerms) => {
  let freeTrialData = {};
  if (currentSubscription?.freeTrial) {
    const { freeTrialEndDt } = currentSubscription;
    freeTrialData = freeTrialTerms && freeTrialEndDt ? getFreeTrialData(freeTrialTerms, freeTrialEndDt) : {};
  }
  return freeTrialData;
};

export default getFormattedFreeTrialData;
