import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CaretIcon from '../assets/icon-caret-up.svg';

const FIELD_HEIGHT = '40px';

const StyledSelectWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledSelectBox = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  height: ${FIELD_HEIGHT};
  &:after {
    content: url(${CaretIcon});
    position: absolute;
    right: 15px;
    top: 60%;
    transform: translate(0, -50%) rotate(180deg);
    width: 14px;
    z-index: 4;
    pointer-events: none;
  }
`;

const StyledSelect = styled.select`
  appearance: none;
  padding: 10px 8px;
  width: 100%;
  max-width: 100%;
  min-height: 100%;
  background-color: white;
  border: 1px solid #a5b9d3;
  border-radius: 5px;
  box-shadow: inset 0 2px 4px #a0a0a01a;
  font-size: 16px;
  font-weight: 200;
  color: #63666a;

  ${({ failing }) =>
    failing &&
    `
    border: 1px solid #ec0000;
    box-shadow: 0 0 0 4px #e4002b33;
  `}
`;

const FormSelectValidationWrapper = ({
  dataAutomationTestElement,
  inputProps,
  input,
  meta: { touched, error },
  children,
}) => {
  const failing = touched && error;

  return (
    <StyledSelectWrapper>
      <StyledSelectBox>
        <StyledSelect
          data-automation-test-element={dataAutomationTestElement}
          {...input}
          {...inputProps}
          failing={failing}
        >
          {children}
        </StyledSelect>
      </StyledSelectBox>
    </StyledSelectWrapper>
  );
};

FormSelectValidationWrapper.propTypes = {
  dataAutomationTestElement: PropTypes.string.isRequired,
  inputProps: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number, PropTypes.func])
  ),
  input: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.func])).isRequired,
  setDisplayError: PropTypes.func,
  meta: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number, PropTypes.func]))
    .isRequired,
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
};

FormSelectValidationWrapper.defaultProps = {
  inputProps: undefined,
  setDisplayError: undefined,
};

export default FormSelectValidationWrapper;
