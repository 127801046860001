import { type as UI_CHANGE_STATE } from '../actions/uiChangeState';

const defaultState = {};

export default (state = defaultState, action = {}) => {
  if (action.error) {
    return state;
  }

  switch (action.type) {
    case UI_CHANGE_STATE:
      return {
        [action.meta.page]: {
          ...state[action.meta.page],
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

export const selectPage = (state, page) => {
  if (!(page in state)) {
    return {};
  }

  return state[page];
};
