import React from 'react';
import { useSelector } from 'react-redux';
import eligibleForCeTrial from '../../actions/ecd/resolvers/eligibleForCeTrial';
import { products } from '../../constants';
import BlueTick from '../ProductPanels/blueTick';
import tooltipForBenefit from '../ProductPanels/tooltipForBenefit';

const useOfferBenefits = offers => {
  const isEligibleForTrial = useSelector(state => eligibleForCeTrial(state));

  const benefitItems = offers.map(offer => {
    const { family } = offer;
    let benefits = null;

    switch (family) {
      case products.credit_expert:
        benefits = [
          isEligibleForTrial && <b>Free trial for 30 days</b>,
          'Understand what influences your score',
          'Tailored tips to build your score',
          'Score Simulator',
        ];
        break;
      case products.identity:
        benefits = [
          isEligibleForTrial && <b>Free trial for 30 days</b>,
          'CreditLock',
          'Your Experian Fraud Report',
          'Web monitoring',
        ];
        break;
      case products.basic:
        benefits = ['Monthly Experian Credit Score', 'Boost your score', 'Compare credit cards, loans & more'];
        break;
      default:
    }

    return (
      benefits && (
        <ul>
          {benefits.filter(Boolean).map(benefit => (
            <li key={benefit}>
              <BlueTick />
              {benefit} {tooltipForBenefit(benefit)}
            </li>
          ))}
        </ul>
      )
    );
  });

  const filteredBenefitItems = benefitItems.filter(Boolean);

  const [creditExpertBenefits, identityBenefits, freeBenefits] = filteredBenefitItems;

  return {
    creditExpertBenefits,
    identityBenefits,
    freeBenefits,
  };
};

export default useOfferBenefits;
