import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { setAutomationElement } from '@experian-uk/corvetteuk-common-ui';
import { CardDetail, CardDetailsContainer, CardDetailsList, CVVLabel } from './index.styles';
import { CardLogo } from '../../Icons/CardLogo';
import { CVVInput } from '../../Inputs';
import { getCardDescription, getCardLogo } from '../../../helpers/billing';
import { cardDetailsSchema } from '../../../schemas';
import { DetailLabel, DetailValue } from '../../Details/index.styles';

@reduxForm({
  form: 'productMovement/savedCard',
  initialValues: {
    newCardCVV: '',
  },
  onSubmit: () => {},
})
export default class SavedCard extends React.Component {
  static propTypes = {
    ...cardDetailsSchema,
    showCvv: PropTypes.bool,
  };

  static defaultProps = {
    showCvv: true,
  };

  render() {
    const { cardNumberEnding, creditCardType, expirationMonth, expirationYear, setCvv, showCvv } = this.props;
    const cardLogo = getCardLogo(creditCardType);
    const cardDescription = getCardDescription(creditCardType);

    return (
      <CardDetailsContainer {...setAutomationElement('savedCardForm')} data-hook="fs-exclude-element">
        <CardDetailsList>
          <CardDetail>
            <DetailLabel>Card type</DetailLabel>
            <DetailValue>
              {cardLogo && <CardLogo src={cardLogo} alt={cardDescription} />}
              <span {...setAutomationElement('cardType')}>{cardDescription}</span>
            </DetailValue>
          </CardDetail>
          <CardDetail>
            <DetailLabel>Card number</DetailLabel>
            <DetailValue {...setAutomationElement('cardNumber')}>Ending {cardNumberEnding}</DetailValue>
          </CardDetail>
          <CardDetail>
            <DetailLabel>Expiry date</DetailLabel>
            <DetailValue {...setAutomationElement('expiryDate')}>
              {expirationMonth} / {expirationYear}
            </DetailValue>
          </CardDetail>
        </CardDetailsList>
        {showCvv && (
          <React.Fragment>
            <CVVLabel htmlFor="savedCardCVV">CVV number</CVVLabel>
            <CVVInput inputName="savedCardCVV" setCvv={setCvv} automation="savedCardCVV" />
          </React.Fragment>
        )}
      </CardDetailsContainer>
    );
  }
}
