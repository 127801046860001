import { SET_CURRENT_USER } from '../actions/auth/setCurrentUser';
import { CUSTOMER_FETCHED } from '../actions/auth/getCustomer';

const initialState = {
  isAuthenticated: false,
  token: null,
  currentUser: {},
  error: false,
  auth: null,
};

export default (state = initialState, action = {}) => {
  if (action.error) {
    return {
      ...state,
      error: [CUSTOMER_FETCHED, SET_CURRENT_USER].includes(action.type),
    };
  }
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: action.payload.authenticated,
        token: action.payload.token,
        sessionId: action.payload.sessionId,
        mockData: 'mockData' in action.payload,
      };
    case CUSTOMER_FETCHED:
      return {
        ...state,
        currentUser: action.payload,
      };
    default:
      return state;
  }
};
